export default {
    icons: function () {
        return [
            {
                "name": "user",
                "icon": "mdi-account"
            },
            {
                "name": "user-box",
                "icon": "mdi-account-box"
            },
            {
                "name": "map",
                "icon": "mdi-map"
            },
            {
                "name": "place",
                "icon": "mdi-marker"
            },
            {
                "name": "alarm",
                "icon": "mdi-alarm"
            },
            {
                "name": "archive",
                "icon": "mdi-archive"
            },
            {
                "name": "badge",
                "icon": "mdi-badge-account-horizontal"
            },
            {
                "name": "book",
                "icon": "mdi-book"
            },
            {
                "name": "book-open",
                "icon": "mdi-book-open-variant"
            },
            {
                "name": "calendar",
                "icon": "mdi-calendar"
            },
            {
                "name": "edit_calendar",
                "icon": "mdi-calendar-edit"
            },
            {
                "name": "camera",
                "icon": "mdi-camera"
            },
            {
                "name": "car",
                "icon": "mdi-car"
            },
            {
                "name": "bar_chart",
                "icon": "mdi-chart-bar"
            },
            {
                "name": "box_chart",
                "icon": "mdi-chart-box"
            },
            {
                "name": "line_chart",
                "icon": "mdi-chart-line-variant"
            },
            {
                "name": "timeline",
                "icon": "mdi-chart-timeline-variant"
            },
            {
                "name": "clock",
                "icon": "mdi-clock-time-eight"
            },
            {
                "name": "cloud",
                "icon": "mdi-cloud"
            },
            {
                "name": "settings",
                "icon": "mdi-cog"
            },
            {
                "name": "credit_card",
                "icon": "mdi-credit-card"
            },
            {
                "name": "gps_fixed",
                "icon": "mdi-crosshairs-gps"
            },
            {
                "name": "folder",
                "icon": "mdi-folder"
            },
            {
                "name": "folder_open",
                "icon": "mdi-folder-open"
            },
            {
                "name": "label",
                "icon": "mdi-label"
            },
            {
                "name": "lightbulb",
                "icon": "mdi-lightbulb"
            },
            {
                "name": "wrench",
                "icon": "mdi-wrench"
            },
            {
                "name": "persons",
                "icon": "mdi-account-supervisor"
            },
            {
                "name": "list",
                "icon": "mdi-clipboard-list"
            },
            {
                "name": "briefcase",
                "icon": "mdi-briefcase"
            },
            {
                "name": "cart",
                "icon": "mdi-cart"
            },
            {
                "name": "store",
                "icon": "mdi-store"
            },
            {
                "name": "printer",
                "icon": "mdi-printer"
            },
            {
                "name": "account-search",
                "icon": "mdi-account-search"
            },
            {
                "name": "sun",
                "icon": "mdi-white-balance-sunny"
            },
            {
                "name": "air",
                "icon": "mdi-weather-windy"
            },
            {
                "name": "cloud_sync",
                "icon": "mdi-cloud-sync"
            },
            {
                "name": "sync",
                "icon": "mdi-sync-circle"
            },
            {
                "name": "human-female",
                "icon": "mdi-human-female"
            },
            {
                "name": "abacus",
                "icon": "mdi-abacus"
            },
            {
                "name": "abjad-arabic",
                "icon": "mdi-abjad-arabic"
            },
            {
                "name": "abjad-hebrew",
                "icon": "mdi-abjad-hebrew"
            },
            {
                "name": "abugida-devanagari",
                "icon": "mdi-abugida-devanagari"
            },
            {
                "name": "abugida-thai",
                "icon": "mdi-abugida-thai"
            },
            {
                "name": "access-point",
                "icon": "mdi-access-point"
            },
            {
                "name": "access-point-check",
                "icon": "mdi-access-point-check"
            },
            {
                "name": "access-point-minus",
                "icon": "mdi-access-point-minus"
            },
            {
                "name": "access-point-network",
                "icon": "mdi-access-point-network"
            },
            {
                "name": "access-point-network-off",
                "icon": "mdi-access-point-network-off"
            },
            {
                "name": "access-point-off",
                "icon": "mdi-access-point-off"
            },
            {
                "name": "access-point-plus",
                "icon": "mdi-access-point-plus"
            },
            {
                "name": "access-point-remove",
                "icon": "mdi-access-point-remove"
            },
            {
                "name": "account",
                "icon": "mdi-account"
            },
            {
                "name": "account-alert",
                "icon": "mdi-account-alert"
            },
            {
                "name": "account-alert-outline",
                "icon": "mdi-account-alert-outline"
            },
            {
                "name": "account-arrow-down",
                "icon": "mdi-account-arrow-down"
            },
            {
                "name": "account-arrow-down-outline",
                "icon": "mdi-account-arrow-down-outline"
            },
            {
                "name": "account-arrow-left",
                "icon": "mdi-account-arrow-left"
            },
            {
                "name": "account-arrow-left-outline",
                "icon": "mdi-account-arrow-left-outline"
            },
            {
                "name": "account-arrow-right",
                "icon": "mdi-account-arrow-right"
            },
            {
                "name": "account-arrow-right-outline",
                "icon": "mdi-account-arrow-right-outline"
            },
            {
                "name": "account-arrow-up",
                "icon": "mdi-account-arrow-up"
            },
            {
                "name": "account-arrow-up-outline",
                "icon": "mdi-account-arrow-up-outline"
            },
            {
                "name": "account-badge",
                "icon": "mdi-account-badge"
            },
            {
                "name": "account-badge-outline",
                "icon": "mdi-account-badge-outline"
            },
            {
                "name": "account-box",
                "icon": "mdi-account-box"
            },
            {
                "name": "account-box-multiple",
                "icon": "mdi-account-box-multiple"
            },
            {
                "name": "account-box-multiple-outline",
                "icon": "mdi-account-box-multiple-outline"
            },
            {
                "name": "account-box-outline",
                "icon": "mdi-account-box-outline"
            },
            {
                "name": "account-cancel",
                "icon": "mdi-account-cancel"
            },
            {
                "name": "account-cancel-outline",
                "icon": "mdi-account-cancel-outline"
            },
            {
                "name": "account-card",
                "icon": "mdi-account-card"
            },
            {
                "name": "account-card-outline",
                "icon": "mdi-account-card-outline"
            },
            {
                "name": "account-cash",
                "icon": "mdi-account-cash"
            },
            {
                "name": "account-cash-outline",
                "icon": "mdi-account-cash-outline"
            },
            {
                "name": "account-check",
                "icon": "mdi-account-check"
            },
            {
                "name": "account-check-outline",
                "icon": "mdi-account-check-outline"
            },
            {
                "name": "account-child",
                "icon": "mdi-account-child"
            },
            {
                "name": "account-child-circle",
                "icon": "mdi-account-child-circle"
            },
            {
                "name": "account-child-outline",
                "icon": "mdi-account-child-outline"
            },
            {
                "name": "account-circle",
                "icon": "mdi-account-circle"
            },
            {
                "name": "account-circle-outline",
                "icon": "mdi-account-circle-outline"
            },
            {
                "name": "account-clock",
                "icon": "mdi-account-clock"
            },
            {
                "name": "account-clock-outline",
                "icon": "mdi-account-clock-outline"
            },
            {
                "name": "account-cog",
                "icon": "mdi-account-cog"
            },
            {
                "name": "account-cog-outline",
                "icon": "mdi-account-cog-outline"
            },
            {
                "name": "account-convert",
                "icon": "mdi-account-convert"
            },
            {
                "name": "account-convert-outline",
                "icon": "mdi-account-convert-outline"
            },
            {
                "name": "account-cowboy-hat",
                "icon": "mdi-account-cowboy-hat"
            },
            {
                "name": "account-cowboy-hat-outline",
                "icon": "mdi-account-cowboy-hat-outline"
            },
            {
                "name": "account-credit-card",
                "icon": "mdi-account-credit-card"
            },
            {
                "name": "account-credit-card-outline",
                "icon": "mdi-account-credit-card-outline"
            },
            {
                "name": "account-details",
                "icon": "mdi-account-details"
            },
            {
                "name": "account-details-outline",
                "icon": "mdi-account-details-outline"
            },
            {
                "name": "account-edit",
                "icon": "mdi-account-edit"
            },
            {
                "name": "account-edit-outline",
                "icon": "mdi-account-edit-outline"
            },
            {
                "name": "account-eye",
                "icon": "mdi-account-eye"
            },
            {
                "name": "account-eye-outline",
                "icon": "mdi-account-eye-outline"
            },
            {
                "name": "account-filter",
                "icon": "mdi-account-filter"
            },
            {
                "name": "account-filter-outline",
                "icon": "mdi-account-filter-outline"
            },
            {
                "name": "account-group",
                "icon": "mdi-account-group"
            },
            {
                "name": "account-group-outline",
                "icon": "mdi-account-group-outline"
            },
            {
                "name": "account-hard-hat",
                "icon": "mdi-account-hard-hat"
            },
            {
                "name": "account-hard-hat-outline",
                "icon": "mdi-account-hard-hat-outline"
            },
            {
                "name": "account-heart",
                "icon": "mdi-account-heart"
            },
            {
                "name": "account-heart-outline",
                "icon": "mdi-account-heart-outline"
            },
            {
                "name": "account-injury",
                "icon": "mdi-account-injury"
            },
            {
                "name": "account-injury-outline",
                "icon": "mdi-account-injury-outline"
            },
            {
                "name": "account-key",
                "icon": "mdi-account-key"
            },
            {
                "name": "account-key-outline",
                "icon": "mdi-account-key-outline"
            },
            {
                "name": "account-lock",
                "icon": "mdi-account-lock"
            },
            {
                "name": "account-lock-open",
                "icon": "mdi-account-lock-open"
            },
            {
                "name": "account-lock-open-outline",
                "icon": "mdi-account-lock-open-outline"
            },
            {
                "name": "account-lock-outline",
                "icon": "mdi-account-lock-outline"
            },
            {
                "name": "account-minus",
                "icon": "mdi-account-minus"
            },
            {
                "name": "account-minus-outline",
                "icon": "mdi-account-minus-outline"
            },
            {
                "name": "account-multiple",
                "icon": "mdi-account-multiple"
            },
            {
                "name": "account-multiple-check",
                "icon": "mdi-account-multiple-check"
            },
            {
                "name": "account-multiple-check-outline",
                "icon": "mdi-account-multiple-check-outline"
            },
            {
                "name": "account-multiple-minus",
                "icon": "mdi-account-multiple-minus"
            },
            {
                "name": "account-multiple-minus-outline",
                "icon": "mdi-account-multiple-minus-outline"
            },
            {
                "name": "account-multiple-outline",
                "icon": "mdi-account-multiple-outline"
            },
            {
                "name": "account-multiple-plus",
                "icon": "mdi-account-multiple-plus"
            },
            {
                "name": "account-multiple-plus-outline",
                "icon": "mdi-account-multiple-plus-outline"
            },
            {
                "name": "account-multiple-remove",
                "icon": "mdi-account-multiple-remove"
            },
            {
                "name": "account-multiple-remove-outline",
                "icon": "mdi-account-multiple-remove-outline"
            },
            {
                "name": "account-music",
                "icon": "mdi-account-music"
            },
            {
                "name": "account-music-outline",
                "icon": "mdi-account-music-outline"
            },
            {
                "name": "account-network",
                "icon": "mdi-account-network"
            },
            {
                "name": "account-network-off",
                "icon": "mdi-account-network-off"
            },
            {
                "name": "account-network-off-outline",
                "icon": "mdi-account-network-off-outline"
            },
            {
                "name": "account-network-outline",
                "icon": "mdi-account-network-outline"
            },
            {
                "name": "account-off",
                "icon": "mdi-account-off"
            },
            {
                "name": "account-off-outline",
                "icon": "mdi-account-off-outline"
            },
            {
                "name": "account-outline",
                "icon": "mdi-account-outline"
            },
            {
                "name": "account-plus",
                "icon": "mdi-account-plus"
            },
            {
                "name": "account-plus-outline",
                "icon": "mdi-account-plus-outline"
            },
            {
                "name": "account-question",
                "icon": "mdi-account-question"
            },
            {
                "name": "account-question-outline",
                "icon": "mdi-account-question-outline"
            },
            {
                "name": "account-reactivate",
                "icon": "mdi-account-reactivate"
            },
            {
                "name": "account-reactivate-outline",
                "icon": "mdi-account-reactivate-outline"
            },
            {
                "name": "account-remove",
                "icon": "mdi-account-remove"
            },
            {
                "name": "account-remove-outline",
                "icon": "mdi-account-remove-outline"
            },
            {
                "name": "account-school",
                "icon": "mdi-account-school"
            },
            {
                "name": "account-school-outline",
                "icon": "mdi-account-school-outline"
            },
            {
                "name": "account-search",
                "icon": "mdi-account-search"
            },
            {
                "name": "account-search-outline",
                "icon": "mdi-account-search-outline"
            },
            {
                "name": "account-settings",
                "icon": "mdi-account-settings"
            },
            {
                "name": "account-settings-outline",
                "icon": "mdi-account-settings-outline"
            },
            {
                "name": "account-star",
                "icon": "mdi-account-star"
            },
            {
                "name": "account-star-outline",
                "icon": "mdi-account-star-outline"
            },
            {
                "name": "account-supervisor",
                "icon": "mdi-account-supervisor"
            },
            {
                "name": "account-supervisor-circle",
                "icon": "mdi-account-supervisor-circle"
            },
            {
                "name": "account-supervisor-circle-outline",
                "icon": "mdi-account-supervisor-circle-outline"
            },
            {
                "name": "account-supervisor-outline",
                "icon": "mdi-account-supervisor-outline"
            },
            {
                "name": "account-switch",
                "icon": "mdi-account-switch"
            },
            {
                "name": "account-switch-outline",
                "icon": "mdi-account-switch-outline"
            },
            {
                "name": "account-sync",
                "icon": "mdi-account-sync"
            },
            {
                "name": "account-sync-outline",
                "icon": "mdi-account-sync-outline"
            },
            {
                "name": "account-tie",
                "icon": "mdi-account-tie"
            },
            {
                "name": "account-tie-hat",
                "icon": "mdi-account-tie-hat"
            },
            {
                "name": "account-tie-hat-outline",
                "icon": "mdi-account-tie-hat-outline"
            },
            {
                "name": "account-tie-outline",
                "icon": "mdi-account-tie-outline"
            },
            {
                "name": "account-tie-voice",
                "icon": "mdi-account-tie-voice"
            },
            {
                "name": "account-tie-voice-off",
                "icon": "mdi-account-tie-voice-off"
            },
            {
                "name": "account-tie-voice-off-outline",
                "icon": "mdi-account-tie-voice-off-outline"
            },
            {
                "name": "account-tie-voice-outline",
                "icon": "mdi-account-tie-voice-outline"
            },
            {
                "name": "account-tie-woman",
                "icon": "mdi-account-tie-woman"
            },
            {
                "name": "account-voice",
                "icon": "mdi-account-voice"
            },
            {
                "name": "account-voice-off",
                "icon": "mdi-account-voice-off"
            },
            {
                "name": "account-wrench",
                "icon": "mdi-account-wrench"
            },
            {
                "name": "account-wrench-outline",
                "icon": "mdi-account-wrench-outline"
            },
            {
                "name": "adjust",
                "icon": "mdi-adjust"
            },
            {
                "name": "advertisements",
                "icon": "mdi-advertisements"
            },
            {
                "name": "advertisements-off",
                "icon": "mdi-advertisements-off"
            },
            {
                "name": "air-conditioner",
                "icon": "mdi-air-conditioner"
            },
            {
                "name": "air-filter",
                "icon": "mdi-air-filter"
            },
            {
                "name": "air-horn",
                "icon": "mdi-air-horn"
            },
            {
                "name": "air-humidifier",
                "icon": "mdi-air-humidifier"
            },
            {
                "name": "air-humidifier-off",
                "icon": "mdi-air-humidifier-off"
            },
            {
                "name": "air-purifier",
                "icon": "mdi-air-purifier"
            },
            {
                "name": "air-purifier-off",
                "icon": "mdi-air-purifier-off"
            },
            {
                "name": "airbag",
                "icon": "mdi-airbag"
            },
            {
                "name": "airballoon",
                "icon": "mdi-airballoon"
            },
            {
                "name": "airballoon-outline",
                "icon": "mdi-airballoon-outline"
            },
            {
                "name": "airplane",
                "icon": "mdi-airplane"
            },
            {
                "name": "airplane-alert",
                "icon": "mdi-airplane-alert"
            },
            {
                "name": "airplane-check",
                "icon": "mdi-airplane-check"
            },
            {
                "name": "airplane-clock",
                "icon": "mdi-airplane-clock"
            },
            {
                "name": "airplane-cog",
                "icon": "mdi-airplane-cog"
            },
            {
                "name": "airplane-edit",
                "icon": "mdi-airplane-edit"
            },
            {
                "name": "airplane-landing",
                "icon": "mdi-airplane-landing"
            },
            {
                "name": "airplane-marker",
                "icon": "mdi-airplane-marker"
            },
            {
                "name": "airplane-minus",
                "icon": "mdi-airplane-minus"
            },
            {
                "name": "airplane-off",
                "icon": "mdi-airplane-off"
            },
            {
                "name": "airplane-plus",
                "icon": "mdi-airplane-plus"
            },
            {
                "name": "airplane-remove",
                "icon": "mdi-airplane-remove"
            },
            {
                "name": "airplane-search",
                "icon": "mdi-airplane-search"
            },
            {
                "name": "airplane-settings",
                "icon": "mdi-airplane-settings"
            },
            {
                "name": "airplane-takeoff",
                "icon": "mdi-airplane-takeoff"
            },
            {
                "name": "airport",
                "icon": "mdi-airport"
            },
            {
                "name": "alarm",
                "icon": "mdi-alarm"
            },
            {
                "name": "alarm-bell",
                "icon": "mdi-alarm-bell"
            },
            {
                "name": "alarm-check",
                "icon": "mdi-alarm-check"
            },
            {
                "name": "alarm-light",
                "icon": "mdi-alarm-light"
            },
            {
                "name": "alarm-light-off",
                "icon": "mdi-alarm-light-off"
            },
            {
                "name": "alarm-light-off-outline",
                "icon": "mdi-alarm-light-off-outline"
            },
            {
                "name": "alarm-light-outline",
                "icon": "mdi-alarm-light-outline"
            },
            {
                "name": "alarm-multiple",
                "icon": "mdi-alarm-multiple"
            },
            {
                "name": "alarm-note",
                "icon": "mdi-alarm-note"
            },
            {
                "name": "alarm-note-off",
                "icon": "mdi-alarm-note-off"
            },
            {
                "name": "alarm-off",
                "icon": "mdi-alarm-off"
            },
            {
                "name": "alarm-panel",
                "icon": "mdi-alarm-panel"
            },
            {
                "name": "alarm-panel-outline",
                "icon": "mdi-alarm-panel-outline"
            },
            {
                "name": "alarm-plus",
                "icon": "mdi-alarm-plus"
            },
            {
                "name": "alarm-snooze",
                "icon": "mdi-alarm-snooze"
            },
            {
                "name": "album",
                "icon": "mdi-album"
            },
            {
                "name": "alert",
                "icon": "mdi-alert"
            },
            {
                "name": "alert-box",
                "icon": "mdi-alert-box"
            },
            {
                "name": "alert-box-outline",
                "icon": "mdi-alert-box-outline"
            },
            {
                "name": "alert-circle",
                "icon": "mdi-alert-circle"
            },
            {
                "name": "alert-circle-check",
                "icon": "mdi-alert-circle-check"
            },
            {
                "name": "alert-circle-check-outline",
                "icon": "mdi-alert-circle-check-outline"
            },
            {
                "name": "alert-circle-outline",
                "icon": "mdi-alert-circle-outline"
            },
            {
                "name": "alert-decagram",
                "icon": "mdi-alert-decagram"
            },
            {
                "name": "alert-decagram-outline",
                "icon": "mdi-alert-decagram-outline"
            },
            {
                "name": "alert-minus",
                "icon": "mdi-alert-minus"
            },
            {
                "name": "alert-minus-outline",
                "icon": "mdi-alert-minus-outline"
            },
            {
                "name": "alert-octagon",
                "icon": "mdi-alert-octagon"
            },
            {
                "name": "alert-octagon-outline",
                "icon": "mdi-alert-octagon-outline"
            },
            {
                "name": "alert-octagram",
                "icon": "mdi-alert-octagram"
            },
            {
                "name": "alert-octagram-outline",
                "icon": "mdi-alert-octagram-outline"
            },
            {
                "name": "alert-outline",
                "icon": "mdi-alert-outline"
            },
            {
                "name": "alert-plus",
                "icon": "mdi-alert-plus"
            },
            {
                "name": "alert-plus-outline",
                "icon": "mdi-alert-plus-outline"
            },
            {
                "name": "alert-remove",
                "icon": "mdi-alert-remove"
            },
            {
                "name": "alert-remove-outline",
                "icon": "mdi-alert-remove-outline"
            },
            {
                "name": "alert-rhombus",
                "icon": "mdi-alert-rhombus"
            },
            {
                "name": "alert-rhombus-outline",
                "icon": "mdi-alert-rhombus-outline"
            },
            {
                "name": "alien",
                "icon": "mdi-alien"
            },
            {
                "name": "alien-outline",
                "icon": "mdi-alien-outline"
            },
            {
                "name": "align-horizontal-center",
                "icon": "mdi-align-horizontal-center"
            },
            {
                "name": "align-horizontal-distribute",
                "icon": "mdi-align-horizontal-distribute"
            },
            {
                "name": "align-horizontal-left",
                "icon": "mdi-align-horizontal-left"
            },
            {
                "name": "align-horizontal-right",
                "icon": "mdi-align-horizontal-right"
            },
            {
                "name": "align-vertical-bottom",
                "icon": "mdi-align-vertical-bottom"
            },
            {
                "name": "align-vertical-center",
                "icon": "mdi-align-vertical-center"
            },
            {
                "name": "align-vertical-distribute",
                "icon": "mdi-align-vertical-distribute"
            },
            {
                "name": "align-vertical-top",
                "icon": "mdi-align-vertical-top"
            },
            {
                "name": "all-inclusive",
                "icon": "mdi-all-inclusive"
            },
            {
                "name": "all-inclusive-box",
                "icon": "mdi-all-inclusive-box"
            },
            {
                "name": "all-inclusive-box-outline",
                "icon": "mdi-all-inclusive-box-outline"
            },
            {
                "name": "allergy",
                "icon": "mdi-allergy"
            },
            {
                "name": "alpha",
                "icon": "mdi-alpha"
            },
            {
                "name": "alpha-a",
                "icon": "mdi-alpha-a"
            },
            {
                "name": "alpha-a-box",
                "icon": "mdi-alpha-a-box"
            },
            {
                "name": "alpha-a-box-outline",
                "icon": "mdi-alpha-a-box-outline"
            },
            {
                "name": "alpha-a-circle",
                "icon": "mdi-alpha-a-circle"
            },
            {
                "name": "alpha-a-circle-outline",
                "icon": "mdi-alpha-a-circle-outline"
            },
            {
                "name": "alpha-b",
                "icon": "mdi-alpha-b"
            },
            {
                "name": "alpha-b-box",
                "icon": "mdi-alpha-b-box"
            },
            {
                "name": "alpha-b-box-outline",
                "icon": "mdi-alpha-b-box-outline"
            },
            {
                "name": "alpha-b-circle",
                "icon": "mdi-alpha-b-circle"
            },
            {
                "name": "alpha-b-circle-outline",
                "icon": "mdi-alpha-b-circle-outline"
            },
            {
                "name": "alpha-c",
                "icon": "mdi-alpha-c"
            },
            {
                "name": "alpha-c-box",
                "icon": "mdi-alpha-c-box"
            },
            {
                "name": "alpha-c-box-outline",
                "icon": "mdi-alpha-c-box-outline"
            },
            {
                "name": "alpha-c-circle",
                "icon": "mdi-alpha-c-circle"
            },
            {
                "name": "alpha-c-circle-outline",
                "icon": "mdi-alpha-c-circle-outline"
            },
            {
                "name": "alpha-d",
                "icon": "mdi-alpha-d"
            },
            {
                "name": "alpha-d-box",
                "icon": "mdi-alpha-d-box"
            },
            {
                "name": "alpha-d-box-outline",
                "icon": "mdi-alpha-d-box-outline"
            },
            {
                "name": "alpha-d-circle",
                "icon": "mdi-alpha-d-circle"
            },
            {
                "name": "alpha-d-circle-outline",
                "icon": "mdi-alpha-d-circle-outline"
            },
            {
                "name": "alpha-e",
                "icon": "mdi-alpha-e"
            },
            {
                "name": "alpha-e-box",
                "icon": "mdi-alpha-e-box"
            },
            {
                "name": "alpha-e-box-outline",
                "icon": "mdi-alpha-e-box-outline"
            },
            {
                "name": "alpha-e-circle",
                "icon": "mdi-alpha-e-circle"
            },
            {
                "name": "alpha-e-circle-outline",
                "icon": "mdi-alpha-e-circle-outline"
            },
            {
                "name": "alpha-f",
                "icon": "mdi-alpha-f"
            },
            {
                "name": "alpha-f-box",
                "icon": "mdi-alpha-f-box"
            },
            {
                "name": "alpha-f-box-outline",
                "icon": "mdi-alpha-f-box-outline"
            },
            {
                "name": "alpha-f-circle",
                "icon": "mdi-alpha-f-circle"
            },
            {
                "name": "alpha-f-circle-outline",
                "icon": "mdi-alpha-f-circle-outline"
            },
            {
                "name": "alpha-g",
                "icon": "mdi-alpha-g"
            },
            {
                "name": "alpha-g-box",
                "icon": "mdi-alpha-g-box"
            },
            {
                "name": "alpha-g-box-outline",
                "icon": "mdi-alpha-g-box-outline"
            },
            {
                "name": "alpha-g-circle",
                "icon": "mdi-alpha-g-circle"
            },
            {
                "name": "alpha-g-circle-outline",
                "icon": "mdi-alpha-g-circle-outline"
            },
            {
                "name": "alpha-h",
                "icon": "mdi-alpha-h"
            },
            {
                "name": "alpha-h-box",
                "icon": "mdi-alpha-h-box"
            },
            {
                "name": "alpha-h-box-outline",
                "icon": "mdi-alpha-h-box-outline"
            },
            {
                "name": "alpha-h-circle",
                "icon": "mdi-alpha-h-circle"
            },
            {
                "name": "alpha-h-circle-outline",
                "icon": "mdi-alpha-h-circle-outline"
            },
            {
                "name": "alpha-i",
                "icon": "mdi-alpha-i"
            },
            {
                "name": "alpha-i-box",
                "icon": "mdi-alpha-i-box"
            },
            {
                "name": "alpha-i-box-outline",
                "icon": "mdi-alpha-i-box-outline"
            },
            {
                "name": "alpha-i-circle",
                "icon": "mdi-alpha-i-circle"
            },
            {
                "name": "alpha-i-circle-outline",
                "icon": "mdi-alpha-i-circle-outline"
            },
            {
                "name": "alpha-j",
                "icon": "mdi-alpha-j"
            },
            {
                "name": "alpha-j-box",
                "icon": "mdi-alpha-j-box"
            },
            {
                "name": "alpha-j-box-outline",
                "icon": "mdi-alpha-j-box-outline"
            },
            {
                "name": "alpha-j-circle",
                "icon": "mdi-alpha-j-circle"
            },
            {
                "name": "alpha-j-circle-outline",
                "icon": "mdi-alpha-j-circle-outline"
            },
            {
                "name": "alpha-k",
                "icon": "mdi-alpha-k"
            },
            {
                "name": "alpha-k-box",
                "icon": "mdi-alpha-k-box"
            },
            {
                "name": "alpha-k-box-outline",
                "icon": "mdi-alpha-k-box-outline"
            },
            {
                "name": "alpha-k-circle",
                "icon": "mdi-alpha-k-circle"
            },
            {
                "name": "alpha-k-circle-outline",
                "icon": "mdi-alpha-k-circle-outline"
            },
            {
                "name": "alpha-l",
                "icon": "mdi-alpha-l"
            },
            {
                "name": "alpha-l-box",
                "icon": "mdi-alpha-l-box"
            },
            {
                "name": "alpha-l-box-outline",
                "icon": "mdi-alpha-l-box-outline"
            },
            {
                "name": "alpha-l-circle",
                "icon": "mdi-alpha-l-circle"
            },
            {
                "name": "alpha-l-circle-outline",
                "icon": "mdi-alpha-l-circle-outline"
            },
            {
                "name": "alpha-m",
                "icon": "mdi-alpha-m"
            },
            {
                "name": "alpha-m-box",
                "icon": "mdi-alpha-m-box"
            },
            {
                "name": "alpha-m-box-outline",
                "icon": "mdi-alpha-m-box-outline"
            },
            {
                "name": "alpha-m-circle",
                "icon": "mdi-alpha-m-circle"
            },
            {
                "name": "alpha-m-circle-outline",
                "icon": "mdi-alpha-m-circle-outline"
            },
            {
                "name": "alpha-n",
                "icon": "mdi-alpha-n"
            },
            {
                "name": "alpha-n-box",
                "icon": "mdi-alpha-n-box"
            },
            {
                "name": "alpha-n-box-outline",
                "icon": "mdi-alpha-n-box-outline"
            },
            {
                "name": "alpha-n-circle",
                "icon": "mdi-alpha-n-circle"
            },
            {
                "name": "alpha-n-circle-outline",
                "icon": "mdi-alpha-n-circle-outline"
            },
            {
                "name": "alpha-o",
                "icon": "mdi-alpha-o"
            },
            {
                "name": "alpha-o-box",
                "icon": "mdi-alpha-o-box"
            },
            {
                "name": "alpha-o-box-outline",
                "icon": "mdi-alpha-o-box-outline"
            },
            {
                "name": "alpha-o-circle",
                "icon": "mdi-alpha-o-circle"
            },
            {
                "name": "alpha-o-circle-outline",
                "icon": "mdi-alpha-o-circle-outline"
            },
            {
                "name": "alpha-p",
                "icon": "mdi-alpha-p"
            },
            {
                "name": "alpha-p-box",
                "icon": "mdi-alpha-p-box"
            },
            {
                "name": "alpha-p-box-outline",
                "icon": "mdi-alpha-p-box-outline"
            },
            {
                "name": "alpha-p-circle",
                "icon": "mdi-alpha-p-circle"
            },
            {
                "name": "alpha-p-circle-outline",
                "icon": "mdi-alpha-p-circle-outline"
            },
            {
                "name": "alpha-q",
                "icon": "mdi-alpha-q"
            },
            {
                "name": "alpha-q-box",
                "icon": "mdi-alpha-q-box"
            },
            {
                "name": "alpha-q-box-outline",
                "icon": "mdi-alpha-q-box-outline"
            },
            {
                "name": "alpha-q-circle",
                "icon": "mdi-alpha-q-circle"
            },
            {
                "name": "alpha-q-circle-outline",
                "icon": "mdi-alpha-q-circle-outline"
            },
            {
                "name": "alpha-r",
                "icon": "mdi-alpha-r"
            },
            {
                "name": "alpha-r-box",
                "icon": "mdi-alpha-r-box"
            },
            {
                "name": "alpha-r-box-outline",
                "icon": "mdi-alpha-r-box-outline"
            },
            {
                "name": "alpha-r-circle",
                "icon": "mdi-alpha-r-circle"
            },
            {
                "name": "alpha-r-circle-outline",
                "icon": "mdi-alpha-r-circle-outline"
            },
            {
                "name": "alpha-s",
                "icon": "mdi-alpha-s"
            },
            {
                "name": "alpha-s-box",
                "icon": "mdi-alpha-s-box"
            },
            {
                "name": "alpha-s-box-outline",
                "icon": "mdi-alpha-s-box-outline"
            },
            {
                "name": "alpha-s-circle",
                "icon": "mdi-alpha-s-circle"
            },
            {
                "name": "alpha-s-circle-outline",
                "icon": "mdi-alpha-s-circle-outline"
            },
            {
                "name": "alpha-t",
                "icon": "mdi-alpha-t"
            },
            {
                "name": "alpha-t-box",
                "icon": "mdi-alpha-t-box"
            },
            {
                "name": "alpha-t-box-outline",
                "icon": "mdi-alpha-t-box-outline"
            },
            {
                "name": "alpha-t-circle",
                "icon": "mdi-alpha-t-circle"
            },
            {
                "name": "alpha-t-circle-outline",
                "icon": "mdi-alpha-t-circle-outline"
            },
            {
                "name": "alpha-u",
                "icon": "mdi-alpha-u"
            },
            {
                "name": "alpha-u-box",
                "icon": "mdi-alpha-u-box"
            },
            {
                "name": "alpha-u-box-outline",
                "icon": "mdi-alpha-u-box-outline"
            },
            {
                "name": "alpha-u-circle",
                "icon": "mdi-alpha-u-circle"
            },
            {
                "name": "alpha-u-circle-outline",
                "icon": "mdi-alpha-u-circle-outline"
            },
            {
                "name": "alpha-v",
                "icon": "mdi-alpha-v"
            },
            {
                "name": "alpha-v-box",
                "icon": "mdi-alpha-v-box"
            },
            {
                "name": "alpha-v-box-outline",
                "icon": "mdi-alpha-v-box-outline"
            },
            {
                "name": "alpha-v-circle",
                "icon": "mdi-alpha-v-circle"
            },
            {
                "name": "alpha-v-circle-outline",
                "icon": "mdi-alpha-v-circle-outline"
            },
            {
                "name": "alpha-w",
                "icon": "mdi-alpha-w"
            },
            {
                "name": "alpha-w-box",
                "icon": "mdi-alpha-w-box"
            },
            {
                "name": "alpha-w-box-outline",
                "icon": "mdi-alpha-w-box-outline"
            },
            {
                "name": "alpha-w-circle",
                "icon": "mdi-alpha-w-circle"
            },
            {
                "name": "alpha-w-circle-outline",
                "icon": "mdi-alpha-w-circle-outline"
            },
            {
                "name": "alpha-x",
                "icon": "mdi-alpha-x"
            },
            {
                "name": "alpha-x-box",
                "icon": "mdi-alpha-x-box"
            },
            {
                "name": "alpha-x-box-outline",
                "icon": "mdi-alpha-x-box-outline"
            },
            {
                "name": "alpha-x-circle",
                "icon": "mdi-alpha-x-circle"
            },
            {
                "name": "alpha-x-circle-outline",
                "icon": "mdi-alpha-x-circle-outline"
            },
            {
                "name": "alpha-y",
                "icon": "mdi-alpha-y"
            },
            {
                "name": "alpha-y-box",
                "icon": "mdi-alpha-y-box"
            },
            {
                "name": "alpha-y-box-outline",
                "icon": "mdi-alpha-y-box-outline"
            },
            {
                "name": "alpha-y-circle",
                "icon": "mdi-alpha-y-circle"
            },
            {
                "name": "alpha-y-circle-outline",
                "icon": "mdi-alpha-y-circle-outline"
            },
            {
                "name": "alpha-z",
                "icon": "mdi-alpha-z"
            },
            {
                "name": "alpha-z-box",
                "icon": "mdi-alpha-z-box"
            },
            {
                "name": "alpha-z-box-outline",
                "icon": "mdi-alpha-z-box-outline"
            },
            {
                "name": "alpha-z-circle",
                "icon": "mdi-alpha-z-circle"
            },
            {
                "name": "alpha-z-circle-outline",
                "icon": "mdi-alpha-z-circle-outline"
            },
            {
                "name": "alphabet-aurebesh",
                "icon": "mdi-alphabet-aurebesh"
            },
            {
                "name": "alphabet-cyrillic",
                "icon": "mdi-alphabet-cyrillic"
            },
            {
                "name": "alphabet-greek",
                "icon": "mdi-alphabet-greek"
            },
            {
                "name": "alphabet-latin",
                "icon": "mdi-alphabet-latin"
            },
            {
                "name": "alphabet-piqad",
                "icon": "mdi-alphabet-piqad"
            },
            {
                "name": "alphabet-tengwar",
                "icon": "mdi-alphabet-tengwar"
            },
            {
                "name": "alphabetical",
                "icon": "mdi-alphabetical"
            },
            {
                "name": "alphabetical-off",
                "icon": "mdi-alphabetical-off"
            },
            {
                "name": "alphabetical-variant",
                "icon": "mdi-alphabetical-variant"
            },
            {
                "name": "alphabetical-variant-off",
                "icon": "mdi-alphabetical-variant-off"
            },
            {
                "name": "altimeter",
                "icon": "mdi-altimeter"
            },
            {
                "name": "ambulance",
                "icon": "mdi-ambulance"
            },
            {
                "name": "ammunition",
                "icon": "mdi-ammunition"
            },
            {
                "name": "ampersand",
                "icon": "mdi-ampersand"
            },
            {
                "name": "amplifier",
                "icon": "mdi-amplifier"
            },
            {
                "name": "amplifier-off",
                "icon": "mdi-amplifier-off"
            },
            {
                "name": "anchor",
                "icon": "mdi-anchor"
            },
            {
                "name": "android",
                "icon": "mdi-android"
            },
            {
                "name": "android-studio",
                "icon": "mdi-android-studio"
            },
            {
                "name": "angle-acute",
                "icon": "mdi-angle-acute"
            },
            {
                "name": "angle-obtuse",
                "icon": "mdi-angle-obtuse"
            },
            {
                "name": "angle-right",
                "icon": "mdi-angle-right"
            },
            {
                "name": "angular",
                "icon": "mdi-angular"
            },
            {
                "name": "angularjs",
                "icon": "mdi-angularjs"
            },
            {
                "name": "animation",
                "icon": "mdi-animation"
            },
            {
                "name": "animation-outline",
                "icon": "mdi-animation-outline"
            },
            {
                "name": "animation-play",
                "icon": "mdi-animation-play"
            },
            {
                "name": "animation-play-outline",
                "icon": "mdi-animation-play-outline"
            },
            {
                "name": "ansible",
                "icon": "mdi-ansible"
            },
            {
                "name": "antenna",
                "icon": "mdi-antenna"
            },
            {
                "name": "anvil",
                "icon": "mdi-anvil"
            },
            {
                "name": "apache-kafka",
                "icon": "mdi-apache-kafka"
            },
            {
                "name": "api",
                "icon": "mdi-api"
            },
            {
                "name": "api-off",
                "icon": "mdi-api-off"
            },
            {
                "name": "apple",
                "icon": "mdi-apple"
            },
            {
                "name": "apple-finder",
                "icon": "mdi-apple-finder"
            },
            {
                "name": "apple-icloud",
                "icon": "mdi-apple-icloud"
            },
            {
                "name": "apple-ios",
                "icon": "mdi-apple-ios"
            },
            {
                "name": "apple-keyboard-caps",
                "icon": "mdi-apple-keyboard-caps"
            },
            {
                "name": "apple-keyboard-command",
                "icon": "mdi-apple-keyboard-command"
            },
            {
                "name": "apple-keyboard-control",
                "icon": "mdi-apple-keyboard-control"
            },
            {
                "name": "apple-keyboard-option",
                "icon": "mdi-apple-keyboard-option"
            },
            {
                "name": "apple-keyboard-shift",
                "icon": "mdi-apple-keyboard-shift"
            },
            {
                "name": "apple-safari",
                "icon": "mdi-apple-safari"
            },
            {
                "name": "application",
                "icon": "mdi-application"
            },
            {
                "name": "application-array",
                "icon": "mdi-application-array"
            },
            {
                "name": "application-array-outline",
                "icon": "mdi-application-array-outline"
            },
            {
                "name": "application-braces",
                "icon": "mdi-application-braces"
            },
            {
                "name": "application-braces-outline",
                "icon": "mdi-application-braces-outline"
            },
            {
                "name": "application-brackets",
                "icon": "mdi-application-brackets"
            },
            {
                "name": "application-brackets-outline",
                "icon": "mdi-application-brackets-outline"
            },
            {
                "name": "application-cog",
                "icon": "mdi-application-cog"
            },
            {
                "name": "application-cog-outline",
                "icon": "mdi-application-cog-outline"
            },
            {
                "name": "application-edit",
                "icon": "mdi-application-edit"
            },
            {
                "name": "application-edit-outline",
                "icon": "mdi-application-edit-outline"
            },
            {
                "name": "application-export",
                "icon": "mdi-application-export"
            },
            {
                "name": "application-import",
                "icon": "mdi-application-import"
            },
            {
                "name": "application-outline",
                "icon": "mdi-application-outline"
            },
            {
                "name": "application-parentheses",
                "icon": "mdi-application-parentheses"
            },
            {
                "name": "application-parentheses-outline",
                "icon": "mdi-application-parentheses-outline"
            },
            {
                "name": "application-settings",
                "icon": "mdi-application-settings"
            },
            {
                "name": "application-settings-outline",
                "icon": "mdi-application-settings-outline"
            },
            {
                "name": "application-variable",
                "icon": "mdi-application-variable"
            },
            {
                "name": "application-variable-outline",
                "icon": "mdi-application-variable-outline"
            },
            {
                "name": "approximately-equal",
                "icon": "mdi-approximately-equal"
            },
            {
                "name": "approximately-equal-box",
                "icon": "mdi-approximately-equal-box"
            },
            {
                "name": "apps",
                "icon": "mdi-apps"
            },
            {
                "name": "apps-box",
                "icon": "mdi-apps-box"
            },
            {
                "name": "arch",
                "icon": "mdi-arch"
            },
            {
                "name": "archive",
                "icon": "mdi-archive"
            },
            {
                "name": "archive-alert",
                "icon": "mdi-archive-alert"
            },
            {
                "name": "archive-alert-outline",
                "icon": "mdi-archive-alert-outline"
            },
            {
                "name": "archive-arrow-down",
                "icon": "mdi-archive-arrow-down"
            },
            {
                "name": "archive-arrow-down-outline",
                "icon": "mdi-archive-arrow-down-outline"
            },
            {
                "name": "archive-arrow-up",
                "icon": "mdi-archive-arrow-up"
            },
            {
                "name": "archive-arrow-up-outline",
                "icon": "mdi-archive-arrow-up-outline"
            },
            {
                "name": "archive-cancel",
                "icon": "mdi-archive-cancel"
            },
            {
                "name": "archive-cancel-outline",
                "icon": "mdi-archive-cancel-outline"
            },
            {
                "name": "archive-check",
                "icon": "mdi-archive-check"
            },
            {
                "name": "archive-check-outline",
                "icon": "mdi-archive-check-outline"
            },
            {
                "name": "archive-clock",
                "icon": "mdi-archive-clock"
            },
            {
                "name": "archive-clock-outline",
                "icon": "mdi-archive-clock-outline"
            },
            {
                "name": "archive-cog",
                "icon": "mdi-archive-cog"
            },
            {
                "name": "archive-cog-outline",
                "icon": "mdi-archive-cog-outline"
            },
            {
                "name": "archive-edit",
                "icon": "mdi-archive-edit"
            },
            {
                "name": "archive-edit-outline",
                "icon": "mdi-archive-edit-outline"
            },
            {
                "name": "archive-eye",
                "icon": "mdi-archive-eye"
            },
            {
                "name": "archive-eye-outline",
                "icon": "mdi-archive-eye-outline"
            },
            {
                "name": "archive-lock",
                "icon": "mdi-archive-lock"
            },
            {
                "name": "archive-lock-open",
                "icon": "mdi-archive-lock-open"
            },
            {
                "name": "archive-lock-open-outline",
                "icon": "mdi-archive-lock-open-outline"
            },
            {
                "name": "archive-lock-outline",
                "icon": "mdi-archive-lock-outline"
            },
            {
                "name": "archive-marker",
                "icon": "mdi-archive-marker"
            },
            {
                "name": "archive-marker-outline",
                "icon": "mdi-archive-marker-outline"
            },
            {
                "name": "archive-minus",
                "icon": "mdi-archive-minus"
            },
            {
                "name": "archive-minus-outline",
                "icon": "mdi-archive-minus-outline"
            },
            {
                "name": "archive-music",
                "icon": "mdi-archive-music"
            },
            {
                "name": "archive-music-outline",
                "icon": "mdi-archive-music-outline"
            },
            {
                "name": "archive-off",
                "icon": "mdi-archive-off"
            },
            {
                "name": "archive-off-outline",
                "icon": "mdi-archive-off-outline"
            },
            {
                "name": "archive-outline",
                "icon": "mdi-archive-outline"
            },
            {
                "name": "archive-plus",
                "icon": "mdi-archive-plus"
            },
            {
                "name": "archive-plus-outline",
                "icon": "mdi-archive-plus-outline"
            },
            {
                "name": "archive-refresh",
                "icon": "mdi-archive-refresh"
            },
            {
                "name": "archive-refresh-outline",
                "icon": "mdi-archive-refresh-outline"
            },
            {
                "name": "archive-remove",
                "icon": "mdi-archive-remove"
            },
            {
                "name": "archive-remove-outline",
                "icon": "mdi-archive-remove-outline"
            },
            {
                "name": "archive-search",
                "icon": "mdi-archive-search"
            },
            {
                "name": "archive-search-outline",
                "icon": "mdi-archive-search-outline"
            },
            {
                "name": "archive-settings",
                "icon": "mdi-archive-settings"
            },
            {
                "name": "archive-settings-outline",
                "icon": "mdi-archive-settings-outline"
            },
            {
                "name": "archive-star",
                "icon": "mdi-archive-star"
            },
            {
                "name": "archive-star-outline",
                "icon": "mdi-archive-star-outline"
            },
            {
                "name": "archive-sync",
                "icon": "mdi-archive-sync"
            },
            {
                "name": "archive-sync-outline",
                "icon": "mdi-archive-sync-outline"
            },
            {
                "name": "arm-flex",
                "icon": "mdi-arm-flex"
            },
            {
                "name": "arm-flex-outline",
                "icon": "mdi-arm-flex-outline"
            },
            {
                "name": "arrange-bring-forward",
                "icon": "mdi-arrange-bring-forward"
            },
            {
                "name": "arrange-bring-to-front",
                "icon": "mdi-arrange-bring-to-front"
            },
            {
                "name": "arrange-send-backward",
                "icon": "mdi-arrange-send-backward"
            },
            {
                "name": "arrange-send-to-back",
                "icon": "mdi-arrange-send-to-back"
            },
            {
                "name": "arrow-all",
                "icon": "mdi-arrow-all"
            },
            {
                "name": "arrow-bottom-left",
                "icon": "mdi-arrow-bottom-left"
            },
            {
                "name": "arrow-bottom-left-bold-box",
                "icon": "mdi-arrow-bottom-left-bold-box"
            },
            {
                "name": "arrow-bottom-left-bold-box-outline",
                "icon": "mdi-arrow-bottom-left-bold-box-outline"
            },
            {
                "name": "arrow-bottom-left-bold-outline",
                "icon": "mdi-arrow-bottom-left-bold-outline"
            },
            {
                "name": "arrow-bottom-left-thick",
                "icon": "mdi-arrow-bottom-left-thick"
            },
            {
                "name": "arrow-bottom-left-thin",
                "icon": "mdi-arrow-bottom-left-thin"
            },
            {
                "name": "arrow-bottom-left-thin-circle-outline",
                "icon": "mdi-arrow-bottom-left-thin-circle-outline"
            },
            {
                "name": "arrow-bottom-right",
                "icon": "mdi-arrow-bottom-right"
            },
            {
                "name": "arrow-bottom-right-bold-box",
                "icon": "mdi-arrow-bottom-right-bold-box"
            },
            {
                "name": "arrow-bottom-right-bold-box-outline",
                "icon": "mdi-arrow-bottom-right-bold-box-outline"
            },
            {
                "name": "arrow-bottom-right-bold-outline",
                "icon": "mdi-arrow-bottom-right-bold-outline"
            },
            {
                "name": "arrow-bottom-right-thick",
                "icon": "mdi-arrow-bottom-right-thick"
            },
            {
                "name": "arrow-bottom-right-thin",
                "icon": "mdi-arrow-bottom-right-thin"
            },
            {
                "name": "arrow-bottom-right-thin-circle-outline",
                "icon": "mdi-arrow-bottom-right-thin-circle-outline"
            },
            {
                "name": "arrow-collapse",
                "icon": "mdi-arrow-collapse"
            },
            {
                "name": "arrow-collapse-all",
                "icon": "mdi-arrow-collapse-all"
            },
            {
                "name": "arrow-collapse-down",
                "icon": "mdi-arrow-collapse-down"
            },
            {
                "name": "arrow-collapse-horizontal",
                "icon": "mdi-arrow-collapse-horizontal"
            },
            {
                "name": "arrow-collapse-left",
                "icon": "mdi-arrow-collapse-left"
            },
            {
                "name": "arrow-collapse-right",
                "icon": "mdi-arrow-collapse-right"
            },
            {
                "name": "arrow-collapse-up",
                "icon": "mdi-arrow-collapse-up"
            },
            {
                "name": "arrow-collapse-vertical",
                "icon": "mdi-arrow-collapse-vertical"
            },
            {
                "name": "arrow-decision",
                "icon": "mdi-arrow-decision"
            },
            {
                "name": "arrow-decision-auto",
                "icon": "mdi-arrow-decision-auto"
            },
            {
                "name": "arrow-decision-auto-outline",
                "icon": "mdi-arrow-decision-auto-outline"
            },
            {
                "name": "arrow-decision-outline",
                "icon": "mdi-arrow-decision-outline"
            },
            {
                "name": "arrow-down",
                "icon": "mdi-arrow-down"
            },
            {
                "name": "arrow-down-bold",
                "icon": "mdi-arrow-down-bold"
            },
            {
                "name": "arrow-down-bold-box",
                "icon": "mdi-arrow-down-bold-box"
            },
            {
                "name": "arrow-down-bold-box-outline",
                "icon": "mdi-arrow-down-bold-box-outline"
            },
            {
                "name": "arrow-down-bold-circle",
                "icon": "mdi-arrow-down-bold-circle"
            },
            {
                "name": "arrow-down-bold-circle-outline",
                "icon": "mdi-arrow-down-bold-circle-outline"
            },
            {
                "name": "arrow-down-bold-hexagon-outline",
                "icon": "mdi-arrow-down-bold-hexagon-outline"
            },
            {
                "name": "arrow-down-bold-outline",
                "icon": "mdi-arrow-down-bold-outline"
            },
            {
                "name": "arrow-down-box",
                "icon": "mdi-arrow-down-box"
            },
            {
                "name": "arrow-down-circle",
                "icon": "mdi-arrow-down-circle"
            },
            {
                "name": "arrow-down-circle-outline",
                "icon": "mdi-arrow-down-circle-outline"
            },
            {
                "name": "arrow-down-drop-circle",
                "icon": "mdi-arrow-down-drop-circle"
            },
            {
                "name": "arrow-down-drop-circle-outline",
                "icon": "mdi-arrow-down-drop-circle-outline"
            },
            {
                "name": "arrow-down-left",
                "icon": "mdi-arrow-down-left"
            },
            {
                "name": "arrow-down-left-bold",
                "icon": "mdi-arrow-down-left-bold"
            },
            {
                "name": "arrow-down-right",
                "icon": "mdi-arrow-down-right"
            },
            {
                "name": "arrow-down-right-bold",
                "icon": "mdi-arrow-down-right-bold"
            },
            {
                "name": "arrow-down-thick",
                "icon": "mdi-arrow-down-thick"
            },
            {
                "name": "arrow-down-thin",
                "icon": "mdi-arrow-down-thin"
            },
            {
                "name": "arrow-down-thin-circle-outline",
                "icon": "mdi-arrow-down-thin-circle-outline"
            },
            {
                "name": "arrow-expand",
                "icon": "mdi-arrow-expand"
            },
            {
                "name": "arrow-expand-all",
                "icon": "mdi-arrow-expand-all"
            },
            {
                "name": "arrow-expand-down",
                "icon": "mdi-arrow-expand-down"
            },
            {
                "name": "arrow-expand-horizontal",
                "icon": "mdi-arrow-expand-horizontal"
            },
            {
                "name": "arrow-expand-left",
                "icon": "mdi-arrow-expand-left"
            },
            {
                "name": "arrow-expand-right",
                "icon": "mdi-arrow-expand-right"
            },
            {
                "name": "arrow-expand-up",
                "icon": "mdi-arrow-expand-up"
            },
            {
                "name": "arrow-expand-vertical",
                "icon": "mdi-arrow-expand-vertical"
            },
            {
                "name": "arrow-horizontal-lock",
                "icon": "mdi-arrow-horizontal-lock"
            },
            {
                "name": "arrow-left",
                "icon": "mdi-arrow-left"
            },
            {
                "name": "arrow-left-bold",
                "icon": "mdi-arrow-left-bold"
            },
            {
                "name": "arrow-left-bold-box",
                "icon": "mdi-arrow-left-bold-box"
            },
            {
                "name": "arrow-left-bold-box-outline",
                "icon": "mdi-arrow-left-bold-box-outline"
            },
            {
                "name": "arrow-left-bold-circle",
                "icon": "mdi-arrow-left-bold-circle"
            },
            {
                "name": "arrow-left-bold-circle-outline",
                "icon": "mdi-arrow-left-bold-circle-outline"
            },
            {
                "name": "arrow-left-bold-hexagon-outline",
                "icon": "mdi-arrow-left-bold-hexagon-outline"
            },
            {
                "name": "arrow-left-bold-outline",
                "icon": "mdi-arrow-left-bold-outline"
            },
            {
                "name": "arrow-left-bottom",
                "icon": "mdi-arrow-left-bottom"
            },
            {
                "name": "arrow-left-bottom-bold",
                "icon": "mdi-arrow-left-bottom-bold"
            },
            {
                "name": "arrow-left-box",
                "icon": "mdi-arrow-left-box"
            },
            {
                "name": "arrow-left-circle",
                "icon": "mdi-arrow-left-circle"
            },
            {
                "name": "arrow-left-circle-outline",
                "icon": "mdi-arrow-left-circle-outline"
            },
            {
                "name": "arrow-left-drop-circle",
                "icon": "mdi-arrow-left-drop-circle"
            },
            {
                "name": "arrow-left-drop-circle-outline",
                "icon": "mdi-arrow-left-drop-circle-outline"
            },
            {
                "name": "arrow-left-right",
                "icon": "mdi-arrow-left-right"
            },
            {
                "name": "arrow-left-right-bold",
                "icon": "mdi-arrow-left-right-bold"
            },
            {
                "name": "arrow-left-right-bold-outline",
                "icon": "mdi-arrow-left-right-bold-outline"
            },
            {
                "name": "arrow-left-thick",
                "icon": "mdi-arrow-left-thick"
            },
            {
                "name": "arrow-left-thin",
                "icon": "mdi-arrow-left-thin"
            },
            {
                "name": "arrow-left-thin-circle-outline",
                "icon": "mdi-arrow-left-thin-circle-outline"
            },
            {
                "name": "arrow-left-top",
                "icon": "mdi-arrow-left-top"
            },
            {
                "name": "arrow-left-top-bold",
                "icon": "mdi-arrow-left-top-bold"
            },
            {
                "name": "arrow-projectile",
                "icon": "mdi-arrow-projectile"
            },
            {
                "name": "arrow-projectile-multiple",
                "icon": "mdi-arrow-projectile-multiple"
            },
            {
                "name": "arrow-right",
                "icon": "mdi-arrow-right"
            },
            {
                "name": "arrow-right-bold",
                "icon": "mdi-arrow-right-bold"
            },
            {
                "name": "arrow-right-bold-box",
                "icon": "mdi-arrow-right-bold-box"
            },
            {
                "name": "arrow-right-bold-box-outline",
                "icon": "mdi-arrow-right-bold-box-outline"
            },
            {
                "name": "arrow-right-bold-circle",
                "icon": "mdi-arrow-right-bold-circle"
            },
            {
                "name": "arrow-right-bold-circle-outline",
                "icon": "mdi-arrow-right-bold-circle-outline"
            },
            {
                "name": "arrow-right-bold-hexagon-outline",
                "icon": "mdi-arrow-right-bold-hexagon-outline"
            },
            {
                "name": "arrow-right-bold-outline",
                "icon": "mdi-arrow-right-bold-outline"
            },
            {
                "name": "arrow-right-bottom",
                "icon": "mdi-arrow-right-bottom"
            },
            {
                "name": "arrow-right-bottom-bold",
                "icon": "mdi-arrow-right-bottom-bold"
            },
            {
                "name": "arrow-right-box",
                "icon": "mdi-arrow-right-box"
            },
            {
                "name": "arrow-right-circle",
                "icon": "mdi-arrow-right-circle"
            },
            {
                "name": "arrow-right-circle-outline",
                "icon": "mdi-arrow-right-circle-outline"
            },
            {
                "name": "arrow-right-drop-circle",
                "icon": "mdi-arrow-right-drop-circle"
            },
            {
                "name": "arrow-right-drop-circle-outline",
                "icon": "mdi-arrow-right-drop-circle-outline"
            },
            {
                "name": "arrow-right-thick",
                "icon": "mdi-arrow-right-thick"
            },
            {
                "name": "arrow-right-thin",
                "icon": "mdi-arrow-right-thin"
            },
            {
                "name": "arrow-right-thin-circle-outline",
                "icon": "mdi-arrow-right-thin-circle-outline"
            },
            {
                "name": "arrow-right-top",
                "icon": "mdi-arrow-right-top"
            },
            {
                "name": "arrow-right-top-bold",
                "icon": "mdi-arrow-right-top-bold"
            },
            {
                "name": "arrow-split-horizontal",
                "icon": "mdi-arrow-split-horizontal"
            },
            {
                "name": "arrow-split-vertical",
                "icon": "mdi-arrow-split-vertical"
            },
            {
                "name": "arrow-top-left",
                "icon": "mdi-arrow-top-left"
            },
            {
                "name": "arrow-top-left-bold-box",
                "icon": "mdi-arrow-top-left-bold-box"
            },
            {
                "name": "arrow-top-left-bold-box-outline",
                "icon": "mdi-arrow-top-left-bold-box-outline"
            },
            {
                "name": "arrow-top-left-bold-outline",
                "icon": "mdi-arrow-top-left-bold-outline"
            },
            {
                "name": "arrow-top-left-bottom-right",
                "icon": "mdi-arrow-top-left-bottom-right"
            },
            {
                "name": "arrow-top-left-bottom-right-bold",
                "icon": "mdi-arrow-top-left-bottom-right-bold"
            },
            {
                "name": "arrow-top-left-thick",
                "icon": "mdi-arrow-top-left-thick"
            },
            {
                "name": "arrow-top-left-thin",
                "icon": "mdi-arrow-top-left-thin"
            },
            {
                "name": "arrow-top-left-thin-circle-outline",
                "icon": "mdi-arrow-top-left-thin-circle-outline"
            },
            {
                "name": "arrow-top-right",
                "icon": "mdi-arrow-top-right"
            },
            {
                "name": "arrow-top-right-bold-box",
                "icon": "mdi-arrow-top-right-bold-box"
            },
            {
                "name": "arrow-top-right-bold-box-outline",
                "icon": "mdi-arrow-top-right-bold-box-outline"
            },
            {
                "name": "arrow-top-right-bold-outline",
                "icon": "mdi-arrow-top-right-bold-outline"
            },
            {
                "name": "arrow-top-right-bottom-left",
                "icon": "mdi-arrow-top-right-bottom-left"
            },
            {
                "name": "arrow-top-right-bottom-left-bold",
                "icon": "mdi-arrow-top-right-bottom-left-bold"
            },
            {
                "name": "arrow-top-right-thick",
                "icon": "mdi-arrow-top-right-thick"
            },
            {
                "name": "arrow-top-right-thin",
                "icon": "mdi-arrow-top-right-thin"
            },
            {
                "name": "arrow-top-right-thin-circle-outline",
                "icon": "mdi-arrow-top-right-thin-circle-outline"
            },
            {
                "name": "arrow-u-down-left",
                "icon": "mdi-arrow-u-down-left"
            },
            {
                "name": "arrow-u-down-left-bold",
                "icon": "mdi-arrow-u-down-left-bold"
            },
            {
                "name": "arrow-u-down-right",
                "icon": "mdi-arrow-u-down-right"
            },
            {
                "name": "arrow-u-down-right-bold",
                "icon": "mdi-arrow-u-down-right-bold"
            },
            {
                "name": "arrow-u-left-bottom",
                "icon": "mdi-arrow-u-left-bottom"
            },
            {
                "name": "arrow-u-left-bottom-bold",
                "icon": "mdi-arrow-u-left-bottom-bold"
            },
            {
                "name": "arrow-u-left-top",
                "icon": "mdi-arrow-u-left-top"
            },
            {
                "name": "arrow-u-left-top-bold",
                "icon": "mdi-arrow-u-left-top-bold"
            },
            {
                "name": "arrow-u-right-bottom",
                "icon": "mdi-arrow-u-right-bottom"
            },
            {
                "name": "arrow-u-right-bottom-bold",
                "icon": "mdi-arrow-u-right-bottom-bold"
            },
            {
                "name": "arrow-u-right-top",
                "icon": "mdi-arrow-u-right-top"
            },
            {
                "name": "arrow-u-right-top-bold",
                "icon": "mdi-arrow-u-right-top-bold"
            },
            {
                "name": "arrow-u-up-left",
                "icon": "mdi-arrow-u-up-left"
            },
            {
                "name": "arrow-u-up-left-bold",
                "icon": "mdi-arrow-u-up-left-bold"
            },
            {
                "name": "arrow-u-up-right",
                "icon": "mdi-arrow-u-up-right"
            },
            {
                "name": "arrow-u-up-right-bold",
                "icon": "mdi-arrow-u-up-right-bold"
            },
            {
                "name": "arrow-up",
                "icon": "mdi-arrow-up"
            },
            {
                "name": "arrow-up-bold",
                "icon": "mdi-arrow-up-bold"
            },
            {
                "name": "arrow-up-bold-box",
                "icon": "mdi-arrow-up-bold-box"
            },
            {
                "name": "arrow-up-bold-box-outline",
                "icon": "mdi-arrow-up-bold-box-outline"
            },
            {
                "name": "arrow-up-bold-circle",
                "icon": "mdi-arrow-up-bold-circle"
            },
            {
                "name": "arrow-up-bold-circle-outline",
                "icon": "mdi-arrow-up-bold-circle-outline"
            },
            {
                "name": "arrow-up-bold-hexagon-outline",
                "icon": "mdi-arrow-up-bold-hexagon-outline"
            },
            {
                "name": "arrow-up-bold-outline",
                "icon": "mdi-arrow-up-bold-outline"
            },
            {
                "name": "arrow-up-box",
                "icon": "mdi-arrow-up-box"
            },
            {
                "name": "arrow-up-circle",
                "icon": "mdi-arrow-up-circle"
            },
            {
                "name": "arrow-up-circle-outline",
                "icon": "mdi-arrow-up-circle-outline"
            },
            {
                "name": "arrow-up-down",
                "icon": "mdi-arrow-up-down"
            },
            {
                "name": "arrow-up-down-bold",
                "icon": "mdi-arrow-up-down-bold"
            },
            {
                "name": "arrow-up-down-bold-outline",
                "icon": "mdi-arrow-up-down-bold-outline"
            },
            {
                "name": "arrow-up-drop-circle",
                "icon": "mdi-arrow-up-drop-circle"
            },
            {
                "name": "arrow-up-drop-circle-outline",
                "icon": "mdi-arrow-up-drop-circle-outline"
            },
            {
                "name": "arrow-up-left",
                "icon": "mdi-arrow-up-left"
            },
            {
                "name": "arrow-up-left-bold",
                "icon": "mdi-arrow-up-left-bold"
            },
            {
                "name": "arrow-up-right",
                "icon": "mdi-arrow-up-right"
            },
            {
                "name": "arrow-up-right-bold",
                "icon": "mdi-arrow-up-right-bold"
            },
            {
                "name": "arrow-up-thick",
                "icon": "mdi-arrow-up-thick"
            },
            {
                "name": "arrow-up-thin",
                "icon": "mdi-arrow-up-thin"
            },
            {
                "name": "arrow-up-thin-circle-outline",
                "icon": "mdi-arrow-up-thin-circle-outline"
            },
            {
                "name": "arrow-vertical-lock",
                "icon": "mdi-arrow-vertical-lock"
            },
            {
                "name": "artboard",
                "icon": "mdi-artboard"
            },
            {
                "name": "artstation",
                "icon": "mdi-artstation"
            },
            {
                "name": "aspect-ratio",
                "icon": "mdi-aspect-ratio"
            },
            {
                "name": "assistant",
                "icon": "mdi-assistant"
            },
            {
                "name": "asterisk",
                "icon": "mdi-asterisk"
            },
            {
                "name": "asterisk-circle-outline",
                "icon": "mdi-asterisk-circle-outline"
            },
            {
                "name": "at",
                "icon": "mdi-at"
            },
            {
                "name": "atlassian",
                "icon": "mdi-atlassian"
            },
            {
                "name": "atm",
                "icon": "mdi-atm"
            },
            {
                "name": "atom",
                "icon": "mdi-atom"
            },
            {
                "name": "atom-variant",
                "icon": "mdi-atom-variant"
            },
            {
                "name": "attachment",
                "icon": "mdi-attachment"
            },
            {
                "name": "attachment-check",
                "icon": "mdi-attachment-check"
            },
            {
                "name": "attachment-lock",
                "icon": "mdi-attachment-lock"
            },
            {
                "name": "attachment-minus",
                "icon": "mdi-attachment-minus"
            },
            {
                "name": "attachment-off",
                "icon": "mdi-attachment-off"
            },
            {
                "name": "attachment-plus",
                "icon": "mdi-attachment-plus"
            },
            {
                "name": "attachment-remove",
                "icon": "mdi-attachment-remove"
            },
            {
                "name": "atv",
                "icon": "mdi-atv"
            },
            {
                "name": "audio-input-rca",
                "icon": "mdi-audio-input-rca"
            },
            {
                "name": "audio-input-stereo-minijack",
                "icon": "mdi-audio-input-stereo-minijack"
            },
            {
                "name": "audio-input-xlr",
                "icon": "mdi-audio-input-xlr"
            },
            {
                "name": "audio-video",
                "icon": "mdi-audio-video"
            },
            {
                "name": "audio-video-off",
                "icon": "mdi-audio-video-off"
            },
            {
                "name": "augmented-reality",
                "icon": "mdi-augmented-reality"
            },
            {
                "name": "aurora",
                "icon": "mdi-aurora"
            },
            {
                "name": "auto-download",
                "icon": "mdi-auto-download"
            },
            {
                "name": "auto-fix",
                "icon": "mdi-auto-fix"
            },
            {
                "name": "auto-upload",
                "icon": "mdi-auto-upload"
            },
            {
                "name": "autorenew",
                "icon": "mdi-autorenew"
            },
            {
                "name": "autorenew-off",
                "icon": "mdi-autorenew-off"
            },
            {
                "name": "av-timer",
                "icon": "mdi-av-timer"
            },
            {
                "name": "awning",
                "icon": "mdi-awning"
            },
            {
                "name": "awning-outline",
                "icon": "mdi-awning-outline"
            },
            {
                "name": "aws",
                "icon": "mdi-aws"
            },
            {
                "name": "axe",
                "icon": "mdi-axe"
            },
            {
                "name": "axe-battle",
                "icon": "mdi-axe-battle"
            },
            {
                "name": "axis",
                "icon": "mdi-axis"
            },
            {
                "name": "axis-arrow",
                "icon": "mdi-axis-arrow"
            },
            {
                "name": "axis-arrow-info",
                "icon": "mdi-axis-arrow-info"
            },
            {
                "name": "axis-arrow-lock",
                "icon": "mdi-axis-arrow-lock"
            },
            {
                "name": "axis-lock",
                "icon": "mdi-axis-lock"
            },
            {
                "name": "axis-x-arrow",
                "icon": "mdi-axis-x-arrow"
            },
            {
                "name": "axis-x-arrow-lock",
                "icon": "mdi-axis-x-arrow-lock"
            },
            {
                "name": "axis-x-rotate-clockwise",
                "icon": "mdi-axis-x-rotate-clockwise"
            },
            {
                "name": "axis-x-rotate-counterclockwise",
                "icon": "mdi-axis-x-rotate-counterclockwise"
            },
            {
                "name": "axis-x-y-arrow-lock",
                "icon": "mdi-axis-x-y-arrow-lock"
            },
            {
                "name": "axis-y-arrow",
                "icon": "mdi-axis-y-arrow"
            },
            {
                "name": "axis-y-arrow-lock",
                "icon": "mdi-axis-y-arrow-lock"
            },
            {
                "name": "axis-y-rotate-clockwise",
                "icon": "mdi-axis-y-rotate-clockwise"
            },
            {
                "name": "axis-y-rotate-counterclockwise",
                "icon": "mdi-axis-y-rotate-counterclockwise"
            },
            {
                "name": "axis-z-arrow",
                "icon": "mdi-axis-z-arrow"
            },
            {
                "name": "axis-z-arrow-lock",
                "icon": "mdi-axis-z-arrow-lock"
            },
            {
                "name": "axis-z-rotate-clockwise",
                "icon": "mdi-axis-z-rotate-clockwise"
            },
            {
                "name": "axis-z-rotate-counterclockwise",
                "icon": "mdi-axis-z-rotate-counterclockwise"
            },
            {
                "name": "babel",
                "icon": "mdi-babel"
            },
            {
                "name": "baby",
                "icon": "mdi-baby"
            },
            {
                "name": "baby-bottle",
                "icon": "mdi-baby-bottle"
            },
            {
                "name": "baby-bottle-outline",
                "icon": "mdi-baby-bottle-outline"
            },
            {
                "name": "baby-buggy",
                "icon": "mdi-baby-buggy"
            },
            {
                "name": "baby-buggy-off",
                "icon": "mdi-baby-buggy-off"
            },
            {
                "name": "baby-carriage",
                "icon": "mdi-baby-carriage"
            },
            {
                "name": "baby-carriage-off",
                "icon": "mdi-baby-carriage-off"
            },
            {
                "name": "baby-face",
                "icon": "mdi-baby-face"
            },
            {
                "name": "baby-face-outline",
                "icon": "mdi-baby-face-outline"
            },
            {
                "name": "backburger",
                "icon": "mdi-backburger"
            },
            {
                "name": "backspace",
                "icon": "mdi-backspace"
            },
            {
                "name": "backspace-outline",
                "icon": "mdi-backspace-outline"
            },
            {
                "name": "backspace-reverse",
                "icon": "mdi-backspace-reverse"
            },
            {
                "name": "backspace-reverse-outline",
                "icon": "mdi-backspace-reverse-outline"
            },
            {
                "name": "backup-restore",
                "icon": "mdi-backup-restore"
            },
            {
                "name": "bacteria",
                "icon": "mdi-bacteria"
            },
            {
                "name": "bacteria-outline",
                "icon": "mdi-bacteria-outline"
            },
            {
                "name": "badge-account",
                "icon": "mdi-badge-account"
            },
            {
                "name": "badge-account-alert",
                "icon": "mdi-badge-account-alert"
            },
            {
                "name": "badge-account-alert-outline",
                "icon": "mdi-badge-account-alert-outline"
            },
            {
                "name": "badge-account-horizontal",
                "icon": "mdi-badge-account-horizontal"
            },
            {
                "name": "badge-account-horizontal-outline",
                "icon": "mdi-badge-account-horizontal-outline"
            },
            {
                "name": "badge-account-outline",
                "icon": "mdi-badge-account-outline"
            },
            {
                "name": "badminton",
                "icon": "mdi-badminton"
            },
            {
                "name": "bag-carry-on",
                "icon": "mdi-bag-carry-on"
            },
            {
                "name": "bag-carry-on-check",
                "icon": "mdi-bag-carry-on-check"
            },
            {
                "name": "bag-carry-on-off",
                "icon": "mdi-bag-carry-on-off"
            },
            {
                "name": "bag-checked",
                "icon": "mdi-bag-checked"
            },
            {
                "name": "bag-personal",
                "icon": "mdi-bag-personal"
            },
            {
                "name": "bag-personal-off",
                "icon": "mdi-bag-personal-off"
            },
            {
                "name": "bag-personal-off-outline",
                "icon": "mdi-bag-personal-off-outline"
            },
            {
                "name": "bag-personal-outline",
                "icon": "mdi-bag-personal-outline"
            },
            {
                "name": "bag-personal-tag",
                "icon": "mdi-bag-personal-tag"
            },
            {
                "name": "bag-personal-tag-outline",
                "icon": "mdi-bag-personal-tag-outline"
            },
            {
                "name": "bag-suitcase",
                "icon": "mdi-bag-suitcase"
            },
            {
                "name": "bag-suitcase-off",
                "icon": "mdi-bag-suitcase-off"
            },
            {
                "name": "bag-suitcase-off-outline",
                "icon": "mdi-bag-suitcase-off-outline"
            },
            {
                "name": "bag-suitcase-outline",
                "icon": "mdi-bag-suitcase-outline"
            },
            {
                "name": "baguette",
                "icon": "mdi-baguette"
            },
            {
                "name": "balcony",
                "icon": "mdi-balcony"
            },
            {
                "name": "balloon",
                "icon": "mdi-balloon"
            },
            {
                "name": "ballot",
                "icon": "mdi-ballot"
            },
            {
                "name": "ballot-outline",
                "icon": "mdi-ballot-outline"
            },
            {
                "name": "ballot-recount",
                "icon": "mdi-ballot-recount"
            },
            {
                "name": "ballot-recount-outline",
                "icon": "mdi-ballot-recount-outline"
            },
            {
                "name": "bandage",
                "icon": "mdi-bandage"
            },
            {
                "name": "bank",
                "icon": "mdi-bank"
            },
            {
                "name": "bank-check",
                "icon": "mdi-bank-check"
            },
            {
                "name": "bank-minus",
                "icon": "mdi-bank-minus"
            },
            {
                "name": "bank-off",
                "icon": "mdi-bank-off"
            },
            {
                "name": "bank-off-outline",
                "icon": "mdi-bank-off-outline"
            },
            {
                "name": "bank-outline",
                "icon": "mdi-bank-outline"
            },
            {
                "name": "bank-plus",
                "icon": "mdi-bank-plus"
            },
            {
                "name": "bank-remove",
                "icon": "mdi-bank-remove"
            },
            {
                "name": "bank-transfer",
                "icon": "mdi-bank-transfer"
            },
            {
                "name": "bank-transfer-in",
                "icon": "mdi-bank-transfer-in"
            },
            {
                "name": "bank-transfer-out",
                "icon": "mdi-bank-transfer-out"
            },
            {
                "name": "barcode",
                "icon": "mdi-barcode"
            },
            {
                "name": "barcode-off",
                "icon": "mdi-barcode-off"
            },
            {
                "name": "barcode-scan",
                "icon": "mdi-barcode-scan"
            },
            {
                "name": "barley",
                "icon": "mdi-barley"
            },
            {
                "name": "barley-off",
                "icon": "mdi-barley-off"
            },
            {
                "name": "barn",
                "icon": "mdi-barn"
            },
            {
                "name": "barrel",
                "icon": "mdi-barrel"
            },
            {
                "name": "barrel-outline",
                "icon": "mdi-barrel-outline"
            },
            {
                "name": "baseball",
                "icon": "mdi-baseball"
            },
            {
                "name": "baseball-bat",
                "icon": "mdi-baseball-bat"
            },
            {
                "name": "baseball-diamond",
                "icon": "mdi-baseball-diamond"
            },
            {
                "name": "baseball-diamond-outline",
                "icon": "mdi-baseball-diamond-outline"
            },
            {
                "name": "bash",
                "icon": "mdi-bash"
            },
            {
                "name": "basket",
                "icon": "mdi-basket"
            },
            {
                "name": "basket-check",
                "icon": "mdi-basket-check"
            },
            {
                "name": "basket-check-outline",
                "icon": "mdi-basket-check-outline"
            },
            {
                "name": "basket-fill",
                "icon": "mdi-basket-fill"
            },
            {
                "name": "basket-minus",
                "icon": "mdi-basket-minus"
            },
            {
                "name": "basket-minus-outline",
                "icon": "mdi-basket-minus-outline"
            },
            {
                "name": "basket-off",
                "icon": "mdi-basket-off"
            },
            {
                "name": "basket-off-outline",
                "icon": "mdi-basket-off-outline"
            },
            {
                "name": "basket-outline",
                "icon": "mdi-basket-outline"
            },
            {
                "name": "basket-plus",
                "icon": "mdi-basket-plus"
            },
            {
                "name": "basket-plus-outline",
                "icon": "mdi-basket-plus-outline"
            },
            {
                "name": "basket-remove",
                "icon": "mdi-basket-remove"
            },
            {
                "name": "basket-remove-outline",
                "icon": "mdi-basket-remove-outline"
            },
            {
                "name": "basket-unfill",
                "icon": "mdi-basket-unfill"
            },
            {
                "name": "basketball",
                "icon": "mdi-basketball"
            },
            {
                "name": "basketball-hoop",
                "icon": "mdi-basketball-hoop"
            },
            {
                "name": "basketball-hoop-outline",
                "icon": "mdi-basketball-hoop-outline"
            },
            {
                "name": "bat",
                "icon": "mdi-bat"
            },
            {
                "name": "bathtub",
                "icon": "mdi-bathtub"
            },
            {
                "name": "bathtub-outline",
                "icon": "mdi-bathtub-outline"
            },
            {
                "name": "battery",
                "icon": "mdi-battery"
            },
            {
                "name": "battery-10",
                "icon": "mdi-battery-10"
            },
            {
                "name": "battery-10-bluetooth",
                "icon": "mdi-battery-10-bluetooth"
            },
            {
                "name": "battery-20",
                "icon": "mdi-battery-20"
            },
            {
                "name": "battery-20-bluetooth",
                "icon": "mdi-battery-20-bluetooth"
            },
            {
                "name": "battery-30",
                "icon": "mdi-battery-30"
            },
            {
                "name": "battery-30-bluetooth",
                "icon": "mdi-battery-30-bluetooth"
            },
            {
                "name": "battery-40",
                "icon": "mdi-battery-40"
            },
            {
                "name": "battery-40-bluetooth",
                "icon": "mdi-battery-40-bluetooth"
            },
            {
                "name": "battery-50",
                "icon": "mdi-battery-50"
            },
            {
                "name": "battery-50-bluetooth",
                "icon": "mdi-battery-50-bluetooth"
            },
            {
                "name": "battery-60",
                "icon": "mdi-battery-60"
            },
            {
                "name": "battery-60-bluetooth",
                "icon": "mdi-battery-60-bluetooth"
            },
            {
                "name": "battery-70",
                "icon": "mdi-battery-70"
            },
            {
                "name": "battery-70-bluetooth",
                "icon": "mdi-battery-70-bluetooth"
            },
            {
                "name": "battery-80",
                "icon": "mdi-battery-80"
            },
            {
                "name": "battery-80-bluetooth",
                "icon": "mdi-battery-80-bluetooth"
            },
            {
                "name": "battery-90",
                "icon": "mdi-battery-90"
            },
            {
                "name": "battery-90-bluetooth",
                "icon": "mdi-battery-90-bluetooth"
            },
            {
                "name": "battery-alert",
                "icon": "mdi-battery-alert"
            },
            {
                "name": "battery-alert-bluetooth",
                "icon": "mdi-battery-alert-bluetooth"
            },
            {
                "name": "battery-alert-variant",
                "icon": "mdi-battery-alert-variant"
            },
            {
                "name": "battery-alert-variant-outline",
                "icon": "mdi-battery-alert-variant-outline"
            },
            {
                "name": "battery-arrow-down",
                "icon": "mdi-battery-arrow-down"
            },
            {
                "name": "battery-arrow-down-outline",
                "icon": "mdi-battery-arrow-down-outline"
            },
            {
                "name": "battery-arrow-up",
                "icon": "mdi-battery-arrow-up"
            },
            {
                "name": "battery-arrow-up-outline",
                "icon": "mdi-battery-arrow-up-outline"
            },
            {
                "name": "battery-bluetooth",
                "icon": "mdi-battery-bluetooth"
            },
            {
                "name": "battery-bluetooth-variant",
                "icon": "mdi-battery-bluetooth-variant"
            },
            {
                "name": "battery-charging",
                "icon": "mdi-battery-charging"
            },
            {
                "name": "battery-charging-10",
                "icon": "mdi-battery-charging-10"
            },
            {
                "name": "battery-charging-100",
                "icon": "mdi-battery-charging-100"
            },
            {
                "name": "battery-charging-20",
                "icon": "mdi-battery-charging-20"
            },
            {
                "name": "battery-charging-30",
                "icon": "mdi-battery-charging-30"
            },
            {
                "name": "battery-charging-40",
                "icon": "mdi-battery-charging-40"
            },
            {
                "name": "battery-charging-50",
                "icon": "mdi-battery-charging-50"
            },
            {
                "name": "battery-charging-60",
                "icon": "mdi-battery-charging-60"
            },
            {
                "name": "battery-charging-70",
                "icon": "mdi-battery-charging-70"
            },
            {
                "name": "battery-charging-80",
                "icon": "mdi-battery-charging-80"
            },
            {
                "name": "battery-charging-90",
                "icon": "mdi-battery-charging-90"
            },
            {
                "name": "battery-charging-high",
                "icon": "mdi-battery-charging-high"
            },
            {
                "name": "battery-charging-low",
                "icon": "mdi-battery-charging-low"
            },
            {
                "name": "battery-charging-medium",
                "icon": "mdi-battery-charging-medium"
            },
            {
                "name": "battery-charging-outline",
                "icon": "mdi-battery-charging-outline"
            },
            {
                "name": "battery-charging-wireless",
                "icon": "mdi-battery-charging-wireless"
            },
            {
                "name": "battery-charging-wireless-10",
                "icon": "mdi-battery-charging-wireless-10"
            },
            {
                "name": "battery-charging-wireless-20",
                "icon": "mdi-battery-charging-wireless-20"
            },
            {
                "name": "battery-charging-wireless-30",
                "icon": "mdi-battery-charging-wireless-30"
            },
            {
                "name": "battery-charging-wireless-40",
                "icon": "mdi-battery-charging-wireless-40"
            },
            {
                "name": "battery-charging-wireless-50",
                "icon": "mdi-battery-charging-wireless-50"
            },
            {
                "name": "battery-charging-wireless-60",
                "icon": "mdi-battery-charging-wireless-60"
            },
            {
                "name": "battery-charging-wireless-70",
                "icon": "mdi-battery-charging-wireless-70"
            },
            {
                "name": "battery-charging-wireless-80",
                "icon": "mdi-battery-charging-wireless-80"
            },
            {
                "name": "battery-charging-wireless-90",
                "icon": "mdi-battery-charging-wireless-90"
            },
            {
                "name": "battery-charging-wireless-alert",
                "icon": "mdi-battery-charging-wireless-alert"
            },
            {
                "name": "battery-charging-wireless-outline",
                "icon": "mdi-battery-charging-wireless-outline"
            },
            {
                "name": "battery-check",
                "icon": "mdi-battery-check"
            },
            {
                "name": "battery-check-outline",
                "icon": "mdi-battery-check-outline"
            },
            {
                "name": "battery-clock",
                "icon": "mdi-battery-clock"
            },
            {
                "name": "battery-clock-outline",
                "icon": "mdi-battery-clock-outline"
            },
            {
                "name": "battery-heart",
                "icon": "mdi-battery-heart"
            },
            {
                "name": "battery-heart-outline",
                "icon": "mdi-battery-heart-outline"
            },
            {
                "name": "battery-heart-variant",
                "icon": "mdi-battery-heart-variant"
            },
            {
                "name": "battery-high",
                "icon": "mdi-battery-high"
            },
            {
                "name": "battery-lock",
                "icon": "mdi-battery-lock"
            },
            {
                "name": "battery-lock-open",
                "icon": "mdi-battery-lock-open"
            },
            {
                "name": "battery-low",
                "icon": "mdi-battery-low"
            },
            {
                "name": "battery-medium",
                "icon": "mdi-battery-medium"
            },
            {
                "name": "battery-minus",
                "icon": "mdi-battery-minus"
            },
            {
                "name": "battery-minus-outline",
                "icon": "mdi-battery-minus-outline"
            },
            {
                "name": "battery-minus-variant",
                "icon": "mdi-battery-minus-variant"
            },
            {
                "name": "battery-negative",
                "icon": "mdi-battery-negative"
            },
            {
                "name": "battery-off",
                "icon": "mdi-battery-off"
            },
            {
                "name": "battery-off-outline",
                "icon": "mdi-battery-off-outline"
            },
            {
                "name": "battery-outline",
                "icon": "mdi-battery-outline"
            },
            {
                "name": "battery-plus",
                "icon": "mdi-battery-plus"
            },
            {
                "name": "battery-plus-outline",
                "icon": "mdi-battery-plus-outline"
            },
            {
                "name": "battery-plus-variant",
                "icon": "mdi-battery-plus-variant"
            },
            {
                "name": "battery-positive",
                "icon": "mdi-battery-positive"
            },
            {
                "name": "battery-remove",
                "icon": "mdi-battery-remove"
            },
            {
                "name": "battery-remove-outline",
                "icon": "mdi-battery-remove-outline"
            },
            {
                "name": "battery-sync",
                "icon": "mdi-battery-sync"
            },
            {
                "name": "battery-sync-outline",
                "icon": "mdi-battery-sync-outline"
            },
            {
                "name": "battery-unknown",
                "icon": "mdi-battery-unknown"
            },
            {
                "name": "battery-unknown-bluetooth",
                "icon": "mdi-battery-unknown-bluetooth"
            },
            {
                "name": "beach",
                "icon": "mdi-beach"
            },
            {
                "name": "beaker",
                "icon": "mdi-beaker"
            },
            {
                "name": "beaker-alert",
                "icon": "mdi-beaker-alert"
            },
            {
                "name": "beaker-alert-outline",
                "icon": "mdi-beaker-alert-outline"
            },
            {
                "name": "beaker-check",
                "icon": "mdi-beaker-check"
            },
            {
                "name": "beaker-check-outline",
                "icon": "mdi-beaker-check-outline"
            },
            {
                "name": "beaker-minus",
                "icon": "mdi-beaker-minus"
            },
            {
                "name": "beaker-minus-outline",
                "icon": "mdi-beaker-minus-outline"
            },
            {
                "name": "beaker-outline",
                "icon": "mdi-beaker-outline"
            },
            {
                "name": "beaker-plus",
                "icon": "mdi-beaker-plus"
            },
            {
                "name": "beaker-plus-outline",
                "icon": "mdi-beaker-plus-outline"
            },
            {
                "name": "beaker-question",
                "icon": "mdi-beaker-question"
            },
            {
                "name": "beaker-question-outline",
                "icon": "mdi-beaker-question-outline"
            },
            {
                "name": "beaker-remove",
                "icon": "mdi-beaker-remove"
            },
            {
                "name": "beaker-remove-outline",
                "icon": "mdi-beaker-remove-outline"
            },
            {
                "name": "bed",
                "icon": "mdi-bed"
            },
            {
                "name": "bed-clock",
                "icon": "mdi-bed-clock"
            },
            {
                "name": "bed-double",
                "icon": "mdi-bed-double"
            },
            {
                "name": "bed-double-outline",
                "icon": "mdi-bed-double-outline"
            },
            {
                "name": "bed-empty",
                "icon": "mdi-bed-empty"
            },
            {
                "name": "bed-king",
                "icon": "mdi-bed-king"
            },
            {
                "name": "bed-king-outline",
                "icon": "mdi-bed-king-outline"
            },
            {
                "name": "bed-outline",
                "icon": "mdi-bed-outline"
            },
            {
                "name": "bed-queen",
                "icon": "mdi-bed-queen"
            },
            {
                "name": "bed-queen-outline",
                "icon": "mdi-bed-queen-outline"
            },
            {
                "name": "bed-single",
                "icon": "mdi-bed-single"
            },
            {
                "name": "bed-single-outline",
                "icon": "mdi-bed-single-outline"
            },
            {
                "name": "bee",
                "icon": "mdi-bee"
            },
            {
                "name": "bee-flower",
                "icon": "mdi-bee-flower"
            },
            {
                "name": "beehive-off-outline",
                "icon": "mdi-beehive-off-outline"
            },
            {
                "name": "beehive-outline",
                "icon": "mdi-beehive-outline"
            },
            {
                "name": "beekeeper",
                "icon": "mdi-beekeeper"
            },
            {
                "name": "beer",
                "icon": "mdi-beer"
            },
            {
                "name": "beer-outline",
                "icon": "mdi-beer-outline"
            },
            {
                "name": "bell",
                "icon": "mdi-bell"
            },
            {
                "name": "bell-alert",
                "icon": "mdi-bell-alert"
            },
            {
                "name": "bell-alert-outline",
                "icon": "mdi-bell-alert-outline"
            },
            {
                "name": "bell-badge",
                "icon": "mdi-bell-badge"
            },
            {
                "name": "bell-badge-outline",
                "icon": "mdi-bell-badge-outline"
            },
            {
                "name": "bell-cancel",
                "icon": "mdi-bell-cancel"
            },
            {
                "name": "bell-cancel-outline",
                "icon": "mdi-bell-cancel-outline"
            },
            {
                "name": "bell-check",
                "icon": "mdi-bell-check"
            },
            {
                "name": "bell-check-outline",
                "icon": "mdi-bell-check-outline"
            },
            {
                "name": "bell-circle",
                "icon": "mdi-bell-circle"
            },
            {
                "name": "bell-circle-outline",
                "icon": "mdi-bell-circle-outline"
            },
            {
                "name": "bell-cog",
                "icon": "mdi-bell-cog"
            },
            {
                "name": "bell-cog-outline",
                "icon": "mdi-bell-cog-outline"
            },
            {
                "name": "bell-minus",
                "icon": "mdi-bell-minus"
            },
            {
                "name": "bell-minus-outline",
                "icon": "mdi-bell-minus-outline"
            },
            {
                "name": "bell-off",
                "icon": "mdi-bell-off"
            },
            {
                "name": "bell-off-outline",
                "icon": "mdi-bell-off-outline"
            },
            {
                "name": "bell-outline",
                "icon": "mdi-bell-outline"
            },
            {
                "name": "bell-plus",
                "icon": "mdi-bell-plus"
            },
            {
                "name": "bell-plus-outline",
                "icon": "mdi-bell-plus-outline"
            },
            {
                "name": "bell-remove",
                "icon": "mdi-bell-remove"
            },
            {
                "name": "bell-remove-outline",
                "icon": "mdi-bell-remove-outline"
            },
            {
                "name": "bell-ring",
                "icon": "mdi-bell-ring"
            },
            {
                "name": "bell-ring-outline",
                "icon": "mdi-bell-ring-outline"
            },
            {
                "name": "bell-sleep",
                "icon": "mdi-bell-sleep"
            },
            {
                "name": "bell-sleep-outline",
                "icon": "mdi-bell-sleep-outline"
            },
            {
                "name": "beta",
                "icon": "mdi-beta"
            },
            {
                "name": "betamax",
                "icon": "mdi-betamax"
            },
            {
                "name": "biathlon",
                "icon": "mdi-biathlon"
            },
            {
                "name": "bicycle",
                "icon": "mdi-bicycle"
            },
            {
                "name": "bicycle-basket",
                "icon": "mdi-bicycle-basket"
            },
            {
                "name": "bicycle-cargo",
                "icon": "mdi-bicycle-cargo"
            },
            {
                "name": "bicycle-electric",
                "icon": "mdi-bicycle-electric"
            },
            {
                "name": "bicycle-penny-farthing",
                "icon": "mdi-bicycle-penny-farthing"
            },
            {
                "name": "bike",
                "icon": "mdi-bike"
            },
            {
                "name": "bike-fast",
                "icon": "mdi-bike-fast"
            },
            {
                "name": "billboard",
                "icon": "mdi-billboard"
            },
            {
                "name": "billiards",
                "icon": "mdi-billiards"
            },
            {
                "name": "billiards-rack",
                "icon": "mdi-billiards-rack"
            },
            {
                "name": "binoculars",
                "icon": "mdi-binoculars"
            },
            {
                "name": "bio",
                "icon": "mdi-bio"
            },
            {
                "name": "biohazard",
                "icon": "mdi-biohazard"
            },
            {
                "name": "bird",
                "icon": "mdi-bird"
            },
            {
                "name": "bitbucket",
                "icon": "mdi-bitbucket"
            },
            {
                "name": "bitcoin",
                "icon": "mdi-bitcoin"
            },
            {
                "name": "black-mesa",
                "icon": "mdi-black-mesa"
            },
            {
                "name": "blender",
                "icon": "mdi-blender"
            },
            {
                "name": "blender-outline",
                "icon": "mdi-blender-outline"
            },
            {
                "name": "blender-software",
                "icon": "mdi-blender-software"
            },
            {
                "name": "blinds",
                "icon": "mdi-blinds"
            },
            {
                "name": "blinds-horizontal",
                "icon": "mdi-blinds-horizontal"
            },
            {
                "name": "blinds-horizontal-closed",
                "icon": "mdi-blinds-horizontal-closed"
            },
            {
                "name": "blinds-open",
                "icon": "mdi-blinds-open"
            },
            {
                "name": "blinds-vertical",
                "icon": "mdi-blinds-vertical"
            },
            {
                "name": "blinds-vertical-closed",
                "icon": "mdi-blinds-vertical-closed"
            },
            {
                "name": "block-helper",
                "icon": "mdi-block-helper"
            },
            {
                "name": "blood-bag",
                "icon": "mdi-blood-bag"
            },
            {
                "name": "bluetooth",
                "icon": "mdi-bluetooth"
            },
            {
                "name": "bluetooth-audio",
                "icon": "mdi-bluetooth-audio"
            },
            {
                "name": "bluetooth-connect",
                "icon": "mdi-bluetooth-connect"
            },
            {
                "name": "bluetooth-off",
                "icon": "mdi-bluetooth-off"
            },
            {
                "name": "bluetooth-settings",
                "icon": "mdi-bluetooth-settings"
            },
            {
                "name": "bluetooth-transfer",
                "icon": "mdi-bluetooth-transfer"
            },
            {
                "name": "blur",
                "icon": "mdi-blur"
            },
            {
                "name": "blur-linear",
                "icon": "mdi-blur-linear"
            },
            {
                "name": "blur-off",
                "icon": "mdi-blur-off"
            },
            {
                "name": "blur-radial",
                "icon": "mdi-blur-radial"
            },
            {
                "name": "bolt",
                "icon": "mdi-bolt"
            },
            {
                "name": "bomb",
                "icon": "mdi-bomb"
            },
            {
                "name": "bomb-off",
                "icon": "mdi-bomb-off"
            },
            {
                "name": "bone",
                "icon": "mdi-bone"
            },
            {
                "name": "bone-off",
                "icon": "mdi-bone-off"
            },
            {
                "name": "book",
                "icon": "mdi-book"
            },
            {
                "name": "book-account",
                "icon": "mdi-book-account"
            },
            {
                "name": "book-account-outline",
                "icon": "mdi-book-account-outline"
            },
            {
                "name": "book-alert",
                "icon": "mdi-book-alert"
            },
            {
                "name": "book-alert-outline",
                "icon": "mdi-book-alert-outline"
            },
            {
                "name": "book-alphabet",
                "icon": "mdi-book-alphabet"
            },
            {
                "name": "book-arrow-down",
                "icon": "mdi-book-arrow-down"
            },
            {
                "name": "book-arrow-down-outline",
                "icon": "mdi-book-arrow-down-outline"
            },
            {
                "name": "book-arrow-left",
                "icon": "mdi-book-arrow-left"
            },
            {
                "name": "book-arrow-left-outline",
                "icon": "mdi-book-arrow-left-outline"
            },
            {
                "name": "book-arrow-right",
                "icon": "mdi-book-arrow-right"
            },
            {
                "name": "book-arrow-right-outline",
                "icon": "mdi-book-arrow-right-outline"
            },
            {
                "name": "book-arrow-up",
                "icon": "mdi-book-arrow-up"
            },
            {
                "name": "book-arrow-up-outline",
                "icon": "mdi-book-arrow-up-outline"
            },
            {
                "name": "book-cancel",
                "icon": "mdi-book-cancel"
            },
            {
                "name": "book-cancel-outline",
                "icon": "mdi-book-cancel-outline"
            },
            {
                "name": "book-check",
                "icon": "mdi-book-check"
            },
            {
                "name": "book-check-outline",
                "icon": "mdi-book-check-outline"
            },
            {
                "name": "book-clock",
                "icon": "mdi-book-clock"
            },
            {
                "name": "book-clock-outline",
                "icon": "mdi-book-clock-outline"
            },
            {
                "name": "book-cog",
                "icon": "mdi-book-cog"
            },
            {
                "name": "book-cog-outline",
                "icon": "mdi-book-cog-outline"
            },
            {
                "name": "book-cross",
                "icon": "mdi-book-cross"
            },
            {
                "name": "book-edit",
                "icon": "mdi-book-edit"
            },
            {
                "name": "book-edit-outline",
                "icon": "mdi-book-edit-outline"
            },
            {
                "name": "book-education",
                "icon": "mdi-book-education"
            },
            {
                "name": "book-education-outline",
                "icon": "mdi-book-education-outline"
            },
            {
                "name": "book-heart",
                "icon": "mdi-book-heart"
            },
            {
                "name": "book-heart-outline",
                "icon": "mdi-book-heart-outline"
            },
            {
                "name": "book-information-variant",
                "icon": "mdi-book-information-variant"
            },
            {
                "name": "book-lock",
                "icon": "mdi-book-lock"
            },
            {
                "name": "book-lock-open",
                "icon": "mdi-book-lock-open"
            },
            {
                "name": "book-lock-open-outline",
                "icon": "mdi-book-lock-open-outline"
            },
            {
                "name": "book-lock-outline",
                "icon": "mdi-book-lock-outline"
            },
            {
                "name": "book-marker",
                "icon": "mdi-book-marker"
            },
            {
                "name": "book-marker-outline",
                "icon": "mdi-book-marker-outline"
            },
            {
                "name": "book-minus",
                "icon": "mdi-book-minus"
            },
            {
                "name": "book-minus-multiple",
                "icon": "mdi-book-minus-multiple"
            },
            {
                "name": "book-minus-multiple-outline",
                "icon": "mdi-book-minus-multiple-outline"
            },
            {
                "name": "book-minus-outline",
                "icon": "mdi-book-minus-outline"
            },
            {
                "name": "book-multiple",
                "icon": "mdi-book-multiple"
            },
            {
                "name": "book-multiple-outline",
                "icon": "mdi-book-multiple-outline"
            },
            {
                "name": "book-music",
                "icon": "mdi-book-music"
            },
            {
                "name": "book-music-outline",
                "icon": "mdi-book-music-outline"
            },
            {
                "name": "book-off",
                "icon": "mdi-book-off"
            },
            {
                "name": "book-off-outline",
                "icon": "mdi-book-off-outline"
            },
            {
                "name": "book-open",
                "icon": "mdi-book-open"
            },
            {
                "name": "book-open-blank-variant",
                "icon": "mdi-book-open-blank-variant"
            },
            {
                "name": "book-open-outline",
                "icon": "mdi-book-open-outline"
            },
            {
                "name": "book-open-page-variant",
                "icon": "mdi-book-open-page-variant"
            },
            {
                "name": "book-open-page-variant-outline",
                "icon": "mdi-book-open-page-variant-outline"
            },
            {
                "name": "book-open-variant",
                "icon": "mdi-book-open-variant"
            },
            {
                "name": "book-outline",
                "icon": "mdi-book-outline"
            },
            {
                "name": "book-play",
                "icon": "mdi-book-play"
            },
            {
                "name": "book-play-outline",
                "icon": "mdi-book-play-outline"
            },
            {
                "name": "book-plus",
                "icon": "mdi-book-plus"
            },
            {
                "name": "book-plus-multiple",
                "icon": "mdi-book-plus-multiple"
            },
            {
                "name": "book-plus-multiple-outline",
                "icon": "mdi-book-plus-multiple-outline"
            },
            {
                "name": "book-plus-outline",
                "icon": "mdi-book-plus-outline"
            },
            {
                "name": "book-refresh",
                "icon": "mdi-book-refresh"
            },
            {
                "name": "book-refresh-outline",
                "icon": "mdi-book-refresh-outline"
            },
            {
                "name": "book-remove",
                "icon": "mdi-book-remove"
            },
            {
                "name": "book-remove-multiple",
                "icon": "mdi-book-remove-multiple"
            },
            {
                "name": "book-remove-multiple-outline",
                "icon": "mdi-book-remove-multiple-outline"
            },
            {
                "name": "book-remove-outline",
                "icon": "mdi-book-remove-outline"
            },
            {
                "name": "book-search",
                "icon": "mdi-book-search"
            },
            {
                "name": "book-search-outline",
                "icon": "mdi-book-search-outline"
            },
            {
                "name": "book-settings",
                "icon": "mdi-book-settings"
            },
            {
                "name": "book-settings-outline",
                "icon": "mdi-book-settings-outline"
            },
            {
                "name": "book-sync",
                "icon": "mdi-book-sync"
            },
            {
                "name": "book-sync-outline",
                "icon": "mdi-book-sync-outline"
            },
            {
                "name": "book-variant",
                "icon": "mdi-book-variant"
            },
            {
                "name": "bookmark",
                "icon": "mdi-bookmark"
            },
            {
                "name": "bookmark-box",
                "icon": "mdi-bookmark-box"
            },
            {
                "name": "bookmark-box-multiple",
                "icon": "mdi-bookmark-box-multiple"
            },
            {
                "name": "bookmark-box-multiple-outline",
                "icon": "mdi-bookmark-box-multiple-outline"
            },
            {
                "name": "bookmark-box-outline",
                "icon": "mdi-bookmark-box-outline"
            },
            {
                "name": "bookmark-check",
                "icon": "mdi-bookmark-check"
            },
            {
                "name": "bookmark-check-outline",
                "icon": "mdi-bookmark-check-outline"
            },
            {
                "name": "bookmark-minus",
                "icon": "mdi-bookmark-minus"
            },
            {
                "name": "bookmark-minus-outline",
                "icon": "mdi-bookmark-minus-outline"
            },
            {
                "name": "bookmark-multiple",
                "icon": "mdi-bookmark-multiple"
            },
            {
                "name": "bookmark-multiple-outline",
                "icon": "mdi-bookmark-multiple-outline"
            },
            {
                "name": "bookmark-music",
                "icon": "mdi-bookmark-music"
            },
            {
                "name": "bookmark-music-outline",
                "icon": "mdi-bookmark-music-outline"
            },
            {
                "name": "bookmark-off",
                "icon": "mdi-bookmark-off"
            },
            {
                "name": "bookmark-off-outline",
                "icon": "mdi-bookmark-off-outline"
            },
            {
                "name": "bookmark-outline",
                "icon": "mdi-bookmark-outline"
            },
            {
                "name": "bookmark-plus",
                "icon": "mdi-bookmark-plus"
            },
            {
                "name": "bookmark-plus-outline",
                "icon": "mdi-bookmark-plus-outline"
            },
            {
                "name": "bookmark-remove",
                "icon": "mdi-bookmark-remove"
            },
            {
                "name": "bookmark-remove-outline",
                "icon": "mdi-bookmark-remove-outline"
            },
            {
                "name": "bookshelf",
                "icon": "mdi-bookshelf"
            },
            {
                "name": "boom-gate",
                "icon": "mdi-boom-gate"
            },
            {
                "name": "boom-gate-alert",
                "icon": "mdi-boom-gate-alert"
            },
            {
                "name": "boom-gate-alert-outline",
                "icon": "mdi-boom-gate-alert-outline"
            },
            {
                "name": "boom-gate-arrow-down",
                "icon": "mdi-boom-gate-arrow-down"
            },
            {
                "name": "boom-gate-arrow-down-outline",
                "icon": "mdi-boom-gate-arrow-down-outline"
            },
            {
                "name": "boom-gate-arrow-up",
                "icon": "mdi-boom-gate-arrow-up"
            },
            {
                "name": "boom-gate-arrow-up-outline",
                "icon": "mdi-boom-gate-arrow-up-outline"
            },
            {
                "name": "boom-gate-outline",
                "icon": "mdi-boom-gate-outline"
            },
            {
                "name": "boom-gate-up",
                "icon": "mdi-boom-gate-up"
            },
            {
                "name": "boom-gate-up-outline",
                "icon": "mdi-boom-gate-up-outline"
            },
            {
                "name": "boombox",
                "icon": "mdi-boombox"
            },
            {
                "name": "boomerang",
                "icon": "mdi-boomerang"
            },
            {
                "name": "bootstrap",
                "icon": "mdi-bootstrap"
            },
            {
                "name": "border-all",
                "icon": "mdi-border-all"
            },
            {
                "name": "border-all-variant",
                "icon": "mdi-border-all-variant"
            },
            {
                "name": "border-bottom",
                "icon": "mdi-border-bottom"
            },
            {
                "name": "border-bottom-variant",
                "icon": "mdi-border-bottom-variant"
            },
            {
                "name": "border-color",
                "icon": "mdi-border-color"
            },
            {
                "name": "border-horizontal",
                "icon": "mdi-border-horizontal"
            },
            {
                "name": "border-inside",
                "icon": "mdi-border-inside"
            },
            {
                "name": "border-left",
                "icon": "mdi-border-left"
            },
            {
                "name": "border-left-variant",
                "icon": "mdi-border-left-variant"
            },
            {
                "name": "border-none",
                "icon": "mdi-border-none"
            },
            {
                "name": "border-none-variant",
                "icon": "mdi-border-none-variant"
            },
            {
                "name": "border-outside",
                "icon": "mdi-border-outside"
            },
            {
                "name": "border-radius",
                "icon": "mdi-border-radius"
            },
            {
                "name": "border-right",
                "icon": "mdi-border-right"
            },
            {
                "name": "border-right-variant",
                "icon": "mdi-border-right-variant"
            },
            {
                "name": "border-style",
                "icon": "mdi-border-style"
            },
            {
                "name": "border-top",
                "icon": "mdi-border-top"
            },
            {
                "name": "border-top-variant",
                "icon": "mdi-border-top-variant"
            },
            {
                "name": "border-vertical",
                "icon": "mdi-border-vertical"
            },
            {
                "name": "bottle-soda",
                "icon": "mdi-bottle-soda"
            },
            {
                "name": "bottle-soda-classic",
                "icon": "mdi-bottle-soda-classic"
            },
            {
                "name": "bottle-soda-classic-outline",
                "icon": "mdi-bottle-soda-classic-outline"
            },
            {
                "name": "bottle-soda-outline",
                "icon": "mdi-bottle-soda-outline"
            },
            {
                "name": "bottle-tonic",
                "icon": "mdi-bottle-tonic"
            },
            {
                "name": "bottle-tonic-outline",
                "icon": "mdi-bottle-tonic-outline"
            },
            {
                "name": "bottle-tonic-plus",
                "icon": "mdi-bottle-tonic-plus"
            },
            {
                "name": "bottle-tonic-plus-outline",
                "icon": "mdi-bottle-tonic-plus-outline"
            },
            {
                "name": "bottle-tonic-skull",
                "icon": "mdi-bottle-tonic-skull"
            },
            {
                "name": "bottle-tonic-skull-outline",
                "icon": "mdi-bottle-tonic-skull-outline"
            },
            {
                "name": "bottle-wine",
                "icon": "mdi-bottle-wine"
            },
            {
                "name": "bottle-wine-outline",
                "icon": "mdi-bottle-wine-outline"
            },
            {
                "name": "bow-arrow",
                "icon": "mdi-bow-arrow"
            },
            {
                "name": "bow-tie",
                "icon": "mdi-bow-tie"
            },
            {
                "name": "bowl",
                "icon": "mdi-bowl"
            },
            {
                "name": "bowl-mix",
                "icon": "mdi-bowl-mix"
            },
            {
                "name": "bowl-mix-outline",
                "icon": "mdi-bowl-mix-outline"
            },
            {
                "name": "bowl-outline",
                "icon": "mdi-bowl-outline"
            },
            {
                "name": "bowling",
                "icon": "mdi-bowling"
            },
            {
                "name": "box",
                "icon": "mdi-box"
            },
            {
                "name": "box-cutter",
                "icon": "mdi-box-cutter"
            },
            {
                "name": "box-cutter-off",
                "icon": "mdi-box-cutter-off"
            },
            {
                "name": "box-shadow",
                "icon": "mdi-box-shadow"
            },
            {
                "name": "boxing-glove",
                "icon": "mdi-boxing-glove"
            },
            {
                "name": "braille",
                "icon": "mdi-braille"
            },
            {
                "name": "brain",
                "icon": "mdi-brain"
            },
            {
                "name": "bread-slice",
                "icon": "mdi-bread-slice"
            },
            {
                "name": "bread-slice-outline",
                "icon": "mdi-bread-slice-outline"
            },
            {
                "name": "bridge",
                "icon": "mdi-bridge"
            },
            {
                "name": "briefcase",
                "icon": "mdi-briefcase"
            },
            {
                "name": "briefcase-account",
                "icon": "mdi-briefcase-account"
            },
            {
                "name": "briefcase-account-outline",
                "icon": "mdi-briefcase-account-outline"
            },
            {
                "name": "briefcase-arrow-left-right",
                "icon": "mdi-briefcase-arrow-left-right"
            },
            {
                "name": "briefcase-arrow-left-right-outline",
                "icon": "mdi-briefcase-arrow-left-right-outline"
            },
            {
                "name": "briefcase-arrow-up-down",
                "icon": "mdi-briefcase-arrow-up-down"
            },
            {
                "name": "briefcase-arrow-up-down-outline",
                "icon": "mdi-briefcase-arrow-up-down-outline"
            },
            {
                "name": "briefcase-check",
                "icon": "mdi-briefcase-check"
            },
            {
                "name": "briefcase-check-outline",
                "icon": "mdi-briefcase-check-outline"
            },
            {
                "name": "briefcase-clock",
                "icon": "mdi-briefcase-clock"
            },
            {
                "name": "briefcase-clock-outline",
                "icon": "mdi-briefcase-clock-outline"
            },
            {
                "name": "briefcase-download",
                "icon": "mdi-briefcase-download"
            },
            {
                "name": "briefcase-download-outline",
                "icon": "mdi-briefcase-download-outline"
            },
            {
                "name": "briefcase-edit",
                "icon": "mdi-briefcase-edit"
            },
            {
                "name": "briefcase-edit-outline",
                "icon": "mdi-briefcase-edit-outline"
            },
            {
                "name": "briefcase-eye",
                "icon": "mdi-briefcase-eye"
            },
            {
                "name": "briefcase-eye-outline",
                "icon": "mdi-briefcase-eye-outline"
            },
            {
                "name": "briefcase-minus",
                "icon": "mdi-briefcase-minus"
            },
            {
                "name": "briefcase-minus-outline",
                "icon": "mdi-briefcase-minus-outline"
            },
            {
                "name": "briefcase-off",
                "icon": "mdi-briefcase-off"
            },
            {
                "name": "briefcase-off-outline",
                "icon": "mdi-briefcase-off-outline"
            },
            {
                "name": "briefcase-outline",
                "icon": "mdi-briefcase-outline"
            },
            {
                "name": "briefcase-plus",
                "icon": "mdi-briefcase-plus"
            },
            {
                "name": "briefcase-plus-outline",
                "icon": "mdi-briefcase-plus-outline"
            },
            {
                "name": "briefcase-remove",
                "icon": "mdi-briefcase-remove"
            },
            {
                "name": "briefcase-remove-outline",
                "icon": "mdi-briefcase-remove-outline"
            },
            {
                "name": "briefcase-search",
                "icon": "mdi-briefcase-search"
            },
            {
                "name": "briefcase-search-outline",
                "icon": "mdi-briefcase-search-outline"
            },
            {
                "name": "briefcase-upload",
                "icon": "mdi-briefcase-upload"
            },
            {
                "name": "briefcase-upload-outline",
                "icon": "mdi-briefcase-upload-outline"
            },
            {
                "name": "briefcase-variant",
                "icon": "mdi-briefcase-variant"
            },
            {
                "name": "briefcase-variant-off",
                "icon": "mdi-briefcase-variant-off"
            },
            {
                "name": "briefcase-variant-off-outline",
                "icon": "mdi-briefcase-variant-off-outline"
            },
            {
                "name": "briefcase-variant-outline",
                "icon": "mdi-briefcase-variant-outline"
            },
            {
                "name": "brightness-1",
                "icon": "mdi-brightness-1"
            },
            {
                "name": "brightness-2",
                "icon": "mdi-brightness-2"
            },
            {
                "name": "brightness-3",
                "icon": "mdi-brightness-3"
            },
            {
                "name": "brightness-4",
                "icon": "mdi-brightness-4"
            },
            {
                "name": "brightness-5",
                "icon": "mdi-brightness-5"
            },
            {
                "name": "brightness-6",
                "icon": "mdi-brightness-6"
            },
            {
                "name": "brightness-7",
                "icon": "mdi-brightness-7"
            },
            {
                "name": "brightness-auto",
                "icon": "mdi-brightness-auto"
            },
            {
                "name": "brightness-percent",
                "icon": "mdi-brightness-percent"
            },
            {
                "name": "broadcast",
                "icon": "mdi-broadcast"
            },
            {
                "name": "broadcast-off",
                "icon": "mdi-broadcast-off"
            },
            {
                "name": "broom",
                "icon": "mdi-broom"
            },
            {
                "name": "brush",
                "icon": "mdi-brush"
            },
            {
                "name": "brush-off",
                "icon": "mdi-brush-off"
            },
            {
                "name": "brush-outline",
                "icon": "mdi-brush-outline"
            },
            {
                "name": "brush-variant",
                "icon": "mdi-brush-variant"
            },
            {
                "name": "bucket",
                "icon": "mdi-bucket"
            },
            {
                "name": "bucket-outline",
                "icon": "mdi-bucket-outline"
            },
            {
                "name": "buffet",
                "icon": "mdi-buffet"
            },
            {
                "name": "bug",
                "icon": "mdi-bug"
            },
            {
                "name": "bug-check",
                "icon": "mdi-bug-check"
            },
            {
                "name": "bug-check-outline",
                "icon": "mdi-bug-check-outline"
            },
            {
                "name": "bug-outline",
                "icon": "mdi-bug-outline"
            },
            {
                "name": "bug-pause",
                "icon": "mdi-bug-pause"
            },
            {
                "name": "bug-pause-outline",
                "icon": "mdi-bug-pause-outline"
            },
            {
                "name": "bug-play",
                "icon": "mdi-bug-play"
            },
            {
                "name": "bug-play-outline",
                "icon": "mdi-bug-play-outline"
            },
            {
                "name": "bug-stop",
                "icon": "mdi-bug-stop"
            },
            {
                "name": "bug-stop-outline",
                "icon": "mdi-bug-stop-outline"
            },
            {
                "name": "bugle",
                "icon": "mdi-bugle"
            },
            {
                "name": "bulkhead-light",
                "icon": "mdi-bulkhead-light"
            },
            {
                "name": "bulldozer",
                "icon": "mdi-bulldozer"
            },
            {
                "name": "bullet",
                "icon": "mdi-bullet"
            },
            {
                "name": "bulletin-board",
                "icon": "mdi-bulletin-board"
            },
            {
                "name": "bullhorn",
                "icon": "mdi-bullhorn"
            },
            {
                "name": "bullhorn-outline",
                "icon": "mdi-bullhorn-outline"
            },
            {
                "name": "bullhorn-variant",
                "icon": "mdi-bullhorn-variant"
            },
            {
                "name": "bullhorn-variant-outline",
                "icon": "mdi-bullhorn-variant-outline"
            },
            {
                "name": "bullseye",
                "icon": "mdi-bullseye"
            },
            {
                "name": "bullseye-arrow",
                "icon": "mdi-bullseye-arrow"
            },
            {
                "name": "bulma",
                "icon": "mdi-bulma"
            },
            {
                "name": "bunk-bed",
                "icon": "mdi-bunk-bed"
            },
            {
                "name": "bunk-bed-outline",
                "icon": "mdi-bunk-bed-outline"
            },
            {
                "name": "bus",
                "icon": "mdi-bus"
            },
            {
                "name": "bus-alert",
                "icon": "mdi-bus-alert"
            },
            {
                "name": "bus-articulated-end",
                "icon": "mdi-bus-articulated-end"
            },
            {
                "name": "bus-articulated-front",
                "icon": "mdi-bus-articulated-front"
            },
            {
                "name": "bus-clock",
                "icon": "mdi-bus-clock"
            },
            {
                "name": "bus-double-decker",
                "icon": "mdi-bus-double-decker"
            },
            {
                "name": "bus-electric",
                "icon": "mdi-bus-electric"
            },
            {
                "name": "bus-marker",
                "icon": "mdi-bus-marker"
            },
            {
                "name": "bus-multiple",
                "icon": "mdi-bus-multiple"
            },
            {
                "name": "bus-school",
                "icon": "mdi-bus-school"
            },
            {
                "name": "bus-side",
                "icon": "mdi-bus-side"
            },
            {
                "name": "bus-stop",
                "icon": "mdi-bus-stop"
            },
            {
                "name": "bus-stop-covered",
                "icon": "mdi-bus-stop-covered"
            },
            {
                "name": "bus-stop-uncovered",
                "icon": "mdi-bus-stop-uncovered"
            },
            {
                "name": "butterfly",
                "icon": "mdi-butterfly"
            },
            {
                "name": "butterfly-outline",
                "icon": "mdi-butterfly-outline"
            },
            {
                "name": "button-cursor",
                "icon": "mdi-button-cursor"
            },
            {
                "name": "button-pointer",
                "icon": "mdi-button-pointer"
            },
            {
                "name": "cabin-a-frame",
                "icon": "mdi-cabin-a-frame"
            },
            {
                "name": "cable-data",
                "icon": "mdi-cable-data"
            },
            {
                "name": "cached",
                "icon": "mdi-cached"
            },
            {
                "name": "cactus",
                "icon": "mdi-cactus"
            },
            {
                "name": "cake",
                "icon": "mdi-cake"
            },
            {
                "name": "cake-layered",
                "icon": "mdi-cake-layered"
            },
            {
                "name": "cake-variant",
                "icon": "mdi-cake-variant"
            },
            {
                "name": "cake-variant-outline",
                "icon": "mdi-cake-variant-outline"
            },
            {
                "name": "calculator",
                "icon": "mdi-calculator"
            },
            {
                "name": "calculator-variant",
                "icon": "mdi-calculator-variant"
            },
            {
                "name": "calculator-variant-outline",
                "icon": "mdi-calculator-variant-outline"
            },
            {
                "name": "calendar",
                "icon": "mdi-calendar"
            },
            {
                "name": "calendar-account",
                "icon": "mdi-calendar-account"
            },
            {
                "name": "calendar-account-outline",
                "icon": "mdi-calendar-account-outline"
            },
            {
                "name": "calendar-alert",
                "icon": "mdi-calendar-alert"
            },
            {
                "name": "calendar-alert-outline",
                "icon": "mdi-calendar-alert-outline"
            },
            {
                "name": "calendar-arrow-left",
                "icon": "mdi-calendar-arrow-left"
            },
            {
                "name": "calendar-arrow-right",
                "icon": "mdi-calendar-arrow-right"
            },
            {
                "name": "calendar-badge",
                "icon": "mdi-calendar-badge"
            },
            {
                "name": "calendar-badge-outline",
                "icon": "mdi-calendar-badge-outline"
            },
            {
                "name": "calendar-blank",
                "icon": "mdi-calendar-blank"
            },
            {
                "name": "calendar-blank-multiple",
                "icon": "mdi-calendar-blank-multiple"
            },
            {
                "name": "calendar-blank-outline",
                "icon": "mdi-calendar-blank-outline"
            },
            {
                "name": "calendar-check",
                "icon": "mdi-calendar-check"
            },
            {
                "name": "calendar-check-outline",
                "icon": "mdi-calendar-check-outline"
            },
            {
                "name": "calendar-clock",
                "icon": "mdi-calendar-clock"
            },
            {
                "name": "calendar-clock-outline",
                "icon": "mdi-calendar-clock-outline"
            },
            {
                "name": "calendar-collapse-horizontal",
                "icon": "mdi-calendar-collapse-horizontal"
            },
            {
                "name": "calendar-collapse-horizontal-outline",
                "icon": "mdi-calendar-collapse-horizontal-outline"
            },
            {
                "name": "calendar-cursor",
                "icon": "mdi-calendar-cursor"
            },
            {
                "name": "calendar-cursor-outline",
                "icon": "mdi-calendar-cursor-outline"
            },
            {
                "name": "calendar-edit",
                "icon": "mdi-calendar-edit"
            },
            {
                "name": "calendar-edit-outline",
                "icon": "mdi-calendar-edit-outline"
            },
            {
                "name": "calendar-end",
                "icon": "mdi-calendar-end"
            },
            {
                "name": "calendar-end-outline",
                "icon": "mdi-calendar-end-outline"
            },
            {
                "name": "calendar-expand-horizontal",
                "icon": "mdi-calendar-expand-horizontal"
            },
            {
                "name": "calendar-expand-horizontal-outline",
                "icon": "mdi-calendar-expand-horizontal-outline"
            },
            {
                "name": "calendar-export",
                "icon": "mdi-calendar-export"
            },
            {
                "name": "calendar-export-outline",
                "icon": "mdi-calendar-export-outline"
            },
            {
                "name": "calendar-filter",
                "icon": "mdi-calendar-filter"
            },
            {
                "name": "calendar-filter-outline",
                "icon": "mdi-calendar-filter-outline"
            },
            {
                "name": "calendar-heart",
                "icon": "mdi-calendar-heart"
            },
            {
                "name": "calendar-heart-outline",
                "icon": "mdi-calendar-heart-outline"
            },
            {
                "name": "calendar-import",
                "icon": "mdi-calendar-import"
            },
            {
                "name": "calendar-import-outline",
                "icon": "mdi-calendar-import-outline"
            },
            {
                "name": "calendar-lock",
                "icon": "mdi-calendar-lock"
            },
            {
                "name": "calendar-lock-open",
                "icon": "mdi-calendar-lock-open"
            },
            {
                "name": "calendar-lock-open-outline",
                "icon": "mdi-calendar-lock-open-outline"
            },
            {
                "name": "calendar-lock-outline",
                "icon": "mdi-calendar-lock-outline"
            },
            {
                "name": "calendar-minus",
                "icon": "mdi-calendar-minus"
            },
            {
                "name": "calendar-minus-outline",
                "icon": "mdi-calendar-minus-outline"
            },
            {
                "name": "calendar-month",
                "icon": "mdi-calendar-month"
            },
            {
                "name": "calendar-month-outline",
                "icon": "mdi-calendar-month-outline"
            },
            {
                "name": "calendar-multiple",
                "icon": "mdi-calendar-multiple"
            },
            {
                "name": "calendar-multiple-check",
                "icon": "mdi-calendar-multiple-check"
            },
            {
                "name": "calendar-multiselect",
                "icon": "mdi-calendar-multiselect"
            },
            {
                "name": "calendar-multiselect-outline",
                "icon": "mdi-calendar-multiselect-outline"
            },
            {
                "name": "calendar-outline",
                "icon": "mdi-calendar-outline"
            },
            {
                "name": "calendar-plus",
                "icon": "mdi-calendar-plus"
            },
            {
                "name": "calendar-plus-outline",
                "icon": "mdi-calendar-plus-outline"
            },
            {
                "name": "calendar-question",
                "icon": "mdi-calendar-question"
            },
            {
                "name": "calendar-question-outline",
                "icon": "mdi-calendar-question-outline"
            },
            {
                "name": "calendar-range",
                "icon": "mdi-calendar-range"
            },
            {
                "name": "calendar-range-outline",
                "icon": "mdi-calendar-range-outline"
            },
            {
                "name": "calendar-refresh",
                "icon": "mdi-calendar-refresh"
            },
            {
                "name": "calendar-refresh-outline",
                "icon": "mdi-calendar-refresh-outline"
            },
            {
                "name": "calendar-remove",
                "icon": "mdi-calendar-remove"
            },
            {
                "name": "calendar-remove-outline",
                "icon": "mdi-calendar-remove-outline"
            },
            {
                "name": "calendar-search",
                "icon": "mdi-calendar-search"
            },
            {
                "name": "calendar-search-outline",
                "icon": "mdi-calendar-search-outline"
            },
            {
                "name": "calendar-star",
                "icon": "mdi-calendar-star"
            },
            {
                "name": "calendar-star-outline",
                "icon": "mdi-calendar-star-outline"
            },
            {
                "name": "calendar-start",
                "icon": "mdi-calendar-start"
            },
            {
                "name": "calendar-start-outline",
                "icon": "mdi-calendar-start-outline"
            },
            {
                "name": "calendar-sync",
                "icon": "mdi-calendar-sync"
            },
            {
                "name": "calendar-sync-outline",
                "icon": "mdi-calendar-sync-outline"
            },
            {
                "name": "calendar-text",
                "icon": "mdi-calendar-text"
            },
            {
                "name": "calendar-text-outline",
                "icon": "mdi-calendar-text-outline"
            },
            {
                "name": "calendar-today",
                "icon": "mdi-calendar-today"
            },
            {
                "name": "calendar-today-outline",
                "icon": "mdi-calendar-today-outline"
            },
            {
                "name": "calendar-week",
                "icon": "mdi-calendar-week"
            },
            {
                "name": "calendar-week-begin",
                "icon": "mdi-calendar-week-begin"
            },
            {
                "name": "calendar-week-begin-outline",
                "icon": "mdi-calendar-week-begin-outline"
            },
            {
                "name": "calendar-week-outline",
                "icon": "mdi-calendar-week-outline"
            },
            {
                "name": "calendar-weekend",
                "icon": "mdi-calendar-weekend"
            },
            {
                "name": "calendar-weekend-outline",
                "icon": "mdi-calendar-weekend-outline"
            },
            {
                "name": "call-made",
                "icon": "mdi-call-made"
            },
            {
                "name": "call-merge",
                "icon": "mdi-call-merge"
            },
            {
                "name": "call-missed",
                "icon": "mdi-call-missed"
            },
            {
                "name": "call-received",
                "icon": "mdi-call-received"
            },
            {
                "name": "call-split",
                "icon": "mdi-call-split"
            },
            {
                "name": "camcorder",
                "icon": "mdi-camcorder"
            },
            {
                "name": "camcorder-off",
                "icon": "mdi-camcorder-off"
            },
            {
                "name": "camera",
                "icon": "mdi-camera"
            },
            {
                "name": "camera-account",
                "icon": "mdi-camera-account"
            },
            {
                "name": "camera-burst",
                "icon": "mdi-camera-burst"
            },
            {
                "name": "camera-control",
                "icon": "mdi-camera-control"
            },
            {
                "name": "camera-document",
                "icon": "mdi-camera-document"
            },
            {
                "name": "camera-document-off",
                "icon": "mdi-camera-document-off"
            },
            {
                "name": "camera-enhance",
                "icon": "mdi-camera-enhance"
            },
            {
                "name": "camera-enhance-outline",
                "icon": "mdi-camera-enhance-outline"
            },
            {
                "name": "camera-flip",
                "icon": "mdi-camera-flip"
            },
            {
                "name": "camera-flip-outline",
                "icon": "mdi-camera-flip-outline"
            },
            {
                "name": "camera-front",
                "icon": "mdi-camera-front"
            },
            {
                "name": "camera-front-variant",
                "icon": "mdi-camera-front-variant"
            },
            {
                "name": "camera-gopro",
                "icon": "mdi-camera-gopro"
            },
            {
                "name": "camera-image",
                "icon": "mdi-camera-image"
            },
            {
                "name": "camera-iris",
                "icon": "mdi-camera-iris"
            },
            {
                "name": "camera-lock",
                "icon": "mdi-camera-lock"
            },
            {
                "name": "camera-lock-outline",
                "icon": "mdi-camera-lock-outline"
            },
            {
                "name": "camera-marker",
                "icon": "mdi-camera-marker"
            },
            {
                "name": "camera-marker-outline",
                "icon": "mdi-camera-marker-outline"
            },
            {
                "name": "camera-metering-center",
                "icon": "mdi-camera-metering-center"
            },
            {
                "name": "camera-metering-matrix",
                "icon": "mdi-camera-metering-matrix"
            },
            {
                "name": "camera-metering-partial",
                "icon": "mdi-camera-metering-partial"
            },
            {
                "name": "camera-metering-spot",
                "icon": "mdi-camera-metering-spot"
            },
            {
                "name": "camera-off",
                "icon": "mdi-camera-off"
            },
            {
                "name": "camera-off-outline",
                "icon": "mdi-camera-off-outline"
            },
            {
                "name": "camera-outline",
                "icon": "mdi-camera-outline"
            },
            {
                "name": "camera-party-mode",
                "icon": "mdi-camera-party-mode"
            },
            {
                "name": "camera-plus",
                "icon": "mdi-camera-plus"
            },
            {
                "name": "camera-plus-outline",
                "icon": "mdi-camera-plus-outline"
            },
            {
                "name": "camera-rear",
                "icon": "mdi-camera-rear"
            },
            {
                "name": "camera-rear-variant",
                "icon": "mdi-camera-rear-variant"
            },
            {
                "name": "camera-retake",
                "icon": "mdi-camera-retake"
            },
            {
                "name": "camera-retake-outline",
                "icon": "mdi-camera-retake-outline"
            },
            {
                "name": "camera-switch",
                "icon": "mdi-camera-switch"
            },
            {
                "name": "camera-switch-outline",
                "icon": "mdi-camera-switch-outline"
            },
            {
                "name": "camera-timer",
                "icon": "mdi-camera-timer"
            },
            {
                "name": "camera-wireless",
                "icon": "mdi-camera-wireless"
            },
            {
                "name": "camera-wireless-outline",
                "icon": "mdi-camera-wireless-outline"
            },
            {
                "name": "campfire",
                "icon": "mdi-campfire"
            },
            {
                "name": "cancel",
                "icon": "mdi-cancel"
            },
            {
                "name": "candelabra",
                "icon": "mdi-candelabra"
            },
            {
                "name": "candelabra-fire",
                "icon": "mdi-candelabra-fire"
            },
            {
                "name": "candle",
                "icon": "mdi-candle"
            },
            {
                "name": "candy",
                "icon": "mdi-candy"
            },
            {
                "name": "candy-off",
                "icon": "mdi-candy-off"
            },
            {
                "name": "candy-off-outline",
                "icon": "mdi-candy-off-outline"
            },
            {
                "name": "candy-outline",
                "icon": "mdi-candy-outline"
            },
            {
                "name": "candycane",
                "icon": "mdi-candycane"
            },
            {
                "name": "cannabis",
                "icon": "mdi-cannabis"
            },
            {
                "name": "cannabis-off",
                "icon": "mdi-cannabis-off"
            },
            {
                "name": "caps-lock",
                "icon": "mdi-caps-lock"
            },
            {
                "name": "car",
                "icon": "mdi-car"
            },
            {
                "name": "car-2-plus",
                "icon": "mdi-car-2-plus"
            },
            {
                "name": "car-3-plus",
                "icon": "mdi-car-3-plus"
            },
            {
                "name": "car-arrow-left",
                "icon": "mdi-car-arrow-left"
            },
            {
                "name": "car-arrow-right",
                "icon": "mdi-car-arrow-right"
            },
            {
                "name": "car-back",
                "icon": "mdi-car-back"
            },
            {
                "name": "car-battery",
                "icon": "mdi-car-battery"
            },
            {
                "name": "car-brake-abs",
                "icon": "mdi-car-brake-abs"
            },
            {
                "name": "car-brake-alert",
                "icon": "mdi-car-brake-alert"
            },
            {
                "name": "car-brake-fluid-level",
                "icon": "mdi-car-brake-fluid-level"
            },
            {
                "name": "car-brake-hold",
                "icon": "mdi-car-brake-hold"
            },
            {
                "name": "car-brake-low-pressure",
                "icon": "mdi-car-brake-low-pressure"
            },
            {
                "name": "car-brake-parking",
                "icon": "mdi-car-brake-parking"
            },
            {
                "name": "car-brake-retarder",
                "icon": "mdi-car-brake-retarder"
            },
            {
                "name": "car-brake-temperature",
                "icon": "mdi-car-brake-temperature"
            },
            {
                "name": "car-brake-worn-linings",
                "icon": "mdi-car-brake-worn-linings"
            },
            {
                "name": "car-child-seat",
                "icon": "mdi-car-child-seat"
            },
            {
                "name": "car-clock",
                "icon": "mdi-car-clock"
            },
            {
                "name": "car-clutch",
                "icon": "mdi-car-clutch"
            },
            {
                "name": "car-cog",
                "icon": "mdi-car-cog"
            },
            {
                "name": "car-connected",
                "icon": "mdi-car-connected"
            },
            {
                "name": "car-convertible",
                "icon": "mdi-car-convertible"
            },
            {
                "name": "car-coolant-level",
                "icon": "mdi-car-coolant-level"
            },
            {
                "name": "car-cruise-control",
                "icon": "mdi-car-cruise-control"
            },
            {
                "name": "car-defrost-front",
                "icon": "mdi-car-defrost-front"
            },
            {
                "name": "car-defrost-rear",
                "icon": "mdi-car-defrost-rear"
            },
            {
                "name": "car-door",
                "icon": "mdi-car-door"
            },
            {
                "name": "car-door-lock",
                "icon": "mdi-car-door-lock"
            },
            {
                "name": "car-electric",
                "icon": "mdi-car-electric"
            },
            {
                "name": "car-electric-outline",
                "icon": "mdi-car-electric-outline"
            },
            {
                "name": "car-emergency",
                "icon": "mdi-car-emergency"
            },
            {
                "name": "car-esp",
                "icon": "mdi-car-esp"
            },
            {
                "name": "car-estate",
                "icon": "mdi-car-estate"
            },
            {
                "name": "car-hatchback",
                "icon": "mdi-car-hatchback"
            },
            {
                "name": "car-info",
                "icon": "mdi-car-info"
            },
            {
                "name": "car-key",
                "icon": "mdi-car-key"
            },
            {
                "name": "car-lifted-pickup",
                "icon": "mdi-car-lifted-pickup"
            },
            {
                "name": "car-light-alert",
                "icon": "mdi-car-light-alert"
            },
            {
                "name": "car-light-dimmed",
                "icon": "mdi-car-light-dimmed"
            },
            {
                "name": "car-light-fog",
                "icon": "mdi-car-light-fog"
            },
            {
                "name": "car-light-high",
                "icon": "mdi-car-light-high"
            },
            {
                "name": "car-limousine",
                "icon": "mdi-car-limousine"
            },
            {
                "name": "car-multiple",
                "icon": "mdi-car-multiple"
            },
            {
                "name": "car-off",
                "icon": "mdi-car-off"
            },
            {
                "name": "car-outline",
                "icon": "mdi-car-outline"
            },
            {
                "name": "car-parking-lights",
                "icon": "mdi-car-parking-lights"
            },
            {
                "name": "car-pickup",
                "icon": "mdi-car-pickup"
            },
            {
                "name": "car-search",
                "icon": "mdi-car-search"
            },
            {
                "name": "car-search-outline",
                "icon": "mdi-car-search-outline"
            },
            {
                "name": "car-seat",
                "icon": "mdi-car-seat"
            },
            {
                "name": "car-seat-cooler",
                "icon": "mdi-car-seat-cooler"
            },
            {
                "name": "car-seat-heater",
                "icon": "mdi-car-seat-heater"
            },
            {
                "name": "car-select",
                "icon": "mdi-car-select"
            },
            {
                "name": "car-settings",
                "icon": "mdi-car-settings"
            },
            {
                "name": "car-shift-pattern",
                "icon": "mdi-car-shift-pattern"
            },
            {
                "name": "car-side",
                "icon": "mdi-car-side"
            },
            {
                "name": "car-speed-limiter",
                "icon": "mdi-car-speed-limiter"
            },
            {
                "name": "car-sports",
                "icon": "mdi-car-sports"
            },
            {
                "name": "car-tire-alert",
                "icon": "mdi-car-tire-alert"
            },
            {
                "name": "car-traction-control",
                "icon": "mdi-car-traction-control"
            },
            {
                "name": "car-turbocharger",
                "icon": "mdi-car-turbocharger"
            },
            {
                "name": "car-wash",
                "icon": "mdi-car-wash"
            },
            {
                "name": "car-windshield",
                "icon": "mdi-car-windshield"
            },
            {
                "name": "car-windshield-outline",
                "icon": "mdi-car-windshield-outline"
            },
            {
                "name": "car-wireless",
                "icon": "mdi-car-wireless"
            },
            {
                "name": "car-wrench",
                "icon": "mdi-car-wrench"
            },
            {
                "name": "carabiner",
                "icon": "mdi-carabiner"
            },
            {
                "name": "caravan",
                "icon": "mdi-caravan"
            },
            {
                "name": "card",
                "icon": "mdi-card"
            },
            {
                "name": "card-account-details",
                "icon": "mdi-card-account-details"
            },
            {
                "name": "card-account-details-outline",
                "icon": "mdi-card-account-details-outline"
            },
            {
                "name": "card-account-details-star",
                "icon": "mdi-card-account-details-star"
            },
            {
                "name": "card-account-details-star-outline",
                "icon": "mdi-card-account-details-star-outline"
            },
            {
                "name": "card-account-mail",
                "icon": "mdi-card-account-mail"
            },
            {
                "name": "card-account-mail-outline",
                "icon": "mdi-card-account-mail-outline"
            },
            {
                "name": "card-account-phone",
                "icon": "mdi-card-account-phone"
            },
            {
                "name": "card-account-phone-outline",
                "icon": "mdi-card-account-phone-outline"
            },
            {
                "name": "card-bulleted",
                "icon": "mdi-card-bulleted"
            },
            {
                "name": "card-bulleted-off",
                "icon": "mdi-card-bulleted-off"
            },
            {
                "name": "card-bulleted-off-outline",
                "icon": "mdi-card-bulleted-off-outline"
            },
            {
                "name": "card-bulleted-outline",
                "icon": "mdi-card-bulleted-outline"
            },
            {
                "name": "card-bulleted-settings",
                "icon": "mdi-card-bulleted-settings"
            },
            {
                "name": "card-bulleted-settings-outline",
                "icon": "mdi-card-bulleted-settings-outline"
            },
            {
                "name": "card-minus",
                "icon": "mdi-card-minus"
            },
            {
                "name": "card-minus-outline",
                "icon": "mdi-card-minus-outline"
            },
            {
                "name": "card-multiple",
                "icon": "mdi-card-multiple"
            },
            {
                "name": "card-multiple-outline",
                "icon": "mdi-card-multiple-outline"
            },
            {
                "name": "card-off",
                "icon": "mdi-card-off"
            },
            {
                "name": "card-off-outline",
                "icon": "mdi-card-off-outline"
            },
            {
                "name": "card-outline",
                "icon": "mdi-card-outline"
            },
            {
                "name": "card-plus",
                "icon": "mdi-card-plus"
            },
            {
                "name": "card-plus-outline",
                "icon": "mdi-card-plus-outline"
            },
            {
                "name": "card-remove",
                "icon": "mdi-card-remove"
            },
            {
                "name": "card-remove-outline",
                "icon": "mdi-card-remove-outline"
            },
            {
                "name": "card-search",
                "icon": "mdi-card-search"
            },
            {
                "name": "card-search-outline",
                "icon": "mdi-card-search-outline"
            },
            {
                "name": "card-text",
                "icon": "mdi-card-text"
            },
            {
                "name": "card-text-outline",
                "icon": "mdi-card-text-outline"
            },
            {
                "name": "cards",
                "icon": "mdi-cards"
            },
            {
                "name": "cards-club",
                "icon": "mdi-cards-club"
            },
            {
                "name": "cards-club-outline",
                "icon": "mdi-cards-club-outline"
            },
            {
                "name": "cards-diamond",
                "icon": "mdi-cards-diamond"
            },
            {
                "name": "cards-diamond-outline",
                "icon": "mdi-cards-diamond-outline"
            },
            {
                "name": "cards-heart",
                "icon": "mdi-cards-heart"
            },
            {
                "name": "cards-heart-outline",
                "icon": "mdi-cards-heart-outline"
            },
            {
                "name": "cards-outline",
                "icon": "mdi-cards-outline"
            },
            {
                "name": "cards-playing",
                "icon": "mdi-cards-playing"
            },
            {
                "name": "cards-playing-club",
                "icon": "mdi-cards-playing-club"
            },
            {
                "name": "cards-playing-club-multiple",
                "icon": "mdi-cards-playing-club-multiple"
            },
            {
                "name": "cards-playing-club-multiple-outline",
                "icon": "mdi-cards-playing-club-multiple-outline"
            },
            {
                "name": "cards-playing-club-outline",
                "icon": "mdi-cards-playing-club-outline"
            },
            {
                "name": "cards-playing-diamond",
                "icon": "mdi-cards-playing-diamond"
            },
            {
                "name": "cards-playing-diamond-multiple",
                "icon": "mdi-cards-playing-diamond-multiple"
            },
            {
                "name": "cards-playing-diamond-multiple-outline",
                "icon": "mdi-cards-playing-diamond-multiple-outline"
            },
            {
                "name": "cards-playing-diamond-outline",
                "icon": "mdi-cards-playing-diamond-outline"
            },
            {
                "name": "cards-playing-heart",
                "icon": "mdi-cards-playing-heart"
            },
            {
                "name": "cards-playing-heart-multiple",
                "icon": "mdi-cards-playing-heart-multiple"
            },
            {
                "name": "cards-playing-heart-multiple-outline",
                "icon": "mdi-cards-playing-heart-multiple-outline"
            },
            {
                "name": "cards-playing-heart-outline",
                "icon": "mdi-cards-playing-heart-outline"
            },
            {
                "name": "cards-playing-outline",
                "icon": "mdi-cards-playing-outline"
            },
            {
                "name": "cards-playing-spade",
                "icon": "mdi-cards-playing-spade"
            },
            {
                "name": "cards-playing-spade-multiple",
                "icon": "mdi-cards-playing-spade-multiple"
            },
            {
                "name": "cards-playing-spade-multiple-outline",
                "icon": "mdi-cards-playing-spade-multiple-outline"
            },
            {
                "name": "cards-playing-spade-outline",
                "icon": "mdi-cards-playing-spade-outline"
            },
            {
                "name": "cards-spade",
                "icon": "mdi-cards-spade"
            },
            {
                "name": "cards-spade-outline",
                "icon": "mdi-cards-spade-outline"
            },
            {
                "name": "cards-variant",
                "icon": "mdi-cards-variant"
            },
            {
                "name": "carrot",
                "icon": "mdi-carrot"
            },
            {
                "name": "cart",
                "icon": "mdi-cart"
            },
            {
                "name": "cart-arrow-down",
                "icon": "mdi-cart-arrow-down"
            },
            {
                "name": "cart-arrow-right",
                "icon": "mdi-cart-arrow-right"
            },
            {
                "name": "cart-arrow-up",
                "icon": "mdi-cart-arrow-up"
            },
            {
                "name": "cart-check",
                "icon": "mdi-cart-check"
            },
            {
                "name": "cart-heart",
                "icon": "mdi-cart-heart"
            },
            {
                "name": "cart-minus",
                "icon": "mdi-cart-minus"
            },
            {
                "name": "cart-off",
                "icon": "mdi-cart-off"
            },
            {
                "name": "cart-outline",
                "icon": "mdi-cart-outline"
            },
            {
                "name": "cart-percent",
                "icon": "mdi-cart-percent"
            },
            {
                "name": "cart-plus",
                "icon": "mdi-cart-plus"
            },
            {
                "name": "cart-remove",
                "icon": "mdi-cart-remove"
            },
            {
                "name": "cart-variant",
                "icon": "mdi-cart-variant"
            },
            {
                "name": "case-sensitive-alt",
                "icon": "mdi-case-sensitive-alt"
            },
            {
                "name": "cash",
                "icon": "mdi-cash"
            },
            {
                "name": "cash-100",
                "icon": "mdi-cash-100"
            },
            {
                "name": "cash-check",
                "icon": "mdi-cash-check"
            },
            {
                "name": "cash-clock",
                "icon": "mdi-cash-clock"
            },
            {
                "name": "cash-fast",
                "icon": "mdi-cash-fast"
            },
            {
                "name": "cash-lock",
                "icon": "mdi-cash-lock"
            },
            {
                "name": "cash-lock-open",
                "icon": "mdi-cash-lock-open"
            },
            {
                "name": "cash-marker",
                "icon": "mdi-cash-marker"
            },
            {
                "name": "cash-minus",
                "icon": "mdi-cash-minus"
            },
            {
                "name": "cash-multiple",
                "icon": "mdi-cash-multiple"
            },
            {
                "name": "cash-plus",
                "icon": "mdi-cash-plus"
            },
            {
                "name": "cash-refund",
                "icon": "mdi-cash-refund"
            },
            {
                "name": "cash-register",
                "icon": "mdi-cash-register"
            },
            {
                "name": "cash-remove",
                "icon": "mdi-cash-remove"
            },
            {
                "name": "cash-sync",
                "icon": "mdi-cash-sync"
            },
            {
                "name": "cassette",
                "icon": "mdi-cassette"
            },
            {
                "name": "cast",
                "icon": "mdi-cast"
            },
            {
                "name": "cast-audio",
                "icon": "mdi-cast-audio"
            },
            {
                "name": "cast-audio-variant",
                "icon": "mdi-cast-audio-variant"
            },
            {
                "name": "cast-connected",
                "icon": "mdi-cast-connected"
            },
            {
                "name": "cast-education",
                "icon": "mdi-cast-education"
            },
            {
                "name": "cast-off",
                "icon": "mdi-cast-off"
            },
            {
                "name": "cast-variant",
                "icon": "mdi-cast-variant"
            },
            {
                "name": "castle",
                "icon": "mdi-castle"
            },
            {
                "name": "cat",
                "icon": "mdi-cat"
            },
            {
                "name": "cctv",
                "icon": "mdi-cctv"
            },
            {
                "name": "cctv-off",
                "icon": "mdi-cctv-off"
            },
            {
                "name": "ceiling-fan",
                "icon": "mdi-ceiling-fan"
            },
            {
                "name": "ceiling-fan-light",
                "icon": "mdi-ceiling-fan-light"
            },
            {
                "name": "ceiling-light",
                "icon": "mdi-ceiling-light"
            },
            {
                "name": "ceiling-light-multiple",
                "icon": "mdi-ceiling-light-multiple"
            },
            {
                "name": "ceiling-light-multiple-outline",
                "icon": "mdi-ceiling-light-multiple-outline"
            },
            {
                "name": "ceiling-light-outline",
                "icon": "mdi-ceiling-light-outline"
            },
            {
                "name": "cellphone",
                "icon": "mdi-cellphone"
            },
            {
                "name": "cellphone-arrow-down",
                "icon": "mdi-cellphone-arrow-down"
            },
            {
                "name": "cellphone-arrow-down-variant",
                "icon": "mdi-cellphone-arrow-down-variant"
            },
            {
                "name": "cellphone-basic",
                "icon": "mdi-cellphone-basic"
            },
            {
                "name": "cellphone-charging",
                "icon": "mdi-cellphone-charging"
            },
            {
                "name": "cellphone-check",
                "icon": "mdi-cellphone-check"
            },
            {
                "name": "cellphone-cog",
                "icon": "mdi-cellphone-cog"
            },
            {
                "name": "cellphone-dock",
                "icon": "mdi-cellphone-dock"
            },
            {
                "name": "cellphone-information",
                "icon": "mdi-cellphone-information"
            },
            {
                "name": "cellphone-key",
                "icon": "mdi-cellphone-key"
            },
            {
                "name": "cellphone-link",
                "icon": "mdi-cellphone-link"
            },
            {
                "name": "cellphone-link-off",
                "icon": "mdi-cellphone-link-off"
            },
            {
                "name": "cellphone-lock",
                "icon": "mdi-cellphone-lock"
            },
            {
                "name": "cellphone-marker",
                "icon": "mdi-cellphone-marker"
            },
            {
                "name": "cellphone-message",
                "icon": "mdi-cellphone-message"
            },
            {
                "name": "cellphone-message-off",
                "icon": "mdi-cellphone-message-off"
            },
            {
                "name": "cellphone-nfc",
                "icon": "mdi-cellphone-nfc"
            },
            {
                "name": "cellphone-nfc-off",
                "icon": "mdi-cellphone-nfc-off"
            },
            {
                "name": "cellphone-off",
                "icon": "mdi-cellphone-off"
            },
            {
                "name": "cellphone-play",
                "icon": "mdi-cellphone-play"
            },
            {
                "name": "cellphone-remove",
                "icon": "mdi-cellphone-remove"
            },
            {
                "name": "cellphone-screenshot",
                "icon": "mdi-cellphone-screenshot"
            },
            {
                "name": "cellphone-settings",
                "icon": "mdi-cellphone-settings"
            },
            {
                "name": "cellphone-sound",
                "icon": "mdi-cellphone-sound"
            },
            {
                "name": "cellphone-text",
                "icon": "mdi-cellphone-text"
            },
            {
                "name": "cellphone-wireless",
                "icon": "mdi-cellphone-wireless"
            },
            {
                "name": "centos",
                "icon": "mdi-centos"
            },
            {
                "name": "certificate",
                "icon": "mdi-certificate"
            },
            {
                "name": "certificate-outline",
                "icon": "mdi-certificate-outline"
            },
            {
                "name": "chair-rolling",
                "icon": "mdi-chair-rolling"
            },
            {
                "name": "chair-school",
                "icon": "mdi-chair-school"
            },
            {
                "name": "chandelier",
                "icon": "mdi-chandelier"
            },
            {
                "name": "charity",
                "icon": "mdi-charity"
            },
            {
                "name": "chart-arc",
                "icon": "mdi-chart-arc"
            },
            {
                "name": "chart-areaspline",
                "icon": "mdi-chart-areaspline"
            },
            {
                "name": "chart-areaspline-variant",
                "icon": "mdi-chart-areaspline-variant"
            },
            {
                "name": "chart-bar",
                "icon": "mdi-chart-bar"
            },
            {
                "name": "chart-bar-stacked",
                "icon": "mdi-chart-bar-stacked"
            },
            {
                "name": "chart-bell-curve",
                "icon": "mdi-chart-bell-curve"
            },
            {
                "name": "chart-bell-curve-cumulative",
                "icon": "mdi-chart-bell-curve-cumulative"
            },
            {
                "name": "chart-box",
                "icon": "mdi-chart-box"
            },
            {
                "name": "chart-box-outline",
                "icon": "mdi-chart-box-outline"
            },
            {
                "name": "chart-box-plus-outline",
                "icon": "mdi-chart-box-plus-outline"
            },
            {
                "name": "chart-bubble",
                "icon": "mdi-chart-bubble"
            },
            {
                "name": "chart-donut",
                "icon": "mdi-chart-donut"
            },
            {
                "name": "chart-donut-variant",
                "icon": "mdi-chart-donut-variant"
            },
            {
                "name": "chart-gantt",
                "icon": "mdi-chart-gantt"
            },
            {
                "name": "chart-histogram",
                "icon": "mdi-chart-histogram"
            },
            {
                "name": "chart-line",
                "icon": "mdi-chart-line"
            },
            {
                "name": "chart-line-stacked",
                "icon": "mdi-chart-line-stacked"
            },
            {
                "name": "chart-line-variant",
                "icon": "mdi-chart-line-variant"
            },
            {
                "name": "chart-multiline",
                "icon": "mdi-chart-multiline"
            },
            {
                "name": "chart-multiple",
                "icon": "mdi-chart-multiple"
            },
            {
                "name": "chart-pie",
                "icon": "mdi-chart-pie"
            },
            {
                "name": "chart-ppf",
                "icon": "mdi-chart-ppf"
            },
            {
                "name": "chart-sankey",
                "icon": "mdi-chart-sankey"
            },
            {
                "name": "chart-sankey-variant",
                "icon": "mdi-chart-sankey-variant"
            },
            {
                "name": "chart-scatter-plot",
                "icon": "mdi-chart-scatter-plot"
            },
            {
                "name": "chart-scatter-plot-hexbin",
                "icon": "mdi-chart-scatter-plot-hexbin"
            },
            {
                "name": "chart-timeline",
                "icon": "mdi-chart-timeline"
            },
            {
                "name": "chart-timeline-variant",
                "icon": "mdi-chart-timeline-variant"
            },
            {
                "name": "chart-timeline-variant-shimmer",
                "icon": "mdi-chart-timeline-variant-shimmer"
            },
            {
                "name": "chart-tree",
                "icon": "mdi-chart-tree"
            },
            {
                "name": "chart-waterfall",
                "icon": "mdi-chart-waterfall"
            },
            {
                "name": "chat",
                "icon": "mdi-chat"
            },
            {
                "name": "chat-alert",
                "icon": "mdi-chat-alert"
            },
            {
                "name": "chat-alert-outline",
                "icon": "mdi-chat-alert-outline"
            },
            {
                "name": "chat-minus",
                "icon": "mdi-chat-minus"
            },
            {
                "name": "chat-minus-outline",
                "icon": "mdi-chat-minus-outline"
            },
            {
                "name": "chat-outline",
                "icon": "mdi-chat-outline"
            },
            {
                "name": "chat-plus",
                "icon": "mdi-chat-plus"
            },
            {
                "name": "chat-plus-outline",
                "icon": "mdi-chat-plus-outline"
            },
            {
                "name": "chat-processing",
                "icon": "mdi-chat-processing"
            },
            {
                "name": "chat-processing-outline",
                "icon": "mdi-chat-processing-outline"
            },
            {
                "name": "chat-question",
                "icon": "mdi-chat-question"
            },
            {
                "name": "chat-question-outline",
                "icon": "mdi-chat-question-outline"
            },
            {
                "name": "chat-remove",
                "icon": "mdi-chat-remove"
            },
            {
                "name": "chat-remove-outline",
                "icon": "mdi-chat-remove-outline"
            },
            {
                "name": "chat-sleep",
                "icon": "mdi-chat-sleep"
            },
            {
                "name": "chat-sleep-outline",
                "icon": "mdi-chat-sleep-outline"
            },
            {
                "name": "check",
                "icon": "mdi-check"
            },
            {
                "name": "check-all",
                "icon": "mdi-check-all"
            },
            {
                "name": "check-bold",
                "icon": "mdi-check-bold"
            },
            {
                "name": "check-circle",
                "icon": "mdi-check-circle"
            },
            {
                "name": "check-circle-outline",
                "icon": "mdi-check-circle-outline"
            },
            {
                "name": "check-decagram",
                "icon": "mdi-check-decagram"
            },
            {
                "name": "check-decagram-outline",
                "icon": "mdi-check-decagram-outline"
            },
            {
                "name": "check-network",
                "icon": "mdi-check-network"
            },
            {
                "name": "check-network-outline",
                "icon": "mdi-check-network-outline"
            },
            {
                "name": "check-outline",
                "icon": "mdi-check-outline"
            },
            {
                "name": "check-underline",
                "icon": "mdi-check-underline"
            },
            {
                "name": "check-underline-circle",
                "icon": "mdi-check-underline-circle"
            },
            {
                "name": "check-underline-circle-outline",
                "icon": "mdi-check-underline-circle-outline"
            },
            {
                "name": "checkbook",
                "icon": "mdi-checkbook"
            },
            {
                "name": "checkbox-blank",
                "icon": "mdi-checkbox-blank"
            },
            {
                "name": "checkbox-blank-badge",
                "icon": "mdi-checkbox-blank-badge"
            },
            {
                "name": "checkbox-blank-badge-outline",
                "icon": "mdi-checkbox-blank-badge-outline"
            },
            {
                "name": "checkbox-blank-circle",
                "icon": "mdi-checkbox-blank-circle"
            },
            {
                "name": "checkbox-blank-circle-outline",
                "icon": "mdi-checkbox-blank-circle-outline"
            },
            {
                "name": "checkbox-blank-off",
                "icon": "mdi-checkbox-blank-off"
            },
            {
                "name": "checkbox-blank-off-outline",
                "icon": "mdi-checkbox-blank-off-outline"
            },
            {
                "name": "checkbox-blank-outline",
                "icon": "mdi-checkbox-blank-outline"
            },
            {
                "name": "checkbox-intermediate",
                "icon": "mdi-checkbox-intermediate"
            },
            {
                "name": "checkbox-intermediate-variant",
                "icon": "mdi-checkbox-intermediate-variant"
            },
            {
                "name": "checkbox-marked",
                "icon": "mdi-checkbox-marked"
            },
            {
                "name": "checkbox-marked-circle",
                "icon": "mdi-checkbox-marked-circle"
            },
            {
                "name": "checkbox-marked-circle-outline",
                "icon": "mdi-checkbox-marked-circle-outline"
            },
            {
                "name": "checkbox-marked-circle-plus-outline",
                "icon": "mdi-checkbox-marked-circle-plus-outline"
            },
            {
                "name": "checkbox-marked-outline",
                "icon": "mdi-checkbox-marked-outline"
            },
            {
                "name": "checkbox-multiple-blank",
                "icon": "mdi-checkbox-multiple-blank"
            },
            {
                "name": "checkbox-multiple-blank-circle",
                "icon": "mdi-checkbox-multiple-blank-circle"
            },
            {
                "name": "checkbox-multiple-blank-circle-outline",
                "icon": "mdi-checkbox-multiple-blank-circle-outline"
            },
            {
                "name": "checkbox-multiple-blank-outline",
                "icon": "mdi-checkbox-multiple-blank-outline"
            },
            {
                "name": "checkbox-multiple-marked",
                "icon": "mdi-checkbox-multiple-marked"
            },
            {
                "name": "checkbox-multiple-marked-circle",
                "icon": "mdi-checkbox-multiple-marked-circle"
            },
            {
                "name": "checkbox-multiple-marked-circle-outline",
                "icon": "mdi-checkbox-multiple-marked-circle-outline"
            },
            {
                "name": "checkbox-multiple-marked-outline",
                "icon": "mdi-checkbox-multiple-marked-outline"
            },
            {
                "name": "checkbox-multiple-outline",
                "icon": "mdi-checkbox-multiple-outline"
            },
            {
                "name": "checkbox-outline",
                "icon": "mdi-checkbox-outline"
            },
            {
                "name": "checkerboard",
                "icon": "mdi-checkerboard"
            },
            {
                "name": "checkerboard-minus",
                "icon": "mdi-checkerboard-minus"
            },
            {
                "name": "checkerboard-plus",
                "icon": "mdi-checkerboard-plus"
            },
            {
                "name": "checkerboard-remove",
                "icon": "mdi-checkerboard-remove"
            },
            {
                "name": "cheese",
                "icon": "mdi-cheese"
            },
            {
                "name": "cheese-off",
                "icon": "mdi-cheese-off"
            },
            {
                "name": "chef-hat",
                "icon": "mdi-chef-hat"
            },
            {
                "name": "chemical-weapon",
                "icon": "mdi-chemical-weapon"
            },
            {
                "name": "chess-bishop",
                "icon": "mdi-chess-bishop"
            },
            {
                "name": "chess-king",
                "icon": "mdi-chess-king"
            },
            {
                "name": "chess-knight",
                "icon": "mdi-chess-knight"
            },
            {
                "name": "chess-pawn",
                "icon": "mdi-chess-pawn"
            },
            {
                "name": "chess-queen",
                "icon": "mdi-chess-queen"
            },
            {
                "name": "chess-rook",
                "icon": "mdi-chess-rook"
            },
            {
                "name": "chevron-double-down",
                "icon": "mdi-chevron-double-down"
            },
            {
                "name": "chevron-double-left",
                "icon": "mdi-chevron-double-left"
            },
            {
                "name": "chevron-double-right",
                "icon": "mdi-chevron-double-right"
            },
            {
                "name": "chevron-double-up",
                "icon": "mdi-chevron-double-up"
            },
            {
                "name": "chevron-down",
                "icon": "mdi-chevron-down"
            },
            {
                "name": "chevron-down-box",
                "icon": "mdi-chevron-down-box"
            },
            {
                "name": "chevron-down-box-outline",
                "icon": "mdi-chevron-down-box-outline"
            },
            {
                "name": "chevron-down-circle",
                "icon": "mdi-chevron-down-circle"
            },
            {
                "name": "chevron-down-circle-outline",
                "icon": "mdi-chevron-down-circle-outline"
            },
            {
                "name": "chevron-left",
                "icon": "mdi-chevron-left"
            },
            {
                "name": "chevron-left-box",
                "icon": "mdi-chevron-left-box"
            },
            {
                "name": "chevron-left-box-outline",
                "icon": "mdi-chevron-left-box-outline"
            },
            {
                "name": "chevron-left-circle",
                "icon": "mdi-chevron-left-circle"
            },
            {
                "name": "chevron-left-circle-outline",
                "icon": "mdi-chevron-left-circle-outline"
            },
            {
                "name": "chevron-right",
                "icon": "mdi-chevron-right"
            },
            {
                "name": "chevron-right-box",
                "icon": "mdi-chevron-right-box"
            },
            {
                "name": "chevron-right-box-outline",
                "icon": "mdi-chevron-right-box-outline"
            },
            {
                "name": "chevron-right-circle",
                "icon": "mdi-chevron-right-circle"
            },
            {
                "name": "chevron-right-circle-outline",
                "icon": "mdi-chevron-right-circle-outline"
            },
            {
                "name": "chevron-triple-down",
                "icon": "mdi-chevron-triple-down"
            },
            {
                "name": "chevron-triple-left",
                "icon": "mdi-chevron-triple-left"
            },
            {
                "name": "chevron-triple-right",
                "icon": "mdi-chevron-triple-right"
            },
            {
                "name": "chevron-triple-up",
                "icon": "mdi-chevron-triple-up"
            },
            {
                "name": "chevron-up",
                "icon": "mdi-chevron-up"
            },
            {
                "name": "chevron-up-box",
                "icon": "mdi-chevron-up-box"
            },
            {
                "name": "chevron-up-box-outline",
                "icon": "mdi-chevron-up-box-outline"
            },
            {
                "name": "chevron-up-circle",
                "icon": "mdi-chevron-up-circle"
            },
            {
                "name": "chevron-up-circle-outline",
                "icon": "mdi-chevron-up-circle-outline"
            },
            {
                "name": "chili-alert",
                "icon": "mdi-chili-alert"
            },
            {
                "name": "chili-alert-outline",
                "icon": "mdi-chili-alert-outline"
            },
            {
                "name": "chili-hot",
                "icon": "mdi-chili-hot"
            },
            {
                "name": "chili-hot-outline",
                "icon": "mdi-chili-hot-outline"
            },
            {
                "name": "chili-medium",
                "icon": "mdi-chili-medium"
            },
            {
                "name": "chili-medium-outline",
                "icon": "mdi-chili-medium-outline"
            },
            {
                "name": "chili-mild",
                "icon": "mdi-chili-mild"
            },
            {
                "name": "chili-mild-outline",
                "icon": "mdi-chili-mild-outline"
            },
            {
                "name": "chili-off",
                "icon": "mdi-chili-off"
            },
            {
                "name": "chili-off-outline",
                "icon": "mdi-chili-off-outline"
            },
            {
                "name": "chip",
                "icon": "mdi-chip"
            },
            {
                "name": "church",
                "icon": "mdi-church"
            },
            {
                "name": "church-outline",
                "icon": "mdi-church-outline"
            },
            {
                "name": "cigar",
                "icon": "mdi-cigar"
            },
            {
                "name": "cigar-off",
                "icon": "mdi-cigar-off"
            },
            {
                "name": "circle",
                "icon": "mdi-circle"
            },
            {
                "name": "circle-box",
                "icon": "mdi-circle-box"
            },
            {
                "name": "circle-box-outline",
                "icon": "mdi-circle-box-outline"
            },
            {
                "name": "circle-double",
                "icon": "mdi-circle-double"
            },
            {
                "name": "circle-edit-outline",
                "icon": "mdi-circle-edit-outline"
            },
            {
                "name": "circle-expand",
                "icon": "mdi-circle-expand"
            },
            {
                "name": "circle-half",
                "icon": "mdi-circle-half"
            },
            {
                "name": "circle-half-full",
                "icon": "mdi-circle-half-full"
            },
            {
                "name": "circle-medium",
                "icon": "mdi-circle-medium"
            },
            {
                "name": "circle-multiple",
                "icon": "mdi-circle-multiple"
            },
            {
                "name": "circle-multiple-outline",
                "icon": "mdi-circle-multiple-outline"
            },
            {
                "name": "circle-off-outline",
                "icon": "mdi-circle-off-outline"
            },
            {
                "name": "circle-opacity",
                "icon": "mdi-circle-opacity"
            },
            {
                "name": "circle-outline",
                "icon": "mdi-circle-outline"
            },
            {
                "name": "circle-slice-1",
                "icon": "mdi-circle-slice-1"
            },
            {
                "name": "circle-slice-2",
                "icon": "mdi-circle-slice-2"
            },
            {
                "name": "circle-slice-3",
                "icon": "mdi-circle-slice-3"
            },
            {
                "name": "circle-slice-4",
                "icon": "mdi-circle-slice-4"
            },
            {
                "name": "circle-slice-5",
                "icon": "mdi-circle-slice-5"
            },
            {
                "name": "circle-slice-6",
                "icon": "mdi-circle-slice-6"
            },
            {
                "name": "circle-slice-7",
                "icon": "mdi-circle-slice-7"
            },
            {
                "name": "circle-slice-8",
                "icon": "mdi-circle-slice-8"
            },
            {
                "name": "circle-small",
                "icon": "mdi-circle-small"
            },
            {
                "name": "circular-saw",
                "icon": "mdi-circular-saw"
            },
            {
                "name": "city",
                "icon": "mdi-city"
            },
            {
                "name": "city-variant",
                "icon": "mdi-city-variant"
            },
            {
                "name": "city-variant-outline",
                "icon": "mdi-city-variant-outline"
            },
            {
                "name": "clipboard",
                "icon": "mdi-clipboard"
            },
            {
                "name": "clipboard-account",
                "icon": "mdi-clipboard-account"
            },
            {
                "name": "clipboard-account-outline",
                "icon": "mdi-clipboard-account-outline"
            },
            {
                "name": "clipboard-alert",
                "icon": "mdi-clipboard-alert"
            },
            {
                "name": "clipboard-alert-outline",
                "icon": "mdi-clipboard-alert-outline"
            },
            {
                "name": "clipboard-arrow-down",
                "icon": "mdi-clipboard-arrow-down"
            },
            {
                "name": "clipboard-arrow-down-outline",
                "icon": "mdi-clipboard-arrow-down-outline"
            },
            {
                "name": "clipboard-arrow-left",
                "icon": "mdi-clipboard-arrow-left"
            },
            {
                "name": "clipboard-arrow-left-outline",
                "icon": "mdi-clipboard-arrow-left-outline"
            },
            {
                "name": "clipboard-arrow-right",
                "icon": "mdi-clipboard-arrow-right"
            },
            {
                "name": "clipboard-arrow-right-outline",
                "icon": "mdi-clipboard-arrow-right-outline"
            },
            {
                "name": "clipboard-arrow-up",
                "icon": "mdi-clipboard-arrow-up"
            },
            {
                "name": "clipboard-arrow-up-outline",
                "icon": "mdi-clipboard-arrow-up-outline"
            },
            {
                "name": "clipboard-check",
                "icon": "mdi-clipboard-check"
            },
            {
                "name": "clipboard-check-multiple",
                "icon": "mdi-clipboard-check-multiple"
            },
            {
                "name": "clipboard-check-multiple-outline",
                "icon": "mdi-clipboard-check-multiple-outline"
            },
            {
                "name": "clipboard-check-outline",
                "icon": "mdi-clipboard-check-outline"
            },
            {
                "name": "clipboard-clock",
                "icon": "mdi-clipboard-clock"
            },
            {
                "name": "clipboard-clock-outline",
                "icon": "mdi-clipboard-clock-outline"
            },
            {
                "name": "clipboard-edit",
                "icon": "mdi-clipboard-edit"
            },
            {
                "name": "clipboard-edit-outline",
                "icon": "mdi-clipboard-edit-outline"
            },
            {
                "name": "clipboard-file",
                "icon": "mdi-clipboard-file"
            },
            {
                "name": "clipboard-file-outline",
                "icon": "mdi-clipboard-file-outline"
            },
            {
                "name": "clipboard-flow",
                "icon": "mdi-clipboard-flow"
            },
            {
                "name": "clipboard-flow-outline",
                "icon": "mdi-clipboard-flow-outline"
            },
            {
                "name": "clipboard-list",
                "icon": "mdi-clipboard-list"
            },
            {
                "name": "clipboard-list-outline",
                "icon": "mdi-clipboard-list-outline"
            },
            {
                "name": "clipboard-minus",
                "icon": "mdi-clipboard-minus"
            },
            {
                "name": "clipboard-minus-outline",
                "icon": "mdi-clipboard-minus-outline"
            },
            {
                "name": "clipboard-multiple",
                "icon": "mdi-clipboard-multiple"
            },
            {
                "name": "clipboard-multiple-outline",
                "icon": "mdi-clipboard-multiple-outline"
            },
            {
                "name": "clipboard-off",
                "icon": "mdi-clipboard-off"
            },
            {
                "name": "clipboard-off-outline",
                "icon": "mdi-clipboard-off-outline"
            },
            {
                "name": "clipboard-outline",
                "icon": "mdi-clipboard-outline"
            },
            {
                "name": "clipboard-play",
                "icon": "mdi-clipboard-play"
            },
            {
                "name": "clipboard-play-multiple",
                "icon": "mdi-clipboard-play-multiple"
            },
            {
                "name": "clipboard-play-multiple-outline",
                "icon": "mdi-clipboard-play-multiple-outline"
            },
            {
                "name": "clipboard-play-outline",
                "icon": "mdi-clipboard-play-outline"
            },
            {
                "name": "clipboard-plus",
                "icon": "mdi-clipboard-plus"
            },
            {
                "name": "clipboard-plus-outline",
                "icon": "mdi-clipboard-plus-outline"
            },
            {
                "name": "clipboard-pulse",
                "icon": "mdi-clipboard-pulse"
            },
            {
                "name": "clipboard-pulse-outline",
                "icon": "mdi-clipboard-pulse-outline"
            },
            {
                "name": "clipboard-remove",
                "icon": "mdi-clipboard-remove"
            },
            {
                "name": "clipboard-remove-outline",
                "icon": "mdi-clipboard-remove-outline"
            },
            {
                "name": "clipboard-search",
                "icon": "mdi-clipboard-search"
            },
            {
                "name": "clipboard-search-outline",
                "icon": "mdi-clipboard-search-outline"
            },
            {
                "name": "clipboard-text",
                "icon": "mdi-clipboard-text"
            },
            {
                "name": "clipboard-text-clock",
                "icon": "mdi-clipboard-text-clock"
            },
            {
                "name": "clipboard-text-clock-outline",
                "icon": "mdi-clipboard-text-clock-outline"
            },
            {
                "name": "clipboard-text-multiple",
                "icon": "mdi-clipboard-text-multiple"
            },
            {
                "name": "clipboard-text-multiple-outline",
                "icon": "mdi-clipboard-text-multiple-outline"
            },
            {
                "name": "clipboard-text-off",
                "icon": "mdi-clipboard-text-off"
            },
            {
                "name": "clipboard-text-off-outline",
                "icon": "mdi-clipboard-text-off-outline"
            },
            {
                "name": "clipboard-text-outline",
                "icon": "mdi-clipboard-text-outline"
            },
            {
                "name": "clipboard-text-play",
                "icon": "mdi-clipboard-text-play"
            },
            {
                "name": "clipboard-text-play-outline",
                "icon": "mdi-clipboard-text-play-outline"
            },
            {
                "name": "clipboard-text-search",
                "icon": "mdi-clipboard-text-search"
            },
            {
                "name": "clipboard-text-search-outline",
                "icon": "mdi-clipboard-text-search-outline"
            },
            {
                "name": "clippy",
                "icon": "mdi-clippy"
            },
            {
                "name": "clock",
                "icon": "mdi-clock"
            },
            {
                "name": "clock-alert",
                "icon": "mdi-clock-alert"
            },
            {
                "name": "clock-alert-outline",
                "icon": "mdi-clock-alert-outline"
            },
            {
                "name": "clock-check",
                "icon": "mdi-clock-check"
            },
            {
                "name": "clock-check-outline",
                "icon": "mdi-clock-check-outline"
            },
            {
                "name": "clock-digital",
                "icon": "mdi-clock-digital"
            },
            {
                "name": "clock-edit",
                "icon": "mdi-clock-edit"
            },
            {
                "name": "clock-edit-outline",
                "icon": "mdi-clock-edit-outline"
            },
            {
                "name": "clock-end",
                "icon": "mdi-clock-end"
            },
            {
                "name": "clock-fast",
                "icon": "mdi-clock-fast"
            },
            {
                "name": "clock-in",
                "icon": "mdi-clock-in"
            },
            {
                "name": "clock-minus",
                "icon": "mdi-clock-minus"
            },
            {
                "name": "clock-minus-outline",
                "icon": "mdi-clock-minus-outline"
            },
            {
                "name": "clock-out",
                "icon": "mdi-clock-out"
            },
            {
                "name": "clock-outline",
                "icon": "mdi-clock-outline"
            },
            {
                "name": "clock-plus",
                "icon": "mdi-clock-plus"
            },
            {
                "name": "clock-plus-outline",
                "icon": "mdi-clock-plus-outline"
            },
            {
                "name": "clock-remove",
                "icon": "mdi-clock-remove"
            },
            {
                "name": "clock-remove-outline",
                "icon": "mdi-clock-remove-outline"
            },
            {
                "name": "clock-start",
                "icon": "mdi-clock-start"
            },
            {
                "name": "clock-time-eight",
                "icon": "mdi-clock-time-eight"
            },
            {
                "name": "clock-time-eight-outline",
                "icon": "mdi-clock-time-eight-outline"
            },
            {
                "name": "clock-time-eleven",
                "icon": "mdi-clock-time-eleven"
            },
            {
                "name": "clock-time-eleven-outline",
                "icon": "mdi-clock-time-eleven-outline"
            },
            {
                "name": "clock-time-five",
                "icon": "mdi-clock-time-five"
            },
            {
                "name": "clock-time-five-outline",
                "icon": "mdi-clock-time-five-outline"
            },
            {
                "name": "clock-time-four",
                "icon": "mdi-clock-time-four"
            },
            {
                "name": "clock-time-four-outline",
                "icon": "mdi-clock-time-four-outline"
            },
            {
                "name": "clock-time-nine",
                "icon": "mdi-clock-time-nine"
            },
            {
                "name": "clock-time-nine-outline",
                "icon": "mdi-clock-time-nine-outline"
            },
            {
                "name": "clock-time-one",
                "icon": "mdi-clock-time-one"
            },
            {
                "name": "clock-time-one-outline",
                "icon": "mdi-clock-time-one-outline"
            },
            {
                "name": "clock-time-seven",
                "icon": "mdi-clock-time-seven"
            },
            {
                "name": "clock-time-seven-outline",
                "icon": "mdi-clock-time-seven-outline"
            },
            {
                "name": "clock-time-six",
                "icon": "mdi-clock-time-six"
            },
            {
                "name": "clock-time-six-outline",
                "icon": "mdi-clock-time-six-outline"
            },
            {
                "name": "clock-time-ten",
                "icon": "mdi-clock-time-ten"
            },
            {
                "name": "clock-time-ten-outline",
                "icon": "mdi-clock-time-ten-outline"
            },
            {
                "name": "clock-time-three",
                "icon": "mdi-clock-time-three"
            },
            {
                "name": "clock-time-three-outline",
                "icon": "mdi-clock-time-three-outline"
            },
            {
                "name": "clock-time-twelve",
                "icon": "mdi-clock-time-twelve"
            },
            {
                "name": "clock-time-twelve-outline",
                "icon": "mdi-clock-time-twelve-outline"
            },
            {
                "name": "clock-time-two",
                "icon": "mdi-clock-time-two"
            },
            {
                "name": "clock-time-two-outline",
                "icon": "mdi-clock-time-two-outline"
            },
            {
                "name": "close",
                "icon": "mdi-close"
            },
            {
                "name": "close-box",
                "icon": "mdi-close-box"
            },
            {
                "name": "close-box-multiple",
                "icon": "mdi-close-box-multiple"
            },
            {
                "name": "close-box-multiple-outline",
                "icon": "mdi-close-box-multiple-outline"
            },
            {
                "name": "close-box-outline",
                "icon": "mdi-close-box-outline"
            },
            {
                "name": "close-circle",
                "icon": "mdi-close-circle"
            },
            {
                "name": "close-circle-multiple",
                "icon": "mdi-close-circle-multiple"
            },
            {
                "name": "close-circle-multiple-outline",
                "icon": "mdi-close-circle-multiple-outline"
            },
            {
                "name": "close-circle-outline",
                "icon": "mdi-close-circle-outline"
            },
            {
                "name": "close-network",
                "icon": "mdi-close-network"
            },
            {
                "name": "close-network-outline",
                "icon": "mdi-close-network-outline"
            },
            {
                "name": "close-octagon",
                "icon": "mdi-close-octagon"
            },
            {
                "name": "close-octagon-outline",
                "icon": "mdi-close-octagon-outline"
            },
            {
                "name": "close-outline",
                "icon": "mdi-close-outline"
            },
            {
                "name": "close-thick",
                "icon": "mdi-close-thick"
            },
            {
                "name": "closed-caption",
                "icon": "mdi-closed-caption"
            },
            {
                "name": "closed-caption-outline",
                "icon": "mdi-closed-caption-outline"
            },
            {
                "name": "cloud",
                "icon": "mdi-cloud"
            },
            {
                "name": "cloud-alert",
                "icon": "mdi-cloud-alert"
            },
            {
                "name": "cloud-braces",
                "icon": "mdi-cloud-braces"
            },
            {
                "name": "cloud-check",
                "icon": "mdi-cloud-check"
            },
            {
                "name": "cloud-check-outline",
                "icon": "mdi-cloud-check-outline"
            },
            {
                "name": "cloud-circle",
                "icon": "mdi-cloud-circle"
            },
            {
                "name": "cloud-download",
                "icon": "mdi-cloud-download"
            },
            {
                "name": "cloud-download-outline",
                "icon": "mdi-cloud-download-outline"
            },
            {
                "name": "cloud-lock",
                "icon": "mdi-cloud-lock"
            },
            {
                "name": "cloud-lock-outline",
                "icon": "mdi-cloud-lock-outline"
            },
            {
                "name": "cloud-off-outline",
                "icon": "mdi-cloud-off-outline"
            },
            {
                "name": "cloud-outline",
                "icon": "mdi-cloud-outline"
            },
            {
                "name": "cloud-percent",
                "icon": "mdi-cloud-percent"
            },
            {
                "name": "cloud-percent-outline",
                "icon": "mdi-cloud-percent-outline"
            },
            {
                "name": "cloud-print",
                "icon": "mdi-cloud-print"
            },
            {
                "name": "cloud-print-outline",
                "icon": "mdi-cloud-print-outline"
            },
            {
                "name": "cloud-question",
                "icon": "mdi-cloud-question"
            },
            {
                "name": "cloud-refresh",
                "icon": "mdi-cloud-refresh"
            },
            {
                "name": "cloud-search",
                "icon": "mdi-cloud-search"
            },
            {
                "name": "cloud-search-outline",
                "icon": "mdi-cloud-search-outline"
            },
            {
                "name": "cloud-sync",
                "icon": "mdi-cloud-sync"
            },
            {
                "name": "cloud-sync-outline",
                "icon": "mdi-cloud-sync-outline"
            },
            {
                "name": "cloud-tags",
                "icon": "mdi-cloud-tags"
            },
            {
                "name": "cloud-upload",
                "icon": "mdi-cloud-upload"
            },
            {
                "name": "cloud-upload-outline",
                "icon": "mdi-cloud-upload-outline"
            },
            {
                "name": "clouds",
                "icon": "mdi-clouds"
            },
            {
                "name": "clover",
                "icon": "mdi-clover"
            },
            {
                "name": "coach-lamp",
                "icon": "mdi-coach-lamp"
            },
            {
                "name": "coach-lamp-variant",
                "icon": "mdi-coach-lamp-variant"
            },
            {
                "name": "coat-rack",
                "icon": "mdi-coat-rack"
            },
            {
                "name": "code-array",
                "icon": "mdi-code-array"
            },
            {
                "name": "code-braces",
                "icon": "mdi-code-braces"
            },
            {
                "name": "code-braces-box",
                "icon": "mdi-code-braces-box"
            },
            {
                "name": "code-brackets",
                "icon": "mdi-code-brackets"
            },
            {
                "name": "code-equal",
                "icon": "mdi-code-equal"
            },
            {
                "name": "code-greater-than",
                "icon": "mdi-code-greater-than"
            },
            {
                "name": "code-greater-than-or-equal",
                "icon": "mdi-code-greater-than-or-equal"
            },
            {
                "name": "code-json",
                "icon": "mdi-code-json"
            },
            {
                "name": "code-less-than",
                "icon": "mdi-code-less-than"
            },
            {
                "name": "code-less-than-or-equal",
                "icon": "mdi-code-less-than-or-equal"
            },
            {
                "name": "code-not-equal",
                "icon": "mdi-code-not-equal"
            },
            {
                "name": "code-not-equal-variant",
                "icon": "mdi-code-not-equal-variant"
            },
            {
                "name": "code-parentheses",
                "icon": "mdi-code-parentheses"
            },
            {
                "name": "code-parentheses-box",
                "icon": "mdi-code-parentheses-box"
            },
            {
                "name": "code-string",
                "icon": "mdi-code-string"
            },
            {
                "name": "code-tags",
                "icon": "mdi-code-tags"
            },
            {
                "name": "code-tags-check",
                "icon": "mdi-code-tags-check"
            },
            {
                "name": "codepen",
                "icon": "mdi-codepen"
            },
            {
                "name": "coffee",
                "icon": "mdi-coffee"
            },
            {
                "name": "coffee-maker",
                "icon": "mdi-coffee-maker"
            },
            {
                "name": "coffee-maker-check",
                "icon": "mdi-coffee-maker-check"
            },
            {
                "name": "coffee-maker-check-outline",
                "icon": "mdi-coffee-maker-check-outline"
            },
            {
                "name": "coffee-maker-outline",
                "icon": "mdi-coffee-maker-outline"
            },
            {
                "name": "coffee-off",
                "icon": "mdi-coffee-off"
            },
            {
                "name": "coffee-off-outline",
                "icon": "mdi-coffee-off-outline"
            },
            {
                "name": "coffee-outline",
                "icon": "mdi-coffee-outline"
            },
            {
                "name": "coffee-to-go",
                "icon": "mdi-coffee-to-go"
            },
            {
                "name": "coffee-to-go-outline",
                "icon": "mdi-coffee-to-go-outline"
            },
            {
                "name": "coffin",
                "icon": "mdi-coffin"
            },
            {
                "name": "cog",
                "icon": "mdi-cog"
            },
            {
                "name": "cog-box",
                "icon": "mdi-cog-box"
            },
            {
                "name": "cog-clockwise",
                "icon": "mdi-cog-clockwise"
            },
            {
                "name": "cog-counterclockwise",
                "icon": "mdi-cog-counterclockwise"
            },
            {
                "name": "cog-off",
                "icon": "mdi-cog-off"
            },
            {
                "name": "cog-off-outline",
                "icon": "mdi-cog-off-outline"
            },
            {
                "name": "cog-outline",
                "icon": "mdi-cog-outline"
            },
            {
                "name": "cog-pause",
                "icon": "mdi-cog-pause"
            },
            {
                "name": "cog-pause-outline",
                "icon": "mdi-cog-pause-outline"
            },
            {
                "name": "cog-play",
                "icon": "mdi-cog-play"
            },
            {
                "name": "cog-play-outline",
                "icon": "mdi-cog-play-outline"
            },
            {
                "name": "cog-refresh",
                "icon": "mdi-cog-refresh"
            },
            {
                "name": "cog-refresh-outline",
                "icon": "mdi-cog-refresh-outline"
            },
            {
                "name": "cog-stop",
                "icon": "mdi-cog-stop"
            },
            {
                "name": "cog-stop-outline",
                "icon": "mdi-cog-stop-outline"
            },
            {
                "name": "cog-sync",
                "icon": "mdi-cog-sync"
            },
            {
                "name": "cog-sync-outline",
                "icon": "mdi-cog-sync-outline"
            },
            {
                "name": "cog-transfer",
                "icon": "mdi-cog-transfer"
            },
            {
                "name": "cog-transfer-outline",
                "icon": "mdi-cog-transfer-outline"
            },
            {
                "name": "cogs",
                "icon": "mdi-cogs"
            },
            {
                "name": "collage",
                "icon": "mdi-collage"
            },
            {
                "name": "collapse-all",
                "icon": "mdi-collapse-all"
            },
            {
                "name": "collapse-all-outline",
                "icon": "mdi-collapse-all-outline"
            },
            {
                "name": "color-helper",
                "icon": "mdi-color-helper"
            },
            {
                "name": "comma",
                "icon": "mdi-comma"
            },
            {
                "name": "comma-box",
                "icon": "mdi-comma-box"
            },
            {
                "name": "comma-box-outline",
                "icon": "mdi-comma-box-outline"
            },
            {
                "name": "comma-circle",
                "icon": "mdi-comma-circle"
            },
            {
                "name": "comma-circle-outline",
                "icon": "mdi-comma-circle-outline"
            },
            {
                "name": "comment",
                "icon": "mdi-comment"
            },
            {
                "name": "comment-account",
                "icon": "mdi-comment-account"
            },
            {
                "name": "comment-account-outline",
                "icon": "mdi-comment-account-outline"
            },
            {
                "name": "comment-alert",
                "icon": "mdi-comment-alert"
            },
            {
                "name": "comment-alert-outline",
                "icon": "mdi-comment-alert-outline"
            },
            {
                "name": "comment-arrow-left",
                "icon": "mdi-comment-arrow-left"
            },
            {
                "name": "comment-arrow-left-outline",
                "icon": "mdi-comment-arrow-left-outline"
            },
            {
                "name": "comment-arrow-right",
                "icon": "mdi-comment-arrow-right"
            },
            {
                "name": "comment-arrow-right-outline",
                "icon": "mdi-comment-arrow-right-outline"
            },
            {
                "name": "comment-bookmark",
                "icon": "mdi-comment-bookmark"
            },
            {
                "name": "comment-bookmark-outline",
                "icon": "mdi-comment-bookmark-outline"
            },
            {
                "name": "comment-check",
                "icon": "mdi-comment-check"
            },
            {
                "name": "comment-check-outline",
                "icon": "mdi-comment-check-outline"
            },
            {
                "name": "comment-edit",
                "icon": "mdi-comment-edit"
            },
            {
                "name": "comment-edit-outline",
                "icon": "mdi-comment-edit-outline"
            },
            {
                "name": "comment-eye",
                "icon": "mdi-comment-eye"
            },
            {
                "name": "comment-eye-outline",
                "icon": "mdi-comment-eye-outline"
            },
            {
                "name": "comment-flash",
                "icon": "mdi-comment-flash"
            },
            {
                "name": "comment-flash-outline",
                "icon": "mdi-comment-flash-outline"
            },
            {
                "name": "comment-minus",
                "icon": "mdi-comment-minus"
            },
            {
                "name": "comment-minus-outline",
                "icon": "mdi-comment-minus-outline"
            },
            {
                "name": "comment-multiple",
                "icon": "mdi-comment-multiple"
            },
            {
                "name": "comment-multiple-outline",
                "icon": "mdi-comment-multiple-outline"
            },
            {
                "name": "comment-off",
                "icon": "mdi-comment-off"
            },
            {
                "name": "comment-off-outline",
                "icon": "mdi-comment-off-outline"
            },
            {
                "name": "comment-outline",
                "icon": "mdi-comment-outline"
            },
            {
                "name": "comment-plus",
                "icon": "mdi-comment-plus"
            },
            {
                "name": "comment-plus-outline",
                "icon": "mdi-comment-plus-outline"
            },
            {
                "name": "comment-processing",
                "icon": "mdi-comment-processing"
            },
            {
                "name": "comment-processing-outline",
                "icon": "mdi-comment-processing-outline"
            },
            {
                "name": "comment-question",
                "icon": "mdi-comment-question"
            },
            {
                "name": "comment-question-outline",
                "icon": "mdi-comment-question-outline"
            },
            {
                "name": "comment-quote",
                "icon": "mdi-comment-quote"
            },
            {
                "name": "comment-quote-outline",
                "icon": "mdi-comment-quote-outline"
            },
            {
                "name": "comment-remove",
                "icon": "mdi-comment-remove"
            },
            {
                "name": "comment-remove-outline",
                "icon": "mdi-comment-remove-outline"
            },
            {
                "name": "comment-search",
                "icon": "mdi-comment-search"
            },
            {
                "name": "comment-search-outline",
                "icon": "mdi-comment-search-outline"
            },
            {
                "name": "comment-text",
                "icon": "mdi-comment-text"
            },
            {
                "name": "comment-text-multiple",
                "icon": "mdi-comment-text-multiple"
            },
            {
                "name": "comment-text-multiple-outline",
                "icon": "mdi-comment-text-multiple-outline"
            },
            {
                "name": "comment-text-outline",
                "icon": "mdi-comment-text-outline"
            },
            {
                "name": "compare",
                "icon": "mdi-compare"
            },
            {
                "name": "compare-horizontal",
                "icon": "mdi-compare-horizontal"
            },
            {
                "name": "compare-remove",
                "icon": "mdi-compare-remove"
            },
            {
                "name": "compare-vertical",
                "icon": "mdi-compare-vertical"
            },
            {
                "name": "compass",
                "icon": "mdi-compass"
            },
            {
                "name": "compass-off",
                "icon": "mdi-compass-off"
            },
            {
                "name": "compass-off-outline",
                "icon": "mdi-compass-off-outline"
            },
            {
                "name": "compass-outline",
                "icon": "mdi-compass-outline"
            },
            {
                "name": "compass-rose",
                "icon": "mdi-compass-rose"
            },
            {
                "name": "compost",
                "icon": "mdi-compost"
            },
            {
                "name": "cone",
                "icon": "mdi-cone"
            },
            {
                "name": "cone-off",
                "icon": "mdi-cone-off"
            },
            {
                "name": "connection",
                "icon": "mdi-connection"
            },
            {
                "name": "console",
                "icon": "mdi-console"
            },
            {
                "name": "console-line",
                "icon": "mdi-console-line"
            },
            {
                "name": "console-network",
                "icon": "mdi-console-network"
            },
            {
                "name": "console-network-outline",
                "icon": "mdi-console-network-outline"
            },
            {
                "name": "consolidate",
                "icon": "mdi-consolidate"
            },
            {
                "name": "contactless-payment",
                "icon": "mdi-contactless-payment"
            },
            {
                "name": "contactless-payment-circle",
                "icon": "mdi-contactless-payment-circle"
            },
            {
                "name": "contactless-payment-circle-outline",
                "icon": "mdi-contactless-payment-circle-outline"
            },
            {
                "name": "contacts",
                "icon": "mdi-contacts"
            },
            {
                "name": "contacts-outline",
                "icon": "mdi-contacts-outline"
            },
            {
                "name": "contain",
                "icon": "mdi-contain"
            },
            {
                "name": "contain-end",
                "icon": "mdi-contain-end"
            },
            {
                "name": "contain-start",
                "icon": "mdi-contain-start"
            },
            {
                "name": "content-copy",
                "icon": "mdi-content-copy"
            },
            {
                "name": "content-cut",
                "icon": "mdi-content-cut"
            },
            {
                "name": "content-duplicate",
                "icon": "mdi-content-duplicate"
            },
            {
                "name": "content-paste",
                "icon": "mdi-content-paste"
            },
            {
                "name": "content-save",
                "icon": "mdi-content-save"
            },
            {
                "name": "content-save-alert",
                "icon": "mdi-content-save-alert"
            },
            {
                "name": "content-save-alert-outline",
                "icon": "mdi-content-save-alert-outline"
            },
            {
                "name": "content-save-all",
                "icon": "mdi-content-save-all"
            },
            {
                "name": "content-save-all-outline",
                "icon": "mdi-content-save-all-outline"
            },
            {
                "name": "content-save-check",
                "icon": "mdi-content-save-check"
            },
            {
                "name": "content-save-check-outline",
                "icon": "mdi-content-save-check-outline"
            },
            {
                "name": "content-save-cog",
                "icon": "mdi-content-save-cog"
            },
            {
                "name": "content-save-cog-outline",
                "icon": "mdi-content-save-cog-outline"
            },
            {
                "name": "content-save-edit",
                "icon": "mdi-content-save-edit"
            },
            {
                "name": "content-save-edit-outline",
                "icon": "mdi-content-save-edit-outline"
            },
            {
                "name": "content-save-minus",
                "icon": "mdi-content-save-minus"
            },
            {
                "name": "content-save-minus-outline",
                "icon": "mdi-content-save-minus-outline"
            },
            {
                "name": "content-save-move",
                "icon": "mdi-content-save-move"
            },
            {
                "name": "content-save-move-outline",
                "icon": "mdi-content-save-move-outline"
            },
            {
                "name": "content-save-off",
                "icon": "mdi-content-save-off"
            },
            {
                "name": "content-save-off-outline",
                "icon": "mdi-content-save-off-outline"
            },
            {
                "name": "content-save-outline",
                "icon": "mdi-content-save-outline"
            },
            {
                "name": "content-save-plus",
                "icon": "mdi-content-save-plus"
            },
            {
                "name": "content-save-plus-outline",
                "icon": "mdi-content-save-plus-outline"
            },
            {
                "name": "content-save-settings",
                "icon": "mdi-content-save-settings"
            },
            {
                "name": "content-save-settings-outline",
                "icon": "mdi-content-save-settings-outline"
            },
            {
                "name": "contrast",
                "icon": "mdi-contrast"
            },
            {
                "name": "contrast-box",
                "icon": "mdi-contrast-box"
            },
            {
                "name": "contrast-circle",
                "icon": "mdi-contrast-circle"
            },
            {
                "name": "controller",
                "icon": "mdi-controller"
            },
            {
                "name": "controller-classic",
                "icon": "mdi-controller-classic"
            },
            {
                "name": "controller-classic-outline",
                "icon": "mdi-controller-classic-outline"
            },
            {
                "name": "controller-off",
                "icon": "mdi-controller-off"
            },
            {
                "name": "cookie",
                "icon": "mdi-cookie"
            },
            {
                "name": "cookie-alert",
                "icon": "mdi-cookie-alert"
            },
            {
                "name": "cookie-alert-outline",
                "icon": "mdi-cookie-alert-outline"
            },
            {
                "name": "cookie-check",
                "icon": "mdi-cookie-check"
            },
            {
                "name": "cookie-check-outline",
                "icon": "mdi-cookie-check-outline"
            },
            {
                "name": "cookie-clock",
                "icon": "mdi-cookie-clock"
            },
            {
                "name": "cookie-clock-outline",
                "icon": "mdi-cookie-clock-outline"
            },
            {
                "name": "cookie-cog",
                "icon": "mdi-cookie-cog"
            },
            {
                "name": "cookie-cog-outline",
                "icon": "mdi-cookie-cog-outline"
            },
            {
                "name": "cookie-edit",
                "icon": "mdi-cookie-edit"
            },
            {
                "name": "cookie-edit-outline",
                "icon": "mdi-cookie-edit-outline"
            },
            {
                "name": "cookie-lock",
                "icon": "mdi-cookie-lock"
            },
            {
                "name": "cookie-lock-outline",
                "icon": "mdi-cookie-lock-outline"
            },
            {
                "name": "cookie-minus",
                "icon": "mdi-cookie-minus"
            },
            {
                "name": "cookie-minus-outline",
                "icon": "mdi-cookie-minus-outline"
            },
            {
                "name": "cookie-off",
                "icon": "mdi-cookie-off"
            },
            {
                "name": "cookie-off-outline",
                "icon": "mdi-cookie-off-outline"
            },
            {
                "name": "cookie-outline",
                "icon": "mdi-cookie-outline"
            },
            {
                "name": "cookie-plus",
                "icon": "mdi-cookie-plus"
            },
            {
                "name": "cookie-plus-outline",
                "icon": "mdi-cookie-plus-outline"
            },
            {
                "name": "cookie-refresh",
                "icon": "mdi-cookie-refresh"
            },
            {
                "name": "cookie-refresh-outline",
                "icon": "mdi-cookie-refresh-outline"
            },
            {
                "name": "cookie-remove",
                "icon": "mdi-cookie-remove"
            },
            {
                "name": "cookie-remove-outline",
                "icon": "mdi-cookie-remove-outline"
            },
            {
                "name": "cookie-settings",
                "icon": "mdi-cookie-settings"
            },
            {
                "name": "cookie-settings-outline",
                "icon": "mdi-cookie-settings-outline"
            },
            {
                "name": "coolant-temperature",
                "icon": "mdi-coolant-temperature"
            },
            {
                "name": "copyleft",
                "icon": "mdi-copyleft"
            },
            {
                "name": "copyright",
                "icon": "mdi-copyright"
            },
            {
                "name": "cordova",
                "icon": "mdi-cordova"
            },
            {
                "name": "corn",
                "icon": "mdi-corn"
            },
            {
                "name": "corn-off",
                "icon": "mdi-corn-off"
            },
            {
                "name": "cosine-wave",
                "icon": "mdi-cosine-wave"
            },
            {
                "name": "counter",
                "icon": "mdi-counter"
            },
            {
                "name": "countertop",
                "icon": "mdi-countertop"
            },
            {
                "name": "countertop-outline",
                "icon": "mdi-countertop-outline"
            },
            {
                "name": "cow",
                "icon": "mdi-cow"
            },
            {
                "name": "cow-off",
                "icon": "mdi-cow-off"
            },
            {
                "name": "cpu-32-bit",
                "icon": "mdi-cpu-32-bit"
            },
            {
                "name": "cpu-64-bit",
                "icon": "mdi-cpu-64-bit"
            },
            {
                "name": "cradle",
                "icon": "mdi-cradle"
            },
            {
                "name": "cradle-outline",
                "icon": "mdi-cradle-outline"
            },
            {
                "name": "crane",
                "icon": "mdi-crane"
            },
            {
                "name": "creation",
                "icon": "mdi-creation"
            },
            {
                "name": "creative-commons",
                "icon": "mdi-creative-commons"
            },
            {
                "name": "credit-card",
                "icon": "mdi-credit-card"
            },
            {
                "name": "credit-card-check",
                "icon": "mdi-credit-card-check"
            },
            {
                "name": "credit-card-check-outline",
                "icon": "mdi-credit-card-check-outline"
            },
            {
                "name": "credit-card-chip",
                "icon": "mdi-credit-card-chip"
            },
            {
                "name": "credit-card-chip-outline",
                "icon": "mdi-credit-card-chip-outline"
            },
            {
                "name": "credit-card-clock",
                "icon": "mdi-credit-card-clock"
            },
            {
                "name": "credit-card-clock-outline",
                "icon": "mdi-credit-card-clock-outline"
            },
            {
                "name": "credit-card-edit",
                "icon": "mdi-credit-card-edit"
            },
            {
                "name": "credit-card-edit-outline",
                "icon": "mdi-credit-card-edit-outline"
            },
            {
                "name": "credit-card-fast",
                "icon": "mdi-credit-card-fast"
            },
            {
                "name": "credit-card-fast-outline",
                "icon": "mdi-credit-card-fast-outline"
            },
            {
                "name": "credit-card-lock",
                "icon": "mdi-credit-card-lock"
            },
            {
                "name": "credit-card-lock-outline",
                "icon": "mdi-credit-card-lock-outline"
            },
            {
                "name": "credit-card-marker",
                "icon": "mdi-credit-card-marker"
            },
            {
                "name": "credit-card-marker-outline",
                "icon": "mdi-credit-card-marker-outline"
            },
            {
                "name": "credit-card-minus",
                "icon": "mdi-credit-card-minus"
            },
            {
                "name": "credit-card-minus-outline",
                "icon": "mdi-credit-card-minus-outline"
            },
            {
                "name": "credit-card-multiple",
                "icon": "mdi-credit-card-multiple"
            },
            {
                "name": "credit-card-multiple-outline",
                "icon": "mdi-credit-card-multiple-outline"
            },
            {
                "name": "credit-card-off",
                "icon": "mdi-credit-card-off"
            },
            {
                "name": "credit-card-off-outline",
                "icon": "mdi-credit-card-off-outline"
            },
            {
                "name": "credit-card-outline",
                "icon": "mdi-credit-card-outline"
            },
            {
                "name": "credit-card-plus",
                "icon": "mdi-credit-card-plus"
            },
            {
                "name": "credit-card-plus-outline",
                "icon": "mdi-credit-card-plus-outline"
            },
            {
                "name": "credit-card-refresh",
                "icon": "mdi-credit-card-refresh"
            },
            {
                "name": "credit-card-refresh-outline",
                "icon": "mdi-credit-card-refresh-outline"
            },
            {
                "name": "credit-card-refund",
                "icon": "mdi-credit-card-refund"
            },
            {
                "name": "credit-card-refund-outline",
                "icon": "mdi-credit-card-refund-outline"
            },
            {
                "name": "credit-card-remove",
                "icon": "mdi-credit-card-remove"
            },
            {
                "name": "credit-card-remove-outline",
                "icon": "mdi-credit-card-remove-outline"
            },
            {
                "name": "credit-card-scan",
                "icon": "mdi-credit-card-scan"
            },
            {
                "name": "credit-card-scan-outline",
                "icon": "mdi-credit-card-scan-outline"
            },
            {
                "name": "credit-card-search",
                "icon": "mdi-credit-card-search"
            },
            {
                "name": "credit-card-search-outline",
                "icon": "mdi-credit-card-search-outline"
            },
            {
                "name": "credit-card-settings",
                "icon": "mdi-credit-card-settings"
            },
            {
                "name": "credit-card-settings-outline",
                "icon": "mdi-credit-card-settings-outline"
            },
            {
                "name": "credit-card-sync",
                "icon": "mdi-credit-card-sync"
            },
            {
                "name": "credit-card-sync-outline",
                "icon": "mdi-credit-card-sync-outline"
            },
            {
                "name": "credit-card-wireless",
                "icon": "mdi-credit-card-wireless"
            },
            {
                "name": "credit-card-wireless-off",
                "icon": "mdi-credit-card-wireless-off"
            },
            {
                "name": "credit-card-wireless-off-outline",
                "icon": "mdi-credit-card-wireless-off-outline"
            },
            {
                "name": "credit-card-wireless-outline",
                "icon": "mdi-credit-card-wireless-outline"
            },
            {
                "name": "cricket",
                "icon": "mdi-cricket"
            },
            {
                "name": "crop",
                "icon": "mdi-crop"
            },
            {
                "name": "crop-free",
                "icon": "mdi-crop-free"
            },
            {
                "name": "crop-landscape",
                "icon": "mdi-crop-landscape"
            },
            {
                "name": "crop-portrait",
                "icon": "mdi-crop-portrait"
            },
            {
                "name": "crop-rotate",
                "icon": "mdi-crop-rotate"
            },
            {
                "name": "crop-square",
                "icon": "mdi-crop-square"
            },
            {
                "name": "cross",
                "icon": "mdi-cross"
            },
            {
                "name": "cross-bolnisi",
                "icon": "mdi-cross-bolnisi"
            },
            {
                "name": "cross-celtic",
                "icon": "mdi-cross-celtic"
            },
            {
                "name": "cross-outline",
                "icon": "mdi-cross-outline"
            },
            {
                "name": "crosshairs",
                "icon": "mdi-crosshairs"
            },
            {
                "name": "crosshairs-gps",
                "icon": "mdi-crosshairs-gps"
            },
            {
                "name": "crosshairs-off",
                "icon": "mdi-crosshairs-off"
            },
            {
                "name": "crosshairs-question",
                "icon": "mdi-crosshairs-question"
            },
            {
                "name": "crowd",
                "icon": "mdi-crowd"
            },
            {
                "name": "crown",
                "icon": "mdi-crown"
            },
            {
                "name": "crown-circle",
                "icon": "mdi-crown-circle"
            },
            {
                "name": "crown-circle-outline",
                "icon": "mdi-crown-circle-outline"
            },
            {
                "name": "crown-outline",
                "icon": "mdi-crown-outline"
            },
            {
                "name": "cryengine",
                "icon": "mdi-cryengine"
            },
            {
                "name": "crystal-ball",
                "icon": "mdi-crystal-ball"
            },
            {
                "name": "cube",
                "icon": "mdi-cube"
            },
            {
                "name": "cube-off",
                "icon": "mdi-cube-off"
            },
            {
                "name": "cube-off-outline",
                "icon": "mdi-cube-off-outline"
            },
            {
                "name": "cube-outline",
                "icon": "mdi-cube-outline"
            },
            {
                "name": "cube-scan",
                "icon": "mdi-cube-scan"
            },
            {
                "name": "cube-send",
                "icon": "mdi-cube-send"
            },
            {
                "name": "cube-unfolded",
                "icon": "mdi-cube-unfolded"
            },
            {
                "name": "cup",
                "icon": "mdi-cup"
            },
            {
                "name": "cup-off",
                "icon": "mdi-cup-off"
            },
            {
                "name": "cup-off-outline",
                "icon": "mdi-cup-off-outline"
            },
            {
                "name": "cup-outline",
                "icon": "mdi-cup-outline"
            },
            {
                "name": "cup-water",
                "icon": "mdi-cup-water"
            },
            {
                "name": "cupboard",
                "icon": "mdi-cupboard"
            },
            {
                "name": "cupboard-outline",
                "icon": "mdi-cupboard-outline"
            },
            {
                "name": "cupcake",
                "icon": "mdi-cupcake"
            },
            {
                "name": "curling",
                "icon": "mdi-curling"
            },
            {
                "name": "currency-bdt",
                "icon": "mdi-currency-bdt"
            },
            {
                "name": "currency-brl",
                "icon": "mdi-currency-brl"
            },
            {
                "name": "currency-btc",
                "icon": "mdi-currency-btc"
            },
            {
                "name": "currency-cny",
                "icon": "mdi-currency-cny"
            },
            {
                "name": "currency-eth",
                "icon": "mdi-currency-eth"
            },
            {
                "name": "currency-eur",
                "icon": "mdi-currency-eur"
            },
            {
                "name": "currency-eur-off",
                "icon": "mdi-currency-eur-off"
            },
            {
                "name": "currency-fra",
                "icon": "mdi-currency-fra"
            },
            {
                "name": "currency-gbp",
                "icon": "mdi-currency-gbp"
            },
            {
                "name": "currency-ils",
                "icon": "mdi-currency-ils"
            },
            {
                "name": "currency-inr",
                "icon": "mdi-currency-inr"
            },
            {
                "name": "currency-jpy",
                "icon": "mdi-currency-jpy"
            },
            {
                "name": "currency-krw",
                "icon": "mdi-currency-krw"
            },
            {
                "name": "currency-kzt",
                "icon": "mdi-currency-kzt"
            },
            {
                "name": "currency-mnt",
                "icon": "mdi-currency-mnt"
            },
            {
                "name": "currency-ngn",
                "icon": "mdi-currency-ngn"
            },
            {
                "name": "currency-php",
                "icon": "mdi-currency-php"
            },
            {
                "name": "currency-rial",
                "icon": "mdi-currency-rial"
            },
            {
                "name": "currency-rub",
                "icon": "mdi-currency-rub"
            },
            {
                "name": "currency-rupee",
                "icon": "mdi-currency-rupee"
            },
            {
                "name": "currency-sign",
                "icon": "mdi-currency-sign"
            },
            {
                "name": "currency-try",
                "icon": "mdi-currency-try"
            },
            {
                "name": "currency-twd",
                "icon": "mdi-currency-twd"
            },
            {
                "name": "currency-uah",
                "icon": "mdi-currency-uah"
            },
            {
                "name": "currency-usd",
                "icon": "mdi-currency-usd"
            },
            {
                "name": "currency-usd-off",
                "icon": "mdi-currency-usd-off"
            },
            {
                "name": "current-ac",
                "icon": "mdi-current-ac"
            },
            {
                "name": "current-dc",
                "icon": "mdi-current-dc"
            },
            {
                "name": "cursor-default",
                "icon": "mdi-cursor-default"
            },
            {
                "name": "cursor-default-click",
                "icon": "mdi-cursor-default-click"
            },
            {
                "name": "cursor-default-click-outline",
                "icon": "mdi-cursor-default-click-outline"
            },
            {
                "name": "cursor-default-gesture",
                "icon": "mdi-cursor-default-gesture"
            },
            {
                "name": "cursor-default-gesture-outline",
                "icon": "mdi-cursor-default-gesture-outline"
            },
            {
                "name": "cursor-default-outline",
                "icon": "mdi-cursor-default-outline"
            },
            {
                "name": "cursor-move",
                "icon": "mdi-cursor-move"
            },
            {
                "name": "cursor-pointer",
                "icon": "mdi-cursor-pointer"
            },
            {
                "name": "cursor-text",
                "icon": "mdi-cursor-text"
            },
            {
                "name": "curtains",
                "icon": "mdi-curtains"
            },
            {
                "name": "curtains-closed",
                "icon": "mdi-curtains-closed"
            },
            {
                "name": "cylinder",
                "icon": "mdi-cylinder"
            },
            {
                "name": "cylinder-off",
                "icon": "mdi-cylinder-off"
            },
            {
                "name": "dance-ballroom",
                "icon": "mdi-dance-ballroom"
            },
            {
                "name": "dance-pole",
                "icon": "mdi-dance-pole"
            },
            {
                "name": "data-matrix",
                "icon": "mdi-data-matrix"
            },
            {
                "name": "data-matrix-edit",
                "icon": "mdi-data-matrix-edit"
            },
            {
                "name": "data-matrix-minus",
                "icon": "mdi-data-matrix-minus"
            },
            {
                "name": "data-matrix-plus",
                "icon": "mdi-data-matrix-plus"
            },
            {
                "name": "data-matrix-remove",
                "icon": "mdi-data-matrix-remove"
            },
            {
                "name": "data-matrix-scan",
                "icon": "mdi-data-matrix-scan"
            },
            {
                "name": "database",
                "icon": "mdi-database"
            },
            {
                "name": "database-alert",
                "icon": "mdi-database-alert"
            },
            {
                "name": "database-alert-outline",
                "icon": "mdi-database-alert-outline"
            },
            {
                "name": "database-arrow-down",
                "icon": "mdi-database-arrow-down"
            },
            {
                "name": "database-arrow-down-outline",
                "icon": "mdi-database-arrow-down-outline"
            },
            {
                "name": "database-arrow-left",
                "icon": "mdi-database-arrow-left"
            },
            {
                "name": "database-arrow-left-outline",
                "icon": "mdi-database-arrow-left-outline"
            },
            {
                "name": "database-arrow-right",
                "icon": "mdi-database-arrow-right"
            },
            {
                "name": "database-arrow-right-outline",
                "icon": "mdi-database-arrow-right-outline"
            },
            {
                "name": "database-arrow-up",
                "icon": "mdi-database-arrow-up"
            },
            {
                "name": "database-arrow-up-outline",
                "icon": "mdi-database-arrow-up-outline"
            },
            {
                "name": "database-check",
                "icon": "mdi-database-check"
            },
            {
                "name": "database-check-outline",
                "icon": "mdi-database-check-outline"
            },
            {
                "name": "database-clock",
                "icon": "mdi-database-clock"
            },
            {
                "name": "database-clock-outline",
                "icon": "mdi-database-clock-outline"
            },
            {
                "name": "database-cog",
                "icon": "mdi-database-cog"
            },
            {
                "name": "database-cog-outline",
                "icon": "mdi-database-cog-outline"
            },
            {
                "name": "database-edit",
                "icon": "mdi-database-edit"
            },
            {
                "name": "database-edit-outline",
                "icon": "mdi-database-edit-outline"
            },
            {
                "name": "database-export",
                "icon": "mdi-database-export"
            },
            {
                "name": "database-export-outline",
                "icon": "mdi-database-export-outline"
            },
            {
                "name": "database-eye",
                "icon": "mdi-database-eye"
            },
            {
                "name": "database-eye-off",
                "icon": "mdi-database-eye-off"
            },
            {
                "name": "database-eye-off-outline",
                "icon": "mdi-database-eye-off-outline"
            },
            {
                "name": "database-eye-outline",
                "icon": "mdi-database-eye-outline"
            },
            {
                "name": "database-import",
                "icon": "mdi-database-import"
            },
            {
                "name": "database-import-outline",
                "icon": "mdi-database-import-outline"
            },
            {
                "name": "database-lock",
                "icon": "mdi-database-lock"
            },
            {
                "name": "database-lock-outline",
                "icon": "mdi-database-lock-outline"
            },
            {
                "name": "database-marker",
                "icon": "mdi-database-marker"
            },
            {
                "name": "database-marker-outline",
                "icon": "mdi-database-marker-outline"
            },
            {
                "name": "database-minus",
                "icon": "mdi-database-minus"
            },
            {
                "name": "database-minus-outline",
                "icon": "mdi-database-minus-outline"
            },
            {
                "name": "database-off",
                "icon": "mdi-database-off"
            },
            {
                "name": "database-off-outline",
                "icon": "mdi-database-off-outline"
            },
            {
                "name": "database-outline",
                "icon": "mdi-database-outline"
            },
            {
                "name": "database-plus",
                "icon": "mdi-database-plus"
            },
            {
                "name": "database-plus-outline",
                "icon": "mdi-database-plus-outline"
            },
            {
                "name": "database-refresh",
                "icon": "mdi-database-refresh"
            },
            {
                "name": "database-refresh-outline",
                "icon": "mdi-database-refresh-outline"
            },
            {
                "name": "database-remove",
                "icon": "mdi-database-remove"
            },
            {
                "name": "database-remove-outline",
                "icon": "mdi-database-remove-outline"
            },
            {
                "name": "database-search",
                "icon": "mdi-database-search"
            },
            {
                "name": "database-search-outline",
                "icon": "mdi-database-search-outline"
            },
            {
                "name": "database-settings",
                "icon": "mdi-database-settings"
            },
            {
                "name": "database-settings-outline",
                "icon": "mdi-database-settings-outline"
            },
            {
                "name": "database-sync",
                "icon": "mdi-database-sync"
            },
            {
                "name": "database-sync-outline",
                "icon": "mdi-database-sync-outline"
            },
            {
                "name": "death-star",
                "icon": "mdi-death-star"
            },
            {
                "name": "death-star-variant",
                "icon": "mdi-death-star-variant"
            },
            {
                "name": "deathly-hallows",
                "icon": "mdi-deathly-hallows"
            },
            {
                "name": "debian",
                "icon": "mdi-debian"
            },
            {
                "name": "debug-step-into",
                "icon": "mdi-debug-step-into"
            },
            {
                "name": "debug-step-out",
                "icon": "mdi-debug-step-out"
            },
            {
                "name": "debug-step-over",
                "icon": "mdi-debug-step-over"
            },
            {
                "name": "decagram",
                "icon": "mdi-decagram"
            },
            {
                "name": "decagram-outline",
                "icon": "mdi-decagram-outline"
            },
            {
                "name": "decimal",
                "icon": "mdi-decimal"
            },
            {
                "name": "decimal-comma",
                "icon": "mdi-decimal-comma"
            },
            {
                "name": "decimal-comma-decrease",
                "icon": "mdi-decimal-comma-decrease"
            },
            {
                "name": "decimal-comma-increase",
                "icon": "mdi-decimal-comma-increase"
            },
            {
                "name": "decimal-decrease",
                "icon": "mdi-decimal-decrease"
            },
            {
                "name": "decimal-increase",
                "icon": "mdi-decimal-increase"
            },
            {
                "name": "delete",
                "icon": "mdi-delete"
            },
            {
                "name": "delete-alert",
                "icon": "mdi-delete-alert"
            },
            {
                "name": "delete-alert-outline",
                "icon": "mdi-delete-alert-outline"
            },
            {
                "name": "delete-circle",
                "icon": "mdi-delete-circle"
            },
            {
                "name": "delete-circle-outline",
                "icon": "mdi-delete-circle-outline"
            },
            {
                "name": "delete-clock",
                "icon": "mdi-delete-clock"
            },
            {
                "name": "delete-clock-outline",
                "icon": "mdi-delete-clock-outline"
            },
            {
                "name": "delete-empty",
                "icon": "mdi-delete-empty"
            },
            {
                "name": "delete-empty-outline",
                "icon": "mdi-delete-empty-outline"
            },
            {
                "name": "delete-forever",
                "icon": "mdi-delete-forever"
            },
            {
                "name": "delete-forever-outline",
                "icon": "mdi-delete-forever-outline"
            },
            {
                "name": "delete-off",
                "icon": "mdi-delete-off"
            },
            {
                "name": "delete-off-outline",
                "icon": "mdi-delete-off-outline"
            },
            {
                "name": "delete-outline",
                "icon": "mdi-delete-outline"
            },
            {
                "name": "delete-restore",
                "icon": "mdi-delete-restore"
            },
            {
                "name": "delete-sweep",
                "icon": "mdi-delete-sweep"
            },
            {
                "name": "delete-sweep-outline",
                "icon": "mdi-delete-sweep-outline"
            },
            {
                "name": "delete-variant",
                "icon": "mdi-delete-variant"
            },
            {
                "name": "delta",
                "icon": "mdi-delta"
            },
            {
                "name": "desk",
                "icon": "mdi-desk"
            },
            {
                "name": "desk-lamp",
                "icon": "mdi-desk-lamp"
            },
            {
                "name": "desk-lamp-off",
                "icon": "mdi-desk-lamp-off"
            },
            {
                "name": "desk-lamp-on",
                "icon": "mdi-desk-lamp-on"
            },
            {
                "name": "deskphone",
                "icon": "mdi-deskphone"
            },
            {
                "name": "desktop-classic",
                "icon": "mdi-desktop-classic"
            },
            {
                "name": "desktop-tower",
                "icon": "mdi-desktop-tower"
            },
            {
                "name": "desktop-tower-monitor",
                "icon": "mdi-desktop-tower-monitor"
            },
            {
                "name": "details",
                "icon": "mdi-details"
            },
            {
                "name": "dev-to",
                "icon": "mdi-dev-to"
            },
            {
                "name": "developer-board",
                "icon": "mdi-developer-board"
            },
            {
                "name": "deviantart",
                "icon": "mdi-deviantart"
            },
            {
                "name": "devices",
                "icon": "mdi-devices"
            },
            {
                "name": "dharmachakra",
                "icon": "mdi-dharmachakra"
            },
            {
                "name": "diabetes",
                "icon": "mdi-diabetes"
            },
            {
                "name": "dialpad",
                "icon": "mdi-dialpad"
            },
            {
                "name": "diameter",
                "icon": "mdi-diameter"
            },
            {
                "name": "diameter-outline",
                "icon": "mdi-diameter-outline"
            },
            {
                "name": "diameter-variant",
                "icon": "mdi-diameter-variant"
            },
            {
                "name": "diamond",
                "icon": "mdi-diamond"
            },
            {
                "name": "diamond-outline",
                "icon": "mdi-diamond-outline"
            },
            {
                "name": "diamond-stone",
                "icon": "mdi-diamond-stone"
            },
            {
                "name": "dice-1",
                "icon": "mdi-dice-1"
            },
            {
                "name": "dice-1-outline",
                "icon": "mdi-dice-1-outline"
            },
            {
                "name": "dice-2",
                "icon": "mdi-dice-2"
            },
            {
                "name": "dice-2-outline",
                "icon": "mdi-dice-2-outline"
            },
            {
                "name": "dice-3",
                "icon": "mdi-dice-3"
            },
            {
                "name": "dice-3-outline",
                "icon": "mdi-dice-3-outline"
            },
            {
                "name": "dice-4",
                "icon": "mdi-dice-4"
            },
            {
                "name": "dice-4-outline",
                "icon": "mdi-dice-4-outline"
            },
            {
                "name": "dice-5",
                "icon": "mdi-dice-5"
            },
            {
                "name": "dice-5-outline",
                "icon": "mdi-dice-5-outline"
            },
            {
                "name": "dice-6",
                "icon": "mdi-dice-6"
            },
            {
                "name": "dice-6-outline",
                "icon": "mdi-dice-6-outline"
            },
            {
                "name": "dice-d10",
                "icon": "mdi-dice-d10"
            },
            {
                "name": "dice-d10-outline",
                "icon": "mdi-dice-d10-outline"
            },
            {
                "name": "dice-d12",
                "icon": "mdi-dice-d12"
            },
            {
                "name": "dice-d12-outline",
                "icon": "mdi-dice-d12-outline"
            },
            {
                "name": "dice-d20",
                "icon": "mdi-dice-d20"
            },
            {
                "name": "dice-d20-outline",
                "icon": "mdi-dice-d20-outline"
            },
            {
                "name": "dice-d4",
                "icon": "mdi-dice-d4"
            },
            {
                "name": "dice-d4-outline",
                "icon": "mdi-dice-d4-outline"
            },
            {
                "name": "dice-d6",
                "icon": "mdi-dice-d6"
            },
            {
                "name": "dice-d6-outline",
                "icon": "mdi-dice-d6-outline"
            },
            {
                "name": "dice-d8",
                "icon": "mdi-dice-d8"
            },
            {
                "name": "dice-d8-outline",
                "icon": "mdi-dice-d8-outline"
            },
            {
                "name": "dice-multiple",
                "icon": "mdi-dice-multiple"
            },
            {
                "name": "dice-multiple-outline",
                "icon": "mdi-dice-multiple-outline"
            },
            {
                "name": "digital-ocean",
                "icon": "mdi-digital-ocean"
            },
            {
                "name": "dip-switch",
                "icon": "mdi-dip-switch"
            },
            {
                "name": "directions",
                "icon": "mdi-directions"
            },
            {
                "name": "directions-fork",
                "icon": "mdi-directions-fork"
            },
            {
                "name": "disc",
                "icon": "mdi-disc"
            },
            {
                "name": "disc-alert",
                "icon": "mdi-disc-alert"
            },
            {
                "name": "disc-player",
                "icon": "mdi-disc-player"
            },
            {
                "name": "dishwasher",
                "icon": "mdi-dishwasher"
            },
            {
                "name": "dishwasher-alert",
                "icon": "mdi-dishwasher-alert"
            },
            {
                "name": "dishwasher-off",
                "icon": "mdi-dishwasher-off"
            },
            {
                "name": "disqus",
                "icon": "mdi-disqus"
            },
            {
                "name": "distribute-horizontal-center",
                "icon": "mdi-distribute-horizontal-center"
            },
            {
                "name": "distribute-horizontal-left",
                "icon": "mdi-distribute-horizontal-left"
            },
            {
                "name": "distribute-horizontal-right",
                "icon": "mdi-distribute-horizontal-right"
            },
            {
                "name": "distribute-vertical-bottom",
                "icon": "mdi-distribute-vertical-bottom"
            },
            {
                "name": "distribute-vertical-center",
                "icon": "mdi-distribute-vertical-center"
            },
            {
                "name": "distribute-vertical-top",
                "icon": "mdi-distribute-vertical-top"
            },
            {
                "name": "diversify",
                "icon": "mdi-diversify"
            },
            {
                "name": "diving",
                "icon": "mdi-diving"
            },
            {
                "name": "diving-flippers",
                "icon": "mdi-diving-flippers"
            },
            {
                "name": "diving-helmet",
                "icon": "mdi-diving-helmet"
            },
            {
                "name": "diving-scuba",
                "icon": "mdi-diving-scuba"
            },
            {
                "name": "diving-scuba-flag",
                "icon": "mdi-diving-scuba-flag"
            },
            {
                "name": "diving-scuba-mask",
                "icon": "mdi-diving-scuba-mask"
            },
            {
                "name": "diving-scuba-tank",
                "icon": "mdi-diving-scuba-tank"
            },
            {
                "name": "diving-scuba-tank-multiple",
                "icon": "mdi-diving-scuba-tank-multiple"
            },
            {
                "name": "diving-snorkel",
                "icon": "mdi-diving-snorkel"
            },
            {
                "name": "division",
                "icon": "mdi-division"
            },
            {
                "name": "division-box",
                "icon": "mdi-division-box"
            },
            {
                "name": "dlna",
                "icon": "mdi-dlna"
            },
            {
                "name": "dna",
                "icon": "mdi-dna"
            },
            {
                "name": "dns",
                "icon": "mdi-dns"
            },
            {
                "name": "dns-outline",
                "icon": "mdi-dns-outline"
            },
            {
                "name": "dock-bottom",
                "icon": "mdi-dock-bottom"
            },
            {
                "name": "dock-left",
                "icon": "mdi-dock-left"
            },
            {
                "name": "dock-right",
                "icon": "mdi-dock-right"
            },
            {
                "name": "dock-top",
                "icon": "mdi-dock-top"
            },
            {
                "name": "dock-window",
                "icon": "mdi-dock-window"
            },
            {
                "name": "docker",
                "icon": "mdi-docker"
            },
            {
                "name": "doctor",
                "icon": "mdi-doctor"
            },
            {
                "name": "dog",
                "icon": "mdi-dog"
            },
            {
                "name": "dog-service",
                "icon": "mdi-dog-service"
            },
            {
                "name": "dog-side",
                "icon": "mdi-dog-side"
            },
            {
                "name": "dog-side-off",
                "icon": "mdi-dog-side-off"
            },
            {
                "name": "dolby",
                "icon": "mdi-dolby"
            },
            {
                "name": "dolly",
                "icon": "mdi-dolly"
            },
            {
                "name": "dolphin",
                "icon": "mdi-dolphin"
            },
            {
                "name": "domain",
                "icon": "mdi-domain"
            },
            {
                "name": "domain-off",
                "icon": "mdi-domain-off"
            },
            {
                "name": "domain-plus",
                "icon": "mdi-domain-plus"
            },
            {
                "name": "domain-remove",
                "icon": "mdi-domain-remove"
            },
            {
                "name": "dome-light",
                "icon": "mdi-dome-light"
            },
            {
                "name": "domino-mask",
                "icon": "mdi-domino-mask"
            },
            {
                "name": "donkey",
                "icon": "mdi-donkey"
            },
            {
                "name": "door",
                "icon": "mdi-door"
            },
            {
                "name": "door-closed",
                "icon": "mdi-door-closed"
            },
            {
                "name": "door-closed-lock",
                "icon": "mdi-door-closed-lock"
            },
            {
                "name": "door-open",
                "icon": "mdi-door-open"
            },
            {
                "name": "door-sliding",
                "icon": "mdi-door-sliding"
            },
            {
                "name": "door-sliding-lock",
                "icon": "mdi-door-sliding-lock"
            },
            {
                "name": "door-sliding-open",
                "icon": "mdi-door-sliding-open"
            },
            {
                "name": "doorbell",
                "icon": "mdi-doorbell"
            },
            {
                "name": "doorbell-video",
                "icon": "mdi-doorbell-video"
            },
            {
                "name": "dot-net",
                "icon": "mdi-dot-net"
            },
            {
                "name": "dots-circle",
                "icon": "mdi-dots-circle"
            },
            {
                "name": "dots-grid",
                "icon": "mdi-dots-grid"
            },
            {
                "name": "dots-hexagon",
                "icon": "mdi-dots-hexagon"
            },
            {
                "name": "dots-horizontal",
                "icon": "mdi-dots-horizontal"
            },
            {
                "name": "dots-horizontal-circle",
                "icon": "mdi-dots-horizontal-circle"
            },
            {
                "name": "dots-horizontal-circle-outline",
                "icon": "mdi-dots-horizontal-circle-outline"
            },
            {
                "name": "dots-square",
                "icon": "mdi-dots-square"
            },
            {
                "name": "dots-triangle",
                "icon": "mdi-dots-triangle"
            },
            {
                "name": "dots-vertical",
                "icon": "mdi-dots-vertical"
            },
            {
                "name": "dots-vertical-circle",
                "icon": "mdi-dots-vertical-circle"
            },
            {
                "name": "dots-vertical-circle-outline",
                "icon": "mdi-dots-vertical-circle-outline"
            },
            {
                "name": "download",
                "icon": "mdi-download"
            },
            {
                "name": "download-box",
                "icon": "mdi-download-box"
            },
            {
                "name": "download-box-outline",
                "icon": "mdi-download-box-outline"
            },
            {
                "name": "download-circle",
                "icon": "mdi-download-circle"
            },
            {
                "name": "download-circle-outline",
                "icon": "mdi-download-circle-outline"
            },
            {
                "name": "download-lock",
                "icon": "mdi-download-lock"
            },
            {
                "name": "download-lock-outline",
                "icon": "mdi-download-lock-outline"
            },
            {
                "name": "download-multiple",
                "icon": "mdi-download-multiple"
            },
            {
                "name": "download-network",
                "icon": "mdi-download-network"
            },
            {
                "name": "download-network-outline",
                "icon": "mdi-download-network-outline"
            },
            {
                "name": "download-off",
                "icon": "mdi-download-off"
            },
            {
                "name": "download-off-outline",
                "icon": "mdi-download-off-outline"
            },
            {
                "name": "download-outline",
                "icon": "mdi-download-outline"
            },
            {
                "name": "drag",
                "icon": "mdi-drag"
            },
            {
                "name": "drag-horizontal",
                "icon": "mdi-drag-horizontal"
            },
            {
                "name": "drag-horizontal-variant",
                "icon": "mdi-drag-horizontal-variant"
            },
            {
                "name": "drag-variant",
                "icon": "mdi-drag-variant"
            },
            {
                "name": "drag-vertical",
                "icon": "mdi-drag-vertical"
            },
            {
                "name": "drag-vertical-variant",
                "icon": "mdi-drag-vertical-variant"
            },
            {
                "name": "drama-masks",
                "icon": "mdi-drama-masks"
            },
            {
                "name": "draw",
                "icon": "mdi-draw"
            },
            {
                "name": "draw-pen",
                "icon": "mdi-draw-pen"
            },
            {
                "name": "drawing",
                "icon": "mdi-drawing"
            },
            {
                "name": "drawing-box",
                "icon": "mdi-drawing-box"
            },
            {
                "name": "dresser",
                "icon": "mdi-dresser"
            },
            {
                "name": "dresser-outline",
                "icon": "mdi-dresser-outline"
            },
            {
                "name": "drone",
                "icon": "mdi-drone"
            },
            {
                "name": "dropbox",
                "icon": "mdi-dropbox"
            },
            {
                "name": "drupal",
                "icon": "mdi-drupal"
            },
            {
                "name": "duck",
                "icon": "mdi-duck"
            },
            {
                "name": "dumbbell",
                "icon": "mdi-dumbbell"
            },
            {
                "name": "dump-truck",
                "icon": "mdi-dump-truck"
            },
            {
                "name": "ear-hearing",
                "icon": "mdi-ear-hearing"
            },
            {
                "name": "ear-hearing-loop",
                "icon": "mdi-ear-hearing-loop"
            },
            {
                "name": "ear-hearing-off",
                "icon": "mdi-ear-hearing-off"
            },
            {
                "name": "earbuds",
                "icon": "mdi-earbuds"
            },
            {
                "name": "earbuds-off",
                "icon": "mdi-earbuds-off"
            },
            {
                "name": "earbuds-off-outline",
                "icon": "mdi-earbuds-off-outline"
            },
            {
                "name": "earbuds-outline",
                "icon": "mdi-earbuds-outline"
            },
            {
                "name": "earth",
                "icon": "mdi-earth"
            },
            {
                "name": "earth-arrow-right",
                "icon": "mdi-earth-arrow-right"
            },
            {
                "name": "earth-box",
                "icon": "mdi-earth-box"
            },
            {
                "name": "earth-box-minus",
                "icon": "mdi-earth-box-minus"
            },
            {
                "name": "earth-box-off",
                "icon": "mdi-earth-box-off"
            },
            {
                "name": "earth-box-plus",
                "icon": "mdi-earth-box-plus"
            },
            {
                "name": "earth-box-remove",
                "icon": "mdi-earth-box-remove"
            },
            {
                "name": "earth-minus",
                "icon": "mdi-earth-minus"
            },
            {
                "name": "earth-off",
                "icon": "mdi-earth-off"
            },
            {
                "name": "earth-plus",
                "icon": "mdi-earth-plus"
            },
            {
                "name": "earth-remove",
                "icon": "mdi-earth-remove"
            },
            {
                "name": "egg",
                "icon": "mdi-egg"
            },
            {
                "name": "egg-easter",
                "icon": "mdi-egg-easter"
            },
            {
                "name": "egg-fried",
                "icon": "mdi-egg-fried"
            },
            {
                "name": "egg-off",
                "icon": "mdi-egg-off"
            },
            {
                "name": "egg-off-outline",
                "icon": "mdi-egg-off-outline"
            },
            {
                "name": "egg-outline",
                "icon": "mdi-egg-outline"
            },
            {
                "name": "eiffel-tower",
                "icon": "mdi-eiffel-tower"
            },
            {
                "name": "eight-track",
                "icon": "mdi-eight-track"
            },
            {
                "name": "eject",
                "icon": "mdi-eject"
            },
            {
                "name": "eject-circle",
                "icon": "mdi-eject-circle"
            },
            {
                "name": "eject-circle-outline",
                "icon": "mdi-eject-circle-outline"
            },
            {
                "name": "eject-outline",
                "icon": "mdi-eject-outline"
            },
            {
                "name": "electric-switch",
                "icon": "mdi-electric-switch"
            },
            {
                "name": "electric-switch-closed",
                "icon": "mdi-electric-switch-closed"
            },
            {
                "name": "electron-framework",
                "icon": "mdi-electron-framework"
            },
            {
                "name": "elephant",
                "icon": "mdi-elephant"
            },
            {
                "name": "elevation-decline",
                "icon": "mdi-elevation-decline"
            },
            {
                "name": "elevation-rise",
                "icon": "mdi-elevation-rise"
            },
            {
                "name": "elevator",
                "icon": "mdi-elevator"
            },
            {
                "name": "elevator-down",
                "icon": "mdi-elevator-down"
            },
            {
                "name": "elevator-passenger",
                "icon": "mdi-elevator-passenger"
            },
            {
                "name": "elevator-passenger-off",
                "icon": "mdi-elevator-passenger-off"
            },
            {
                "name": "elevator-passenger-off-outline",
                "icon": "mdi-elevator-passenger-off-outline"
            },
            {
                "name": "elevator-passenger-outline",
                "icon": "mdi-elevator-passenger-outline"
            },
            {
                "name": "elevator-up",
                "icon": "mdi-elevator-up"
            },
            {
                "name": "ellipse",
                "icon": "mdi-ellipse"
            },
            {
                "name": "ellipse-outline",
                "icon": "mdi-ellipse-outline"
            },
            {
                "name": "email",
                "icon": "mdi-email"
            },
            {
                "name": "email-alert",
                "icon": "mdi-email-alert"
            },
            {
                "name": "email-alert-outline",
                "icon": "mdi-email-alert-outline"
            },
            {
                "name": "email-arrow-left",
                "icon": "mdi-email-arrow-left"
            },
            {
                "name": "email-arrow-left-outline",
                "icon": "mdi-email-arrow-left-outline"
            },
            {
                "name": "email-arrow-right",
                "icon": "mdi-email-arrow-right"
            },
            {
                "name": "email-arrow-right-outline",
                "icon": "mdi-email-arrow-right-outline"
            },
            {
                "name": "email-box",
                "icon": "mdi-email-box"
            },
            {
                "name": "email-check",
                "icon": "mdi-email-check"
            },
            {
                "name": "email-check-outline",
                "icon": "mdi-email-check-outline"
            },
            {
                "name": "email-edit",
                "icon": "mdi-email-edit"
            },
            {
                "name": "email-edit-outline",
                "icon": "mdi-email-edit-outline"
            },
            {
                "name": "email-fast",
                "icon": "mdi-email-fast"
            },
            {
                "name": "email-fast-outline",
                "icon": "mdi-email-fast-outline"
            },
            {
                "name": "email-lock",
                "icon": "mdi-email-lock"
            },
            {
                "name": "email-lock-outline",
                "icon": "mdi-email-lock-outline"
            },
            {
                "name": "email-mark-as-unread",
                "icon": "mdi-email-mark-as-unread"
            },
            {
                "name": "email-minus",
                "icon": "mdi-email-minus"
            },
            {
                "name": "email-minus-outline",
                "icon": "mdi-email-minus-outline"
            },
            {
                "name": "email-multiple",
                "icon": "mdi-email-multiple"
            },
            {
                "name": "email-multiple-outline",
                "icon": "mdi-email-multiple-outline"
            },
            {
                "name": "email-newsletter",
                "icon": "mdi-email-newsletter"
            },
            {
                "name": "email-off",
                "icon": "mdi-email-off"
            },
            {
                "name": "email-off-outline",
                "icon": "mdi-email-off-outline"
            },
            {
                "name": "email-open",
                "icon": "mdi-email-open"
            },
            {
                "name": "email-open-multiple",
                "icon": "mdi-email-open-multiple"
            },
            {
                "name": "email-open-multiple-outline",
                "icon": "mdi-email-open-multiple-outline"
            },
            {
                "name": "email-open-outline",
                "icon": "mdi-email-open-outline"
            },
            {
                "name": "email-outline",
                "icon": "mdi-email-outline"
            },
            {
                "name": "email-plus",
                "icon": "mdi-email-plus"
            },
            {
                "name": "email-plus-outline",
                "icon": "mdi-email-plus-outline"
            },
            {
                "name": "email-remove",
                "icon": "mdi-email-remove"
            },
            {
                "name": "email-remove-outline",
                "icon": "mdi-email-remove-outline"
            },
            {
                "name": "email-seal",
                "icon": "mdi-email-seal"
            },
            {
                "name": "email-seal-outline",
                "icon": "mdi-email-seal-outline"
            },
            {
                "name": "email-search",
                "icon": "mdi-email-search"
            },
            {
                "name": "email-search-outline",
                "icon": "mdi-email-search-outline"
            },
            {
                "name": "email-sync",
                "icon": "mdi-email-sync"
            },
            {
                "name": "email-sync-outline",
                "icon": "mdi-email-sync-outline"
            },
            {
                "name": "email-variant",
                "icon": "mdi-email-variant"
            },
            {
                "name": "ember",
                "icon": "mdi-ember"
            },
            {
                "name": "emby",
                "icon": "mdi-emby"
            },
            {
                "name": "emoticon",
                "icon": "mdi-emoticon"
            },
            {
                "name": "emoticon-angry",
                "icon": "mdi-emoticon-angry"
            },
            {
                "name": "emoticon-angry-outline",
                "icon": "mdi-emoticon-angry-outline"
            },
            {
                "name": "emoticon-confused",
                "icon": "mdi-emoticon-confused"
            },
            {
                "name": "emoticon-confused-outline",
                "icon": "mdi-emoticon-confused-outline"
            },
            {
                "name": "emoticon-cool",
                "icon": "mdi-emoticon-cool"
            },
            {
                "name": "emoticon-cool-outline",
                "icon": "mdi-emoticon-cool-outline"
            },
            {
                "name": "emoticon-cry",
                "icon": "mdi-emoticon-cry"
            },
            {
                "name": "emoticon-cry-outline",
                "icon": "mdi-emoticon-cry-outline"
            },
            {
                "name": "emoticon-dead",
                "icon": "mdi-emoticon-dead"
            },
            {
                "name": "emoticon-dead-outline",
                "icon": "mdi-emoticon-dead-outline"
            },
            {
                "name": "emoticon-devil",
                "icon": "mdi-emoticon-devil"
            },
            {
                "name": "emoticon-devil-outline",
                "icon": "mdi-emoticon-devil-outline"
            },
            {
                "name": "emoticon-excited",
                "icon": "mdi-emoticon-excited"
            },
            {
                "name": "emoticon-excited-outline",
                "icon": "mdi-emoticon-excited-outline"
            },
            {
                "name": "emoticon-frown",
                "icon": "mdi-emoticon-frown"
            },
            {
                "name": "emoticon-frown-outline",
                "icon": "mdi-emoticon-frown-outline"
            },
            {
                "name": "emoticon-happy",
                "icon": "mdi-emoticon-happy"
            },
            {
                "name": "emoticon-happy-outline",
                "icon": "mdi-emoticon-happy-outline"
            },
            {
                "name": "emoticon-kiss",
                "icon": "mdi-emoticon-kiss"
            },
            {
                "name": "emoticon-kiss-outline",
                "icon": "mdi-emoticon-kiss-outline"
            },
            {
                "name": "emoticon-lol",
                "icon": "mdi-emoticon-lol"
            },
            {
                "name": "emoticon-lol-outline",
                "icon": "mdi-emoticon-lol-outline"
            },
            {
                "name": "emoticon-neutral",
                "icon": "mdi-emoticon-neutral"
            },
            {
                "name": "emoticon-neutral-outline",
                "icon": "mdi-emoticon-neutral-outline"
            },
            {
                "name": "emoticon-outline",
                "icon": "mdi-emoticon-outline"
            },
            {
                "name": "emoticon-poop",
                "icon": "mdi-emoticon-poop"
            },
            {
                "name": "emoticon-poop-outline",
                "icon": "mdi-emoticon-poop-outline"
            },
            {
                "name": "emoticon-sad",
                "icon": "mdi-emoticon-sad"
            },
            {
                "name": "emoticon-sad-outline",
                "icon": "mdi-emoticon-sad-outline"
            },
            {
                "name": "emoticon-sick",
                "icon": "mdi-emoticon-sick"
            },
            {
                "name": "emoticon-sick-outline",
                "icon": "mdi-emoticon-sick-outline"
            },
            {
                "name": "emoticon-tongue",
                "icon": "mdi-emoticon-tongue"
            },
            {
                "name": "emoticon-tongue-outline",
                "icon": "mdi-emoticon-tongue-outline"
            },
            {
                "name": "emoticon-wink",
                "icon": "mdi-emoticon-wink"
            },
            {
                "name": "emoticon-wink-outline",
                "icon": "mdi-emoticon-wink-outline"
            },
            {
                "name": "engine",
                "icon": "mdi-engine"
            },
            {
                "name": "engine-off",
                "icon": "mdi-engine-off"
            },
            {
                "name": "engine-off-outline",
                "icon": "mdi-engine-off-outline"
            },
            {
                "name": "engine-outline",
                "icon": "mdi-engine-outline"
            },
            {
                "name": "epsilon",
                "icon": "mdi-epsilon"
            },
            {
                "name": "equal",
                "icon": "mdi-equal"
            },
            {
                "name": "equal-box",
                "icon": "mdi-equal-box"
            },
            {
                "name": "equalizer",
                "icon": "mdi-equalizer"
            },
            {
                "name": "equalizer-outline",
                "icon": "mdi-equalizer-outline"
            },
            {
                "name": "eraser",
                "icon": "mdi-eraser"
            },
            {
                "name": "eraser-variant",
                "icon": "mdi-eraser-variant"
            },
            {
                "name": "escalator",
                "icon": "mdi-escalator"
            },
            {
                "name": "escalator-box",
                "icon": "mdi-escalator-box"
            },
            {
                "name": "escalator-down",
                "icon": "mdi-escalator-down"
            },
            {
                "name": "escalator-up",
                "icon": "mdi-escalator-up"
            },
            {
                "name": "eslint",
                "icon": "mdi-eslint"
            },
            {
                "name": "et",
                "icon": "mdi-et"
            },
            {
                "name": "ethereum",
                "icon": "mdi-ethereum"
            },
            {
                "name": "ethernet",
                "icon": "mdi-ethernet"
            },
            {
                "name": "ethernet-cable",
                "icon": "mdi-ethernet-cable"
            },
            {
                "name": "ethernet-cable-off",
                "icon": "mdi-ethernet-cable-off"
            },
            {
                "name": "ev-plug-ccs1",
                "icon": "mdi-ev-plug-ccs1"
            },
            {
                "name": "ev-plug-ccs2",
                "icon": "mdi-ev-plug-ccs2"
            },
            {
                "name": "ev-plug-chademo",
                "icon": "mdi-ev-plug-chademo"
            },
            {
                "name": "ev-plug-tesla",
                "icon": "mdi-ev-plug-tesla"
            },
            {
                "name": "ev-plug-type1",
                "icon": "mdi-ev-plug-type1"
            },
            {
                "name": "ev-plug-type2",
                "icon": "mdi-ev-plug-type2"
            },
            {
                "name": "ev-station",
                "icon": "mdi-ev-station"
            },
            {
                "name": "evernote",
                "icon": "mdi-evernote"
            },
            {
                "name": "excavator",
                "icon": "mdi-excavator"
            },
            {
                "name": "exclamation",
                "icon": "mdi-exclamation"
            },
            {
                "name": "exclamation-thick",
                "icon": "mdi-exclamation-thick"
            },
            {
                "name": "exit-run",
                "icon": "mdi-exit-run"
            },
            {
                "name": "exit-to-app",
                "icon": "mdi-exit-to-app"
            },
            {
                "name": "expand-all",
                "icon": "mdi-expand-all"
            },
            {
                "name": "expand-all-outline",
                "icon": "mdi-expand-all-outline"
            },
            {
                "name": "expansion-card",
                "icon": "mdi-expansion-card"
            },
            {
                "name": "expansion-card-variant",
                "icon": "mdi-expansion-card-variant"
            },
            {
                "name": "exponent",
                "icon": "mdi-exponent"
            },
            {
                "name": "exponent-box",
                "icon": "mdi-exponent-box"
            },
            {
                "name": "export",
                "icon": "mdi-export"
            },
            {
                "name": "export-variant",
                "icon": "mdi-export-variant"
            },
            {
                "name": "eye",
                "icon": "mdi-eye"
            },
            {
                "name": "eye-arrow-left",
                "icon": "mdi-eye-arrow-left"
            },
            {
                "name": "eye-arrow-left-outline",
                "icon": "mdi-eye-arrow-left-outline"
            },
            {
                "name": "eye-arrow-right",
                "icon": "mdi-eye-arrow-right"
            },
            {
                "name": "eye-arrow-right-outline",
                "icon": "mdi-eye-arrow-right-outline"
            },
            {
                "name": "eye-check",
                "icon": "mdi-eye-check"
            },
            {
                "name": "eye-check-outline",
                "icon": "mdi-eye-check-outline"
            },
            {
                "name": "eye-circle",
                "icon": "mdi-eye-circle"
            },
            {
                "name": "eye-circle-outline",
                "icon": "mdi-eye-circle-outline"
            },
            {
                "name": "eye-minus",
                "icon": "mdi-eye-minus"
            },
            {
                "name": "eye-minus-outline",
                "icon": "mdi-eye-minus-outline"
            },
            {
                "name": "eye-off",
                "icon": "mdi-eye-off"
            },
            {
                "name": "eye-off-outline",
                "icon": "mdi-eye-off-outline"
            },
            {
                "name": "eye-outline",
                "icon": "mdi-eye-outline"
            },
            {
                "name": "eye-plus",
                "icon": "mdi-eye-plus"
            },
            {
                "name": "eye-plus-outline",
                "icon": "mdi-eye-plus-outline"
            },
            {
                "name": "eye-refresh",
                "icon": "mdi-eye-refresh"
            },
            {
                "name": "eye-refresh-outline",
                "icon": "mdi-eye-refresh-outline"
            },
            {
                "name": "eye-remove",
                "icon": "mdi-eye-remove"
            },
            {
                "name": "eye-remove-outline",
                "icon": "mdi-eye-remove-outline"
            },
            {
                "name": "eye-settings",
                "icon": "mdi-eye-settings"
            },
            {
                "name": "eye-settings-outline",
                "icon": "mdi-eye-settings-outline"
            },
            {
                "name": "eyedropper",
                "icon": "mdi-eyedropper"
            },
            {
                "name": "eyedropper-minus",
                "icon": "mdi-eyedropper-minus"
            },
            {
                "name": "eyedropper-off",
                "icon": "mdi-eyedropper-off"
            },
            {
                "name": "eyedropper-plus",
                "icon": "mdi-eyedropper-plus"
            },
            {
                "name": "eyedropper-remove",
                "icon": "mdi-eyedropper-remove"
            },
            {
                "name": "eyedropper-variant",
                "icon": "mdi-eyedropper-variant"
            },
            {
                "name": "face-agent",
                "icon": "mdi-face-agent"
            },
            {
                "name": "face-man",
                "icon": "mdi-face-man"
            },
            {
                "name": "face-man-outline",
                "icon": "mdi-face-man-outline"
            },
            {
                "name": "face-man-profile",
                "icon": "mdi-face-man-profile"
            },
            {
                "name": "face-man-shimmer",
                "icon": "mdi-face-man-shimmer"
            },
            {
                "name": "face-man-shimmer-outline",
                "icon": "mdi-face-man-shimmer-outline"
            },
            {
                "name": "face-mask",
                "icon": "mdi-face-mask"
            },
            {
                "name": "face-mask-outline",
                "icon": "mdi-face-mask-outline"
            },
            {
                "name": "face-recognition",
                "icon": "mdi-face-recognition"
            },
            {
                "name": "face-woman",
                "icon": "mdi-face-woman"
            },
            {
                "name": "face-woman-outline",
                "icon": "mdi-face-woman-outline"
            },
            {
                "name": "face-woman-profile",
                "icon": "mdi-face-woman-profile"
            },
            {
                "name": "face-woman-shimmer",
                "icon": "mdi-face-woman-shimmer"
            },
            {
                "name": "face-woman-shimmer-outline",
                "icon": "mdi-face-woman-shimmer-outline"
            },
            {
                "name": "facebook",
                "icon": "mdi-facebook"
            },
            {
                "name": "facebook-gaming",
                "icon": "mdi-facebook-gaming"
            },
            {
                "name": "facebook-messenger",
                "icon": "mdi-facebook-messenger"
            },
            {
                "name": "facebook-workplace",
                "icon": "mdi-facebook-workplace"
            },
            {
                "name": "factory",
                "icon": "mdi-factory"
            },
            {
                "name": "family-tree",
                "icon": "mdi-family-tree"
            },
            {
                "name": "fan",
                "icon": "mdi-fan"
            },
            {
                "name": "fan-alert",
                "icon": "mdi-fan-alert"
            },
            {
                "name": "fan-auto",
                "icon": "mdi-fan-auto"
            },
            {
                "name": "fan-chevron-down",
                "icon": "mdi-fan-chevron-down"
            },
            {
                "name": "fan-chevron-up",
                "icon": "mdi-fan-chevron-up"
            },
            {
                "name": "fan-clock",
                "icon": "mdi-fan-clock"
            },
            {
                "name": "fan-minus",
                "icon": "mdi-fan-minus"
            },
            {
                "name": "fan-off",
                "icon": "mdi-fan-off"
            },
            {
                "name": "fan-plus",
                "icon": "mdi-fan-plus"
            },
            {
                "name": "fan-remove",
                "icon": "mdi-fan-remove"
            },
            {
                "name": "fan-speed-1",
                "icon": "mdi-fan-speed-1"
            },
            {
                "name": "fan-speed-2",
                "icon": "mdi-fan-speed-2"
            },
            {
                "name": "fan-speed-3",
                "icon": "mdi-fan-speed-3"
            },
            {
                "name": "fast-forward",
                "icon": "mdi-fast-forward"
            },
            {
                "name": "fast-forward-10",
                "icon": "mdi-fast-forward-10"
            },
            {
                "name": "fast-forward-15",
                "icon": "mdi-fast-forward-15"
            },
            {
                "name": "fast-forward-30",
                "icon": "mdi-fast-forward-30"
            },
            {
                "name": "fast-forward-45",
                "icon": "mdi-fast-forward-45"
            },
            {
                "name": "fast-forward-5",
                "icon": "mdi-fast-forward-5"
            },
            {
                "name": "fast-forward-60",
                "icon": "mdi-fast-forward-60"
            },
            {
                "name": "fast-forward-outline",
                "icon": "mdi-fast-forward-outline"
            },
            {
                "name": "faucet",
                "icon": "mdi-faucet"
            },
            {
                "name": "faucet-variant",
                "icon": "mdi-faucet-variant"
            },
            {
                "name": "fax",
                "icon": "mdi-fax"
            },
            {
                "name": "feather",
                "icon": "mdi-feather"
            },
            {
                "name": "feature-search",
                "icon": "mdi-feature-search"
            },
            {
                "name": "feature-search-outline",
                "icon": "mdi-feature-search-outline"
            },
            {
                "name": "fedora",
                "icon": "mdi-fedora"
            },
            {
                "name": "fence",
                "icon": "mdi-fence"
            },
            {
                "name": "fence-electric",
                "icon": "mdi-fence-electric"
            },
            {
                "name": "fencing",
                "icon": "mdi-fencing"
            },
            {
                "name": "ferris-wheel",
                "icon": "mdi-ferris-wheel"
            },
            {
                "name": "ferry",
                "icon": "mdi-ferry"
            },
            {
                "name": "file",
                "icon": "mdi-file"
            },
            {
                "name": "file-account",
                "icon": "mdi-file-account"
            },
            {
                "name": "file-account-outline",
                "icon": "mdi-file-account-outline"
            },
            {
                "name": "file-alert",
                "icon": "mdi-file-alert"
            },
            {
                "name": "file-alert-outline",
                "icon": "mdi-file-alert-outline"
            },
            {
                "name": "file-arrow-left-right",
                "icon": "mdi-file-arrow-left-right"
            },
            {
                "name": "file-arrow-left-right-outline",
                "icon": "mdi-file-arrow-left-right-outline"
            },
            {
                "name": "file-arrow-up-down",
                "icon": "mdi-file-arrow-up-down"
            },
            {
                "name": "file-arrow-up-down-outline",
                "icon": "mdi-file-arrow-up-down-outline"
            },
            {
                "name": "file-cabinet",
                "icon": "mdi-file-cabinet"
            },
            {
                "name": "file-cad",
                "icon": "mdi-file-cad"
            },
            {
                "name": "file-cad-box",
                "icon": "mdi-file-cad-box"
            },
            {
                "name": "file-cancel",
                "icon": "mdi-file-cancel"
            },
            {
                "name": "file-cancel-outline",
                "icon": "mdi-file-cancel-outline"
            },
            {
                "name": "file-certificate",
                "icon": "mdi-file-certificate"
            },
            {
                "name": "file-certificate-outline",
                "icon": "mdi-file-certificate-outline"
            },
            {
                "name": "file-chart",
                "icon": "mdi-file-chart"
            },
            {
                "name": "file-chart-check",
                "icon": "mdi-file-chart-check"
            },
            {
                "name": "file-chart-check-outline",
                "icon": "mdi-file-chart-check-outline"
            },
            {
                "name": "file-chart-outline",
                "icon": "mdi-file-chart-outline"
            },
            {
                "name": "file-check",
                "icon": "mdi-file-check"
            },
            {
                "name": "file-check-outline",
                "icon": "mdi-file-check-outline"
            },
            {
                "name": "file-clock",
                "icon": "mdi-file-clock"
            },
            {
                "name": "file-clock-outline",
                "icon": "mdi-file-clock-outline"
            },
            {
                "name": "file-cloud",
                "icon": "mdi-file-cloud"
            },
            {
                "name": "file-cloud-outline",
                "icon": "mdi-file-cloud-outline"
            },
            {
                "name": "file-code",
                "icon": "mdi-file-code"
            },
            {
                "name": "file-code-outline",
                "icon": "mdi-file-code-outline"
            },
            {
                "name": "file-cog",
                "icon": "mdi-file-cog"
            },
            {
                "name": "file-cog-outline",
                "icon": "mdi-file-cog-outline"
            },
            {
                "name": "file-compare",
                "icon": "mdi-file-compare"
            },
            {
                "name": "file-delimited",
                "icon": "mdi-file-delimited"
            },
            {
                "name": "file-delimited-outline",
                "icon": "mdi-file-delimited-outline"
            },
            {
                "name": "file-document",
                "icon": "mdi-file-document"
            },
            {
                "name": "file-document-alert",
                "icon": "mdi-file-document-alert"
            },
            {
                "name": "file-document-alert-outline",
                "icon": "mdi-file-document-alert-outline"
            },
            {
                "name": "file-document-check",
                "icon": "mdi-file-document-check"
            },
            {
                "name": "file-document-check-outline",
                "icon": "mdi-file-document-check-outline"
            },
            {
                "name": "file-document-edit",
                "icon": "mdi-file-document-edit"
            },
            {
                "name": "file-document-edit-outline",
                "icon": "mdi-file-document-edit-outline"
            },
            {
                "name": "file-document-minus",
                "icon": "mdi-file-document-minus"
            },
            {
                "name": "file-document-minus-outline",
                "icon": "mdi-file-document-minus-outline"
            },
            {
                "name": "file-document-multiple",
                "icon": "mdi-file-document-multiple"
            },
            {
                "name": "file-document-multiple-outline",
                "icon": "mdi-file-document-multiple-outline"
            },
            {
                "name": "file-document-outline",
                "icon": "mdi-file-document-outline"
            },
            {
                "name": "file-document-plus",
                "icon": "mdi-file-document-plus"
            },
            {
                "name": "file-document-plus-outline",
                "icon": "mdi-file-document-plus-outline"
            },
            {
                "name": "file-document-remove",
                "icon": "mdi-file-document-remove"
            },
            {
                "name": "file-document-remove-outline",
                "icon": "mdi-file-document-remove-outline"
            },
            {
                "name": "file-download",
                "icon": "mdi-file-download"
            },
            {
                "name": "file-download-outline",
                "icon": "mdi-file-download-outline"
            },
            {
                "name": "file-edit",
                "icon": "mdi-file-edit"
            },
            {
                "name": "file-edit-outline",
                "icon": "mdi-file-edit-outline"
            },
            {
                "name": "file-excel",
                "icon": "mdi-file-excel"
            },
            {
                "name": "file-excel-box",
                "icon": "mdi-file-excel-box"
            },
            {
                "name": "file-excel-box-outline",
                "icon": "mdi-file-excel-box-outline"
            },
            {
                "name": "file-excel-outline",
                "icon": "mdi-file-excel-outline"
            },
            {
                "name": "file-export",
                "icon": "mdi-file-export"
            },
            {
                "name": "file-export-outline",
                "icon": "mdi-file-export-outline"
            },
            {
                "name": "file-eye",
                "icon": "mdi-file-eye"
            },
            {
                "name": "file-eye-outline",
                "icon": "mdi-file-eye-outline"
            },
            {
                "name": "file-find",
                "icon": "mdi-file-find"
            },
            {
                "name": "file-find-outline",
                "icon": "mdi-file-find-outline"
            },
            {
                "name": "file-gif-box",
                "icon": "mdi-file-gif-box"
            },
            {
                "name": "file-hidden",
                "icon": "mdi-file-hidden"
            },
            {
                "name": "file-image",
                "icon": "mdi-file-image"
            },
            {
                "name": "file-image-marker",
                "icon": "mdi-file-image-marker"
            },
            {
                "name": "file-image-marker-outline",
                "icon": "mdi-file-image-marker-outline"
            },
            {
                "name": "file-image-minus",
                "icon": "mdi-file-image-minus"
            },
            {
                "name": "file-image-minus-outline",
                "icon": "mdi-file-image-minus-outline"
            },
            {
                "name": "file-image-outline",
                "icon": "mdi-file-image-outline"
            },
            {
                "name": "file-image-plus",
                "icon": "mdi-file-image-plus"
            },
            {
                "name": "file-image-plus-outline",
                "icon": "mdi-file-image-plus-outline"
            },
            {
                "name": "file-image-remove",
                "icon": "mdi-file-image-remove"
            },
            {
                "name": "file-image-remove-outline",
                "icon": "mdi-file-image-remove-outline"
            },
            {
                "name": "file-import",
                "icon": "mdi-file-import"
            },
            {
                "name": "file-import-outline",
                "icon": "mdi-file-import-outline"
            },
            {
                "name": "file-jpg-box",
                "icon": "mdi-file-jpg-box"
            },
            {
                "name": "file-key",
                "icon": "mdi-file-key"
            },
            {
                "name": "file-key-outline",
                "icon": "mdi-file-key-outline"
            },
            {
                "name": "file-link",
                "icon": "mdi-file-link"
            },
            {
                "name": "file-link-outline",
                "icon": "mdi-file-link-outline"
            },
            {
                "name": "file-lock",
                "icon": "mdi-file-lock"
            },
            {
                "name": "file-lock-open",
                "icon": "mdi-file-lock-open"
            },
            {
                "name": "file-lock-open-outline",
                "icon": "mdi-file-lock-open-outline"
            },
            {
                "name": "file-lock-outline",
                "icon": "mdi-file-lock-outline"
            },
            {
                "name": "file-marker",
                "icon": "mdi-file-marker"
            },
            {
                "name": "file-marker-outline",
                "icon": "mdi-file-marker-outline"
            },
            {
                "name": "file-minus",
                "icon": "mdi-file-minus"
            },
            {
                "name": "file-minus-outline",
                "icon": "mdi-file-minus-outline"
            },
            {
                "name": "file-move",
                "icon": "mdi-file-move"
            },
            {
                "name": "file-move-outline",
                "icon": "mdi-file-move-outline"
            },
            {
                "name": "file-multiple",
                "icon": "mdi-file-multiple"
            },
            {
                "name": "file-multiple-outline",
                "icon": "mdi-file-multiple-outline"
            },
            {
                "name": "file-music",
                "icon": "mdi-file-music"
            },
            {
                "name": "file-music-outline",
                "icon": "mdi-file-music-outline"
            },
            {
                "name": "file-outline",
                "icon": "mdi-file-outline"
            },
            {
                "name": "file-pdf-box",
                "icon": "mdi-file-pdf-box"
            },
            {
                "name": "file-percent",
                "icon": "mdi-file-percent"
            },
            {
                "name": "file-percent-outline",
                "icon": "mdi-file-percent-outline"
            },
            {
                "name": "file-phone",
                "icon": "mdi-file-phone"
            },
            {
                "name": "file-phone-outline",
                "icon": "mdi-file-phone-outline"
            },
            {
                "name": "file-plus",
                "icon": "mdi-file-plus"
            },
            {
                "name": "file-plus-outline",
                "icon": "mdi-file-plus-outline"
            },
            {
                "name": "file-png-box",
                "icon": "mdi-file-png-box"
            },
            {
                "name": "file-powerpoint",
                "icon": "mdi-file-powerpoint"
            },
            {
                "name": "file-powerpoint-box",
                "icon": "mdi-file-powerpoint-box"
            },
            {
                "name": "file-powerpoint-box-outline",
                "icon": "mdi-file-powerpoint-box-outline"
            },
            {
                "name": "file-powerpoint-outline",
                "icon": "mdi-file-powerpoint-outline"
            },
            {
                "name": "file-presentation-box",
                "icon": "mdi-file-presentation-box"
            },
            {
                "name": "file-question",
                "icon": "mdi-file-question"
            },
            {
                "name": "file-question-outline",
                "icon": "mdi-file-question-outline"
            },
            {
                "name": "file-refresh",
                "icon": "mdi-file-refresh"
            },
            {
                "name": "file-refresh-outline",
                "icon": "mdi-file-refresh-outline"
            },
            {
                "name": "file-remove",
                "icon": "mdi-file-remove"
            },
            {
                "name": "file-remove-outline",
                "icon": "mdi-file-remove-outline"
            },
            {
                "name": "file-replace",
                "icon": "mdi-file-replace"
            },
            {
                "name": "file-replace-outline",
                "icon": "mdi-file-replace-outline"
            },
            {
                "name": "file-restore",
                "icon": "mdi-file-restore"
            },
            {
                "name": "file-restore-outline",
                "icon": "mdi-file-restore-outline"
            },
            {
                "name": "file-rotate-left",
                "icon": "mdi-file-rotate-left"
            },
            {
                "name": "file-rotate-left-outline",
                "icon": "mdi-file-rotate-left-outline"
            },
            {
                "name": "file-rotate-right",
                "icon": "mdi-file-rotate-right"
            },
            {
                "name": "file-rotate-right-outline",
                "icon": "mdi-file-rotate-right-outline"
            },
            {
                "name": "file-search",
                "icon": "mdi-file-search"
            },
            {
                "name": "file-search-outline",
                "icon": "mdi-file-search-outline"
            },
            {
                "name": "file-send",
                "icon": "mdi-file-send"
            },
            {
                "name": "file-send-outline",
                "icon": "mdi-file-send-outline"
            },
            {
                "name": "file-settings",
                "icon": "mdi-file-settings"
            },
            {
                "name": "file-settings-outline",
                "icon": "mdi-file-settings-outline"
            },
            {
                "name": "file-sign",
                "icon": "mdi-file-sign"
            },
            {
                "name": "file-star",
                "icon": "mdi-file-star"
            },
            {
                "name": "file-star-outline",
                "icon": "mdi-file-star-outline"
            },
            {
                "name": "file-swap",
                "icon": "mdi-file-swap"
            },
            {
                "name": "file-swap-outline",
                "icon": "mdi-file-swap-outline"
            },
            {
                "name": "file-sync",
                "icon": "mdi-file-sync"
            },
            {
                "name": "file-sync-outline",
                "icon": "mdi-file-sync-outline"
            },
            {
                "name": "file-table",
                "icon": "mdi-file-table"
            },
            {
                "name": "file-table-box",
                "icon": "mdi-file-table-box"
            },
            {
                "name": "file-table-box-multiple",
                "icon": "mdi-file-table-box-multiple"
            },
            {
                "name": "file-table-box-multiple-outline",
                "icon": "mdi-file-table-box-multiple-outline"
            },
            {
                "name": "file-table-box-outline",
                "icon": "mdi-file-table-box-outline"
            },
            {
                "name": "file-table-outline",
                "icon": "mdi-file-table-outline"
            },
            {
                "name": "file-tree",
                "icon": "mdi-file-tree"
            },
            {
                "name": "file-tree-outline",
                "icon": "mdi-file-tree-outline"
            },
            {
                "name": "file-undo",
                "icon": "mdi-file-undo"
            },
            {
                "name": "file-undo-outline",
                "icon": "mdi-file-undo-outline"
            },
            {
                "name": "file-upload",
                "icon": "mdi-file-upload"
            },
            {
                "name": "file-upload-outline",
                "icon": "mdi-file-upload-outline"
            },
            {
                "name": "file-video",
                "icon": "mdi-file-video"
            },
            {
                "name": "file-video-outline",
                "icon": "mdi-file-video-outline"
            },
            {
                "name": "file-word",
                "icon": "mdi-file-word"
            },
            {
                "name": "file-word-box",
                "icon": "mdi-file-word-box"
            },
            {
                "name": "file-word-box-outline",
                "icon": "mdi-file-word-box-outline"
            },
            {
                "name": "file-word-outline",
                "icon": "mdi-file-word-outline"
            },
            {
                "name": "file-xml-box",
                "icon": "mdi-file-xml-box"
            },
            {
                "name": "film",
                "icon": "mdi-film"
            },
            {
                "name": "filmstrip",
                "icon": "mdi-filmstrip"
            },
            {
                "name": "filmstrip-box",
                "icon": "mdi-filmstrip-box"
            },
            {
                "name": "filmstrip-box-multiple",
                "icon": "mdi-filmstrip-box-multiple"
            },
            {
                "name": "filmstrip-off",
                "icon": "mdi-filmstrip-off"
            },
            {
                "name": "filter",
                "icon": "mdi-filter"
            },
            {
                "name": "filter-check",
                "icon": "mdi-filter-check"
            },
            {
                "name": "filter-check-outline",
                "icon": "mdi-filter-check-outline"
            },
            {
                "name": "filter-cog",
                "icon": "mdi-filter-cog"
            },
            {
                "name": "filter-cog-outline",
                "icon": "mdi-filter-cog-outline"
            },
            {
                "name": "filter-menu",
                "icon": "mdi-filter-menu"
            },
            {
                "name": "filter-menu-outline",
                "icon": "mdi-filter-menu-outline"
            },
            {
                "name": "filter-minus",
                "icon": "mdi-filter-minus"
            },
            {
                "name": "filter-minus-outline",
                "icon": "mdi-filter-minus-outline"
            },
            {
                "name": "filter-multiple",
                "icon": "mdi-filter-multiple"
            },
            {
                "name": "filter-multiple-outline",
                "icon": "mdi-filter-multiple-outline"
            },
            {
                "name": "filter-off",
                "icon": "mdi-filter-off"
            },
            {
                "name": "filter-off-outline",
                "icon": "mdi-filter-off-outline"
            },
            {
                "name": "filter-outline",
                "icon": "mdi-filter-outline"
            },
            {
                "name": "filter-plus",
                "icon": "mdi-filter-plus"
            },
            {
                "name": "filter-plus-outline",
                "icon": "mdi-filter-plus-outline"
            },
            {
                "name": "filter-remove",
                "icon": "mdi-filter-remove"
            },
            {
                "name": "filter-remove-outline",
                "icon": "mdi-filter-remove-outline"
            },
            {
                "name": "filter-settings",
                "icon": "mdi-filter-settings"
            },
            {
                "name": "filter-settings-outline",
                "icon": "mdi-filter-settings-outline"
            },
            {
                "name": "filter-variant",
                "icon": "mdi-filter-variant"
            },
            {
                "name": "filter-variant-minus",
                "icon": "mdi-filter-variant-minus"
            },
            {
                "name": "filter-variant-plus",
                "icon": "mdi-filter-variant-plus"
            },
            {
                "name": "filter-variant-remove",
                "icon": "mdi-filter-variant-remove"
            },
            {
                "name": "finance",
                "icon": "mdi-finance"
            },
            {
                "name": "find-replace",
                "icon": "mdi-find-replace"
            },
            {
                "name": "fingerprint",
                "icon": "mdi-fingerprint"
            },
            {
                "name": "fingerprint-off",
                "icon": "mdi-fingerprint-off"
            },
            {
                "name": "fire",
                "icon": "mdi-fire"
            },
            {
                "name": "fire-alert",
                "icon": "mdi-fire-alert"
            },
            {
                "name": "fire-circle",
                "icon": "mdi-fire-circle"
            },
            {
                "name": "fire-extinguisher",
                "icon": "mdi-fire-extinguisher"
            },
            {
                "name": "fire-hydrant",
                "icon": "mdi-fire-hydrant"
            },
            {
                "name": "fire-hydrant-alert",
                "icon": "mdi-fire-hydrant-alert"
            },
            {
                "name": "fire-hydrant-off",
                "icon": "mdi-fire-hydrant-off"
            },
            {
                "name": "fire-off",
                "icon": "mdi-fire-off"
            },
            {
                "name": "fire-truck",
                "icon": "mdi-fire-truck"
            },
            {
                "name": "firebase",
                "icon": "mdi-firebase"
            },
            {
                "name": "firefox",
                "icon": "mdi-firefox"
            },
            {
                "name": "fireplace",
                "icon": "mdi-fireplace"
            },
            {
                "name": "fireplace-off",
                "icon": "mdi-fireplace-off"
            },
            {
                "name": "firewire",
                "icon": "mdi-firewire"
            },
            {
                "name": "firework",
                "icon": "mdi-firework"
            },
            {
                "name": "firework-off",
                "icon": "mdi-firework-off"
            },
            {
                "name": "fish",
                "icon": "mdi-fish"
            },
            {
                "name": "fish-off",
                "icon": "mdi-fish-off"
            },
            {
                "name": "fishbowl",
                "icon": "mdi-fishbowl"
            },
            {
                "name": "fishbowl-outline",
                "icon": "mdi-fishbowl-outline"
            },
            {
                "name": "fit-to-page",
                "icon": "mdi-fit-to-page"
            },
            {
                "name": "fit-to-page-outline",
                "icon": "mdi-fit-to-page-outline"
            },
            {
                "name": "fit-to-screen",
                "icon": "mdi-fit-to-screen"
            },
            {
                "name": "fit-to-screen-outline",
                "icon": "mdi-fit-to-screen-outline"
            },
            {
                "name": "flag",
                "icon": "mdi-flag"
            },
            {
                "name": "flag-checkered",
                "icon": "mdi-flag-checkered"
            },
            {
                "name": "flag-minus",
                "icon": "mdi-flag-minus"
            },
            {
                "name": "flag-minus-outline",
                "icon": "mdi-flag-minus-outline"
            },
            {
                "name": "flag-off",
                "icon": "mdi-flag-off"
            },
            {
                "name": "flag-off-outline",
                "icon": "mdi-flag-off-outline"
            },
            {
                "name": "flag-outline",
                "icon": "mdi-flag-outline"
            },
            {
                "name": "flag-plus",
                "icon": "mdi-flag-plus"
            },
            {
                "name": "flag-plus-outline",
                "icon": "mdi-flag-plus-outline"
            },
            {
                "name": "flag-remove",
                "icon": "mdi-flag-remove"
            },
            {
                "name": "flag-remove-outline",
                "icon": "mdi-flag-remove-outline"
            },
            {
                "name": "flag-triangle",
                "icon": "mdi-flag-triangle"
            },
            {
                "name": "flag-variant",
                "icon": "mdi-flag-variant"
            },
            {
                "name": "flag-variant-minus",
                "icon": "mdi-flag-variant-minus"
            },
            {
                "name": "flag-variant-minus-outline",
                "icon": "mdi-flag-variant-minus-outline"
            },
            {
                "name": "flag-variant-off",
                "icon": "mdi-flag-variant-off"
            },
            {
                "name": "flag-variant-off-outline",
                "icon": "mdi-flag-variant-off-outline"
            },
            {
                "name": "flag-variant-outline",
                "icon": "mdi-flag-variant-outline"
            },
            {
                "name": "flag-variant-plus",
                "icon": "mdi-flag-variant-plus"
            },
            {
                "name": "flag-variant-plus-outline",
                "icon": "mdi-flag-variant-plus-outline"
            },
            {
                "name": "flag-variant-remove",
                "icon": "mdi-flag-variant-remove"
            },
            {
                "name": "flag-variant-remove-outline",
                "icon": "mdi-flag-variant-remove-outline"
            },
            {
                "name": "flare",
                "icon": "mdi-flare"
            },
            {
                "name": "flash",
                "icon": "mdi-flash"
            },
            {
                "name": "flash-alert",
                "icon": "mdi-flash-alert"
            },
            {
                "name": "flash-alert-outline",
                "icon": "mdi-flash-alert-outline"
            },
            {
                "name": "flash-auto",
                "icon": "mdi-flash-auto"
            },
            {
                "name": "flash-off",
                "icon": "mdi-flash-off"
            },
            {
                "name": "flash-off-outline",
                "icon": "mdi-flash-off-outline"
            },
            {
                "name": "flash-outline",
                "icon": "mdi-flash-outline"
            },
            {
                "name": "flash-red-eye",
                "icon": "mdi-flash-red-eye"
            },
            {
                "name": "flash-triangle",
                "icon": "mdi-flash-triangle"
            },
            {
                "name": "flash-triangle-outline",
                "icon": "mdi-flash-triangle-outline"
            },
            {
                "name": "flashlight",
                "icon": "mdi-flashlight"
            },
            {
                "name": "flashlight-off",
                "icon": "mdi-flashlight-off"
            },
            {
                "name": "flask",
                "icon": "mdi-flask"
            },
            {
                "name": "flask-empty",
                "icon": "mdi-flask-empty"
            },
            {
                "name": "flask-empty-minus",
                "icon": "mdi-flask-empty-minus"
            },
            {
                "name": "flask-empty-minus-outline",
                "icon": "mdi-flask-empty-minus-outline"
            },
            {
                "name": "flask-empty-off",
                "icon": "mdi-flask-empty-off"
            },
            {
                "name": "flask-empty-off-outline",
                "icon": "mdi-flask-empty-off-outline"
            },
            {
                "name": "flask-empty-outline",
                "icon": "mdi-flask-empty-outline"
            },
            {
                "name": "flask-empty-plus",
                "icon": "mdi-flask-empty-plus"
            },
            {
                "name": "flask-empty-plus-outline",
                "icon": "mdi-flask-empty-plus-outline"
            },
            {
                "name": "flask-empty-remove",
                "icon": "mdi-flask-empty-remove"
            },
            {
                "name": "flask-empty-remove-outline",
                "icon": "mdi-flask-empty-remove-outline"
            },
            {
                "name": "flask-minus",
                "icon": "mdi-flask-minus"
            },
            {
                "name": "flask-minus-outline",
                "icon": "mdi-flask-minus-outline"
            },
            {
                "name": "flask-off",
                "icon": "mdi-flask-off"
            },
            {
                "name": "flask-off-outline",
                "icon": "mdi-flask-off-outline"
            },
            {
                "name": "flask-outline",
                "icon": "mdi-flask-outline"
            },
            {
                "name": "flask-plus",
                "icon": "mdi-flask-plus"
            },
            {
                "name": "flask-plus-outline",
                "icon": "mdi-flask-plus-outline"
            },
            {
                "name": "flask-remove",
                "icon": "mdi-flask-remove"
            },
            {
                "name": "flask-remove-outline",
                "icon": "mdi-flask-remove-outline"
            },
            {
                "name": "flask-round-bottom",
                "icon": "mdi-flask-round-bottom"
            },
            {
                "name": "flask-round-bottom-empty",
                "icon": "mdi-flask-round-bottom-empty"
            },
            {
                "name": "flask-round-bottom-empty-outline",
                "icon": "mdi-flask-round-bottom-empty-outline"
            },
            {
                "name": "flask-round-bottom-outline",
                "icon": "mdi-flask-round-bottom-outline"
            },
            {
                "name": "fleur-de-lis",
                "icon": "mdi-fleur-de-lis"
            },
            {
                "name": "flip-horizontal",
                "icon": "mdi-flip-horizontal"
            },
            {
                "name": "flip-to-back",
                "icon": "mdi-flip-to-back"
            },
            {
                "name": "flip-to-front",
                "icon": "mdi-flip-to-front"
            },
            {
                "name": "flip-vertical",
                "icon": "mdi-flip-vertical"
            },
            {
                "name": "floor-lamp",
                "icon": "mdi-floor-lamp"
            },
            {
                "name": "floor-lamp-dual",
                "icon": "mdi-floor-lamp-dual"
            },
            {
                "name": "floor-lamp-dual-outline",
                "icon": "mdi-floor-lamp-dual-outline"
            },
            {
                "name": "floor-lamp-outline",
                "icon": "mdi-floor-lamp-outline"
            },
            {
                "name": "floor-lamp-torchiere",
                "icon": "mdi-floor-lamp-torchiere"
            },
            {
                "name": "floor-lamp-torchiere-outline",
                "icon": "mdi-floor-lamp-torchiere-outline"
            },
            {
                "name": "floor-lamp-torchiere-variant",
                "icon": "mdi-floor-lamp-torchiere-variant"
            },
            {
                "name": "floor-lamp-torchiere-variant-outline",
                "icon": "mdi-floor-lamp-torchiere-variant-outline"
            },
            {
                "name": "floor-plan",
                "icon": "mdi-floor-plan"
            },
            {
                "name": "floppy",
                "icon": "mdi-floppy"
            },
            {
                "name": "floppy-variant",
                "icon": "mdi-floppy-variant"
            },
            {
                "name": "flower",
                "icon": "mdi-flower"
            },
            {
                "name": "flower-outline",
                "icon": "mdi-flower-outline"
            },
            {
                "name": "flower-pollen",
                "icon": "mdi-flower-pollen"
            },
            {
                "name": "flower-pollen-outline",
                "icon": "mdi-flower-pollen-outline"
            },
            {
                "name": "flower-poppy",
                "icon": "mdi-flower-poppy"
            },
            {
                "name": "flower-tulip",
                "icon": "mdi-flower-tulip"
            },
            {
                "name": "flower-tulip-outline",
                "icon": "mdi-flower-tulip-outline"
            },
            {
                "name": "focus-auto",
                "icon": "mdi-focus-auto"
            },
            {
                "name": "focus-field",
                "icon": "mdi-focus-field"
            },
            {
                "name": "focus-field-horizontal",
                "icon": "mdi-focus-field-horizontal"
            },
            {
                "name": "focus-field-vertical",
                "icon": "mdi-focus-field-vertical"
            },
            {
                "name": "folder",
                "icon": "mdi-folder"
            },
            {
                "name": "folder-account",
                "icon": "mdi-folder-account"
            },
            {
                "name": "folder-account-outline",
                "icon": "mdi-folder-account-outline"
            },
            {
                "name": "folder-alert",
                "icon": "mdi-folder-alert"
            },
            {
                "name": "folder-alert-outline",
                "icon": "mdi-folder-alert-outline"
            },
            {
                "name": "folder-arrow-down",
                "icon": "mdi-folder-arrow-down"
            },
            {
                "name": "folder-arrow-down-outline",
                "icon": "mdi-folder-arrow-down-outline"
            },
            {
                "name": "folder-arrow-left",
                "icon": "mdi-folder-arrow-left"
            },
            {
                "name": "folder-arrow-left-outline",
                "icon": "mdi-folder-arrow-left-outline"
            },
            {
                "name": "folder-arrow-left-right",
                "icon": "mdi-folder-arrow-left-right"
            },
            {
                "name": "folder-arrow-left-right-outline",
                "icon": "mdi-folder-arrow-left-right-outline"
            },
            {
                "name": "folder-arrow-right",
                "icon": "mdi-folder-arrow-right"
            },
            {
                "name": "folder-arrow-right-outline",
                "icon": "mdi-folder-arrow-right-outline"
            },
            {
                "name": "folder-arrow-up",
                "icon": "mdi-folder-arrow-up"
            },
            {
                "name": "folder-arrow-up-down",
                "icon": "mdi-folder-arrow-up-down"
            },
            {
                "name": "folder-arrow-up-down-outline",
                "icon": "mdi-folder-arrow-up-down-outline"
            },
            {
                "name": "folder-arrow-up-outline",
                "icon": "mdi-folder-arrow-up-outline"
            },
            {
                "name": "folder-cancel",
                "icon": "mdi-folder-cancel"
            },
            {
                "name": "folder-cancel-outline",
                "icon": "mdi-folder-cancel-outline"
            },
            {
                "name": "folder-check",
                "icon": "mdi-folder-check"
            },
            {
                "name": "folder-check-outline",
                "icon": "mdi-folder-check-outline"
            },
            {
                "name": "folder-clock",
                "icon": "mdi-folder-clock"
            },
            {
                "name": "folder-clock-outline",
                "icon": "mdi-folder-clock-outline"
            },
            {
                "name": "folder-cog",
                "icon": "mdi-folder-cog"
            },
            {
                "name": "folder-cog-outline",
                "icon": "mdi-folder-cog-outline"
            },
            {
                "name": "folder-download",
                "icon": "mdi-folder-download"
            },
            {
                "name": "folder-download-outline",
                "icon": "mdi-folder-download-outline"
            },
            {
                "name": "folder-edit",
                "icon": "mdi-folder-edit"
            },
            {
                "name": "folder-edit-outline",
                "icon": "mdi-folder-edit-outline"
            },
            {
                "name": "folder-eye",
                "icon": "mdi-folder-eye"
            },
            {
                "name": "folder-eye-outline",
                "icon": "mdi-folder-eye-outline"
            },
            {
                "name": "folder-file",
                "icon": "mdi-folder-file"
            },
            {
                "name": "folder-file-outline",
                "icon": "mdi-folder-file-outline"
            },
            {
                "name": "folder-google-drive",
                "icon": "mdi-folder-google-drive"
            },
            {
                "name": "folder-heart",
                "icon": "mdi-folder-heart"
            },
            {
                "name": "folder-heart-outline",
                "icon": "mdi-folder-heart-outline"
            },
            {
                "name": "folder-hidden",
                "icon": "mdi-folder-hidden"
            },
            {
                "name": "folder-home",
                "icon": "mdi-folder-home"
            },
            {
                "name": "folder-home-outline",
                "icon": "mdi-folder-home-outline"
            },
            {
                "name": "folder-image",
                "icon": "mdi-folder-image"
            },
            {
                "name": "folder-information",
                "icon": "mdi-folder-information"
            },
            {
                "name": "folder-information-outline",
                "icon": "mdi-folder-information-outline"
            },
            {
                "name": "folder-key",
                "icon": "mdi-folder-key"
            },
            {
                "name": "folder-key-network",
                "icon": "mdi-folder-key-network"
            },
            {
                "name": "folder-key-network-outline",
                "icon": "mdi-folder-key-network-outline"
            },
            {
                "name": "folder-key-outline",
                "icon": "mdi-folder-key-outline"
            },
            {
                "name": "folder-lock",
                "icon": "mdi-folder-lock"
            },
            {
                "name": "folder-lock-open",
                "icon": "mdi-folder-lock-open"
            },
            {
                "name": "folder-lock-open-outline",
                "icon": "mdi-folder-lock-open-outline"
            },
            {
                "name": "folder-lock-outline",
                "icon": "mdi-folder-lock-outline"
            },
            {
                "name": "folder-marker",
                "icon": "mdi-folder-marker"
            },
            {
                "name": "folder-marker-outline",
                "icon": "mdi-folder-marker-outline"
            },
            {
                "name": "folder-minus",
                "icon": "mdi-folder-minus"
            },
            {
                "name": "folder-minus-outline",
                "icon": "mdi-folder-minus-outline"
            },
            {
                "name": "folder-move",
                "icon": "mdi-folder-move"
            },
            {
                "name": "folder-move-outline",
                "icon": "mdi-folder-move-outline"
            },
            {
                "name": "folder-multiple",
                "icon": "mdi-folder-multiple"
            },
            {
                "name": "folder-multiple-image",
                "icon": "mdi-folder-multiple-image"
            },
            {
                "name": "folder-multiple-outline",
                "icon": "mdi-folder-multiple-outline"
            },
            {
                "name": "folder-multiple-plus",
                "icon": "mdi-folder-multiple-plus"
            },
            {
                "name": "folder-multiple-plus-outline",
                "icon": "mdi-folder-multiple-plus-outline"
            },
            {
                "name": "folder-music",
                "icon": "mdi-folder-music"
            },
            {
                "name": "folder-music-outline",
                "icon": "mdi-folder-music-outline"
            },
            {
                "name": "folder-network",
                "icon": "mdi-folder-network"
            },
            {
                "name": "folder-network-outline",
                "icon": "mdi-folder-network-outline"
            },
            {
                "name": "folder-off",
                "icon": "mdi-folder-off"
            },
            {
                "name": "folder-off-outline",
                "icon": "mdi-folder-off-outline"
            },
            {
                "name": "folder-open",
                "icon": "mdi-folder-open"
            },
            {
                "name": "folder-open-outline",
                "icon": "mdi-folder-open-outline"
            },
            {
                "name": "folder-outline",
                "icon": "mdi-folder-outline"
            },
            {
                "name": "folder-play",
                "icon": "mdi-folder-play"
            },
            {
                "name": "folder-play-outline",
                "icon": "mdi-folder-play-outline"
            },
            {
                "name": "folder-plus",
                "icon": "mdi-folder-plus"
            },
            {
                "name": "folder-plus-outline",
                "icon": "mdi-folder-plus-outline"
            },
            {
                "name": "folder-pound",
                "icon": "mdi-folder-pound"
            },
            {
                "name": "folder-pound-outline",
                "icon": "mdi-folder-pound-outline"
            },
            {
                "name": "folder-question",
                "icon": "mdi-folder-question"
            },
            {
                "name": "folder-question-outline",
                "icon": "mdi-folder-question-outline"
            },
            {
                "name": "folder-refresh",
                "icon": "mdi-folder-refresh"
            },
            {
                "name": "folder-refresh-outline",
                "icon": "mdi-folder-refresh-outline"
            },
            {
                "name": "folder-remove",
                "icon": "mdi-folder-remove"
            },
            {
                "name": "folder-remove-outline",
                "icon": "mdi-folder-remove-outline"
            },
            {
                "name": "folder-search",
                "icon": "mdi-folder-search"
            },
            {
                "name": "folder-search-outline",
                "icon": "mdi-folder-search-outline"
            },
            {
                "name": "folder-settings",
                "icon": "mdi-folder-settings"
            },
            {
                "name": "folder-settings-outline",
                "icon": "mdi-folder-settings-outline"
            },
            {
                "name": "folder-star",
                "icon": "mdi-folder-star"
            },
            {
                "name": "folder-star-multiple",
                "icon": "mdi-folder-star-multiple"
            },
            {
                "name": "folder-star-multiple-outline",
                "icon": "mdi-folder-star-multiple-outline"
            },
            {
                "name": "folder-star-outline",
                "icon": "mdi-folder-star-outline"
            },
            {
                "name": "folder-swap",
                "icon": "mdi-folder-swap"
            },
            {
                "name": "folder-swap-outline",
                "icon": "mdi-folder-swap-outline"
            },
            {
                "name": "folder-sync",
                "icon": "mdi-folder-sync"
            },
            {
                "name": "folder-sync-outline",
                "icon": "mdi-folder-sync-outline"
            },
            {
                "name": "folder-table",
                "icon": "mdi-folder-table"
            },
            {
                "name": "folder-table-outline",
                "icon": "mdi-folder-table-outline"
            },
            {
                "name": "folder-text",
                "icon": "mdi-folder-text"
            },
            {
                "name": "folder-text-outline",
                "icon": "mdi-folder-text-outline"
            },
            {
                "name": "folder-upload",
                "icon": "mdi-folder-upload"
            },
            {
                "name": "folder-upload-outline",
                "icon": "mdi-folder-upload-outline"
            },
            {
                "name": "folder-wrench",
                "icon": "mdi-folder-wrench"
            },
            {
                "name": "folder-wrench-outline",
                "icon": "mdi-folder-wrench-outline"
            },
            {
                "name": "folder-zip",
                "icon": "mdi-folder-zip"
            },
            {
                "name": "folder-zip-outline",
                "icon": "mdi-folder-zip-outline"
            },
            {
                "name": "font-awesome",
                "icon": "mdi-font-awesome"
            },
            {
                "name": "food",
                "icon": "mdi-food"
            },
            {
                "name": "food-apple",
                "icon": "mdi-food-apple"
            },
            {
                "name": "food-apple-outline",
                "icon": "mdi-food-apple-outline"
            },
            {
                "name": "food-croissant",
                "icon": "mdi-food-croissant"
            },
            {
                "name": "food-drumstick",
                "icon": "mdi-food-drumstick"
            },
            {
                "name": "food-drumstick-off",
                "icon": "mdi-food-drumstick-off"
            },
            {
                "name": "food-drumstick-off-outline",
                "icon": "mdi-food-drumstick-off-outline"
            },
            {
                "name": "food-drumstick-outline",
                "icon": "mdi-food-drumstick-outline"
            },
            {
                "name": "food-fork-drink",
                "icon": "mdi-food-fork-drink"
            },
            {
                "name": "food-halal",
                "icon": "mdi-food-halal"
            },
            {
                "name": "food-hot-dog",
                "icon": "mdi-food-hot-dog"
            },
            {
                "name": "food-kosher",
                "icon": "mdi-food-kosher"
            },
            {
                "name": "food-off",
                "icon": "mdi-food-off"
            },
            {
                "name": "food-off-outline",
                "icon": "mdi-food-off-outline"
            },
            {
                "name": "food-outline",
                "icon": "mdi-food-outline"
            },
            {
                "name": "food-steak",
                "icon": "mdi-food-steak"
            },
            {
                "name": "food-steak-off",
                "icon": "mdi-food-steak-off"
            },
            {
                "name": "food-takeout-box",
                "icon": "mdi-food-takeout-box"
            },
            {
                "name": "food-takeout-box-outline",
                "icon": "mdi-food-takeout-box-outline"
            },
            {
                "name": "food-turkey",
                "icon": "mdi-food-turkey"
            },
            {
                "name": "food-variant",
                "icon": "mdi-food-variant"
            },
            {
                "name": "food-variant-off",
                "icon": "mdi-food-variant-off"
            },
            {
                "name": "foot-print",
                "icon": "mdi-foot-print"
            },
            {
                "name": "football",
                "icon": "mdi-football"
            },
            {
                "name": "football-australian",
                "icon": "mdi-football-australian"
            },
            {
                "name": "football-helmet",
                "icon": "mdi-football-helmet"
            },
            {
                "name": "forest",
                "icon": "mdi-forest"
            },
            {
                "name": "forklift",
                "icon": "mdi-forklift"
            },
            {
                "name": "form-dropdown",
                "icon": "mdi-form-dropdown"
            },
            {
                "name": "form-select",
                "icon": "mdi-form-select"
            },
            {
                "name": "form-textarea",
                "icon": "mdi-form-textarea"
            },
            {
                "name": "form-textbox",
                "icon": "mdi-form-textbox"
            },
            {
                "name": "form-textbox-lock",
                "icon": "mdi-form-textbox-lock"
            },
            {
                "name": "form-textbox-password",
                "icon": "mdi-form-textbox-password"
            },
            {
                "name": "format-align-bottom",
                "icon": "mdi-format-align-bottom"
            },
            {
                "name": "format-align-center",
                "icon": "mdi-format-align-center"
            },
            {
                "name": "format-align-justify",
                "icon": "mdi-format-align-justify"
            },
            {
                "name": "format-align-left",
                "icon": "mdi-format-align-left"
            },
            {
                "name": "format-align-middle",
                "icon": "mdi-format-align-middle"
            },
            {
                "name": "format-align-right",
                "icon": "mdi-format-align-right"
            },
            {
                "name": "format-align-top",
                "icon": "mdi-format-align-top"
            },
            {
                "name": "format-annotation-minus",
                "icon": "mdi-format-annotation-minus"
            },
            {
                "name": "format-annotation-plus",
                "icon": "mdi-format-annotation-plus"
            },
            {
                "name": "format-bold",
                "icon": "mdi-format-bold"
            },
            {
                "name": "format-clear",
                "icon": "mdi-format-clear"
            },
            {
                "name": "format-color-fill",
                "icon": "mdi-format-color-fill"
            },
            {
                "name": "format-color-highlight",
                "icon": "mdi-format-color-highlight"
            },
            {
                "name": "format-color-marker-cancel",
                "icon": "mdi-format-color-marker-cancel"
            },
            {
                "name": "format-color-text",
                "icon": "mdi-format-color-text"
            },
            {
                "name": "format-columns",
                "icon": "mdi-format-columns"
            },
            {
                "name": "format-float-center",
                "icon": "mdi-format-float-center"
            },
            {
                "name": "format-float-left",
                "icon": "mdi-format-float-left"
            },
            {
                "name": "format-float-none",
                "icon": "mdi-format-float-none"
            },
            {
                "name": "format-float-right",
                "icon": "mdi-format-float-right"
            },
            {
                "name": "format-font",
                "icon": "mdi-format-font"
            },
            {
                "name": "format-font-size-decrease",
                "icon": "mdi-format-font-size-decrease"
            },
            {
                "name": "format-font-size-increase",
                "icon": "mdi-format-font-size-increase"
            },
            {
                "name": "format-header-1",
                "icon": "mdi-format-header-1"
            },
            {
                "name": "format-header-2",
                "icon": "mdi-format-header-2"
            },
            {
                "name": "format-header-3",
                "icon": "mdi-format-header-3"
            },
            {
                "name": "format-header-4",
                "icon": "mdi-format-header-4"
            },
            {
                "name": "format-header-5",
                "icon": "mdi-format-header-5"
            },
            {
                "name": "format-header-6",
                "icon": "mdi-format-header-6"
            },
            {
                "name": "format-header-decrease",
                "icon": "mdi-format-header-decrease"
            },
            {
                "name": "format-header-equal",
                "icon": "mdi-format-header-equal"
            },
            {
                "name": "format-header-increase",
                "icon": "mdi-format-header-increase"
            },
            {
                "name": "format-header-pound",
                "icon": "mdi-format-header-pound"
            },
            {
                "name": "format-horizontal-align-center",
                "icon": "mdi-format-horizontal-align-center"
            },
            {
                "name": "format-horizontal-align-left",
                "icon": "mdi-format-horizontal-align-left"
            },
            {
                "name": "format-horizontal-align-right",
                "icon": "mdi-format-horizontal-align-right"
            },
            {
                "name": "format-indent-decrease",
                "icon": "mdi-format-indent-decrease"
            },
            {
                "name": "format-indent-increase",
                "icon": "mdi-format-indent-increase"
            },
            {
                "name": "format-italic",
                "icon": "mdi-format-italic"
            },
            {
                "name": "format-letter-case",
                "icon": "mdi-format-letter-case"
            },
            {
                "name": "format-letter-case-lower",
                "icon": "mdi-format-letter-case-lower"
            },
            {
                "name": "format-letter-case-upper",
                "icon": "mdi-format-letter-case-upper"
            },
            {
                "name": "format-letter-ends-with",
                "icon": "mdi-format-letter-ends-with"
            },
            {
                "name": "format-letter-matches",
                "icon": "mdi-format-letter-matches"
            },
            {
                "name": "format-letter-spacing",
                "icon": "mdi-format-letter-spacing"
            },
            {
                "name": "format-letter-spacing-variant",
                "icon": "mdi-format-letter-spacing-variant"
            },
            {
                "name": "format-letter-starts-with",
                "icon": "mdi-format-letter-starts-with"
            },
            {
                "name": "format-line-height",
                "icon": "mdi-format-line-height"
            },
            {
                "name": "ab-testing",
                "icon": "mdi-ab-testing"
            },
            {
                "name": "format-line-style",
                "icon": "mdi-format-line-style"
            },
            {
                "name": "format-line-weight",
                "icon": "mdi-format-line-weight"
            },
            {
                "name": "format-list-bulleted",
                "icon": "mdi-format-list-bulleted"
            },
            {
                "name": "format-list-bulleted-square",
                "icon": "mdi-format-list-bulleted-square"
            },
            {
                "name": "format-list-bulleted-triangle",
                "icon": "mdi-format-list-bulleted-triangle"
            },
            {
                "name": "format-list-bulleted-type",
                "icon": "mdi-format-list-bulleted-type"
            },
            {
                "name": "format-list-checkbox",
                "icon": "mdi-format-list-checkbox"
            },
            {
                "name": "format-list-checks",
                "icon": "mdi-format-list-checks"
            },
            {
                "name": "format-list-group",
                "icon": "mdi-format-list-group"
            },
            {
                "name": "format-list-group-plus",
                "icon": "mdi-format-list-group-plus"
            },
            {
                "name": "format-list-numbered",
                "icon": "mdi-format-list-numbered"
            },
            {
                "name": "format-list-numbered-rtl",
                "icon": "mdi-format-list-numbered-rtl"
            },
            {
                "name": "format-list-text",
                "icon": "mdi-format-list-text"
            },
            {
                "name": "format-overline",
                "icon": "mdi-format-overline"
            },
            {
                "name": "format-page-break",
                "icon": "mdi-format-page-break"
            },
            {
                "name": "format-page-split",
                "icon": "mdi-format-page-split"
            },
            {
                "name": "format-paint",
                "icon": "mdi-format-paint"
            },
            {
                "name": "format-paragraph",
                "icon": "mdi-format-paragraph"
            },
            {
                "name": "format-paragraph-spacing",
                "icon": "mdi-format-paragraph-spacing"
            },
            {
                "name": "format-pilcrow",
                "icon": "mdi-format-pilcrow"
            },
            {
                "name": "format-pilcrow-arrow-left",
                "icon": "mdi-format-pilcrow-arrow-left"
            },
            {
                "name": "format-pilcrow-arrow-right",
                "icon": "mdi-format-pilcrow-arrow-right"
            },
            {
                "name": "format-quote-close",
                "icon": "mdi-format-quote-close"
            },
            {
                "name": "format-quote-close-outline",
                "icon": "mdi-format-quote-close-outline"
            },
            {
                "name": "format-quote-open",
                "icon": "mdi-format-quote-open"
            },
            {
                "name": "format-quote-open-outline",
                "icon": "mdi-format-quote-open-outline"
            },
            {
                "name": "format-rotate-90",
                "icon": "mdi-format-rotate-90"
            },
            {
                "name": "format-section",
                "icon": "mdi-format-section"
            },
            {
                "name": "format-size",
                "icon": "mdi-format-size"
            },
            {
                "name": "format-strikethrough",
                "icon": "mdi-format-strikethrough"
            },
            {
                "name": "format-strikethrough-variant",
                "icon": "mdi-format-strikethrough-variant"
            },
            {
                "name": "format-subscript",
                "icon": "mdi-format-subscript"
            },
            {
                "name": "format-superscript",
                "icon": "mdi-format-superscript"
            },
            {
                "name": "format-text",
                "icon": "mdi-format-text"
            },
            {
                "name": "format-text-rotation-angle-down",
                "icon": "mdi-format-text-rotation-angle-down"
            },
            {
                "name": "format-text-rotation-angle-up",
                "icon": "mdi-format-text-rotation-angle-up"
            },
            {
                "name": "format-text-rotation-down",
                "icon": "mdi-format-text-rotation-down"
            },
            {
                "name": "format-text-rotation-down-vertical",
                "icon": "mdi-format-text-rotation-down-vertical"
            },
            {
                "name": "format-text-rotation-none",
                "icon": "mdi-format-text-rotation-none"
            },
            {
                "name": "format-text-rotation-up",
                "icon": "mdi-format-text-rotation-up"
            },
            {
                "name": "format-text-rotation-vertical",
                "icon": "mdi-format-text-rotation-vertical"
            },
            {
                "name": "format-text-variant",
                "icon": "mdi-format-text-variant"
            },
            {
                "name": "format-text-variant-outline",
                "icon": "mdi-format-text-variant-outline"
            },
            {
                "name": "format-text-wrapping-clip",
                "icon": "mdi-format-text-wrapping-clip"
            },
            {
                "name": "format-text-wrapping-overflow",
                "icon": "mdi-format-text-wrapping-overflow"
            },
            {
                "name": "format-text-wrapping-wrap",
                "icon": "mdi-format-text-wrapping-wrap"
            },
            {
                "name": "format-textbox",
                "icon": "mdi-format-textbox"
            },
            {
                "name": "format-title",
                "icon": "mdi-format-title"
            },
            {
                "name": "format-underline",
                "icon": "mdi-format-underline"
            },
            {
                "name": "format-underline-wavy",
                "icon": "mdi-format-underline-wavy"
            },
            {
                "name": "format-vertical-align-bottom",
                "icon": "mdi-format-vertical-align-bottom"
            },
            {
                "name": "format-vertical-align-center",
                "icon": "mdi-format-vertical-align-center"
            },
            {
                "name": "format-vertical-align-top",
                "icon": "mdi-format-vertical-align-top"
            },
            {
                "name": "format-wrap-inline",
                "icon": "mdi-format-wrap-inline"
            },
            {
                "name": "format-wrap-square",
                "icon": "mdi-format-wrap-square"
            },
            {
                "name": "format-wrap-tight",
                "icon": "mdi-format-wrap-tight"
            },
            {
                "name": "format-wrap-top-bottom",
                "icon": "mdi-format-wrap-top-bottom"
            },
            {
                "name": "forum",
                "icon": "mdi-forum"
            },
            {
                "name": "forum-minus",
                "icon": "mdi-forum-minus"
            },
            {
                "name": "forum-minus-outline",
                "icon": "mdi-forum-minus-outline"
            },
            {
                "name": "forum-outline",
                "icon": "mdi-forum-outline"
            },
            {
                "name": "forum-plus",
                "icon": "mdi-forum-plus"
            },
            {
                "name": "forum-plus-outline",
                "icon": "mdi-forum-plus-outline"
            },
            {
                "name": "forum-remove",
                "icon": "mdi-forum-remove"
            },
            {
                "name": "forum-remove-outline",
                "icon": "mdi-forum-remove-outline"
            },
            {
                "name": "forward",
                "icon": "mdi-forward"
            },
            {
                "name": "forwardburger",
                "icon": "mdi-forwardburger"
            },
            {
                "name": "fountain",
                "icon": "mdi-fountain"
            },
            {
                "name": "fountain-pen",
                "icon": "mdi-fountain-pen"
            },
            {
                "name": "fountain-pen-tip",
                "icon": "mdi-fountain-pen-tip"
            },
            {
                "name": "fraction-one-half",
                "icon": "mdi-fraction-one-half"
            },
            {
                "name": "freebsd",
                "icon": "mdi-freebsd"
            },
            {
                "name": "french-fries",
                "icon": "mdi-french-fries"
            },
            {
                "name": "frequently-asked-questions",
                "icon": "mdi-frequently-asked-questions"
            },
            {
                "name": "fridge",
                "icon": "mdi-fridge"
            },
            {
                "name": "fridge-alert",
                "icon": "mdi-fridge-alert"
            },
            {
                "name": "fridge-alert-outline",
                "icon": "mdi-fridge-alert-outline"
            },
            {
                "name": "fridge-bottom",
                "icon": "mdi-fridge-bottom"
            },
            {
                "name": "fridge-industrial",
                "icon": "mdi-fridge-industrial"
            },
            {
                "name": "fridge-industrial-alert",
                "icon": "mdi-fridge-industrial-alert"
            },
            {
                "name": "fridge-industrial-alert-outline",
                "icon": "mdi-fridge-industrial-alert-outline"
            },
            {
                "name": "fridge-industrial-off",
                "icon": "mdi-fridge-industrial-off"
            },
            {
                "name": "fridge-industrial-off-outline",
                "icon": "mdi-fridge-industrial-off-outline"
            },
            {
                "name": "fridge-industrial-outline",
                "icon": "mdi-fridge-industrial-outline"
            },
            {
                "name": "fridge-off",
                "icon": "mdi-fridge-off"
            },
            {
                "name": "fridge-off-outline",
                "icon": "mdi-fridge-off-outline"
            },
            {
                "name": "fridge-outline",
                "icon": "mdi-fridge-outline"
            },
            {
                "name": "fridge-top",
                "icon": "mdi-fridge-top"
            },
            {
                "name": "fridge-variant",
                "icon": "mdi-fridge-variant"
            },
            {
                "name": "fridge-variant-alert",
                "icon": "mdi-fridge-variant-alert"
            },
            {
                "name": "fridge-variant-alert-outline",
                "icon": "mdi-fridge-variant-alert-outline"
            },
            {
                "name": "fridge-variant-off",
                "icon": "mdi-fridge-variant-off"
            },
            {
                "name": "fridge-variant-off-outline",
                "icon": "mdi-fridge-variant-off-outline"
            },
            {
                "name": "fridge-variant-outline",
                "icon": "mdi-fridge-variant-outline"
            },
            {
                "name": "fruit-cherries",
                "icon": "mdi-fruit-cherries"
            },
            {
                "name": "fruit-cherries-off",
                "icon": "mdi-fruit-cherries-off"
            },
            {
                "name": "fruit-citrus",
                "icon": "mdi-fruit-citrus"
            },
            {
                "name": "fruit-citrus-off",
                "icon": "mdi-fruit-citrus-off"
            },
            {
                "name": "fruit-grapes",
                "icon": "mdi-fruit-grapes"
            },
            {
                "name": "fruit-grapes-outline",
                "icon": "mdi-fruit-grapes-outline"
            },
            {
                "name": "fruit-pear",
                "icon": "mdi-fruit-pear"
            },
            {
                "name": "fruit-pineapple",
                "icon": "mdi-fruit-pineapple"
            },
            {
                "name": "fruit-watermelon",
                "icon": "mdi-fruit-watermelon"
            },
            {
                "name": "fuel",
                "icon": "mdi-fuel"
            },
            {
                "name": "fuel-cell",
                "icon": "mdi-fuel-cell"
            },
            {
                "name": "fullscreen",
                "icon": "mdi-fullscreen"
            },
            {
                "name": "fullscreen-exit",
                "icon": "mdi-fullscreen-exit"
            },
            {
                "name": "function",
                "icon": "mdi-function"
            },
            {
                "name": "function-variant",
                "icon": "mdi-function-variant"
            },
            {
                "name": "furigana-horizontal",
                "icon": "mdi-furigana-horizontal"
            },
            {
                "name": "furigana-vertical",
                "icon": "mdi-furigana-vertical"
            },
            {
                "name": "fuse",
                "icon": "mdi-fuse"
            },
            {
                "name": "fuse-alert",
                "icon": "mdi-fuse-alert"
            },
            {
                "name": "fuse-blade",
                "icon": "mdi-fuse-blade"
            },
            {
                "name": "fuse-off",
                "icon": "mdi-fuse-off"
            },
            {
                "name": "gamepad",
                "icon": "mdi-gamepad"
            },
            {
                "name": "gamepad-circle",
                "icon": "mdi-gamepad-circle"
            },
            {
                "name": "gamepad-circle-down",
                "icon": "mdi-gamepad-circle-down"
            },
            {
                "name": "gamepad-circle-left",
                "icon": "mdi-gamepad-circle-left"
            },
            {
                "name": "gamepad-circle-outline",
                "icon": "mdi-gamepad-circle-outline"
            },
            {
                "name": "gamepad-circle-right",
                "icon": "mdi-gamepad-circle-right"
            },
            {
                "name": "gamepad-circle-up",
                "icon": "mdi-gamepad-circle-up"
            },
            {
                "name": "gamepad-down",
                "icon": "mdi-gamepad-down"
            },
            {
                "name": "gamepad-left",
                "icon": "mdi-gamepad-left"
            },
            {
                "name": "gamepad-outline",
                "icon": "mdi-gamepad-outline"
            },
            {
                "name": "gamepad-right",
                "icon": "mdi-gamepad-right"
            },
            {
                "name": "gamepad-round",
                "icon": "mdi-gamepad-round"
            },
            {
                "name": "gamepad-round-down",
                "icon": "mdi-gamepad-round-down"
            },
            {
                "name": "gamepad-round-left",
                "icon": "mdi-gamepad-round-left"
            },
            {
                "name": "gamepad-round-outline",
                "icon": "mdi-gamepad-round-outline"
            },
            {
                "name": "gamepad-round-right",
                "icon": "mdi-gamepad-round-right"
            },
            {
                "name": "gamepad-round-up",
                "icon": "mdi-gamepad-round-up"
            },
            {
                "name": "gamepad-square",
                "icon": "mdi-gamepad-square"
            },
            {
                "name": "gamepad-square-outline",
                "icon": "mdi-gamepad-square-outline"
            },
            {
                "name": "gamepad-up",
                "icon": "mdi-gamepad-up"
            },
            {
                "name": "gamepad-variant",
                "icon": "mdi-gamepad-variant"
            },
            {
                "name": "gamepad-variant-outline",
                "icon": "mdi-gamepad-variant-outline"
            },
            {
                "name": "gamma",
                "icon": "mdi-gamma"
            },
            {
                "name": "gantry-crane",
                "icon": "mdi-gantry-crane"
            },
            {
                "name": "garage",
                "icon": "mdi-garage"
            },
            {
                "name": "garage-alert",
                "icon": "mdi-garage-alert"
            },
            {
                "name": "garage-alert-variant",
                "icon": "mdi-garage-alert-variant"
            },
            {
                "name": "garage-lock",
                "icon": "mdi-garage-lock"
            },
            {
                "name": "garage-open",
                "icon": "mdi-garage-open"
            },
            {
                "name": "garage-open-variant",
                "icon": "mdi-garage-open-variant"
            },
            {
                "name": "garage-variant",
                "icon": "mdi-garage-variant"
            },
            {
                "name": "garage-variant-lock",
                "icon": "mdi-garage-variant-lock"
            },
            {
                "name": "gas-burner",
                "icon": "mdi-gas-burner"
            },
            {
                "name": "gas-cylinder",
                "icon": "mdi-gas-cylinder"
            },
            {
                "name": "gas-station",
                "icon": "mdi-gas-station"
            },
            {
                "name": "gas-station-off",
                "icon": "mdi-gas-station-off"
            },
            {
                "name": "gas-station-off-outline",
                "icon": "mdi-gas-station-off-outline"
            },
            {
                "name": "gas-station-outline",
                "icon": "mdi-gas-station-outline"
            },
            {
                "name": "gate",
                "icon": "mdi-gate"
            },
            {
                "name": "gate-alert",
                "icon": "mdi-gate-alert"
            },
            {
                "name": "gate-and",
                "icon": "mdi-gate-and"
            },
            {
                "name": "gate-arrow-left",
                "icon": "mdi-gate-arrow-left"
            },
            {
                "name": "gate-arrow-right",
                "icon": "mdi-gate-arrow-right"
            },
            {
                "name": "gate-buffer",
                "icon": "mdi-gate-buffer"
            },
            {
                "name": "gate-nand",
                "icon": "mdi-gate-nand"
            },
            {
                "name": "gate-nor",
                "icon": "mdi-gate-nor"
            },
            {
                "name": "gate-not",
                "icon": "mdi-gate-not"
            },
            {
                "name": "gate-open",
                "icon": "mdi-gate-open"
            },
            {
                "name": "gate-or",
                "icon": "mdi-gate-or"
            },
            {
                "name": "gate-xnor",
                "icon": "mdi-gate-xnor"
            },
            {
                "name": "gate-xor",
                "icon": "mdi-gate-xor"
            },
            {
                "name": "gatsby",
                "icon": "mdi-gatsby"
            },
            {
                "name": "gauge",
                "icon": "mdi-gauge"
            },
            {
                "name": "gauge-empty",
                "icon": "mdi-gauge-empty"
            },
            {
                "name": "gauge-full",
                "icon": "mdi-gauge-full"
            },
            {
                "name": "gauge-low",
                "icon": "mdi-gauge-low"
            },
            {
                "name": "gavel",
                "icon": "mdi-gavel"
            },
            {
                "name": "gender-female",
                "icon": "mdi-gender-female"
            },
            {
                "name": "gender-male",
                "icon": "mdi-gender-male"
            },
            {
                "name": "gender-male-female",
                "icon": "mdi-gender-male-female"
            },
            {
                "name": "gender-male-female-variant",
                "icon": "mdi-gender-male-female-variant"
            },
            {
                "name": "gender-non-binary",
                "icon": "mdi-gender-non-binary"
            },
            {
                "name": "gender-transgender",
                "icon": "mdi-gender-transgender"
            },
            {
                "name": "gentoo",
                "icon": "mdi-gentoo"
            },
            {
                "name": "gesture",
                "icon": "mdi-gesture"
            },
            {
                "name": "gesture-double-tap",
                "icon": "mdi-gesture-double-tap"
            },
            {
                "name": "gesture-pinch",
                "icon": "mdi-gesture-pinch"
            },
            {
                "name": "gesture-spread",
                "icon": "mdi-gesture-spread"
            },
            {
                "name": "gesture-swipe",
                "icon": "mdi-gesture-swipe"
            },
            {
                "name": "gesture-swipe-down",
                "icon": "mdi-gesture-swipe-down"
            },
            {
                "name": "gesture-swipe-horizontal",
                "icon": "mdi-gesture-swipe-horizontal"
            },
            {
                "name": "gesture-swipe-left",
                "icon": "mdi-gesture-swipe-left"
            },
            {
                "name": "gesture-swipe-right",
                "icon": "mdi-gesture-swipe-right"
            },
            {
                "name": "gesture-swipe-up",
                "icon": "mdi-gesture-swipe-up"
            },
            {
                "name": "gesture-swipe-vertical",
                "icon": "mdi-gesture-swipe-vertical"
            },
            {
                "name": "gesture-tap",
                "icon": "mdi-gesture-tap"
            },
            {
                "name": "gesture-tap-box",
                "icon": "mdi-gesture-tap-box"
            },
            {
                "name": "gesture-tap-button",
                "icon": "mdi-gesture-tap-button"
            },
            {
                "name": "gesture-tap-hold",
                "icon": "mdi-gesture-tap-hold"
            },
            {
                "name": "gesture-two-double-tap",
                "icon": "mdi-gesture-two-double-tap"
            },
            {
                "name": "gesture-two-tap",
                "icon": "mdi-gesture-two-tap"
            },
            {
                "name": "ghost",
                "icon": "mdi-ghost"
            },
            {
                "name": "ghost-off",
                "icon": "mdi-ghost-off"
            },
            {
                "name": "ghost-off-outline",
                "icon": "mdi-ghost-off-outline"
            },
            {
                "name": "ghost-outline",
                "icon": "mdi-ghost-outline"
            },
            {
                "name": "gift",
                "icon": "mdi-gift"
            },
            {
                "name": "gift-off",
                "icon": "mdi-gift-off"
            },
            {
                "name": "gift-off-outline",
                "icon": "mdi-gift-off-outline"
            },
            {
                "name": "gift-open",
                "icon": "mdi-gift-open"
            },
            {
                "name": "gift-open-outline",
                "icon": "mdi-gift-open-outline"
            },
            {
                "name": "gift-outline",
                "icon": "mdi-gift-outline"
            },
            {
                "name": "git",
                "icon": "mdi-git"
            },
            {
                "name": "github",
                "icon": "mdi-github"
            },
            {
                "name": "gitlab",
                "icon": "mdi-gitlab"
            },
            {
                "name": "glass-cocktail",
                "icon": "mdi-glass-cocktail"
            },
            {
                "name": "glass-cocktail-off",
                "icon": "mdi-glass-cocktail-off"
            },
            {
                "name": "glass-flute",
                "icon": "mdi-glass-flute"
            },
            {
                "name": "glass-fragile",
                "icon": "mdi-glass-fragile"
            },
            {
                "name": "glass-mug",
                "icon": "mdi-glass-mug"
            },
            {
                "name": "glass-mug-off",
                "icon": "mdi-glass-mug-off"
            },
            {
                "name": "glass-mug-variant",
                "icon": "mdi-glass-mug-variant"
            },
            {
                "name": "glass-mug-variant-off",
                "icon": "mdi-glass-mug-variant-off"
            },
            {
                "name": "glass-pint-outline",
                "icon": "mdi-glass-pint-outline"
            },
            {
                "name": "glass-stange",
                "icon": "mdi-glass-stange"
            },
            {
                "name": "glass-tulip",
                "icon": "mdi-glass-tulip"
            },
            {
                "name": "glass-wine",
                "icon": "mdi-glass-wine"
            },
            {
                "name": "glasses",
                "icon": "mdi-glasses"
            },
            {
                "name": "globe-light",
                "icon": "mdi-globe-light"
            },
            {
                "name": "globe-light-outline",
                "icon": "mdi-globe-light-outline"
            },
            {
                "name": "globe-model",
                "icon": "mdi-globe-model"
            },
            {
                "name": "gmail",
                "icon": "mdi-gmail"
            },
            {
                "name": "gnome",
                "icon": "mdi-gnome"
            },
            {
                "name": "go-kart",
                "icon": "mdi-go-kart"
            },
            {
                "name": "go-kart-track",
                "icon": "mdi-go-kart-track"
            },
            {
                "name": "gog",
                "icon": "mdi-gog"
            },
            {
                "name": "gold",
                "icon": "mdi-gold"
            },
            {
                "name": "golf",
                "icon": "mdi-golf"
            },
            {
                "name": "golf-cart",
                "icon": "mdi-golf-cart"
            },
            {
                "name": "golf-tee",
                "icon": "mdi-golf-tee"
            },
            {
                "name": "gondola",
                "icon": "mdi-gondola"
            },
            {
                "name": "goodreads",
                "icon": "mdi-goodreads"
            },
            {
                "name": "google",
                "icon": "mdi-google"
            },
            {
                "name": "google-ads",
                "icon": "mdi-google-ads"
            },
            {
                "name": "google-analytics",
                "icon": "mdi-google-analytics"
            },
            {
                "name": "google-assistant",
                "icon": "mdi-google-assistant"
            },
            {
                "name": "google-cardboard",
                "icon": "mdi-google-cardboard"
            },
            {
                "name": "google-chrome",
                "icon": "mdi-google-chrome"
            },
            {
                "name": "google-circles",
                "icon": "mdi-google-circles"
            },
            {
                "name": "google-circles-communities",
                "icon": "mdi-google-circles-communities"
            },
            {
                "name": "google-circles-extended",
                "icon": "mdi-google-circles-extended"
            },
            {
                "name": "google-circles-group",
                "icon": "mdi-google-circles-group"
            },
            {
                "name": "google-classroom",
                "icon": "mdi-google-classroom"
            },
            {
                "name": "google-cloud",
                "icon": "mdi-google-cloud"
            },
            {
                "name": "google-downasaur",
                "icon": "mdi-google-downasaur"
            },
            {
                "name": "google-drive",
                "icon": "mdi-google-drive"
            },
            {
                "name": "google-earth",
                "icon": "mdi-google-earth"
            },
            {
                "name": "google-fit",
                "icon": "mdi-google-fit"
            },
            {
                "name": "google-glass",
                "icon": "mdi-google-glass"
            },
            {
                "name": "google-hangouts",
                "icon": "mdi-google-hangouts"
            },
            {
                "name": "google-keep",
                "icon": "mdi-google-keep"
            },
            {
                "name": "google-lens",
                "icon": "mdi-google-lens"
            },
            {
                "name": "google-maps",
                "icon": "mdi-google-maps"
            },
            {
                "name": "google-my-business",
                "icon": "mdi-google-my-business"
            },
            {
                "name": "google-nearby",
                "icon": "mdi-google-nearby"
            },
            {
                "name": "google-play",
                "icon": "mdi-google-play"
            },
            {
                "name": "google-plus",
                "icon": "mdi-google-plus"
            },
            {
                "name": "google-podcast",
                "icon": "mdi-google-podcast"
            },
            {
                "name": "google-spreadsheet",
                "icon": "mdi-google-spreadsheet"
            },
            {
                "name": "google-street-view",
                "icon": "mdi-google-street-view"
            },
            {
                "name": "google-translate",
                "icon": "mdi-google-translate"
            },
            {
                "name": "gradient-horizontal",
                "icon": "mdi-gradient-horizontal"
            },
            {
                "name": "gradient-vertical",
                "icon": "mdi-gradient-vertical"
            },
            {
                "name": "grain",
                "icon": "mdi-grain"
            },
            {
                "name": "graph",
                "icon": "mdi-graph"
            },
            {
                "name": "graph-outline",
                "icon": "mdi-graph-outline"
            },
            {
                "name": "graphql",
                "icon": "mdi-graphql"
            },
            {
                "name": "grass",
                "icon": "mdi-grass"
            },
            {
                "name": "grave-stone",
                "icon": "mdi-grave-stone"
            },
            {
                "name": "grease-pencil",
                "icon": "mdi-grease-pencil"
            },
            {
                "name": "greater-than",
                "icon": "mdi-greater-than"
            },
            {
                "name": "greater-than-or-equal",
                "icon": "mdi-greater-than-or-equal"
            },
            {
                "name": "greenhouse",
                "icon": "mdi-greenhouse"
            },
            {
                "name": "grid",
                "icon": "mdi-grid"
            },
            {
                "name": "grid-large",
                "icon": "mdi-grid-large"
            },
            {
                "name": "grid-off",
                "icon": "mdi-grid-off"
            },
            {
                "name": "grill",
                "icon": "mdi-grill"
            },
            {
                "name": "grill-outline",
                "icon": "mdi-grill-outline"
            },
            {
                "name": "group",
                "icon": "mdi-group"
            },
            {
                "name": "guitar-acoustic",
                "icon": "mdi-guitar-acoustic"
            },
            {
                "name": "guitar-electric",
                "icon": "mdi-guitar-electric"
            },
            {
                "name": "guitar-pick",
                "icon": "mdi-guitar-pick"
            },
            {
                "name": "guitar-pick-outline",
                "icon": "mdi-guitar-pick-outline"
            },
            {
                "name": "guy-fawkes-mask",
                "icon": "mdi-guy-fawkes-mask"
            },
            {
                "name": "gymnastics",
                "icon": "mdi-gymnastics"
            },
            {
                "name": "hail",
                "icon": "mdi-hail"
            },
            {
                "name": "hair-dryer",
                "icon": "mdi-hair-dryer"
            },
            {
                "name": "hair-dryer-outline",
                "icon": "mdi-hair-dryer-outline"
            },
            {
                "name": "halloween",
                "icon": "mdi-halloween"
            },
            {
                "name": "hamburger",
                "icon": "mdi-hamburger"
            },
            {
                "name": "hamburger-check",
                "icon": "mdi-hamburger-check"
            },
            {
                "name": "hamburger-minus",
                "icon": "mdi-hamburger-minus"
            },
            {
                "name": "hamburger-off",
                "icon": "mdi-hamburger-off"
            },
            {
                "name": "hamburger-plus",
                "icon": "mdi-hamburger-plus"
            },
            {
                "name": "hamburger-remove",
                "icon": "mdi-hamburger-remove"
            },
            {
                "name": "hammer",
                "icon": "mdi-hammer"
            },
            {
                "name": "hammer-screwdriver",
                "icon": "mdi-hammer-screwdriver"
            },
            {
                "name": "hammer-sickle",
                "icon": "mdi-hammer-sickle"
            },
            {
                "name": "hammer-wrench",
                "icon": "mdi-hammer-wrench"
            },
            {
                "name": "hand-back-left",
                "icon": "mdi-hand-back-left"
            },
            {
                "name": "hand-back-left-off",
                "icon": "mdi-hand-back-left-off"
            },
            {
                "name": "hand-back-left-off-outline",
                "icon": "mdi-hand-back-left-off-outline"
            },
            {
                "name": "hand-back-left-outline",
                "icon": "mdi-hand-back-left-outline"
            },
            {
                "name": "hand-back-right",
                "icon": "mdi-hand-back-right"
            },
            {
                "name": "hand-back-right-off",
                "icon": "mdi-hand-back-right-off"
            },
            {
                "name": "hand-back-right-off-outline",
                "icon": "mdi-hand-back-right-off-outline"
            },
            {
                "name": "hand-back-right-outline",
                "icon": "mdi-hand-back-right-outline"
            },
            {
                "name": "hand-clap",
                "icon": "mdi-hand-clap"
            },
            {
                "name": "hand-clap-off",
                "icon": "mdi-hand-clap-off"
            },
            {
                "name": "hand-coin",
                "icon": "mdi-hand-coin"
            },
            {
                "name": "hand-coin-outline",
                "icon": "mdi-hand-coin-outline"
            },
            {
                "name": "hand-cycle",
                "icon": "mdi-hand-cycle"
            },
            {
                "name": "hand-extended",
                "icon": "mdi-hand-extended"
            },
            {
                "name": "hand-extended-outline",
                "icon": "mdi-hand-extended-outline"
            },
            {
                "name": "hand-front-left",
                "icon": "mdi-hand-front-left"
            },
            {
                "name": "hand-front-left-outline",
                "icon": "mdi-hand-front-left-outline"
            },
            {
                "name": "hand-front-right",
                "icon": "mdi-hand-front-right"
            },
            {
                "name": "hand-front-right-outline",
                "icon": "mdi-hand-front-right-outline"
            },
            {
                "name": "hand-heart",
                "icon": "mdi-hand-heart"
            },
            {
                "name": "hand-heart-outline",
                "icon": "mdi-hand-heart-outline"
            },
            {
                "name": "hand-okay",
                "icon": "mdi-hand-okay"
            },
            {
                "name": "hand-peace",
                "icon": "mdi-hand-peace"
            },
            {
                "name": "hand-peace-variant",
                "icon": "mdi-hand-peace-variant"
            },
            {
                "name": "hand-pointing-down",
                "icon": "mdi-hand-pointing-down"
            },
            {
                "name": "hand-pointing-left",
                "icon": "mdi-hand-pointing-left"
            },
            {
                "name": "hand-pointing-right",
                "icon": "mdi-hand-pointing-right"
            },
            {
                "name": "hand-pointing-up",
                "icon": "mdi-hand-pointing-up"
            },
            {
                "name": "hand-saw",
                "icon": "mdi-hand-saw"
            },
            {
                "name": "hand-wash",
                "icon": "mdi-hand-wash"
            },
            {
                "name": "hand-wash-outline",
                "icon": "mdi-hand-wash-outline"
            },
            {
                "name": "hand-water",
                "icon": "mdi-hand-water"
            },
            {
                "name": "hand-wave",
                "icon": "mdi-hand-wave"
            },
            {
                "name": "hand-wave-outline",
                "icon": "mdi-hand-wave-outline"
            },
            {
                "name": "handball",
                "icon": "mdi-handball"
            },
            {
                "name": "handcuffs",
                "icon": "mdi-handcuffs"
            },
            {
                "name": "hands-pray",
                "icon": "mdi-hands-pray"
            },
            {
                "name": "handshake",
                "icon": "mdi-handshake"
            },
            {
                "name": "handshake-outline",
                "icon": "mdi-handshake-outline"
            },
            {
                "name": "hanger",
                "icon": "mdi-hanger"
            },
            {
                "name": "hard-hat",
                "icon": "mdi-hard-hat"
            },
            {
                "name": "harddisk",
                "icon": "mdi-harddisk"
            },
            {
                "name": "harddisk-plus",
                "icon": "mdi-harddisk-plus"
            },
            {
                "name": "harddisk-remove",
                "icon": "mdi-harddisk-remove"
            },
            {
                "name": "hat-fedora",
                "icon": "mdi-hat-fedora"
            },
            {
                "name": "hazard-lights",
                "icon": "mdi-hazard-lights"
            },
            {
                "name": "hdmi-port",
                "icon": "mdi-hdmi-port"
            },
            {
                "name": "hdr",
                "icon": "mdi-hdr"
            },
            {
                "name": "hdr-off",
                "icon": "mdi-hdr-off"
            },
            {
                "name": "head",
                "icon": "mdi-head"
            },
            {
                "name": "head-alert",
                "icon": "mdi-head-alert"
            },
            {
                "name": "head-alert-outline",
                "icon": "mdi-head-alert-outline"
            },
            {
                "name": "head-check",
                "icon": "mdi-head-check"
            },
            {
                "name": "head-check-outline",
                "icon": "mdi-head-check-outline"
            },
            {
                "name": "head-cog",
                "icon": "mdi-head-cog"
            },
            {
                "name": "head-cog-outline",
                "icon": "mdi-head-cog-outline"
            },
            {
                "name": "head-dots-horizontal",
                "icon": "mdi-head-dots-horizontal"
            },
            {
                "name": "head-dots-horizontal-outline",
                "icon": "mdi-head-dots-horizontal-outline"
            },
            {
                "name": "head-flash",
                "icon": "mdi-head-flash"
            },
            {
                "name": "head-flash-outline",
                "icon": "mdi-head-flash-outline"
            },
            {
                "name": "head-heart",
                "icon": "mdi-head-heart"
            },
            {
                "name": "head-heart-outline",
                "icon": "mdi-head-heart-outline"
            },
            {
                "name": "head-lightbulb",
                "icon": "mdi-head-lightbulb"
            },
            {
                "name": "head-lightbulb-outline",
                "icon": "mdi-head-lightbulb-outline"
            },
            {
                "name": "head-minus",
                "icon": "mdi-head-minus"
            },
            {
                "name": "head-minus-outline",
                "icon": "mdi-head-minus-outline"
            },
            {
                "name": "head-outline",
                "icon": "mdi-head-outline"
            },
            {
                "name": "head-plus",
                "icon": "mdi-head-plus"
            },
            {
                "name": "head-plus-outline",
                "icon": "mdi-head-plus-outline"
            },
            {
                "name": "head-question",
                "icon": "mdi-head-question"
            },
            {
                "name": "head-question-outline",
                "icon": "mdi-head-question-outline"
            },
            {
                "name": "head-remove",
                "icon": "mdi-head-remove"
            },
            {
                "name": "head-remove-outline",
                "icon": "mdi-head-remove-outline"
            },
            {
                "name": "head-snowflake",
                "icon": "mdi-head-snowflake"
            },
            {
                "name": "head-snowflake-outline",
                "icon": "mdi-head-snowflake-outline"
            },
            {
                "name": "head-sync",
                "icon": "mdi-head-sync"
            },
            {
                "name": "head-sync-outline",
                "icon": "mdi-head-sync-outline"
            },
            {
                "name": "headphones",
                "icon": "mdi-headphones"
            },
            {
                "name": "headphones-bluetooth",
                "icon": "mdi-headphones-bluetooth"
            },
            {
                "name": "headphones-box",
                "icon": "mdi-headphones-box"
            },
            {
                "name": "headphones-off",
                "icon": "mdi-headphones-off"
            },
            {
                "name": "headphones-settings",
                "icon": "mdi-headphones-settings"
            },
            {
                "name": "headset",
                "icon": "mdi-headset"
            },
            {
                "name": "headset-dock",
                "icon": "mdi-headset-dock"
            },
            {
                "name": "headset-off",
                "icon": "mdi-headset-off"
            },
            {
                "name": "heart",
                "icon": "mdi-heart"
            },
            {
                "name": "heart-box",
                "icon": "mdi-heart-box"
            },
            {
                "name": "heart-box-outline",
                "icon": "mdi-heart-box-outline"
            },
            {
                "name": "heart-broken",
                "icon": "mdi-heart-broken"
            },
            {
                "name": "heart-broken-outline",
                "icon": "mdi-heart-broken-outline"
            },
            {
                "name": "heart-circle",
                "icon": "mdi-heart-circle"
            },
            {
                "name": "heart-circle-outline",
                "icon": "mdi-heart-circle-outline"
            },
            {
                "name": "heart-cog",
                "icon": "mdi-heart-cog"
            },
            {
                "name": "heart-cog-outline",
                "icon": "mdi-heart-cog-outline"
            },
            {
                "name": "heart-flash",
                "icon": "mdi-heart-flash"
            },
            {
                "name": "heart-half",
                "icon": "mdi-heart-half"
            },
            {
                "name": "heart-half-full",
                "icon": "mdi-heart-half-full"
            },
            {
                "name": "heart-half-outline",
                "icon": "mdi-heart-half-outline"
            },
            {
                "name": "heart-minus",
                "icon": "mdi-heart-minus"
            },
            {
                "name": "heart-minus-outline",
                "icon": "mdi-heart-minus-outline"
            },
            {
                "name": "heart-multiple",
                "icon": "mdi-heart-multiple"
            },
            {
                "name": "heart-multiple-outline",
                "icon": "mdi-heart-multiple-outline"
            },
            {
                "name": "heart-off",
                "icon": "mdi-heart-off"
            },
            {
                "name": "heart-off-outline",
                "icon": "mdi-heart-off-outline"
            },
            {
                "name": "heart-outline",
                "icon": "mdi-heart-outline"
            },
            {
                "name": "heart-plus",
                "icon": "mdi-heart-plus"
            },
            {
                "name": "heart-plus-outline",
                "icon": "mdi-heart-plus-outline"
            },
            {
                "name": "heart-pulse",
                "icon": "mdi-heart-pulse"
            },
            {
                "name": "heart-remove",
                "icon": "mdi-heart-remove"
            },
            {
                "name": "heart-remove-outline",
                "icon": "mdi-heart-remove-outline"
            },
            {
                "name": "heart-settings",
                "icon": "mdi-heart-settings"
            },
            {
                "name": "heart-settings-outline",
                "icon": "mdi-heart-settings-outline"
            },
            {
                "name": "heat-pump",
                "icon": "mdi-heat-pump"
            },
            {
                "name": "heat-pump-outline",
                "icon": "mdi-heat-pump-outline"
            },
            {
                "name": "heat-wave",
                "icon": "mdi-heat-wave"
            },
            {
                "name": "heating-coil",
                "icon": "mdi-heating-coil"
            },
            {
                "name": "helicopter",
                "icon": "mdi-helicopter"
            },
            {
                "name": "help",
                "icon": "mdi-help"
            },
            {
                "name": "help-box",
                "icon": "mdi-help-box"
            },
            {
                "name": "help-circle",
                "icon": "mdi-help-circle"
            },
            {
                "name": "help-circle-outline",
                "icon": "mdi-help-circle-outline"
            },
            {
                "name": "help-network",
                "icon": "mdi-help-network"
            },
            {
                "name": "help-network-outline",
                "icon": "mdi-help-network-outline"
            },
            {
                "name": "help-rhombus",
                "icon": "mdi-help-rhombus"
            },
            {
                "name": "help-rhombus-outline",
                "icon": "mdi-help-rhombus-outline"
            },
            {
                "name": "hexadecimal",
                "icon": "mdi-hexadecimal"
            },
            {
                "name": "hexagon",
                "icon": "mdi-hexagon"
            },
            {
                "name": "hexagon-multiple",
                "icon": "mdi-hexagon-multiple"
            },
            {
                "name": "hexagon-multiple-outline",
                "icon": "mdi-hexagon-multiple-outline"
            },
            {
                "name": "hexagon-outline",
                "icon": "mdi-hexagon-outline"
            },
            {
                "name": "hexagon-slice-1",
                "icon": "mdi-hexagon-slice-1"
            },
            {
                "name": "hexagon-slice-2",
                "icon": "mdi-hexagon-slice-2"
            },
            {
                "name": "hexagon-slice-3",
                "icon": "mdi-hexagon-slice-3"
            },
            {
                "name": "hexagon-slice-4",
                "icon": "mdi-hexagon-slice-4"
            },
            {
                "name": "hexagon-slice-5",
                "icon": "mdi-hexagon-slice-5"
            },
            {
                "name": "hexagon-slice-6",
                "icon": "mdi-hexagon-slice-6"
            },
            {
                "name": "hexagram",
                "icon": "mdi-hexagram"
            },
            {
                "name": "hexagram-outline",
                "icon": "mdi-hexagram-outline"
            },
            {
                "name": "high-definition",
                "icon": "mdi-high-definition"
            },
            {
                "name": "high-definition-box",
                "icon": "mdi-high-definition-box"
            },
            {
                "name": "highway",
                "icon": "mdi-highway"
            },
            {
                "name": "hiking",
                "icon": "mdi-hiking"
            },
            {
                "name": "history",
                "icon": "mdi-history"
            },
            {
                "name": "hockey-puck",
                "icon": "mdi-hockey-puck"
            },
            {
                "name": "hockey-sticks",
                "icon": "mdi-hockey-sticks"
            },
            {
                "name": "hololens",
                "icon": "mdi-hololens"
            },
            {
                "name": "home",
                "icon": "mdi-home"
            },
            {
                "name": "home-account",
                "icon": "mdi-home-account"
            },
            {
                "name": "home-alert",
                "icon": "mdi-home-alert"
            },
            {
                "name": "home-alert-outline",
                "icon": "mdi-home-alert-outline"
            },
            {
                "name": "home-analytics",
                "icon": "mdi-home-analytics"
            },
            {
                "name": "home-assistant",
                "icon": "mdi-home-assistant"
            },
            {
                "name": "home-automation",
                "icon": "mdi-home-automation"
            },
            {
                "name": "home-battery",
                "icon": "mdi-home-battery"
            },
            {
                "name": "home-battery-outline",
                "icon": "mdi-home-battery-outline"
            },
            {
                "name": "home-circle",
                "icon": "mdi-home-circle"
            },
            {
                "name": "home-circle-outline",
                "icon": "mdi-home-circle-outline"
            },
            {
                "name": "home-city",
                "icon": "mdi-home-city"
            },
            {
                "name": "home-city-outline",
                "icon": "mdi-home-city-outline"
            },
            {
                "name": "home-clock",
                "icon": "mdi-home-clock"
            },
            {
                "name": "home-clock-outline",
                "icon": "mdi-home-clock-outline"
            },
            {
                "name": "home-edit",
                "icon": "mdi-home-edit"
            },
            {
                "name": "home-edit-outline",
                "icon": "mdi-home-edit-outline"
            },
            {
                "name": "home-export-outline",
                "icon": "mdi-home-export-outline"
            },
            {
                "name": "home-flood",
                "icon": "mdi-home-flood"
            },
            {
                "name": "home-floor-0",
                "icon": "mdi-home-floor-0"
            },
            {
                "name": "home-floor-1",
                "icon": "mdi-home-floor-1"
            },
            {
                "name": "home-floor-2",
                "icon": "mdi-home-floor-2"
            },
            {
                "name": "home-floor-3",
                "icon": "mdi-home-floor-3"
            },
            {
                "name": "home-floor-a",
                "icon": "mdi-home-floor-a"
            },
            {
                "name": "home-floor-b",
                "icon": "mdi-home-floor-b"
            },
            {
                "name": "home-floor-g",
                "icon": "mdi-home-floor-g"
            },
            {
                "name": "home-floor-l",
                "icon": "mdi-home-floor-l"
            },
            {
                "name": "home-floor-negative-1",
                "icon": "mdi-home-floor-negative-1"
            },
            {
                "name": "home-group",
                "icon": "mdi-home-group"
            },
            {
                "name": "home-group-minus",
                "icon": "mdi-home-group-minus"
            },
            {
                "name": "home-group-plus",
                "icon": "mdi-home-group-plus"
            },
            {
                "name": "home-group-remove",
                "icon": "mdi-home-group-remove"
            },
            {
                "name": "home-heart",
                "icon": "mdi-home-heart"
            },
            {
                "name": "home-import-outline",
                "icon": "mdi-home-import-outline"
            },
            {
                "name": "home-lightbulb",
                "icon": "mdi-home-lightbulb"
            },
            {
                "name": "home-lightbulb-outline",
                "icon": "mdi-home-lightbulb-outline"
            },
            {
                "name": "home-lightning-bolt",
                "icon": "mdi-home-lightning-bolt"
            },
            {
                "name": "home-lightning-bolt-outline",
                "icon": "mdi-home-lightning-bolt-outline"
            },
            {
                "name": "home-lock",
                "icon": "mdi-home-lock"
            },
            {
                "name": "home-lock-open",
                "icon": "mdi-home-lock-open"
            },
            {
                "name": "home-map-marker",
                "icon": "mdi-home-map-marker"
            },
            {
                "name": "home-minus",
                "icon": "mdi-home-minus"
            },
            {
                "name": "home-minus-outline",
                "icon": "mdi-home-minus-outline"
            },
            {
                "name": "home-modern",
                "icon": "mdi-home-modern"
            },
            {
                "name": "home-off",
                "icon": "mdi-home-off"
            },
            {
                "name": "home-off-outline",
                "icon": "mdi-home-off-outline"
            },
            {
                "name": "home-outline",
                "icon": "mdi-home-outline"
            },
            {
                "name": "home-plus",
                "icon": "mdi-home-plus"
            },
            {
                "name": "home-plus-outline",
                "icon": "mdi-home-plus-outline"
            },
            {
                "name": "home-remove",
                "icon": "mdi-home-remove"
            },
            {
                "name": "home-remove-outline",
                "icon": "mdi-home-remove-outline"
            },
            {
                "name": "home-roof",
                "icon": "mdi-home-roof"
            },
            {
                "name": "home-search",
                "icon": "mdi-home-search"
            },
            {
                "name": "home-search-outline",
                "icon": "mdi-home-search-outline"
            },
            {
                "name": "home-silo",
                "icon": "mdi-home-silo"
            },
            {
                "name": "home-silo-outline",
                "icon": "mdi-home-silo-outline"
            },
            {
                "name": "home-switch",
                "icon": "mdi-home-switch"
            },
            {
                "name": "home-switch-outline",
                "icon": "mdi-home-switch-outline"
            },
            {
                "name": "home-thermometer",
                "icon": "mdi-home-thermometer"
            },
            {
                "name": "home-thermometer-outline",
                "icon": "mdi-home-thermometer-outline"
            },
            {
                "name": "home-variant",
                "icon": "mdi-home-variant"
            },
            {
                "name": "home-variant-outline",
                "icon": "mdi-home-variant-outline"
            },
            {
                "name": "hook",
                "icon": "mdi-hook"
            },
            {
                "name": "hook-off",
                "icon": "mdi-hook-off"
            },
            {
                "name": "hoop-house",
                "icon": "mdi-hoop-house"
            },
            {
                "name": "hops",
                "icon": "mdi-hops"
            },
            {
                "name": "horizontal-rotate-clockwise",
                "icon": "mdi-horizontal-rotate-clockwise"
            },
            {
                "name": "horizontal-rotate-counterclockwise",
                "icon": "mdi-horizontal-rotate-counterclockwise"
            },
            {
                "name": "horse",
                "icon": "mdi-horse"
            },
            {
                "name": "horse-human",
                "icon": "mdi-horse-human"
            },
            {
                "name": "horse-variant",
                "icon": "mdi-horse-variant"
            },
            {
                "name": "horse-variant-fast",
                "icon": "mdi-horse-variant-fast"
            },
            {
                "name": "horseshoe",
                "icon": "mdi-horseshoe"
            },
            {
                "name": "hospital",
                "icon": "mdi-hospital"
            },
            {
                "name": "hospital-box",
                "icon": "mdi-hospital-box"
            },
            {
                "name": "hospital-box-outline",
                "icon": "mdi-hospital-box-outline"
            },
            {
                "name": "hospital-building",
                "icon": "mdi-hospital-building"
            },
            {
                "name": "hospital-marker",
                "icon": "mdi-hospital-marker"
            },
            {
                "name": "hot-tub",
                "icon": "mdi-hot-tub"
            },
            {
                "name": "hours-24",
                "icon": "mdi-hours-24"
            },
            {
                "name": "hubspot",
                "icon": "mdi-hubspot"
            },
            {
                "name": "hulu",
                "icon": "mdi-hulu"
            },
            {
                "name": "human",
                "icon": "mdi-human"
            },
            {
                "name": "human-baby-changing-table",
                "icon": "mdi-human-baby-changing-table"
            },
            {
                "name": "human-cane",
                "icon": "mdi-human-cane"
            },
            {
                "name": "human-capacity-decrease",
                "icon": "mdi-human-capacity-decrease"
            },
            {
                "name": "human-capacity-increase",
                "icon": "mdi-human-capacity-increase"
            },
            {
                "name": "human-child",
                "icon": "mdi-human-child"
            },
            {
                "name": "human-dolly",
                "icon": "mdi-human-dolly"
            },
            {
                "name": "human-edit",
                "icon": "mdi-human-edit"
            },
            {
                "name": "human-female",
                "icon": "mdi-human-female"
            },
            {
                "name": "human-female-boy",
                "icon": "mdi-human-female-boy"
            },
            {
                "name": "human-female-dance",
                "icon": "mdi-human-female-dance"
            },
            {
                "name": "human-female-female",
                "icon": "mdi-human-female-female"
            },
            {
                "name": "human-female-girl",
                "icon": "mdi-human-female-girl"
            },
            {
                "name": "human-greeting",
                "icon": "mdi-human-greeting"
            },
            {
                "name": "human-greeting-proximity",
                "icon": "mdi-human-greeting-proximity"
            },
            {
                "name": "human-greeting-variant",
                "icon": "mdi-human-greeting-variant"
            },
            {
                "name": "human-handsdown",
                "icon": "mdi-human-handsdown"
            },
            {
                "name": "human-handsup",
                "icon": "mdi-human-handsup"
            },
            {
                "name": "human-male",
                "icon": "mdi-human-male"
            },
            {
                "name": "human-male-board",
                "icon": "mdi-human-male-board"
            },
            {
                "name": "human-male-board-poll",
                "icon": "mdi-human-male-board-poll"
            },
            {
                "name": "human-male-boy",
                "icon": "mdi-human-male-boy"
            },
            {
                "name": "human-male-child",
                "icon": "mdi-human-male-child"
            },
            {
                "name": "human-male-female",
                "icon": "mdi-human-male-female"
            },
            {
                "name": "human-male-female-child",
                "icon": "mdi-human-male-female-child"
            },
            {
                "name": "human-male-girl",
                "icon": "mdi-human-male-girl"
            },
            {
                "name": "human-male-height",
                "icon": "mdi-human-male-height"
            },
            {
                "name": "human-male-height-variant",
                "icon": "mdi-human-male-height-variant"
            },
            {
                "name": "human-male-male",
                "icon": "mdi-human-male-male"
            },
            {
                "name": "human-non-binary",
                "icon": "mdi-human-non-binary"
            },
            {
                "name": "human-pregnant",
                "icon": "mdi-human-pregnant"
            },
            {
                "name": "human-queue",
                "icon": "mdi-human-queue"
            },
            {
                "name": "human-scooter",
                "icon": "mdi-human-scooter"
            },
            {
                "name": "human-walker",
                "icon": "mdi-human-walker"
            },
            {
                "name": "human-wheelchair",
                "icon": "mdi-human-wheelchair"
            },
            {
                "name": "human-white-cane",
                "icon": "mdi-human-white-cane"
            },
            {
                "name": "humble-bundle",
                "icon": "mdi-humble-bundle"
            },
            {
                "name": "hvac",
                "icon": "mdi-hvac"
            },
            {
                "name": "hvac-off",
                "icon": "mdi-hvac-off"
            },
            {
                "name": "hydraulic-oil-level",
                "icon": "mdi-hydraulic-oil-level"
            },
            {
                "name": "hydraulic-oil-temperature",
                "icon": "mdi-hydraulic-oil-temperature"
            },
            {
                "name": "hydro-power",
                "icon": "mdi-hydro-power"
            },
            {
                "name": "hydrogen-station",
                "icon": "mdi-hydrogen-station"
            },
            {
                "name": "ice-cream",
                "icon": "mdi-ice-cream"
            },
            {
                "name": "ice-cream-off",
                "icon": "mdi-ice-cream-off"
            },
            {
                "name": "ice-pop",
                "icon": "mdi-ice-pop"
            },
            {
                "name": "id-card",
                "icon": "mdi-id-card"
            },
            {
                "name": "identifier",
                "icon": "mdi-identifier"
            },
            {
                "name": "ideogram-cjk",
                "icon": "mdi-ideogram-cjk"
            },
            {
                "name": "ideogram-cjk-variant",
                "icon": "mdi-ideogram-cjk-variant"
            },
            {
                "name": "image",
                "icon": "mdi-image"
            },
            {
                "name": "image-album",
                "icon": "mdi-image-album"
            },
            {
                "name": "image-area",
                "icon": "mdi-image-area"
            },
            {
                "name": "image-area-close",
                "icon": "mdi-image-area-close"
            },
            {
                "name": "image-auto-adjust",
                "icon": "mdi-image-auto-adjust"
            },
            {
                "name": "image-broken",
                "icon": "mdi-image-broken"
            },
            {
                "name": "image-broken-variant",
                "icon": "mdi-image-broken-variant"
            },
            {
                "name": "image-check",
                "icon": "mdi-image-check"
            },
            {
                "name": "image-check-outline",
                "icon": "mdi-image-check-outline"
            },
            {
                "name": "image-edit",
                "icon": "mdi-image-edit"
            },
            {
                "name": "image-edit-outline",
                "icon": "mdi-image-edit-outline"
            },
            {
                "name": "image-filter-black-white",
                "icon": "mdi-image-filter-black-white"
            },
            {
                "name": "image-filter-center-focus",
                "icon": "mdi-image-filter-center-focus"
            },
            {
                "name": "image-filter-center-focus-strong",
                "icon": "mdi-image-filter-center-focus-strong"
            },
            {
                "name": "image-filter-center-focus-strong-outline",
                "icon": "mdi-image-filter-center-focus-strong-outline"
            },
            {
                "name": "image-filter-center-focus-weak",
                "icon": "mdi-image-filter-center-focus-weak"
            },
            {
                "name": "image-filter-drama",
                "icon": "mdi-image-filter-drama"
            },
            {
                "name": "image-filter-frames",
                "icon": "mdi-image-filter-frames"
            },
            {
                "name": "image-filter-hdr",
                "icon": "mdi-image-filter-hdr"
            },
            {
                "name": "image-filter-none",
                "icon": "mdi-image-filter-none"
            },
            {
                "name": "image-filter-tilt-shift",
                "icon": "mdi-image-filter-tilt-shift"
            },
            {
                "name": "image-filter-vintage",
                "icon": "mdi-image-filter-vintage"
            },
            {
                "name": "image-frame",
                "icon": "mdi-image-frame"
            },
            {
                "name": "image-lock",
                "icon": "mdi-image-lock"
            },
            {
                "name": "image-lock-outline",
                "icon": "mdi-image-lock-outline"
            },
            {
                "name": "image-marker",
                "icon": "mdi-image-marker"
            },
            {
                "name": "image-marker-outline",
                "icon": "mdi-image-marker-outline"
            },
            {
                "name": "image-minus",
                "icon": "mdi-image-minus"
            },
            {
                "name": "image-minus-outline",
                "icon": "mdi-image-minus-outline"
            },
            {
                "name": "image-move",
                "icon": "mdi-image-move"
            },
            {
                "name": "image-multiple",
                "icon": "mdi-image-multiple"
            },
            {
                "name": "image-multiple-outline",
                "icon": "mdi-image-multiple-outline"
            },
            {
                "name": "image-off",
                "icon": "mdi-image-off"
            },
            {
                "name": "image-off-outline",
                "icon": "mdi-image-off-outline"
            },
            {
                "name": "image-outline",
                "icon": "mdi-image-outline"
            },
            {
                "name": "image-plus",
                "icon": "mdi-image-plus"
            },
            {
                "name": "image-plus-outline",
                "icon": "mdi-image-plus-outline"
            },
            {
                "name": "image-refresh",
                "icon": "mdi-image-refresh"
            },
            {
                "name": "image-refresh-outline",
                "icon": "mdi-image-refresh-outline"
            },
            {
                "name": "image-remove",
                "icon": "mdi-image-remove"
            },
            {
                "name": "image-remove-outline",
                "icon": "mdi-image-remove-outline"
            },
            {
                "name": "image-search",
                "icon": "mdi-image-search"
            },
            {
                "name": "image-search-outline",
                "icon": "mdi-image-search-outline"
            },
            {
                "name": "image-size-select-actual",
                "icon": "mdi-image-size-select-actual"
            },
            {
                "name": "image-size-select-large",
                "icon": "mdi-image-size-select-large"
            },
            {
                "name": "image-size-select-small",
                "icon": "mdi-image-size-select-small"
            },
            {
                "name": "image-sync",
                "icon": "mdi-image-sync"
            },
            {
                "name": "image-sync-outline",
                "icon": "mdi-image-sync-outline"
            },
            {
                "name": "image-text",
                "icon": "mdi-image-text"
            },
            {
                "name": "import",
                "icon": "mdi-import"
            },
            {
                "name": "inbox",
                "icon": "mdi-inbox"
            },
            {
                "name": "inbox-arrow-down",
                "icon": "mdi-inbox-arrow-down"
            },
            {
                "name": "inbox-arrow-down-outline",
                "icon": "mdi-inbox-arrow-down-outline"
            },
            {
                "name": "inbox-arrow-up",
                "icon": "mdi-inbox-arrow-up"
            },
            {
                "name": "inbox-arrow-up-outline",
                "icon": "mdi-inbox-arrow-up-outline"
            },
            {
                "name": "inbox-full",
                "icon": "mdi-inbox-full"
            },
            {
                "name": "inbox-full-outline",
                "icon": "mdi-inbox-full-outline"
            },
            {
                "name": "inbox-multiple",
                "icon": "mdi-inbox-multiple"
            },
            {
                "name": "inbox-multiple-outline",
                "icon": "mdi-inbox-multiple-outline"
            },
            {
                "name": "inbox-outline",
                "icon": "mdi-inbox-outline"
            },
            {
                "name": "inbox-remove",
                "icon": "mdi-inbox-remove"
            },
            {
                "name": "inbox-remove-outline",
                "icon": "mdi-inbox-remove-outline"
            },
            {
                "name": "incognito",
                "icon": "mdi-incognito"
            },
            {
                "name": "incognito-circle",
                "icon": "mdi-incognito-circle"
            },
            {
                "name": "incognito-circle-off",
                "icon": "mdi-incognito-circle-off"
            },
            {
                "name": "incognito-off",
                "icon": "mdi-incognito-off"
            },
            {
                "name": "induction",
                "icon": "mdi-induction"
            },
            {
                "name": "infinity",
                "icon": "mdi-infinity"
            },
            {
                "name": "information",
                "icon": "mdi-information"
            },
            {
                "name": "information-off",
                "icon": "mdi-information-off"
            },
            {
                "name": "information-off-outline",
                "icon": "mdi-information-off-outline"
            },
            {
                "name": "information-outline",
                "icon": "mdi-information-outline"
            },
            {
                "name": "information-variant",
                "icon": "mdi-information-variant"
            },
            {
                "name": "instagram",
                "icon": "mdi-instagram"
            },
            {
                "name": "instrument-triangle",
                "icon": "mdi-instrument-triangle"
            },
            {
                "name": "integrated-circuit-chip",
                "icon": "mdi-integrated-circuit-chip"
            },
            {
                "name": "invert-colors",
                "icon": "mdi-invert-colors"
            },
            {
                "name": "invert-colors-off",
                "icon": "mdi-invert-colors-off"
            },
            {
                "name": "iobroker",
                "icon": "mdi-iobroker"
            },
            {
                "name": "ip",
                "icon": "mdi-ip"
            },
            {
                "name": "ip-network",
                "icon": "mdi-ip-network"
            },
            {
                "name": "ip-network-outline",
                "icon": "mdi-ip-network-outline"
            },
            {
                "name": "ip-outline",
                "icon": "mdi-ip-outline"
            },
            {
                "name": "ipod",
                "icon": "mdi-ipod"
            },
            {
                "name": "iron",
                "icon": "mdi-iron"
            },
            {
                "name": "iron-board",
                "icon": "mdi-iron-board"
            },
            {
                "name": "iron-outline",
                "icon": "mdi-iron-outline"
            },
            {
                "name": "island",
                "icon": "mdi-island"
            },
            {
                "name": "iv-bag",
                "icon": "mdi-iv-bag"
            },
            {
                "name": "jabber",
                "icon": "mdi-jabber"
            },
            {
                "name": "jeepney",
                "icon": "mdi-jeepney"
            },
            {
                "name": "jellyfish",
                "icon": "mdi-jellyfish"
            },
            {
                "name": "jellyfish-outline",
                "icon": "mdi-jellyfish-outline"
            },
            {
                "name": "jira",
                "icon": "mdi-jira"
            },
            {
                "name": "jquery",
                "icon": "mdi-jquery"
            },
            {
                "name": "jsfiddle",
                "icon": "mdi-jsfiddle"
            },
            {
                "name": "jump-rope",
                "icon": "mdi-jump-rope"
            },
            {
                "name": "kabaddi",
                "icon": "mdi-kabaddi"
            },
            {
                "name": "kangaroo",
                "icon": "mdi-kangaroo"
            },
            {
                "name": "karate",
                "icon": "mdi-karate"
            },
            {
                "name": "kayaking",
                "icon": "mdi-kayaking"
            },
            {
                "name": "keg",
                "icon": "mdi-keg"
            },
            {
                "name": "kettle",
                "icon": "mdi-kettle"
            },
            {
                "name": "kettle-alert",
                "icon": "mdi-kettle-alert"
            },
            {
                "name": "kettle-alert-outline",
                "icon": "mdi-kettle-alert-outline"
            },
            {
                "name": "kettle-off",
                "icon": "mdi-kettle-off"
            },
            {
                "name": "kettle-off-outline",
                "icon": "mdi-kettle-off-outline"
            },
            {
                "name": "kettle-outline",
                "icon": "mdi-kettle-outline"
            },
            {
                "name": "kettle-pour-over",
                "icon": "mdi-kettle-pour-over"
            },
            {
                "name": "kettle-steam",
                "icon": "mdi-kettle-steam"
            },
            {
                "name": "kettle-steam-outline",
                "icon": "mdi-kettle-steam-outline"
            },
            {
                "name": "kettlebell",
                "icon": "mdi-kettlebell"
            },
            {
                "name": "key",
                "icon": "mdi-key"
            },
            {
                "name": "key-alert",
                "icon": "mdi-key-alert"
            },
            {
                "name": "key-alert-outline",
                "icon": "mdi-key-alert-outline"
            },
            {
                "name": "key-arrow-right",
                "icon": "mdi-key-arrow-right"
            },
            {
                "name": "key-chain",
                "icon": "mdi-key-chain"
            },
            {
                "name": "key-chain-variant",
                "icon": "mdi-key-chain-variant"
            },
            {
                "name": "key-change",
                "icon": "mdi-key-change"
            },
            {
                "name": "key-link",
                "icon": "mdi-key-link"
            },
            {
                "name": "key-minus",
                "icon": "mdi-key-minus"
            },
            {
                "name": "key-outline",
                "icon": "mdi-key-outline"
            },
            {
                "name": "key-plus",
                "icon": "mdi-key-plus"
            },
            {
                "name": "key-remove",
                "icon": "mdi-key-remove"
            },
            {
                "name": "key-star",
                "icon": "mdi-key-star"
            },
            {
                "name": "key-variant",
                "icon": "mdi-key-variant"
            },
            {
                "name": "key-wireless",
                "icon": "mdi-key-wireless"
            },
            {
                "name": "keyboard",
                "icon": "mdi-keyboard"
            },
            {
                "name": "keyboard-backspace",
                "icon": "mdi-keyboard-backspace"
            },
            {
                "name": "keyboard-caps",
                "icon": "mdi-keyboard-caps"
            },
            {
                "name": "keyboard-close",
                "icon": "mdi-keyboard-close"
            },
            {
                "name": "keyboard-esc",
                "icon": "mdi-keyboard-esc"
            },
            {
                "name": "keyboard-f1",
                "icon": "mdi-keyboard-f1"
            },
            {
                "name": "keyboard-f10",
                "icon": "mdi-keyboard-f10"
            },
            {
                "name": "keyboard-f11",
                "icon": "mdi-keyboard-f11"
            },
            {
                "name": "keyboard-f12",
                "icon": "mdi-keyboard-f12"
            },
            {
                "name": "keyboard-f2",
                "icon": "mdi-keyboard-f2"
            },
            {
                "name": "keyboard-f3",
                "icon": "mdi-keyboard-f3"
            },
            {
                "name": "keyboard-f4",
                "icon": "mdi-keyboard-f4"
            },
            {
                "name": "keyboard-f5",
                "icon": "mdi-keyboard-f5"
            },
            {
                "name": "keyboard-f6",
                "icon": "mdi-keyboard-f6"
            },
            {
                "name": "keyboard-f7",
                "icon": "mdi-keyboard-f7"
            },
            {
                "name": "keyboard-f8",
                "icon": "mdi-keyboard-f8"
            },
            {
                "name": "keyboard-f9",
                "icon": "mdi-keyboard-f9"
            },
            {
                "name": "keyboard-off",
                "icon": "mdi-keyboard-off"
            },
            {
                "name": "keyboard-off-outline",
                "icon": "mdi-keyboard-off-outline"
            },
            {
                "name": "keyboard-outline",
                "icon": "mdi-keyboard-outline"
            },
            {
                "name": "keyboard-return",
                "icon": "mdi-keyboard-return"
            },
            {
                "name": "keyboard-settings",
                "icon": "mdi-keyboard-settings"
            },
            {
                "name": "keyboard-settings-outline",
                "icon": "mdi-keyboard-settings-outline"
            },
            {
                "name": "keyboard-space",
                "icon": "mdi-keyboard-space"
            },
            {
                "name": "keyboard-tab",
                "icon": "mdi-keyboard-tab"
            },
            {
                "name": "keyboard-tab-reverse",
                "icon": "mdi-keyboard-tab-reverse"
            },
            {
                "name": "keyboard-variant",
                "icon": "mdi-keyboard-variant"
            },
            {
                "name": "khanda",
                "icon": "mdi-khanda"
            },
            {
                "name": "kickstarter",
                "icon": "mdi-kickstarter"
            },
            {
                "name": "kite",
                "icon": "mdi-kite"
            },
            {
                "name": "kite-outline",
                "icon": "mdi-kite-outline"
            },
            {
                "name": "kitesurfing",
                "icon": "mdi-kitesurfing"
            },
            {
                "name": "klingon",
                "icon": "mdi-klingon"
            },
            {
                "name": "knife",
                "icon": "mdi-knife"
            },
            {
                "name": "knife-military",
                "icon": "mdi-knife-military"
            },
            {
                "name": "knob",
                "icon": "mdi-knob"
            },
            {
                "name": "koala",
                "icon": "mdi-koala"
            },
            {
                "name": "kodi",
                "icon": "mdi-kodi"
            },
            {
                "name": "kubernetes",
                "icon": "mdi-kubernetes"
            },
            {
                "name": "label",
                "icon": "mdi-label"
            },
            {
                "name": "label-multiple",
                "icon": "mdi-label-multiple"
            },
            {
                "name": "label-multiple-outline",
                "icon": "mdi-label-multiple-outline"
            },
            {
                "name": "label-off",
                "icon": "mdi-label-off"
            },
            {
                "name": "label-off-outline",
                "icon": "mdi-label-off-outline"
            },
            {
                "name": "label-outline",
                "icon": "mdi-label-outline"
            },
            {
                "name": "label-percent",
                "icon": "mdi-label-percent"
            },
            {
                "name": "label-percent-outline",
                "icon": "mdi-label-percent-outline"
            },
            {
                "name": "label-variant",
                "icon": "mdi-label-variant"
            },
            {
                "name": "label-variant-outline",
                "icon": "mdi-label-variant-outline"
            },
            {
                "name": "ladder",
                "icon": "mdi-ladder"
            },
            {
                "name": "ladybug",
                "icon": "mdi-ladybug"
            },
            {
                "name": "lambda",
                "icon": "mdi-lambda"
            },
            {
                "name": "lamp",
                "icon": "mdi-lamp"
            },
            {
                "name": "lamp-outline",
                "icon": "mdi-lamp-outline"
            },
            {
                "name": "lamps",
                "icon": "mdi-lamps"
            },
            {
                "name": "lamps-outline",
                "icon": "mdi-lamps-outline"
            },
            {
                "name": "lan",
                "icon": "mdi-lan"
            },
            {
                "name": "lan-check",
                "icon": "mdi-lan-check"
            },
            {
                "name": "lan-connect",
                "icon": "mdi-lan-connect"
            },
            {
                "name": "lan-disconnect",
                "icon": "mdi-lan-disconnect"
            },
            {
                "name": "lan-pending",
                "icon": "mdi-lan-pending"
            },
            {
                "name": "land-fields",
                "icon": "mdi-land-fields"
            },
            {
                "name": "land-plots",
                "icon": "mdi-land-plots"
            },
            {
                "name": "land-plots-circle",
                "icon": "mdi-land-plots-circle"
            },
            {
                "name": "land-plots-circle-variant",
                "icon": "mdi-land-plots-circle-variant"
            },
            {
                "name": "land-rows-horizontal",
                "icon": "mdi-land-rows-horizontal"
            },
            {
                "name": "land-rows-vertical",
                "icon": "mdi-land-rows-vertical"
            },
            {
                "name": "landslide",
                "icon": "mdi-landslide"
            },
            {
                "name": "landslide-outline",
                "icon": "mdi-landslide-outline"
            },
            {
                "name": "language-c",
                "icon": "mdi-language-c"
            },
            {
                "name": "language-cpp",
                "icon": "mdi-language-cpp"
            },
            {
                "name": "language-csharp",
                "icon": "mdi-language-csharp"
            },
            {
                "name": "language-css3",
                "icon": "mdi-language-css3"
            },
            {
                "name": "language-fortran",
                "icon": "mdi-language-fortran"
            },
            {
                "name": "language-go",
                "icon": "mdi-language-go"
            },
            {
                "name": "language-haskell",
                "icon": "mdi-language-haskell"
            },
            {
                "name": "language-html5",
                "icon": "mdi-language-html5"
            },
            {
                "name": "language-java",
                "icon": "mdi-language-java"
            },
            {
                "name": "language-javascript",
                "icon": "mdi-language-javascript"
            },
            {
                "name": "language-kotlin",
                "icon": "mdi-language-kotlin"
            },
            {
                "name": "language-lua",
                "icon": "mdi-language-lua"
            },
            {
                "name": "language-markdown",
                "icon": "mdi-language-markdown"
            },
            {
                "name": "language-markdown-outline",
                "icon": "mdi-language-markdown-outline"
            },
            {
                "name": "language-php",
                "icon": "mdi-language-php"
            },
            {
                "name": "language-python",
                "icon": "mdi-language-python"
            },
            {
                "name": "language-r",
                "icon": "mdi-language-r"
            },
            {
                "name": "language-ruby",
                "icon": "mdi-language-ruby"
            },
            {
                "name": "language-ruby-on-rails",
                "icon": "mdi-language-ruby-on-rails"
            },
            {
                "name": "language-rust",
                "icon": "mdi-language-rust"
            },
            {
                "name": "language-swift",
                "icon": "mdi-language-swift"
            },
            {
                "name": "language-typescript",
                "icon": "mdi-language-typescript"
            },
            {
                "name": "language-xaml",
                "icon": "mdi-language-xaml"
            },
            {
                "name": "laptop",
                "icon": "mdi-laptop"
            },
            {
                "name": "laptop-account",
                "icon": "mdi-laptop-account"
            },
            {
                "name": "laptop-off",
                "icon": "mdi-laptop-off"
            },
            {
                "name": "laravel",
                "icon": "mdi-laravel"
            },
            {
                "name": "laser-pointer",
                "icon": "mdi-laser-pointer"
            },
            {
                "name": "lasso",
                "icon": "mdi-lasso"
            },
            {
                "name": "lastpass",
                "icon": "mdi-lastpass"
            },
            {
                "name": "latitude",
                "icon": "mdi-latitude"
            },
            {
                "name": "launch",
                "icon": "mdi-launch"
            },
            {
                "name": "lava-lamp",
                "icon": "mdi-lava-lamp"
            },
            {
                "name": "layers",
                "icon": "mdi-layers"
            },
            {
                "name": "layers-edit",
                "icon": "mdi-layers-edit"
            },
            {
                "name": "layers-minus",
                "icon": "mdi-layers-minus"
            },
            {
                "name": "layers-off",
                "icon": "mdi-layers-off"
            },
            {
                "name": "layers-off-outline",
                "icon": "mdi-layers-off-outline"
            },
            {
                "name": "layers-outline",
                "icon": "mdi-layers-outline"
            },
            {
                "name": "layers-plus",
                "icon": "mdi-layers-plus"
            },
            {
                "name": "layers-remove",
                "icon": "mdi-layers-remove"
            },
            {
                "name": "layers-search",
                "icon": "mdi-layers-search"
            },
            {
                "name": "layers-search-outline",
                "icon": "mdi-layers-search-outline"
            },
            {
                "name": "layers-triple",
                "icon": "mdi-layers-triple"
            },
            {
                "name": "layers-triple-outline",
                "icon": "mdi-layers-triple-outline"
            },
            {
                "name": "lead-pencil",
                "icon": "mdi-lead-pencil"
            },
            {
                "name": "leaf",
                "icon": "mdi-leaf"
            },
            {
                "name": "leaf-circle",
                "icon": "mdi-leaf-circle"
            },
            {
                "name": "leaf-circle-outline",
                "icon": "mdi-leaf-circle-outline"
            },
            {
                "name": "leaf-maple",
                "icon": "mdi-leaf-maple"
            },
            {
                "name": "leaf-maple-off",
                "icon": "mdi-leaf-maple-off"
            },
            {
                "name": "leaf-off",
                "icon": "mdi-leaf-off"
            },
            {
                "name": "leak",
                "icon": "mdi-leak"
            },
            {
                "name": "leak-off",
                "icon": "mdi-leak-off"
            },
            {
                "name": "lectern",
                "icon": "mdi-lectern"
            },
            {
                "name": "led-off",
                "icon": "mdi-led-off"
            },
            {
                "name": "led-on",
                "icon": "mdi-led-on"
            },
            {
                "name": "led-outline",
                "icon": "mdi-led-outline"
            },
            {
                "name": "led-strip",
                "icon": "mdi-led-strip"
            },
            {
                "name": "led-strip-variant",
                "icon": "mdi-led-strip-variant"
            },
            {
                "name": "led-strip-variant-off",
                "icon": "mdi-led-strip-variant-off"
            },
            {
                "name": "led-variant-off",
                "icon": "mdi-led-variant-off"
            },
            {
                "name": "led-variant-on",
                "icon": "mdi-led-variant-on"
            },
            {
                "name": "led-variant-outline",
                "icon": "mdi-led-variant-outline"
            },
            {
                "name": "leek",
                "icon": "mdi-leek"
            },
            {
                "name": "less-than",
                "icon": "mdi-less-than"
            },
            {
                "name": "less-than-or-equal",
                "icon": "mdi-less-than-or-equal"
            },
            {
                "name": "library",
                "icon": "mdi-library"
            },
            {
                "name": "library-outline",
                "icon": "mdi-library-outline"
            },
            {
                "name": "library-shelves",
                "icon": "mdi-library-shelves"
            },
            {
                "name": "license",
                "icon": "mdi-license"
            },
            {
                "name": "lifebuoy",
                "icon": "mdi-lifebuoy"
            },
            {
                "name": "light-flood-down",
                "icon": "mdi-light-flood-down"
            },
            {
                "name": "light-flood-up",
                "icon": "mdi-light-flood-up"
            },
            {
                "name": "light-recessed",
                "icon": "mdi-light-recessed"
            },
            {
                "name": "light-switch",
                "icon": "mdi-light-switch"
            },
            {
                "name": "light-switch-off",
                "icon": "mdi-light-switch-off"
            },
            {
                "name": "lightbulb",
                "icon": "mdi-lightbulb"
            },
            {
                "name": "lightbulb-alert",
                "icon": "mdi-lightbulb-alert"
            },
            {
                "name": "lightbulb-alert-outline",
                "icon": "mdi-lightbulb-alert-outline"
            },
            {
                "name": "lightbulb-auto",
                "icon": "mdi-lightbulb-auto"
            },
            {
                "name": "lightbulb-auto-outline",
                "icon": "mdi-lightbulb-auto-outline"
            },
            {
                "name": "lightbulb-cfl",
                "icon": "mdi-lightbulb-cfl"
            },
            {
                "name": "lightbulb-cfl-off",
                "icon": "mdi-lightbulb-cfl-off"
            },
            {
                "name": "lightbulb-cfl-spiral",
                "icon": "mdi-lightbulb-cfl-spiral"
            },
            {
                "name": "lightbulb-cfl-spiral-off",
                "icon": "mdi-lightbulb-cfl-spiral-off"
            },
            {
                "name": "lightbulb-fluorescent-tube",
                "icon": "mdi-lightbulb-fluorescent-tube"
            },
            {
                "name": "lightbulb-fluorescent-tube-outline",
                "icon": "mdi-lightbulb-fluorescent-tube-outline"
            },
            {
                "name": "lightbulb-group",
                "icon": "mdi-lightbulb-group"
            },
            {
                "name": "lightbulb-group-off",
                "icon": "mdi-lightbulb-group-off"
            },
            {
                "name": "lightbulb-group-off-outline",
                "icon": "mdi-lightbulb-group-off-outline"
            },
            {
                "name": "lightbulb-group-outline",
                "icon": "mdi-lightbulb-group-outline"
            },
            {
                "name": "lightbulb-multiple",
                "icon": "mdi-lightbulb-multiple"
            },
            {
                "name": "lightbulb-multiple-off",
                "icon": "mdi-lightbulb-multiple-off"
            },
            {
                "name": "lightbulb-multiple-off-outline",
                "icon": "mdi-lightbulb-multiple-off-outline"
            },
            {
                "name": "lightbulb-multiple-outline",
                "icon": "mdi-lightbulb-multiple-outline"
            },
            {
                "name": "lightbulb-night",
                "icon": "mdi-lightbulb-night"
            },
            {
                "name": "lightbulb-night-outline",
                "icon": "mdi-lightbulb-night-outline"
            },
            {
                "name": "lightbulb-off",
                "icon": "mdi-lightbulb-off"
            },
            {
                "name": "lightbulb-off-outline",
                "icon": "mdi-lightbulb-off-outline"
            },
            {
                "name": "lightbulb-on",
                "icon": "mdi-lightbulb-on"
            },
            {
                "name": "lightbulb-on-10",
                "icon": "mdi-lightbulb-on-10"
            },
            {
                "name": "lightbulb-on-20",
                "icon": "mdi-lightbulb-on-20"
            },
            {
                "name": "lightbulb-on-30",
                "icon": "mdi-lightbulb-on-30"
            },
            {
                "name": "lightbulb-on-40",
                "icon": "mdi-lightbulb-on-40"
            },
            {
                "name": "lightbulb-on-50",
                "icon": "mdi-lightbulb-on-50"
            },
            {
                "name": "lightbulb-on-60",
                "icon": "mdi-lightbulb-on-60"
            },
            {
                "name": "lightbulb-on-70",
                "icon": "mdi-lightbulb-on-70"
            },
            {
                "name": "lightbulb-on-80",
                "icon": "mdi-lightbulb-on-80"
            },
            {
                "name": "lightbulb-on-90",
                "icon": "mdi-lightbulb-on-90"
            },
            {
                "name": "lightbulb-on-outline",
                "icon": "mdi-lightbulb-on-outline"
            },
            {
                "name": "lightbulb-outline",
                "icon": "mdi-lightbulb-outline"
            },
            {
                "name": "lightbulb-question",
                "icon": "mdi-lightbulb-question"
            },
            {
                "name": "lightbulb-question-outline",
                "icon": "mdi-lightbulb-question-outline"
            },
            {
                "name": "lightbulb-spot",
                "icon": "mdi-lightbulb-spot"
            },
            {
                "name": "lightbulb-spot-off",
                "icon": "mdi-lightbulb-spot-off"
            },
            {
                "name": "lightbulb-variant",
                "icon": "mdi-lightbulb-variant"
            },
            {
                "name": "lightbulb-variant-outline",
                "icon": "mdi-lightbulb-variant-outline"
            },
            {
                "name": "lighthouse",
                "icon": "mdi-lighthouse"
            },
            {
                "name": "lighthouse-on",
                "icon": "mdi-lighthouse-on"
            },
            {
                "name": "lightning-bolt",
                "icon": "mdi-lightning-bolt"
            },
            {
                "name": "lightning-bolt-circle",
                "icon": "mdi-lightning-bolt-circle"
            },
            {
                "name": "lightning-bolt-outline",
                "icon": "mdi-lightning-bolt-outline"
            },
            {
                "name": "line-scan",
                "icon": "mdi-line-scan"
            },
            {
                "name": "lingerie",
                "icon": "mdi-lingerie"
            },
            {
                "name": "link",
                "icon": "mdi-link"
            },
            {
                "name": "link-box",
                "icon": "mdi-link-box"
            },
            {
                "name": "link-box-outline",
                "icon": "mdi-link-box-outline"
            },
            {
                "name": "link-box-variant",
                "icon": "mdi-link-box-variant"
            },
            {
                "name": "link-box-variant-outline",
                "icon": "mdi-link-box-variant-outline"
            },
            {
                "name": "link-lock",
                "icon": "mdi-link-lock"
            },
            {
                "name": "link-off",
                "icon": "mdi-link-off"
            },
            {
                "name": "link-plus",
                "icon": "mdi-link-plus"
            },
            {
                "name": "link-variant",
                "icon": "mdi-link-variant"
            },
            {
                "name": "link-variant-minus",
                "icon": "mdi-link-variant-minus"
            },
            {
                "name": "link-variant-off",
                "icon": "mdi-link-variant-off"
            },
            {
                "name": "link-variant-plus",
                "icon": "mdi-link-variant-plus"
            },
            {
                "name": "link-variant-remove",
                "icon": "mdi-link-variant-remove"
            },
            {
                "name": "linkedin",
                "icon": "mdi-linkedin"
            },
            {
                "name": "linux",
                "icon": "mdi-linux"
            },
            {
                "name": "linux-mint",
                "icon": "mdi-linux-mint"
            },
            {
                "name": "lipstick",
                "icon": "mdi-lipstick"
            },
            {
                "name": "liquid-spot",
                "icon": "mdi-liquid-spot"
            },
            {
                "name": "liquor",
                "icon": "mdi-liquor"
            },
            {
                "name": "list-box",
                "icon": "mdi-list-box"
            },
            {
                "name": "list-box-outline",
                "icon": "mdi-list-box-outline"
            },
            {
                "name": "list-status",
                "icon": "mdi-list-status"
            },
            {
                "name": "litecoin",
                "icon": "mdi-litecoin"
            },
            {
                "name": "loading",
                "icon": "mdi-loading"
            },
            {
                "name": "location-enter",
                "icon": "mdi-location-enter"
            },
            {
                "name": "location-exit",
                "icon": "mdi-location-exit"
            },
            {
                "name": "lock",
                "icon": "mdi-lock"
            },
            {
                "name": "lock-alert",
                "icon": "mdi-lock-alert"
            },
            {
                "name": "lock-alert-outline",
                "icon": "mdi-lock-alert-outline"
            },
            {
                "name": "lock-check",
                "icon": "mdi-lock-check"
            },
            {
                "name": "lock-check-outline",
                "icon": "mdi-lock-check-outline"
            },
            {
                "name": "lock-clock",
                "icon": "mdi-lock-clock"
            },
            {
                "name": "lock-minus",
                "icon": "mdi-lock-minus"
            },
            {
                "name": "lock-minus-outline",
                "icon": "mdi-lock-minus-outline"
            },
            {
                "name": "lock-off",
                "icon": "mdi-lock-off"
            },
            {
                "name": "lock-off-outline",
                "icon": "mdi-lock-off-outline"
            },
            {
                "name": "lock-open",
                "icon": "mdi-lock-open"
            },
            {
                "name": "lock-open-alert",
                "icon": "mdi-lock-open-alert"
            },
            {
                "name": "lock-open-alert-outline",
                "icon": "mdi-lock-open-alert-outline"
            },
            {
                "name": "lock-open-check",
                "icon": "mdi-lock-open-check"
            },
            {
                "name": "lock-open-check-outline",
                "icon": "mdi-lock-open-check-outline"
            },
            {
                "name": "lock-open-minus",
                "icon": "mdi-lock-open-minus"
            },
            {
                "name": "lock-open-minus-outline",
                "icon": "mdi-lock-open-minus-outline"
            },
            {
                "name": "lock-open-outline",
                "icon": "mdi-lock-open-outline"
            },
            {
                "name": "lock-open-plus",
                "icon": "mdi-lock-open-plus"
            },
            {
                "name": "lock-open-plus-outline",
                "icon": "mdi-lock-open-plus-outline"
            },
            {
                "name": "lock-open-remove",
                "icon": "mdi-lock-open-remove"
            },
            {
                "name": "lock-open-remove-outline",
                "icon": "mdi-lock-open-remove-outline"
            },
            {
                "name": "lock-open-variant",
                "icon": "mdi-lock-open-variant"
            },
            {
                "name": "lock-open-variant-outline",
                "icon": "mdi-lock-open-variant-outline"
            },
            {
                "name": "lock-outline",
                "icon": "mdi-lock-outline"
            },
            {
                "name": "lock-pattern",
                "icon": "mdi-lock-pattern"
            },
            {
                "name": "lock-plus",
                "icon": "mdi-lock-plus"
            },
            {
                "name": "lock-plus-outline",
                "icon": "mdi-lock-plus-outline"
            },
            {
                "name": "lock-question",
                "icon": "mdi-lock-question"
            },
            {
                "name": "lock-remove",
                "icon": "mdi-lock-remove"
            },
            {
                "name": "lock-remove-outline",
                "icon": "mdi-lock-remove-outline"
            },
            {
                "name": "format-line-spacing",
                "icon": "mdi-format-line-spacing"
            },
            {
                "name": "lock-reset",
                "icon": "mdi-lock-reset"
            },
            {
                "name": "lock-smart",
                "icon": "mdi-lock-smart"
            },
            {
                "name": "locker",
                "icon": "mdi-locker"
            },
            {
                "name": "locker-multiple",
                "icon": "mdi-locker-multiple"
            },
            {
                "name": "login",
                "icon": "mdi-login"
            },
            {
                "name": "login-variant",
                "icon": "mdi-login-variant"
            },
            {
                "name": "logout",
                "icon": "mdi-logout"
            },
            {
                "name": "logout-variant",
                "icon": "mdi-logout-variant"
            },
            {
                "name": "longitude",
                "icon": "mdi-longitude"
            },
            {
                "name": "looks",
                "icon": "mdi-looks"
            },
            {
                "name": "lotion",
                "icon": "mdi-lotion"
            },
            {
                "name": "lotion-outline",
                "icon": "mdi-lotion-outline"
            },
            {
                "name": "lotion-plus",
                "icon": "mdi-lotion-plus"
            },
            {
                "name": "lotion-plus-outline",
                "icon": "mdi-lotion-plus-outline"
            },
            {
                "name": "loupe",
                "icon": "mdi-loupe"
            },
            {
                "name": "lumx",
                "icon": "mdi-lumx"
            },
            {
                "name": "lungs",
                "icon": "mdi-lungs"
            },
            {
                "name": "mace",
                "icon": "mdi-mace"
            },
            {
                "name": "magazine-pistol",
                "icon": "mdi-magazine-pistol"
            },
            {
                "name": "magazine-rifle",
                "icon": "mdi-magazine-rifle"
            },
            {
                "name": "magic-staff",
                "icon": "mdi-magic-staff"
            },
            {
                "name": "magnet",
                "icon": "mdi-magnet"
            },
            {
                "name": "magnet-on",
                "icon": "mdi-magnet-on"
            },
            {
                "name": "magnify",
                "icon": "mdi-magnify"
            },
            {
                "name": "magnify-close",
                "icon": "mdi-magnify-close"
            },
            {
                "name": "magnify-expand",
                "icon": "mdi-magnify-expand"
            },
            {
                "name": "magnify-minus",
                "icon": "mdi-magnify-minus"
            },
            {
                "name": "magnify-minus-cursor",
                "icon": "mdi-magnify-minus-cursor"
            },
            {
                "name": "magnify-minus-outline",
                "icon": "mdi-magnify-minus-outline"
            },
            {
                "name": "magnify-plus",
                "icon": "mdi-magnify-plus"
            },
            {
                "name": "magnify-plus-cursor",
                "icon": "mdi-magnify-plus-cursor"
            },
            {
                "name": "magnify-plus-outline",
                "icon": "mdi-magnify-plus-outline"
            },
            {
                "name": "magnify-remove-cursor",
                "icon": "mdi-magnify-remove-cursor"
            },
            {
                "name": "magnify-remove-outline",
                "icon": "mdi-magnify-remove-outline"
            },
            {
                "name": "magnify-scan",
                "icon": "mdi-magnify-scan"
            },
            {
                "name": "mail",
                "icon": "mdi-mail"
            },
            {
                "name": "mailbox",
                "icon": "mdi-mailbox"
            },
            {
                "name": "mailbox-open",
                "icon": "mdi-mailbox-open"
            },
            {
                "name": "mailbox-open-outline",
                "icon": "mdi-mailbox-open-outline"
            },
            {
                "name": "mailbox-open-up",
                "icon": "mdi-mailbox-open-up"
            },
            {
                "name": "mailbox-open-up-outline",
                "icon": "mdi-mailbox-open-up-outline"
            },
            {
                "name": "mailbox-outline",
                "icon": "mdi-mailbox-outline"
            },
            {
                "name": "mailbox-up",
                "icon": "mdi-mailbox-up"
            },
            {
                "name": "mailbox-up-outline",
                "icon": "mdi-mailbox-up-outline"
            },
            {
                "name": "manjaro",
                "icon": "mdi-manjaro"
            },
            {
                "name": "map",
                "icon": "mdi-map"
            },
            {
                "name": "map-check",
                "icon": "mdi-map-check"
            },
            {
                "name": "map-check-outline",
                "icon": "mdi-map-check-outline"
            },
            {
                "name": "map-clock",
                "icon": "mdi-map-clock"
            },
            {
                "name": "map-clock-outline",
                "icon": "mdi-map-clock-outline"
            },
            {
                "name": "map-legend",
                "icon": "mdi-map-legend"
            },
            {
                "name": "map-marker",
                "icon": "mdi-map-marker"
            },
            {
                "name": "map-marker-account",
                "icon": "mdi-map-marker-account"
            },
            {
                "name": "map-marker-account-outline",
                "icon": "mdi-map-marker-account-outline"
            },
            {
                "name": "map-marker-alert",
                "icon": "mdi-map-marker-alert"
            },
            {
                "name": "map-marker-alert-outline",
                "icon": "mdi-map-marker-alert-outline"
            },
            {
                "name": "map-marker-check",
                "icon": "mdi-map-marker-check"
            },
            {
                "name": "map-marker-check-outline",
                "icon": "mdi-map-marker-check-outline"
            },
            {
                "name": "map-marker-circle",
                "icon": "mdi-map-marker-circle"
            },
            {
                "name": "map-marker-distance",
                "icon": "mdi-map-marker-distance"
            },
            {
                "name": "map-marker-down",
                "icon": "mdi-map-marker-down"
            },
            {
                "name": "map-marker-left",
                "icon": "mdi-map-marker-left"
            },
            {
                "name": "map-marker-left-outline",
                "icon": "mdi-map-marker-left-outline"
            },
            {
                "name": "map-marker-minus",
                "icon": "mdi-map-marker-minus"
            },
            {
                "name": "map-marker-minus-outline",
                "icon": "mdi-map-marker-minus-outline"
            },
            {
                "name": "map-marker-multiple",
                "icon": "mdi-map-marker-multiple"
            },
            {
                "name": "map-marker-multiple-outline",
                "icon": "mdi-map-marker-multiple-outline"
            },
            {
                "name": "map-marker-off",
                "icon": "mdi-map-marker-off"
            },
            {
                "name": "map-marker-off-outline",
                "icon": "mdi-map-marker-off-outline"
            },
            {
                "name": "map-marker-outline",
                "icon": "mdi-map-marker-outline"
            },
            {
                "name": "map-marker-path",
                "icon": "mdi-map-marker-path"
            },
            {
                "name": "map-marker-plus",
                "icon": "mdi-map-marker-plus"
            },
            {
                "name": "map-marker-plus-outline",
                "icon": "mdi-map-marker-plus-outline"
            },
            {
                "name": "map-marker-question",
                "icon": "mdi-map-marker-question"
            },
            {
                "name": "map-marker-question-outline",
                "icon": "mdi-map-marker-question-outline"
            },
            {
                "name": "map-marker-radius",
                "icon": "mdi-map-marker-radius"
            },
            {
                "name": "map-marker-radius-outline",
                "icon": "mdi-map-marker-radius-outline"
            },
            {
                "name": "map-marker-remove",
                "icon": "mdi-map-marker-remove"
            },
            {
                "name": "map-marker-remove-outline",
                "icon": "mdi-map-marker-remove-outline"
            },
            {
                "name": "map-marker-remove-variant",
                "icon": "mdi-map-marker-remove-variant"
            },
            {
                "name": "map-marker-right",
                "icon": "mdi-map-marker-right"
            },
            {
                "name": "map-marker-right-outline",
                "icon": "mdi-map-marker-right-outline"
            },
            {
                "name": "map-marker-star",
                "icon": "mdi-map-marker-star"
            },
            {
                "name": "map-marker-star-outline",
                "icon": "mdi-map-marker-star-outline"
            },
            {
                "name": "map-marker-up",
                "icon": "mdi-map-marker-up"
            },
            {
                "name": "map-minus",
                "icon": "mdi-map-minus"
            },
            {
                "name": "map-outline",
                "icon": "mdi-map-outline"
            },
            {
                "name": "map-plus",
                "icon": "mdi-map-plus"
            },
            {
                "name": "map-search",
                "icon": "mdi-map-search"
            },
            {
                "name": "map-search-outline",
                "icon": "mdi-map-search-outline"
            },
            {
                "name": "mapbox",
                "icon": "mdi-mapbox"
            },
            {
                "name": "margin",
                "icon": "mdi-margin"
            },
            {
                "name": "marker",
                "icon": "mdi-marker"
            },
            {
                "name": "marker-cancel",
                "icon": "mdi-marker-cancel"
            },
            {
                "name": "marker-check",
                "icon": "mdi-marker-check"
            },
            {
                "name": "mastodon",
                "icon": "mdi-mastodon"
            },
            {
                "name": "material-design",
                "icon": "mdi-material-design"
            },
            {
                "name": "material-ui",
                "icon": "mdi-material-ui"
            },
            {
                "name": "math-compass",
                "icon": "mdi-math-compass"
            },
            {
                "name": "math-cos",
                "icon": "mdi-math-cos"
            },
            {
                "name": "math-integral",
                "icon": "mdi-math-integral"
            },
            {
                "name": "math-integral-box",
                "icon": "mdi-math-integral-box"
            },
            {
                "name": "math-log",
                "icon": "mdi-math-log"
            },
            {
                "name": "math-norm",
                "icon": "mdi-math-norm"
            },
            {
                "name": "math-norm-box",
                "icon": "mdi-math-norm-box"
            },
            {
                "name": "math-sin",
                "icon": "mdi-math-sin"
            },
            {
                "name": "math-tan",
                "icon": "mdi-math-tan"
            },
            {
                "name": "matrix",
                "icon": "mdi-matrix"
            },
            {
                "name": "medal",
                "icon": "mdi-medal"
            },
            {
                "name": "medal-outline",
                "icon": "mdi-medal-outline"
            },
            {
                "name": "medical-bag",
                "icon": "mdi-medical-bag"
            },
            {
                "name": "medical-cotton-swab",
                "icon": "mdi-medical-cotton-swab"
            },
            {
                "name": "medication",
                "icon": "mdi-medication"
            },
            {
                "name": "medication-outline",
                "icon": "mdi-medication-outline"
            },
            {
                "name": "meditation",
                "icon": "mdi-meditation"
            },
            {
                "name": "memory",
                "icon": "mdi-memory"
            },
            {
                "name": "menorah",
                "icon": "mdi-menorah"
            },
            {
                "name": "menorah-fire",
                "icon": "mdi-menorah-fire"
            },
            {
                "name": "menu",
                "icon": "mdi-menu"
            },
            {
                "name": "menu-down",
                "icon": "mdi-menu-down"
            },
            {
                "name": "menu-down-outline",
                "icon": "mdi-menu-down-outline"
            },
            {
                "name": "menu-left",
                "icon": "mdi-menu-left"
            },
            {
                "name": "menu-left-outline",
                "icon": "mdi-menu-left-outline"
            },
            {
                "name": "menu-open",
                "icon": "mdi-menu-open"
            },
            {
                "name": "menu-right",
                "icon": "mdi-menu-right"
            },
            {
                "name": "menu-right-outline",
                "icon": "mdi-menu-right-outline"
            },
            {
                "name": "menu-swap",
                "icon": "mdi-menu-swap"
            },
            {
                "name": "menu-swap-outline",
                "icon": "mdi-menu-swap-outline"
            },
            {
                "name": "menu-up",
                "icon": "mdi-menu-up"
            },
            {
                "name": "menu-up-outline",
                "icon": "mdi-menu-up-outline"
            },
            {
                "name": "merge",
                "icon": "mdi-merge"
            },
            {
                "name": "message",
                "icon": "mdi-message"
            },
            {
                "name": "message-alert",
                "icon": "mdi-message-alert"
            },
            {
                "name": "message-alert-outline",
                "icon": "mdi-message-alert-outline"
            },
            {
                "name": "message-arrow-left",
                "icon": "mdi-message-arrow-left"
            },
            {
                "name": "message-arrow-left-outline",
                "icon": "mdi-message-arrow-left-outline"
            },
            {
                "name": "message-arrow-right",
                "icon": "mdi-message-arrow-right"
            },
            {
                "name": "message-arrow-right-outline",
                "icon": "mdi-message-arrow-right-outline"
            },
            {
                "name": "message-badge",
                "icon": "mdi-message-badge"
            },
            {
                "name": "message-badge-outline",
                "icon": "mdi-message-badge-outline"
            },
            {
                "name": "message-bookmark",
                "icon": "mdi-message-bookmark"
            },
            {
                "name": "message-bookmark-outline",
                "icon": "mdi-message-bookmark-outline"
            },
            {
                "name": "message-bulleted",
                "icon": "mdi-message-bulleted"
            },
            {
                "name": "message-bulleted-off",
                "icon": "mdi-message-bulleted-off"
            },
            {
                "name": "message-check",
                "icon": "mdi-message-check"
            },
            {
                "name": "message-check-outline",
                "icon": "mdi-message-check-outline"
            },
            {
                "name": "message-cog",
                "icon": "mdi-message-cog"
            },
            {
                "name": "message-cog-outline",
                "icon": "mdi-message-cog-outline"
            },
            {
                "name": "message-draw",
                "icon": "mdi-message-draw"
            },
            {
                "name": "message-fast",
                "icon": "mdi-message-fast"
            },
            {
                "name": "message-fast-outline",
                "icon": "mdi-message-fast-outline"
            },
            {
                "name": "message-flash",
                "icon": "mdi-message-flash"
            },
            {
                "name": "message-flash-outline",
                "icon": "mdi-message-flash-outline"
            },
            {
                "name": "message-image",
                "icon": "mdi-message-image"
            },
            {
                "name": "message-image-outline",
                "icon": "mdi-message-image-outline"
            },
            {
                "name": "message-lock",
                "icon": "mdi-message-lock"
            },
            {
                "name": "message-lock-outline",
                "icon": "mdi-message-lock-outline"
            },
            {
                "name": "message-minus",
                "icon": "mdi-message-minus"
            },
            {
                "name": "message-minus-outline",
                "icon": "mdi-message-minus-outline"
            },
            {
                "name": "message-off",
                "icon": "mdi-message-off"
            },
            {
                "name": "message-off-outline",
                "icon": "mdi-message-off-outline"
            },
            {
                "name": "message-outline",
                "icon": "mdi-message-outline"
            },
            {
                "name": "message-plus",
                "icon": "mdi-message-plus"
            },
            {
                "name": "message-plus-outline",
                "icon": "mdi-message-plus-outline"
            },
            {
                "name": "message-processing",
                "icon": "mdi-message-processing"
            },
            {
                "name": "message-processing-outline",
                "icon": "mdi-message-processing-outline"
            },
            {
                "name": "message-question",
                "icon": "mdi-message-question"
            },
            {
                "name": "message-question-outline",
                "icon": "mdi-message-question-outline"
            },
            {
                "name": "message-reply",
                "icon": "mdi-message-reply"
            },
            {
                "name": "message-reply-outline",
                "icon": "mdi-message-reply-outline"
            },
            {
                "name": "message-reply-text",
                "icon": "mdi-message-reply-text"
            },
            {
                "name": "message-reply-text-outline",
                "icon": "mdi-message-reply-text-outline"
            },
            {
                "name": "message-settings",
                "icon": "mdi-message-settings"
            },
            {
                "name": "message-settings-outline",
                "icon": "mdi-message-settings-outline"
            },
            {
                "name": "message-star",
                "icon": "mdi-message-star"
            },
            {
                "name": "message-star-outline",
                "icon": "mdi-message-star-outline"
            },
            {
                "name": "message-text",
                "icon": "mdi-message-text"
            },
            {
                "name": "message-text-clock",
                "icon": "mdi-message-text-clock"
            },
            {
                "name": "message-text-clock-outline",
                "icon": "mdi-message-text-clock-outline"
            },
            {
                "name": "message-text-fast",
                "icon": "mdi-message-text-fast"
            },
            {
                "name": "message-text-fast-outline",
                "icon": "mdi-message-text-fast-outline"
            },
            {
                "name": "message-text-lock",
                "icon": "mdi-message-text-lock"
            },
            {
                "name": "message-text-lock-outline",
                "icon": "mdi-message-text-lock-outline"
            },
            {
                "name": "message-text-outline",
                "icon": "mdi-message-text-outline"
            },
            {
                "name": "message-video",
                "icon": "mdi-message-video"
            },
            {
                "name": "meteor",
                "icon": "mdi-meteor"
            },
            {
                "name": "meter-electric",
                "icon": "mdi-meter-electric"
            },
            {
                "name": "meter-electric-outline",
                "icon": "mdi-meter-electric-outline"
            },
            {
                "name": "meter-gas",
                "icon": "mdi-meter-gas"
            },
            {
                "name": "meter-gas-outline",
                "icon": "mdi-meter-gas-outline"
            },
            {
                "name": "metronome",
                "icon": "mdi-metronome"
            },
            {
                "name": "metronome-tick",
                "icon": "mdi-metronome-tick"
            },
            {
                "name": "micro-sd",
                "icon": "mdi-micro-sd"
            },
            {
                "name": "microphone",
                "icon": "mdi-microphone"
            },
            {
                "name": "microphone-message",
                "icon": "mdi-microphone-message"
            },
            {
                "name": "microphone-message-off",
                "icon": "mdi-microphone-message-off"
            },
            {
                "name": "microphone-minus",
                "icon": "mdi-microphone-minus"
            },
            {
                "name": "microphone-off",
                "icon": "mdi-microphone-off"
            },
            {
                "name": "microphone-outline",
                "icon": "mdi-microphone-outline"
            },
            {
                "name": "microphone-plus",
                "icon": "mdi-microphone-plus"
            },
            {
                "name": "microphone-question",
                "icon": "mdi-microphone-question"
            },
            {
                "name": "microphone-question-outline",
                "icon": "mdi-microphone-question-outline"
            },
            {
                "name": "microphone-settings",
                "icon": "mdi-microphone-settings"
            },
            {
                "name": "microphone-variant",
                "icon": "mdi-microphone-variant"
            },
            {
                "name": "microphone-variant-off",
                "icon": "mdi-microphone-variant-off"
            },
            {
                "name": "microscope",
                "icon": "mdi-microscope"
            },
            {
                "name": "microsoft",
                "icon": "mdi-microsoft"
            },
            {
                "name": "microsoft-access",
                "icon": "mdi-microsoft-access"
            },
            {
                "name": "microsoft-azure",
                "icon": "mdi-microsoft-azure"
            },
            {
                "name": "microsoft-azure-devops",
                "icon": "mdi-microsoft-azure-devops"
            },
            {
                "name": "microsoft-bing",
                "icon": "mdi-microsoft-bing"
            },
            {
                "name": "microsoft-dynamics-365",
                "icon": "mdi-microsoft-dynamics-365"
            },
            {
                "name": "microsoft-edge",
                "icon": "mdi-microsoft-edge"
            },
            {
                "name": "microsoft-excel",
                "icon": "mdi-microsoft-excel"
            },
            {
                "name": "microsoft-internet-explorer",
                "icon": "mdi-microsoft-internet-explorer"
            },
            {
                "name": "microsoft-office",
                "icon": "mdi-microsoft-office"
            },
            {
                "name": "microsoft-onedrive",
                "icon": "mdi-microsoft-onedrive"
            },
            {
                "name": "microsoft-onenote",
                "icon": "mdi-microsoft-onenote"
            },
            {
                "name": "microsoft-outlook",
                "icon": "mdi-microsoft-outlook"
            },
            {
                "name": "microsoft-powerpoint",
                "icon": "mdi-microsoft-powerpoint"
            },
            {
                "name": "microsoft-sharepoint",
                "icon": "mdi-microsoft-sharepoint"
            },
            {
                "name": "microsoft-teams",
                "icon": "mdi-microsoft-teams"
            },
            {
                "name": "microsoft-visual-studio",
                "icon": "mdi-microsoft-visual-studio"
            },
            {
                "name": "microsoft-visual-studio-code",
                "icon": "mdi-microsoft-visual-studio-code"
            },
            {
                "name": "microsoft-windows",
                "icon": "mdi-microsoft-windows"
            },
            {
                "name": "microsoft-windows-classic",
                "icon": "mdi-microsoft-windows-classic"
            },
            {
                "name": "microsoft-word",
                "icon": "mdi-microsoft-word"
            },
            {
                "name": "microsoft-xbox",
                "icon": "mdi-microsoft-xbox"
            },
            {
                "name": "microsoft-xbox-controller",
                "icon": "mdi-microsoft-xbox-controller"
            },
            {
                "name": "microsoft-xbox-controller-battery-alert",
                "icon": "mdi-microsoft-xbox-controller-battery-alert"
            },
            {
                "name": "microsoft-xbox-controller-battery-charging",
                "icon": "mdi-microsoft-xbox-controller-battery-charging"
            },
            {
                "name": "microsoft-xbox-controller-battery-empty",
                "icon": "mdi-microsoft-xbox-controller-battery-empty"
            },
            {
                "name": "microsoft-xbox-controller-battery-full",
                "icon": "mdi-microsoft-xbox-controller-battery-full"
            },
            {
                "name": "microsoft-xbox-controller-battery-low",
                "icon": "mdi-microsoft-xbox-controller-battery-low"
            },
            {
                "name": "microsoft-xbox-controller-battery-medium",
                "icon": "mdi-microsoft-xbox-controller-battery-medium"
            },
            {
                "name": "microsoft-xbox-controller-battery-unknown",
                "icon": "mdi-microsoft-xbox-controller-battery-unknown"
            },
            {
                "name": "microsoft-xbox-controller-menu",
                "icon": "mdi-microsoft-xbox-controller-menu"
            },
            {
                "name": "microsoft-xbox-controller-off",
                "icon": "mdi-microsoft-xbox-controller-off"
            },
            {
                "name": "microsoft-xbox-controller-view",
                "icon": "mdi-microsoft-xbox-controller-view"
            },
            {
                "name": "microwave",
                "icon": "mdi-microwave"
            },
            {
                "name": "microwave-off",
                "icon": "mdi-microwave-off"
            },
            {
                "name": "middleware",
                "icon": "mdi-middleware"
            },
            {
                "name": "middleware-outline",
                "icon": "mdi-middleware-outline"
            },
            {
                "name": "midi",
                "icon": "mdi-midi"
            },
            {
                "name": "midi-port",
                "icon": "mdi-midi-port"
            },
            {
                "name": "mine",
                "icon": "mdi-mine"
            },
            {
                "name": "minecraft",
                "icon": "mdi-minecraft"
            },
            {
                "name": "mini-sd",
                "icon": "mdi-mini-sd"
            },
            {
                "name": "minidisc",
                "icon": "mdi-minidisc"
            },
            {
                "name": "minus",
                "icon": "mdi-minus"
            },
            {
                "name": "minus-box",
                "icon": "mdi-minus-box"
            },
            {
                "name": "minus-box-multiple",
                "icon": "mdi-minus-box-multiple"
            },
            {
                "name": "minus-box-multiple-outline",
                "icon": "mdi-minus-box-multiple-outline"
            },
            {
                "name": "minus-box-outline",
                "icon": "mdi-minus-box-outline"
            },
            {
                "name": "minus-circle",
                "icon": "mdi-minus-circle"
            },
            {
                "name": "minus-circle-multiple",
                "icon": "mdi-minus-circle-multiple"
            },
            {
                "name": "minus-circle-multiple-outline",
                "icon": "mdi-minus-circle-multiple-outline"
            },
            {
                "name": "minus-circle-off",
                "icon": "mdi-minus-circle-off"
            },
            {
                "name": "minus-circle-off-outline",
                "icon": "mdi-minus-circle-off-outline"
            },
            {
                "name": "minus-circle-outline",
                "icon": "mdi-minus-circle-outline"
            },
            {
                "name": "minus-network",
                "icon": "mdi-minus-network"
            },
            {
                "name": "minus-network-outline",
                "icon": "mdi-minus-network-outline"
            },
            {
                "name": "minus-thick",
                "icon": "mdi-minus-thick"
            },
            {
                "name": "mirror",
                "icon": "mdi-mirror"
            },
            {
                "name": "mirror-rectangle",
                "icon": "mdi-mirror-rectangle"
            },
            {
                "name": "mirror-variant",
                "icon": "mdi-mirror-variant"
            },
            {
                "name": "mixed-martial-arts",
                "icon": "mdi-mixed-martial-arts"
            },
            {
                "name": "mixed-reality",
                "icon": "mdi-mixed-reality"
            },
            {
                "name": "molecule",
                "icon": "mdi-molecule"
            },
            {
                "name": "molecule-co",
                "icon": "mdi-molecule-co"
            },
            {
                "name": "molecule-co2",
                "icon": "mdi-molecule-co2"
            },
            {
                "name": "monitor",
                "icon": "mdi-monitor"
            },
            {
                "name": "monitor-account",
                "icon": "mdi-monitor-account"
            },
            {
                "name": "monitor-arrow-down",
                "icon": "mdi-monitor-arrow-down"
            },
            {
                "name": "monitor-arrow-down-variant",
                "icon": "mdi-monitor-arrow-down-variant"
            },
            {
                "name": "monitor-cellphone",
                "icon": "mdi-monitor-cellphone"
            },
            {
                "name": "monitor-cellphone-star",
                "icon": "mdi-monitor-cellphone-star"
            },
            {
                "name": "monitor-dashboard",
                "icon": "mdi-monitor-dashboard"
            },
            {
                "name": "monitor-edit",
                "icon": "mdi-monitor-edit"
            },
            {
                "name": "monitor-eye",
                "icon": "mdi-monitor-eye"
            },
            {
                "name": "monitor-lock",
                "icon": "mdi-monitor-lock"
            },
            {
                "name": "monitor-multiple",
                "icon": "mdi-monitor-multiple"
            },
            {
                "name": "monitor-off",
                "icon": "mdi-monitor-off"
            },
            {
                "name": "monitor-screenshot",
                "icon": "mdi-monitor-screenshot"
            },
            {
                "name": "monitor-share",
                "icon": "mdi-monitor-share"
            },
            {
                "name": "monitor-shimmer",
                "icon": "mdi-monitor-shimmer"
            },
            {
                "name": "monitor-small",
                "icon": "mdi-monitor-small"
            },
            {
                "name": "monitor-speaker",
                "icon": "mdi-monitor-speaker"
            },
            {
                "name": "monitor-speaker-off",
                "icon": "mdi-monitor-speaker-off"
            },
            {
                "name": "monitor-star",
                "icon": "mdi-monitor-star"
            },
            {
                "name": "moon-first-quarter",
                "icon": "mdi-moon-first-quarter"
            },
            {
                "name": "moon-full",
                "icon": "mdi-moon-full"
            },
            {
                "name": "moon-last-quarter",
                "icon": "mdi-moon-last-quarter"
            },
            {
                "name": "moon-new",
                "icon": "mdi-moon-new"
            },
            {
                "name": "moon-waning-crescent",
                "icon": "mdi-moon-waning-crescent"
            },
            {
                "name": "moon-waning-gibbous",
                "icon": "mdi-moon-waning-gibbous"
            },
            {
                "name": "moon-waxing-crescent",
                "icon": "mdi-moon-waxing-crescent"
            },
            {
                "name": "moon-waxing-gibbous",
                "icon": "mdi-moon-waxing-gibbous"
            },
            {
                "name": "moped",
                "icon": "mdi-moped"
            },
            {
                "name": "moped-electric",
                "icon": "mdi-moped-electric"
            },
            {
                "name": "moped-electric-outline",
                "icon": "mdi-moped-electric-outline"
            },
            {
                "name": "moped-outline",
                "icon": "mdi-moped-outline"
            },
            {
                "name": "more",
                "icon": "mdi-more"
            },
            {
                "name": "mortar-pestle",
                "icon": "mdi-mortar-pestle"
            },
            {
                "name": "mortar-pestle-plus",
                "icon": "mdi-mortar-pestle-plus"
            },
            {
                "name": "mosque",
                "icon": "mdi-mosque"
            },
            {
                "name": "mosque-outline",
                "icon": "mdi-mosque-outline"
            },
            {
                "name": "mother-heart",
                "icon": "mdi-mother-heart"
            },
            {
                "name": "mother-nurse",
                "icon": "mdi-mother-nurse"
            },
            {
                "name": "motion",
                "icon": "mdi-motion"
            },
            {
                "name": "motion-outline",
                "icon": "mdi-motion-outline"
            },
            {
                "name": "motion-pause",
                "icon": "mdi-motion-pause"
            },
            {
                "name": "motion-pause-outline",
                "icon": "mdi-motion-pause-outline"
            },
            {
                "name": "motion-play",
                "icon": "mdi-motion-play"
            },
            {
                "name": "motion-play-outline",
                "icon": "mdi-motion-play-outline"
            },
            {
                "name": "motion-sensor",
                "icon": "mdi-motion-sensor"
            },
            {
                "name": "motion-sensor-off",
                "icon": "mdi-motion-sensor-off"
            },
            {
                "name": "motorbike",
                "icon": "mdi-motorbike"
            },
            {
                "name": "motorbike-electric",
                "icon": "mdi-motorbike-electric"
            },
            {
                "name": "motorbike-off",
                "icon": "mdi-motorbike-off"
            },
            {
                "name": "mouse",
                "icon": "mdi-mouse"
            },
            {
                "name": "mouse-bluetooth",
                "icon": "mdi-mouse-bluetooth"
            },
            {
                "name": "mouse-move-down",
                "icon": "mdi-mouse-move-down"
            },
            {
                "name": "mouse-move-up",
                "icon": "mdi-mouse-move-up"
            },
            {
                "name": "mouse-move-vertical",
                "icon": "mdi-mouse-move-vertical"
            },
            {
                "name": "mouse-off",
                "icon": "mdi-mouse-off"
            },
            {
                "name": "mouse-variant",
                "icon": "mdi-mouse-variant"
            },
            {
                "name": "mouse-variant-off",
                "icon": "mdi-mouse-variant-off"
            },
            {
                "name": "move-resize",
                "icon": "mdi-move-resize"
            },
            {
                "name": "move-resize-variant",
                "icon": "mdi-move-resize-variant"
            },
            {
                "name": "movie",
                "icon": "mdi-movie"
            },
            {
                "name": "movie-check",
                "icon": "mdi-movie-check"
            },
            {
                "name": "movie-check-outline",
                "icon": "mdi-movie-check-outline"
            },
            {
                "name": "movie-cog",
                "icon": "mdi-movie-cog"
            },
            {
                "name": "movie-cog-outline",
                "icon": "mdi-movie-cog-outline"
            },
            {
                "name": "movie-edit",
                "icon": "mdi-movie-edit"
            },
            {
                "name": "movie-edit-outline",
                "icon": "mdi-movie-edit-outline"
            },
            {
                "name": "movie-filter",
                "icon": "mdi-movie-filter"
            },
            {
                "name": "movie-filter-outline",
                "icon": "mdi-movie-filter-outline"
            },
            {
                "name": "movie-minus",
                "icon": "mdi-movie-minus"
            },
            {
                "name": "movie-minus-outline",
                "icon": "mdi-movie-minus-outline"
            },
            {
                "name": "movie-off",
                "icon": "mdi-movie-off"
            },
            {
                "name": "movie-off-outline",
                "icon": "mdi-movie-off-outline"
            },
            {
                "name": "movie-open",
                "icon": "mdi-movie-open"
            },
            {
                "name": "movie-open-check",
                "icon": "mdi-movie-open-check"
            },
            {
                "name": "movie-open-check-outline",
                "icon": "mdi-movie-open-check-outline"
            },
            {
                "name": "movie-open-cog",
                "icon": "mdi-movie-open-cog"
            },
            {
                "name": "movie-open-cog-outline",
                "icon": "mdi-movie-open-cog-outline"
            },
            {
                "name": "movie-open-edit",
                "icon": "mdi-movie-open-edit"
            },
            {
                "name": "movie-open-edit-outline",
                "icon": "mdi-movie-open-edit-outline"
            },
            {
                "name": "movie-open-minus",
                "icon": "mdi-movie-open-minus"
            },
            {
                "name": "movie-open-minus-outline",
                "icon": "mdi-movie-open-minus-outline"
            },
            {
                "name": "movie-open-off",
                "icon": "mdi-movie-open-off"
            },
            {
                "name": "movie-open-off-outline",
                "icon": "mdi-movie-open-off-outline"
            },
            {
                "name": "movie-open-outline",
                "icon": "mdi-movie-open-outline"
            },
            {
                "name": "movie-open-play",
                "icon": "mdi-movie-open-play"
            },
            {
                "name": "movie-open-play-outline",
                "icon": "mdi-movie-open-play-outline"
            },
            {
                "name": "movie-open-plus",
                "icon": "mdi-movie-open-plus"
            },
            {
                "name": "movie-open-plus-outline",
                "icon": "mdi-movie-open-plus-outline"
            },
            {
                "name": "movie-open-remove",
                "icon": "mdi-movie-open-remove"
            },
            {
                "name": "movie-open-remove-outline",
                "icon": "mdi-movie-open-remove-outline"
            },
            {
                "name": "movie-open-settings",
                "icon": "mdi-movie-open-settings"
            },
            {
                "name": "movie-open-settings-outline",
                "icon": "mdi-movie-open-settings-outline"
            },
            {
                "name": "movie-open-star",
                "icon": "mdi-movie-open-star"
            },
            {
                "name": "movie-open-star-outline",
                "icon": "mdi-movie-open-star-outline"
            },
            {
                "name": "movie-outline",
                "icon": "mdi-movie-outline"
            },
            {
                "name": "movie-play",
                "icon": "mdi-movie-play"
            },
            {
                "name": "movie-play-outline",
                "icon": "mdi-movie-play-outline"
            },
            {
                "name": "movie-plus",
                "icon": "mdi-movie-plus"
            },
            {
                "name": "movie-plus-outline",
                "icon": "mdi-movie-plus-outline"
            },
            {
                "name": "movie-remove",
                "icon": "mdi-movie-remove"
            },
            {
                "name": "movie-remove-outline",
                "icon": "mdi-movie-remove-outline"
            },
            {
                "name": "movie-roll",
                "icon": "mdi-movie-roll"
            },
            {
                "name": "movie-search",
                "icon": "mdi-movie-search"
            },
            {
                "name": "movie-search-outline",
                "icon": "mdi-movie-search-outline"
            },
            {
                "name": "movie-settings",
                "icon": "mdi-movie-settings"
            },
            {
                "name": "movie-settings-outline",
                "icon": "mdi-movie-settings-outline"
            },
            {
                "name": "movie-star",
                "icon": "mdi-movie-star"
            },
            {
                "name": "movie-star-outline",
                "icon": "mdi-movie-star-outline"
            },
            {
                "name": "mower",
                "icon": "mdi-mower"
            },
            {
                "name": "mower-bag",
                "icon": "mdi-mower-bag"
            },
            {
                "name": "mower-bag-on",
                "icon": "mdi-mower-bag-on"
            },
            {
                "name": "mower-on",
                "icon": "mdi-mower-on"
            },
            {
                "name": "muffin",
                "icon": "mdi-muffin"
            },
            {
                "name": "multicast",
                "icon": "mdi-multicast"
            },
            {
                "name": "multimedia",
                "icon": "mdi-multimedia"
            },
            {
                "name": "multiplication",
                "icon": "mdi-multiplication"
            },
            {
                "name": "multiplication-box",
                "icon": "mdi-multiplication-box"
            },
            {
                "name": "mushroom",
                "icon": "mdi-mushroom"
            },
            {
                "name": "mushroom-off",
                "icon": "mdi-mushroom-off"
            },
            {
                "name": "mushroom-off-outline",
                "icon": "mdi-mushroom-off-outline"
            },
            {
                "name": "mushroom-outline",
                "icon": "mdi-mushroom-outline"
            },
            {
                "name": "music",
                "icon": "mdi-music"
            },
            {
                "name": "music-accidental-double-flat",
                "icon": "mdi-music-accidental-double-flat"
            },
            {
                "name": "music-accidental-double-sharp",
                "icon": "mdi-music-accidental-double-sharp"
            },
            {
                "name": "music-accidental-flat",
                "icon": "mdi-music-accidental-flat"
            },
            {
                "name": "music-accidental-natural",
                "icon": "mdi-music-accidental-natural"
            },
            {
                "name": "music-accidental-sharp",
                "icon": "mdi-music-accidental-sharp"
            },
            {
                "name": "music-box",
                "icon": "mdi-music-box"
            },
            {
                "name": "music-box-multiple",
                "icon": "mdi-music-box-multiple"
            },
            {
                "name": "music-box-multiple-outline",
                "icon": "mdi-music-box-multiple-outline"
            },
            {
                "name": "music-box-outline",
                "icon": "mdi-music-box-outline"
            },
            {
                "name": "music-circle",
                "icon": "mdi-music-circle"
            },
            {
                "name": "music-circle-outline",
                "icon": "mdi-music-circle-outline"
            },
            {
                "name": "music-clef-alto",
                "icon": "mdi-music-clef-alto"
            },
            {
                "name": "music-clef-bass",
                "icon": "mdi-music-clef-bass"
            },
            {
                "name": "music-clef-treble",
                "icon": "mdi-music-clef-treble"
            },
            {
                "name": "music-note",
                "icon": "mdi-music-note"
            },
            {
                "name": "music-note-bluetooth",
                "icon": "mdi-music-note-bluetooth"
            },
            {
                "name": "music-note-bluetooth-off",
                "icon": "mdi-music-note-bluetooth-off"
            },
            {
                "name": "music-note-eighth",
                "icon": "mdi-music-note-eighth"
            },
            {
                "name": "music-note-eighth-dotted",
                "icon": "mdi-music-note-eighth-dotted"
            },
            {
                "name": "music-note-half",
                "icon": "mdi-music-note-half"
            },
            {
                "name": "music-note-half-dotted",
                "icon": "mdi-music-note-half-dotted"
            },
            {
                "name": "music-note-minus",
                "icon": "mdi-music-note-minus"
            },
            {
                "name": "music-note-off",
                "icon": "mdi-music-note-off"
            },
            {
                "name": "music-note-off-outline",
                "icon": "mdi-music-note-off-outline"
            },
            {
                "name": "music-note-outline",
                "icon": "mdi-music-note-outline"
            },
            {
                "name": "music-note-plus",
                "icon": "mdi-music-note-plus"
            },
            {
                "name": "music-note-quarter",
                "icon": "mdi-music-note-quarter"
            },
            {
                "name": "music-note-quarter-dotted",
                "icon": "mdi-music-note-quarter-dotted"
            },
            {
                "name": "music-note-sixteenth",
                "icon": "mdi-music-note-sixteenth"
            },
            {
                "name": "music-note-sixteenth-dotted",
                "icon": "mdi-music-note-sixteenth-dotted"
            },
            {
                "name": "music-note-whole",
                "icon": "mdi-music-note-whole"
            },
            {
                "name": "music-note-whole-dotted",
                "icon": "mdi-music-note-whole-dotted"
            },
            {
                "name": "music-off",
                "icon": "mdi-music-off"
            },
            {
                "name": "music-rest-eighth",
                "icon": "mdi-music-rest-eighth"
            },
            {
                "name": "music-rest-half",
                "icon": "mdi-music-rest-half"
            },
            {
                "name": "music-rest-quarter",
                "icon": "mdi-music-rest-quarter"
            },
            {
                "name": "music-rest-sixteenth",
                "icon": "mdi-music-rest-sixteenth"
            },
            {
                "name": "music-rest-whole",
                "icon": "mdi-music-rest-whole"
            },
            {
                "name": "mustache",
                "icon": "mdi-mustache"
            },
            {
                "name": "nail",
                "icon": "mdi-nail"
            },
            {
                "name": "nas",
                "icon": "mdi-nas"
            },
            {
                "name": "nativescript",
                "icon": "mdi-nativescript"
            },
            {
                "name": "nature",
                "icon": "mdi-nature"
            },
            {
                "name": "nature-people",
                "icon": "mdi-nature-people"
            },
            {
                "name": "navigation",
                "icon": "mdi-navigation"
            },
            {
                "name": "navigation-outline",
                "icon": "mdi-navigation-outline"
            },
            {
                "name": "navigation-variant",
                "icon": "mdi-navigation-variant"
            },
            {
                "name": "navigation-variant-outline",
                "icon": "mdi-navigation-variant-outline"
            },
            {
                "name": "near-me",
                "icon": "mdi-near-me"
            },
            {
                "name": "necklace",
                "icon": "mdi-necklace"
            },
            {
                "name": "needle",
                "icon": "mdi-needle"
            },
            {
                "name": "needle-off",
                "icon": "mdi-needle-off"
            },
            {
                "name": "netflix",
                "icon": "mdi-netflix"
            },
            {
                "name": "network",
                "icon": "mdi-network"
            },
            {
                "name": "network-off",
                "icon": "mdi-network-off"
            },
            {
                "name": "network-off-outline",
                "icon": "mdi-network-off-outline"
            },
            {
                "name": "network-outline",
                "icon": "mdi-network-outline"
            },
            {
                "name": "network-pos",
                "icon": "mdi-network-pos"
            },
            {
                "name": "network-strength-1",
                "icon": "mdi-network-strength-1"
            },
            {
                "name": "network-strength-1-alert",
                "icon": "mdi-network-strength-1-alert"
            },
            {
                "name": "network-strength-2",
                "icon": "mdi-network-strength-2"
            },
            {
                "name": "network-strength-2-alert",
                "icon": "mdi-network-strength-2-alert"
            },
            {
                "name": "network-strength-3",
                "icon": "mdi-network-strength-3"
            },
            {
                "name": "network-strength-3-alert",
                "icon": "mdi-network-strength-3-alert"
            },
            {
                "name": "network-strength-4",
                "icon": "mdi-network-strength-4"
            },
            {
                "name": "network-strength-4-alert",
                "icon": "mdi-network-strength-4-alert"
            },
            {
                "name": "network-strength-4-cog",
                "icon": "mdi-network-strength-4-cog"
            },
            {
                "name": "network-strength-off",
                "icon": "mdi-network-strength-off"
            },
            {
                "name": "network-strength-off-outline",
                "icon": "mdi-network-strength-off-outline"
            },
            {
                "name": "network-strength-outline",
                "icon": "mdi-network-strength-outline"
            },
            {
                "name": "new-box",
                "icon": "mdi-new-box"
            },
            {
                "name": "newspaper",
                "icon": "mdi-newspaper"
            },
            {
                "name": "newspaper-check",
                "icon": "mdi-newspaper-check"
            },
            {
                "name": "newspaper-minus",
                "icon": "mdi-newspaper-minus"
            },
            {
                "name": "newspaper-plus",
                "icon": "mdi-newspaper-plus"
            },
            {
                "name": "newspaper-remove",
                "icon": "mdi-newspaper-remove"
            },
            {
                "name": "newspaper-variant",
                "icon": "mdi-newspaper-variant"
            },
            {
                "name": "newspaper-variant-multiple",
                "icon": "mdi-newspaper-variant-multiple"
            },
            {
                "name": "newspaper-variant-multiple-outline",
                "icon": "mdi-newspaper-variant-multiple-outline"
            },
            {
                "name": "newspaper-variant-outline",
                "icon": "mdi-newspaper-variant-outline"
            },
            {
                "name": "nfc",
                "icon": "mdi-nfc"
            },
            {
                "name": "nfc-search-variant",
                "icon": "mdi-nfc-search-variant"
            },
            {
                "name": "nfc-tap",
                "icon": "mdi-nfc-tap"
            },
            {
                "name": "nfc-variant",
                "icon": "mdi-nfc-variant"
            },
            {
                "name": "nfc-variant-off",
                "icon": "mdi-nfc-variant-off"
            },
            {
                "name": "ninja",
                "icon": "mdi-ninja"
            },
            {
                "name": "nintendo-game-boy",
                "icon": "mdi-nintendo-game-boy"
            },
            {
                "name": "nintendo-switch",
                "icon": "mdi-nintendo-switch"
            },
            {
                "name": "nintendo-wii",
                "icon": "mdi-nintendo-wii"
            },
            {
                "name": "nintendo-wiiu",
                "icon": "mdi-nintendo-wiiu"
            },
            {
                "name": "nix",
                "icon": "mdi-nix"
            },
            {
                "name": "nodejs",
                "icon": "mdi-nodejs"
            },
            {
                "name": "noodles",
                "icon": "mdi-noodles"
            },
            {
                "name": "not-equal",
                "icon": "mdi-not-equal"
            },
            {
                "name": "not-equal-variant",
                "icon": "mdi-not-equal-variant"
            },
            {
                "name": "note",
                "icon": "mdi-note"
            },
            {
                "name": "note-alert",
                "icon": "mdi-note-alert"
            },
            {
                "name": "note-alert-outline",
                "icon": "mdi-note-alert-outline"
            },
            {
                "name": "note-check",
                "icon": "mdi-note-check"
            },
            {
                "name": "note-check-outline",
                "icon": "mdi-note-check-outline"
            },
            {
                "name": "note-edit",
                "icon": "mdi-note-edit"
            },
            {
                "name": "note-edit-outline",
                "icon": "mdi-note-edit-outline"
            },
            {
                "name": "note-minus",
                "icon": "mdi-note-minus"
            },
            {
                "name": "note-minus-outline",
                "icon": "mdi-note-minus-outline"
            },
            {
                "name": "note-multiple",
                "icon": "mdi-note-multiple"
            },
            {
                "name": "note-multiple-outline",
                "icon": "mdi-note-multiple-outline"
            },
            {
                "name": "note-off",
                "icon": "mdi-note-off"
            },
            {
                "name": "note-off-outline",
                "icon": "mdi-note-off-outline"
            },
            {
                "name": "note-outline",
                "icon": "mdi-note-outline"
            },
            {
                "name": "note-plus",
                "icon": "mdi-note-plus"
            },
            {
                "name": "note-plus-outline",
                "icon": "mdi-note-plus-outline"
            },
            {
                "name": "note-remove",
                "icon": "mdi-note-remove"
            },
            {
                "name": "note-remove-outline",
                "icon": "mdi-note-remove-outline"
            },
            {
                "name": "note-search",
                "icon": "mdi-note-search"
            },
            {
                "name": "note-search-outline",
                "icon": "mdi-note-search-outline"
            },
            {
                "name": "note-text",
                "icon": "mdi-note-text"
            },
            {
                "name": "note-text-outline",
                "icon": "mdi-note-text-outline"
            },
            {
                "name": "notebook",
                "icon": "mdi-notebook"
            },
            {
                "name": "notebook-check",
                "icon": "mdi-notebook-check"
            },
            {
                "name": "notebook-check-outline",
                "icon": "mdi-notebook-check-outline"
            },
            {
                "name": "notebook-edit",
                "icon": "mdi-notebook-edit"
            },
            {
                "name": "notebook-edit-outline",
                "icon": "mdi-notebook-edit-outline"
            },
            {
                "name": "notebook-heart",
                "icon": "mdi-notebook-heart"
            },
            {
                "name": "notebook-heart-outline",
                "icon": "mdi-notebook-heart-outline"
            },
            {
                "name": "notebook-minus",
                "icon": "mdi-notebook-minus"
            },
            {
                "name": "notebook-minus-outline",
                "icon": "mdi-notebook-minus-outline"
            },
            {
                "name": "notebook-multiple",
                "icon": "mdi-notebook-multiple"
            },
            {
                "name": "notebook-outline",
                "icon": "mdi-notebook-outline"
            },
            {
                "name": "notebook-plus",
                "icon": "mdi-notebook-plus"
            },
            {
                "name": "notebook-plus-outline",
                "icon": "mdi-notebook-plus-outline"
            },
            {
                "name": "notebook-remove",
                "icon": "mdi-notebook-remove"
            },
            {
                "name": "notebook-remove-outline",
                "icon": "mdi-notebook-remove-outline"
            },
            {
                "name": "notification-clear-all",
                "icon": "mdi-notification-clear-all"
            },
            {
                "name": "npm",
                "icon": "mdi-npm"
            },
            {
                "name": "nuke",
                "icon": "mdi-nuke"
            },
            {
                "name": "null",
                "icon": "mdi-null"
            },
            {
                "name": "numeric",
                "icon": "mdi-numeric"
            },
            {
                "name": "numeric-0",
                "icon": "mdi-numeric-0"
            },
            {
                "name": "numeric-0-box",
                "icon": "mdi-numeric-0-box"
            },
            {
                "name": "numeric-0-box-multiple",
                "icon": "mdi-numeric-0-box-multiple"
            },
            {
                "name": "numeric-0-box-multiple-outline",
                "icon": "mdi-numeric-0-box-multiple-outline"
            },
            {
                "name": "numeric-0-box-outline",
                "icon": "mdi-numeric-0-box-outline"
            },
            {
                "name": "numeric-0-circle",
                "icon": "mdi-numeric-0-circle"
            },
            {
                "name": "numeric-0-circle-outline",
                "icon": "mdi-numeric-0-circle-outline"
            },
            {
                "name": "numeric-1",
                "icon": "mdi-numeric-1"
            },
            {
                "name": "numeric-1-box",
                "icon": "mdi-numeric-1-box"
            },
            {
                "name": "numeric-1-box-multiple",
                "icon": "mdi-numeric-1-box-multiple"
            },
            {
                "name": "numeric-1-box-multiple-outline",
                "icon": "mdi-numeric-1-box-multiple-outline"
            },
            {
                "name": "numeric-1-box-outline",
                "icon": "mdi-numeric-1-box-outline"
            },
            {
                "name": "numeric-1-circle",
                "icon": "mdi-numeric-1-circle"
            },
            {
                "name": "numeric-1-circle-outline",
                "icon": "mdi-numeric-1-circle-outline"
            },
            {
                "name": "numeric-10",
                "icon": "mdi-numeric-10"
            },
            {
                "name": "numeric-10-box",
                "icon": "mdi-numeric-10-box"
            },
            {
                "name": "numeric-10-box-multiple",
                "icon": "mdi-numeric-10-box-multiple"
            },
            {
                "name": "numeric-10-box-multiple-outline",
                "icon": "mdi-numeric-10-box-multiple-outline"
            },
            {
                "name": "numeric-10-box-outline",
                "icon": "mdi-numeric-10-box-outline"
            },
            {
                "name": "numeric-10-circle",
                "icon": "mdi-numeric-10-circle"
            },
            {
                "name": "numeric-10-circle-outline",
                "icon": "mdi-numeric-10-circle-outline"
            },
            {
                "name": "numeric-2",
                "icon": "mdi-numeric-2"
            },
            {
                "name": "numeric-2-box",
                "icon": "mdi-numeric-2-box"
            },
            {
                "name": "numeric-2-box-multiple",
                "icon": "mdi-numeric-2-box-multiple"
            },
            {
                "name": "numeric-2-box-multiple-outline",
                "icon": "mdi-numeric-2-box-multiple-outline"
            },
            {
                "name": "numeric-2-box-outline",
                "icon": "mdi-numeric-2-box-outline"
            },
            {
                "name": "numeric-2-circle",
                "icon": "mdi-numeric-2-circle"
            },
            {
                "name": "numeric-2-circle-outline",
                "icon": "mdi-numeric-2-circle-outline"
            },
            {
                "name": "numeric-3",
                "icon": "mdi-numeric-3"
            },
            {
                "name": "numeric-3-box",
                "icon": "mdi-numeric-3-box"
            },
            {
                "name": "numeric-3-box-multiple",
                "icon": "mdi-numeric-3-box-multiple"
            },
            {
                "name": "numeric-3-box-multiple-outline",
                "icon": "mdi-numeric-3-box-multiple-outline"
            },
            {
                "name": "numeric-3-box-outline",
                "icon": "mdi-numeric-3-box-outline"
            },
            {
                "name": "numeric-3-circle",
                "icon": "mdi-numeric-3-circle"
            },
            {
                "name": "numeric-3-circle-outline",
                "icon": "mdi-numeric-3-circle-outline"
            },
            {
                "name": "numeric-4",
                "icon": "mdi-numeric-4"
            },
            {
                "name": "numeric-4-box",
                "icon": "mdi-numeric-4-box"
            },
            {
                "name": "numeric-4-box-multiple",
                "icon": "mdi-numeric-4-box-multiple"
            },
            {
                "name": "numeric-4-box-multiple-outline",
                "icon": "mdi-numeric-4-box-multiple-outline"
            },
            {
                "name": "numeric-4-box-outline",
                "icon": "mdi-numeric-4-box-outline"
            },
            {
                "name": "numeric-4-circle",
                "icon": "mdi-numeric-4-circle"
            },
            {
                "name": "numeric-4-circle-outline",
                "icon": "mdi-numeric-4-circle-outline"
            },
            {
                "name": "numeric-5",
                "icon": "mdi-numeric-5"
            },
            {
                "name": "numeric-5-box",
                "icon": "mdi-numeric-5-box"
            },
            {
                "name": "numeric-5-box-multiple",
                "icon": "mdi-numeric-5-box-multiple"
            },
            {
                "name": "numeric-5-box-multiple-outline",
                "icon": "mdi-numeric-5-box-multiple-outline"
            },
            {
                "name": "numeric-5-box-outline",
                "icon": "mdi-numeric-5-box-outline"
            },
            {
                "name": "numeric-5-circle",
                "icon": "mdi-numeric-5-circle"
            },
            {
                "name": "numeric-5-circle-outline",
                "icon": "mdi-numeric-5-circle-outline"
            },
            {
                "name": "numeric-6",
                "icon": "mdi-numeric-6"
            },
            {
                "name": "numeric-6-box",
                "icon": "mdi-numeric-6-box"
            },
            {
                "name": "numeric-6-box-multiple",
                "icon": "mdi-numeric-6-box-multiple"
            },
            {
                "name": "numeric-6-box-multiple-outline",
                "icon": "mdi-numeric-6-box-multiple-outline"
            },
            {
                "name": "numeric-6-box-outline",
                "icon": "mdi-numeric-6-box-outline"
            },
            {
                "name": "numeric-6-circle",
                "icon": "mdi-numeric-6-circle"
            },
            {
                "name": "numeric-6-circle-outline",
                "icon": "mdi-numeric-6-circle-outline"
            },
            {
                "name": "numeric-7",
                "icon": "mdi-numeric-7"
            },
            {
                "name": "numeric-7-box",
                "icon": "mdi-numeric-7-box"
            },
            {
                "name": "numeric-7-box-multiple",
                "icon": "mdi-numeric-7-box-multiple"
            },
            {
                "name": "numeric-7-box-multiple-outline",
                "icon": "mdi-numeric-7-box-multiple-outline"
            },
            {
                "name": "numeric-7-box-outline",
                "icon": "mdi-numeric-7-box-outline"
            },
            {
                "name": "numeric-7-circle",
                "icon": "mdi-numeric-7-circle"
            },
            {
                "name": "numeric-7-circle-outline",
                "icon": "mdi-numeric-7-circle-outline"
            },
            {
                "name": "numeric-8",
                "icon": "mdi-numeric-8"
            },
            {
                "name": "numeric-8-box",
                "icon": "mdi-numeric-8-box"
            },
            {
                "name": "numeric-8-box-multiple",
                "icon": "mdi-numeric-8-box-multiple"
            },
            {
                "name": "numeric-8-box-multiple-outline",
                "icon": "mdi-numeric-8-box-multiple-outline"
            },
            {
                "name": "numeric-8-box-outline",
                "icon": "mdi-numeric-8-box-outline"
            },
            {
                "name": "numeric-8-circle",
                "icon": "mdi-numeric-8-circle"
            },
            {
                "name": "numeric-8-circle-outline",
                "icon": "mdi-numeric-8-circle-outline"
            },
            {
                "name": "numeric-9",
                "icon": "mdi-numeric-9"
            },
            {
                "name": "numeric-9-box",
                "icon": "mdi-numeric-9-box"
            },
            {
                "name": "numeric-9-box-multiple",
                "icon": "mdi-numeric-9-box-multiple"
            },
            {
                "name": "numeric-9-box-multiple-outline",
                "icon": "mdi-numeric-9-box-multiple-outline"
            },
            {
                "name": "numeric-9-box-outline",
                "icon": "mdi-numeric-9-box-outline"
            },
            {
                "name": "numeric-9-circle",
                "icon": "mdi-numeric-9-circle"
            },
            {
                "name": "numeric-9-circle-outline",
                "icon": "mdi-numeric-9-circle-outline"
            },
            {
                "name": "numeric-9-plus",
                "icon": "mdi-numeric-9-plus"
            },
            {
                "name": "numeric-9-plus-box",
                "icon": "mdi-numeric-9-plus-box"
            },
            {
                "name": "numeric-9-plus-box-multiple",
                "icon": "mdi-numeric-9-plus-box-multiple"
            },
            {
                "name": "numeric-9-plus-box-multiple-outline",
                "icon": "mdi-numeric-9-plus-box-multiple-outline"
            },
            {
                "name": "numeric-9-plus-box-outline",
                "icon": "mdi-numeric-9-plus-box-outline"
            },
            {
                "name": "numeric-9-plus-circle",
                "icon": "mdi-numeric-9-plus-circle"
            },
            {
                "name": "numeric-9-plus-circle-outline",
                "icon": "mdi-numeric-9-plus-circle-outline"
            },
            {
                "name": "numeric-negative-1",
                "icon": "mdi-numeric-negative-1"
            },
            {
                "name": "numeric-off",
                "icon": "mdi-numeric-off"
            },
            {
                "name": "numeric-positive-1",
                "icon": "mdi-numeric-positive-1"
            },
            {
                "name": "nut",
                "icon": "mdi-nut"
            },
            {
                "name": "nutrition",
                "icon": "mdi-nutrition"
            },
            {
                "name": "nuxt",
                "icon": "mdi-nuxt"
            },
            {
                "name": "oar",
                "icon": "mdi-oar"
            },
            {
                "name": "ocarina",
                "icon": "mdi-ocarina"
            },
            {
                "name": "oci",
                "icon": "mdi-oci"
            },
            {
                "name": "ocr",
                "icon": "mdi-ocr"
            },
            {
                "name": "octagon",
                "icon": "mdi-octagon"
            },
            {
                "name": "octagon-outline",
                "icon": "mdi-octagon-outline"
            },
            {
                "name": "octagram",
                "icon": "mdi-octagram"
            },
            {
                "name": "octagram-outline",
                "icon": "mdi-octagram-outline"
            },
            {
                "name": "octahedron",
                "icon": "mdi-octahedron"
            },
            {
                "name": "octahedron-off",
                "icon": "mdi-octahedron-off"
            },
            {
                "name": "odnoklassniki",
                "icon": "mdi-odnoklassniki"
            },
            {
                "name": "offer",
                "icon": "mdi-offer"
            },
            {
                "name": "office-building",
                "icon": "mdi-office-building"
            },
            {
                "name": "office-building-cog",
                "icon": "mdi-office-building-cog"
            },
            {
                "name": "office-building-cog-outline",
                "icon": "mdi-office-building-cog-outline"
            },
            {
                "name": "office-building-marker",
                "icon": "mdi-office-building-marker"
            },
            {
                "name": "office-building-marker-outline",
                "icon": "mdi-office-building-marker-outline"
            },
            {
                "name": "office-building-minus",
                "icon": "mdi-office-building-minus"
            },
            {
                "name": "office-building-minus-outline",
                "icon": "mdi-office-building-minus-outline"
            },
            {
                "name": "office-building-outline",
                "icon": "mdi-office-building-outline"
            },
            {
                "name": "office-building-plus",
                "icon": "mdi-office-building-plus"
            },
            {
                "name": "office-building-plus-outline",
                "icon": "mdi-office-building-plus-outline"
            },
            {
                "name": "office-building-remove",
                "icon": "mdi-office-building-remove"
            },
            {
                "name": "office-building-remove-outline",
                "icon": "mdi-office-building-remove-outline"
            },
            {
                "name": "oil",
                "icon": "mdi-oil"
            },
            {
                "name": "oil-lamp",
                "icon": "mdi-oil-lamp"
            },
            {
                "name": "oil-level",
                "icon": "mdi-oil-level"
            },
            {
                "name": "oil-temperature",
                "icon": "mdi-oil-temperature"
            },
            {
                "name": "om",
                "icon": "mdi-om"
            },
            {
                "name": "omega",
                "icon": "mdi-omega"
            },
            {
                "name": "one-up",
                "icon": "mdi-one-up"
            },
            {
                "name": "onepassword",
                "icon": "mdi-onepassword"
            },
            {
                "name": "opacity",
                "icon": "mdi-opacity"
            },
            {
                "name": "open-in-app",
                "icon": "mdi-open-in-app"
            },
            {
                "name": "open-in-new",
                "icon": "mdi-open-in-new"
            },
            {
                "name": "open-source-initiative",
                "icon": "mdi-open-source-initiative"
            },
            {
                "name": "openid",
                "icon": "mdi-openid"
            },
            {
                "name": "opera",
                "icon": "mdi-opera"
            },
            {
                "name": "orbit",
                "icon": "mdi-orbit"
            },
            {
                "name": "orbit-variant",
                "icon": "mdi-orbit-variant"
            },
            {
                "name": "order-alphabetical-ascending",
                "icon": "mdi-order-alphabetical-ascending"
            },
            {
                "name": "order-alphabetical-descending",
                "icon": "mdi-order-alphabetical-descending"
            },
            {
                "name": "order-bool-ascending",
                "icon": "mdi-order-bool-ascending"
            },
            {
                "name": "order-bool-ascending-variant",
                "icon": "mdi-order-bool-ascending-variant"
            },
            {
                "name": "order-bool-descending",
                "icon": "mdi-order-bool-descending"
            },
            {
                "name": "order-bool-descending-variant",
                "icon": "mdi-order-bool-descending-variant"
            },
            {
                "name": "order-numeric-ascending",
                "icon": "mdi-order-numeric-ascending"
            },
            {
                "name": "order-numeric-descending",
                "icon": "mdi-order-numeric-descending"
            },
            {
                "name": "origin",
                "icon": "mdi-origin"
            },
            {
                "name": "ornament",
                "icon": "mdi-ornament"
            },
            {
                "name": "ornament-variant",
                "icon": "mdi-ornament-variant"
            },
            {
                "name": "outdoor-lamp",
                "icon": "mdi-outdoor-lamp"
            },
            {
                "name": "overscan",
                "icon": "mdi-overscan"
            },
            {
                "name": "owl",
                "icon": "mdi-owl"
            },
            {
                "name": "pac-man",
                "icon": "mdi-pac-man"
            },
            {
                "name": "package",
                "icon": "mdi-package"
            },
            {
                "name": "package-check",
                "icon": "mdi-package-check"
            },
            {
                "name": "package-down",
                "icon": "mdi-package-down"
            },
            {
                "name": "package-up",
                "icon": "mdi-package-up"
            },
            {
                "name": "package-variant",
                "icon": "mdi-package-variant"
            },
            {
                "name": "package-variant-closed",
                "icon": "mdi-package-variant-closed"
            },
            {
                "name": "package-variant-closed-check",
                "icon": "mdi-package-variant-closed-check"
            },
            {
                "name": "package-variant-closed-minus",
                "icon": "mdi-package-variant-closed-minus"
            },
            {
                "name": "package-variant-closed-plus",
                "icon": "mdi-package-variant-closed-plus"
            },
            {
                "name": "package-variant-closed-remove",
                "icon": "mdi-package-variant-closed-remove"
            },
            {
                "name": "package-variant-minus",
                "icon": "mdi-package-variant-minus"
            },
            {
                "name": "package-variant-plus",
                "icon": "mdi-package-variant-plus"
            },
            {
                "name": "package-variant-remove",
                "icon": "mdi-package-variant-remove"
            },
            {
                "name": "page-first",
                "icon": "mdi-page-first"
            },
            {
                "name": "page-last",
                "icon": "mdi-page-last"
            },
            {
                "name": "page-layout-body",
                "icon": "mdi-page-layout-body"
            },
            {
                "name": "page-layout-footer",
                "icon": "mdi-page-layout-footer"
            },
            {
                "name": "page-layout-header",
                "icon": "mdi-page-layout-header"
            },
            {
                "name": "page-layout-header-footer",
                "icon": "mdi-page-layout-header-footer"
            },
            {
                "name": "page-layout-sidebar-left",
                "icon": "mdi-page-layout-sidebar-left"
            },
            {
                "name": "page-layout-sidebar-right",
                "icon": "mdi-page-layout-sidebar-right"
            },
            {
                "name": "page-next",
                "icon": "mdi-page-next"
            },
            {
                "name": "page-next-outline",
                "icon": "mdi-page-next-outline"
            },
            {
                "name": "page-previous",
                "icon": "mdi-page-previous"
            },
            {
                "name": "page-previous-outline",
                "icon": "mdi-page-previous-outline"
            },
            {
                "name": "pail",
                "icon": "mdi-pail"
            },
            {
                "name": "pail-minus",
                "icon": "mdi-pail-minus"
            },
            {
                "name": "pail-minus-outline",
                "icon": "mdi-pail-minus-outline"
            },
            {
                "name": "pail-off",
                "icon": "mdi-pail-off"
            },
            {
                "name": "pail-off-outline",
                "icon": "mdi-pail-off-outline"
            },
            {
                "name": "pail-outline",
                "icon": "mdi-pail-outline"
            },
            {
                "name": "pail-plus",
                "icon": "mdi-pail-plus"
            },
            {
                "name": "pail-plus-outline",
                "icon": "mdi-pail-plus-outline"
            },
            {
                "name": "pail-remove",
                "icon": "mdi-pail-remove"
            },
            {
                "name": "pail-remove-outline",
                "icon": "mdi-pail-remove-outline"
            },
            {
                "name": "palette",
                "icon": "mdi-palette"
            },
            {
                "name": "palette-advanced",
                "icon": "mdi-palette-advanced"
            },
            {
                "name": "palette-outline",
                "icon": "mdi-palette-outline"
            },
            {
                "name": "palette-swatch",
                "icon": "mdi-palette-swatch"
            },
            {
                "name": "palette-swatch-outline",
                "icon": "mdi-palette-swatch-outline"
            },
            {
                "name": "palette-swatch-variant",
                "icon": "mdi-palette-swatch-variant"
            },
            {
                "name": "palm-tree",
                "icon": "mdi-palm-tree"
            },
            {
                "name": "pan",
                "icon": "mdi-pan"
            },
            {
                "name": "pan-bottom-left",
                "icon": "mdi-pan-bottom-left"
            },
            {
                "name": "pan-bottom-right",
                "icon": "mdi-pan-bottom-right"
            },
            {
                "name": "pan-down",
                "icon": "mdi-pan-down"
            },
            {
                "name": "pan-horizontal",
                "icon": "mdi-pan-horizontal"
            },
            {
                "name": "pan-left",
                "icon": "mdi-pan-left"
            },
            {
                "name": "pan-right",
                "icon": "mdi-pan-right"
            },
            {
                "name": "pan-top-left",
                "icon": "mdi-pan-top-left"
            },
            {
                "name": "pan-top-right",
                "icon": "mdi-pan-top-right"
            },
            {
                "name": "pan-up",
                "icon": "mdi-pan-up"
            },
            {
                "name": "pan-vertical",
                "icon": "mdi-pan-vertical"
            },
            {
                "name": "panda",
                "icon": "mdi-panda"
            },
            {
                "name": "pandora",
                "icon": "mdi-pandora"
            },
            {
                "name": "panorama",
                "icon": "mdi-panorama"
            },
            {
                "name": "panorama-fisheye",
                "icon": "mdi-panorama-fisheye"
            },
            {
                "name": "panorama-horizontal",
                "icon": "mdi-panorama-horizontal"
            },
            {
                "name": "panorama-horizontal-outline",
                "icon": "mdi-panorama-horizontal-outline"
            },
            {
                "name": "panorama-outline",
                "icon": "mdi-panorama-outline"
            },
            {
                "name": "panorama-sphere",
                "icon": "mdi-panorama-sphere"
            },
            {
                "name": "panorama-sphere-outline",
                "icon": "mdi-panorama-sphere-outline"
            },
            {
                "name": "panorama-variant",
                "icon": "mdi-panorama-variant"
            },
            {
                "name": "panorama-variant-outline",
                "icon": "mdi-panorama-variant-outline"
            },
            {
                "name": "panorama-vertical",
                "icon": "mdi-panorama-vertical"
            },
            {
                "name": "panorama-vertical-outline",
                "icon": "mdi-panorama-vertical-outline"
            },
            {
                "name": "panorama-wide-angle",
                "icon": "mdi-panorama-wide-angle"
            },
            {
                "name": "panorama-wide-angle-outline",
                "icon": "mdi-panorama-wide-angle-outline"
            },
            {
                "name": "paper-cut-vertical",
                "icon": "mdi-paper-cut-vertical"
            },
            {
                "name": "paper-roll",
                "icon": "mdi-paper-roll"
            },
            {
                "name": "paper-roll-outline",
                "icon": "mdi-paper-roll-outline"
            },
            {
                "name": "paperclip",
                "icon": "mdi-paperclip"
            },
            {
                "name": "paperclip-check",
                "icon": "mdi-paperclip-check"
            },
            {
                "name": "paperclip-lock",
                "icon": "mdi-paperclip-lock"
            },
            {
                "name": "paperclip-minus",
                "icon": "mdi-paperclip-minus"
            },
            {
                "name": "paperclip-off",
                "icon": "mdi-paperclip-off"
            },
            {
                "name": "paperclip-plus",
                "icon": "mdi-paperclip-plus"
            },
            {
                "name": "paperclip-remove",
                "icon": "mdi-paperclip-remove"
            },
            {
                "name": "parachute",
                "icon": "mdi-parachute"
            },
            {
                "name": "parachute-outline",
                "icon": "mdi-parachute-outline"
            },
            {
                "name": "paragliding",
                "icon": "mdi-paragliding"
            },
            {
                "name": "parking",
                "icon": "mdi-parking"
            },
            {
                "name": "party-popper",
                "icon": "mdi-party-popper"
            },
            {
                "name": "passport",
                "icon": "mdi-passport"
            },
            {
                "name": "passport-biometric",
                "icon": "mdi-passport-biometric"
            },
            {
                "name": "pasta",
                "icon": "mdi-pasta"
            },
            {
                "name": "patio-heater",
                "icon": "mdi-patio-heater"
            },
            {
                "name": "patreon",
                "icon": "mdi-patreon"
            },
            {
                "name": "pause",
                "icon": "mdi-pause"
            },
            {
                "name": "pause-box",
                "icon": "mdi-pause-box"
            },
            {
                "name": "pause-box-outline",
                "icon": "mdi-pause-box-outline"
            },
            {
                "name": "pause-circle",
                "icon": "mdi-pause-circle"
            },
            {
                "name": "pause-circle-outline",
                "icon": "mdi-pause-circle-outline"
            },
            {
                "name": "pause-octagon",
                "icon": "mdi-pause-octagon"
            },
            {
                "name": "pause-octagon-outline",
                "icon": "mdi-pause-octagon-outline"
            },
            {
                "name": "paw",
                "icon": "mdi-paw"
            },
            {
                "name": "paw-off",
                "icon": "mdi-paw-off"
            },
            {
                "name": "paw-off-outline",
                "icon": "mdi-paw-off-outline"
            },
            {
                "name": "paw-outline",
                "icon": "mdi-paw-outline"
            },
            {
                "name": "peace",
                "icon": "mdi-peace"
            },
            {
                "name": "peanut",
                "icon": "mdi-peanut"
            },
            {
                "name": "peanut-off",
                "icon": "mdi-peanut-off"
            },
            {
                "name": "peanut-off-outline",
                "icon": "mdi-peanut-off-outline"
            },
            {
                "name": "peanut-outline",
                "icon": "mdi-peanut-outline"
            },
            {
                "name": "pen",
                "icon": "mdi-pen"
            },
            {
                "name": "pen-lock",
                "icon": "mdi-pen-lock"
            },
            {
                "name": "pen-minus",
                "icon": "mdi-pen-minus"
            },
            {
                "name": "pen-off",
                "icon": "mdi-pen-off"
            },
            {
                "name": "pen-plus",
                "icon": "mdi-pen-plus"
            },
            {
                "name": "pen-remove",
                "icon": "mdi-pen-remove"
            },
            {
                "name": "pencil",
                "icon": "mdi-pencil"
            },
            {
                "name": "pencil-box",
                "icon": "mdi-pencil-box"
            },
            {
                "name": "pencil-box-multiple",
                "icon": "mdi-pencil-box-multiple"
            },
            {
                "name": "pencil-box-multiple-outline",
                "icon": "mdi-pencil-box-multiple-outline"
            },
            {
                "name": "pencil-box-outline",
                "icon": "mdi-pencil-box-outline"
            },
            {
                "name": "pencil-circle",
                "icon": "mdi-pencil-circle"
            },
            {
                "name": "pencil-circle-outline",
                "icon": "mdi-pencil-circle-outline"
            },
            {
                "name": "pencil-lock",
                "icon": "mdi-pencil-lock"
            },
            {
                "name": "pencil-lock-outline",
                "icon": "mdi-pencil-lock-outline"
            },
            {
                "name": "pencil-minus",
                "icon": "mdi-pencil-minus"
            },
            {
                "name": "pencil-minus-outline",
                "icon": "mdi-pencil-minus-outline"
            },
            {
                "name": "pencil-off",
                "icon": "mdi-pencil-off"
            },
            {
                "name": "pencil-off-outline",
                "icon": "mdi-pencil-off-outline"
            },
            {
                "name": "pencil-outline",
                "icon": "mdi-pencil-outline"
            },
            {
                "name": "pencil-plus",
                "icon": "mdi-pencil-plus"
            },
            {
                "name": "pencil-plus-outline",
                "icon": "mdi-pencil-plus-outline"
            },
            {
                "name": "pencil-remove",
                "icon": "mdi-pencil-remove"
            },
            {
                "name": "pencil-remove-outline",
                "icon": "mdi-pencil-remove-outline"
            },
            {
                "name": "pencil-ruler",
                "icon": "mdi-pencil-ruler"
            },
            {
                "name": "penguin",
                "icon": "mdi-penguin"
            },
            {
                "name": "pentagon",
                "icon": "mdi-pentagon"
            },
            {
                "name": "pentagon-outline",
                "icon": "mdi-pentagon-outline"
            },
            {
                "name": "pentagram",
                "icon": "mdi-pentagram"
            },
            {
                "name": "percent",
                "icon": "mdi-percent"
            },
            {
                "name": "percent-box",
                "icon": "mdi-percent-box"
            },
            {
                "name": "percent-box-outline",
                "icon": "mdi-percent-box-outline"
            },
            {
                "name": "percent-circle",
                "icon": "mdi-percent-circle"
            },
            {
                "name": "percent-circle-outline",
                "icon": "mdi-percent-circle-outline"
            },
            {
                "name": "percent-outline",
                "icon": "mdi-percent-outline"
            },
            {
                "name": "periodic-table",
                "icon": "mdi-periodic-table"
            },
            {
                "name": "perspective-less",
                "icon": "mdi-perspective-less"
            },
            {
                "name": "perspective-more",
                "icon": "mdi-perspective-more"
            },
            {
                "name": "ph",
                "icon": "mdi-ph"
            },
            {
                "name": "phone",
                "icon": "mdi-phone"
            },
            {
                "name": "phone-alert",
                "icon": "mdi-phone-alert"
            },
            {
                "name": "phone-alert-outline",
                "icon": "mdi-phone-alert-outline"
            },
            {
                "name": "phone-bluetooth",
                "icon": "mdi-phone-bluetooth"
            },
            {
                "name": "phone-bluetooth-outline",
                "icon": "mdi-phone-bluetooth-outline"
            },
            {
                "name": "phone-cancel",
                "icon": "mdi-phone-cancel"
            },
            {
                "name": "phone-cancel-outline",
                "icon": "mdi-phone-cancel-outline"
            },
            {
                "name": "phone-check",
                "icon": "mdi-phone-check"
            },
            {
                "name": "phone-check-outline",
                "icon": "mdi-phone-check-outline"
            },
            {
                "name": "phone-classic",
                "icon": "mdi-phone-classic"
            },
            {
                "name": "phone-classic-off",
                "icon": "mdi-phone-classic-off"
            },
            {
                "name": "phone-clock",
                "icon": "mdi-phone-clock"
            },
            {
                "name": "phone-dial",
                "icon": "mdi-phone-dial"
            },
            {
                "name": "phone-dial-outline",
                "icon": "mdi-phone-dial-outline"
            },
            {
                "name": "phone-forward",
                "icon": "mdi-phone-forward"
            },
            {
                "name": "phone-forward-outline",
                "icon": "mdi-phone-forward-outline"
            },
            {
                "name": "phone-hangup",
                "icon": "mdi-phone-hangup"
            },
            {
                "name": "phone-hangup-outline",
                "icon": "mdi-phone-hangup-outline"
            },
            {
                "name": "phone-in-talk",
                "icon": "mdi-phone-in-talk"
            },
            {
                "name": "phone-in-talk-outline",
                "icon": "mdi-phone-in-talk-outline"
            },
            {
                "name": "phone-incoming",
                "icon": "mdi-phone-incoming"
            },
            {
                "name": "phone-incoming-outgoing",
                "icon": "mdi-phone-incoming-outgoing"
            },
            {
                "name": "phone-incoming-outgoing-outline",
                "icon": "mdi-phone-incoming-outgoing-outline"
            },
            {
                "name": "phone-incoming-outline",
                "icon": "mdi-phone-incoming-outline"
            },
            {
                "name": "phone-lock",
                "icon": "mdi-phone-lock"
            },
            {
                "name": "phone-lock-outline",
                "icon": "mdi-phone-lock-outline"
            },
            {
                "name": "phone-log",
                "icon": "mdi-phone-log"
            },
            {
                "name": "phone-log-outline",
                "icon": "mdi-phone-log-outline"
            },
            {
                "name": "phone-message",
                "icon": "mdi-phone-message"
            },
            {
                "name": "phone-message-outline",
                "icon": "mdi-phone-message-outline"
            },
            {
                "name": "phone-minus",
                "icon": "mdi-phone-minus"
            },
            {
                "name": "phone-minus-outline",
                "icon": "mdi-phone-minus-outline"
            },
            {
                "name": "phone-missed",
                "icon": "mdi-phone-missed"
            },
            {
                "name": "phone-missed-outline",
                "icon": "mdi-phone-missed-outline"
            },
            {
                "name": "phone-off",
                "icon": "mdi-phone-off"
            },
            {
                "name": "phone-off-outline",
                "icon": "mdi-phone-off-outline"
            },
            {
                "name": "phone-outgoing",
                "icon": "mdi-phone-outgoing"
            },
            {
                "name": "phone-outgoing-outline",
                "icon": "mdi-phone-outgoing-outline"
            },
            {
                "name": "phone-outline",
                "icon": "mdi-phone-outline"
            },
            {
                "name": "phone-paused",
                "icon": "mdi-phone-paused"
            },
            {
                "name": "phone-paused-outline",
                "icon": "mdi-phone-paused-outline"
            },
            {
                "name": "phone-plus",
                "icon": "mdi-phone-plus"
            },
            {
                "name": "phone-plus-outline",
                "icon": "mdi-phone-plus-outline"
            },
            {
                "name": "phone-refresh",
                "icon": "mdi-phone-refresh"
            },
            {
                "name": "phone-refresh-outline",
                "icon": "mdi-phone-refresh-outline"
            },
            {
                "name": "phone-remove",
                "icon": "mdi-phone-remove"
            },
            {
                "name": "phone-remove-outline",
                "icon": "mdi-phone-remove-outline"
            },
            {
                "name": "phone-return",
                "icon": "mdi-phone-return"
            },
            {
                "name": "phone-return-outline",
                "icon": "mdi-phone-return-outline"
            },
            {
                "name": "phone-ring",
                "icon": "mdi-phone-ring"
            },
            {
                "name": "phone-ring-outline",
                "icon": "mdi-phone-ring-outline"
            },
            {
                "name": "phone-rotate-landscape",
                "icon": "mdi-phone-rotate-landscape"
            },
            {
                "name": "phone-rotate-portrait",
                "icon": "mdi-phone-rotate-portrait"
            },
            {
                "name": "phone-settings",
                "icon": "mdi-phone-settings"
            },
            {
                "name": "phone-settings-outline",
                "icon": "mdi-phone-settings-outline"
            },
            {
                "name": "phone-sync",
                "icon": "mdi-phone-sync"
            },
            {
                "name": "phone-sync-outline",
                "icon": "mdi-phone-sync-outline"
            },
            {
                "name": "phone-voip",
                "icon": "mdi-phone-voip"
            },
            {
                "name": "pi",
                "icon": "mdi-pi"
            },
            {
                "name": "pi-box",
                "icon": "mdi-pi-box"
            },
            {
                "name": "pi-hole",
                "icon": "mdi-pi-hole"
            },
            {
                "name": "piano",
                "icon": "mdi-piano"
            },
            {
                "name": "piano-off",
                "icon": "mdi-piano-off"
            },
            {
                "name": "pickaxe",
                "icon": "mdi-pickaxe"
            },
            {
                "name": "picture-in-picture-bottom-right",
                "icon": "mdi-picture-in-picture-bottom-right"
            },
            {
                "name": "picture-in-picture-bottom-right-outline",
                "icon": "mdi-picture-in-picture-bottom-right-outline"
            },
            {
                "name": "picture-in-picture-top-right",
                "icon": "mdi-picture-in-picture-top-right"
            },
            {
                "name": "picture-in-picture-top-right-outline",
                "icon": "mdi-picture-in-picture-top-right-outline"
            },
            {
                "name": "pier",
                "icon": "mdi-pier"
            },
            {
                "name": "pier-crane",
                "icon": "mdi-pier-crane"
            },
            {
                "name": "pig",
                "icon": "mdi-pig"
            },
            {
                "name": "pig-variant",
                "icon": "mdi-pig-variant"
            },
            {
                "name": "pig-variant-outline",
                "icon": "mdi-pig-variant-outline"
            },
            {
                "name": "piggy-bank",
                "icon": "mdi-piggy-bank"
            },
            {
                "name": "piggy-bank-outline",
                "icon": "mdi-piggy-bank-outline"
            },
            {
                "name": "pill",
                "icon": "mdi-pill"
            },
            {
                "name": "pill-multiple",
                "icon": "mdi-pill-multiple"
            },
            {
                "name": "pill-off",
                "icon": "mdi-pill-off"
            },
            {
                "name": "pillar",
                "icon": "mdi-pillar"
            },
            {
                "name": "pin",
                "icon": "mdi-pin"
            },
            {
                "name": "pin-off",
                "icon": "mdi-pin-off"
            },
            {
                "name": "pin-off-outline",
                "icon": "mdi-pin-off-outline"
            },
            {
                "name": "pin-outline",
                "icon": "mdi-pin-outline"
            },
            {
                "name": "pine-tree",
                "icon": "mdi-pine-tree"
            },
            {
                "name": "pine-tree-box",
                "icon": "mdi-pine-tree-box"
            },
            {
                "name": "pine-tree-fire",
                "icon": "mdi-pine-tree-fire"
            },
            {
                "name": "pinterest",
                "icon": "mdi-pinterest"
            },
            {
                "name": "pinwheel",
                "icon": "mdi-pinwheel"
            },
            {
                "name": "pinwheel-outline",
                "icon": "mdi-pinwheel-outline"
            },
            {
                "name": "pipe",
                "icon": "mdi-pipe"
            },
            {
                "name": "pipe-disconnected",
                "icon": "mdi-pipe-disconnected"
            },
            {
                "name": "pipe-leak",
                "icon": "mdi-pipe-leak"
            },
            {
                "name": "pipe-valve",
                "icon": "mdi-pipe-valve"
            },
            {
                "name": "pipe-wrench",
                "icon": "mdi-pipe-wrench"
            },
            {
                "name": "pirate",
                "icon": "mdi-pirate"
            },
            {
                "name": "pistol",
                "icon": "mdi-pistol"
            },
            {
                "name": "piston",
                "icon": "mdi-piston"
            },
            {
                "name": "pitchfork",
                "icon": "mdi-pitchfork"
            },
            {
                "name": "pizza",
                "icon": "mdi-pizza"
            },
            {
                "name": "plane-car",
                "icon": "mdi-plane-car"
            },
            {
                "name": "plane-train",
                "icon": "mdi-plane-train"
            },
            {
                "name": "play",
                "icon": "mdi-play"
            },
            {
                "name": "play-box",
                "icon": "mdi-play-box"
            },
            {
                "name": "play-box-lock",
                "icon": "mdi-play-box-lock"
            },
            {
                "name": "play-box-lock-open",
                "icon": "mdi-play-box-lock-open"
            },
            {
                "name": "play-box-lock-open-outline",
                "icon": "mdi-play-box-lock-open-outline"
            },
            {
                "name": "play-box-lock-outline",
                "icon": "mdi-play-box-lock-outline"
            },
            {
                "name": "play-box-multiple",
                "icon": "mdi-play-box-multiple"
            },
            {
                "name": "play-box-multiple-outline",
                "icon": "mdi-play-box-multiple-outline"
            },
            {
                "name": "play-box-outline",
                "icon": "mdi-play-box-outline"
            },
            {
                "name": "play-circle",
                "icon": "mdi-play-circle"
            },
            {
                "name": "play-circle-outline",
                "icon": "mdi-play-circle-outline"
            },
            {
                "name": "play-network",
                "icon": "mdi-play-network"
            },
            {
                "name": "play-network-outline",
                "icon": "mdi-play-network-outline"
            },
            {
                "name": "play-outline",
                "icon": "mdi-play-outline"
            },
            {
                "name": "play-pause",
                "icon": "mdi-play-pause"
            },
            {
                "name": "play-protected-content",
                "icon": "mdi-play-protected-content"
            },
            {
                "name": "play-speed",
                "icon": "mdi-play-speed"
            },
            {
                "name": "playlist-check",
                "icon": "mdi-playlist-check"
            },
            {
                "name": "playlist-edit",
                "icon": "mdi-playlist-edit"
            },
            {
                "name": "playlist-minus",
                "icon": "mdi-playlist-minus"
            },
            {
                "name": "playlist-music",
                "icon": "mdi-playlist-music"
            },
            {
                "name": "playlist-music-outline",
                "icon": "mdi-playlist-music-outline"
            },
            {
                "name": "playlist-play",
                "icon": "mdi-playlist-play"
            },
            {
                "name": "playlist-plus",
                "icon": "mdi-playlist-plus"
            },
            {
                "name": "playlist-remove",
                "icon": "mdi-playlist-remove"
            },
            {
                "name": "playlist-star",
                "icon": "mdi-playlist-star"
            },
            {
                "name": "plex",
                "icon": "mdi-plex"
            },
            {
                "name": "pliers",
                "icon": "mdi-pliers"
            },
            {
                "name": "plus",
                "icon": "mdi-plus"
            },
            {
                "name": "plus-box",
                "icon": "mdi-plus-box"
            },
            {
                "name": "plus-box-multiple",
                "icon": "mdi-plus-box-multiple"
            },
            {
                "name": "plus-box-multiple-outline",
                "icon": "mdi-plus-box-multiple-outline"
            },
            {
                "name": "plus-box-outline",
                "icon": "mdi-plus-box-outline"
            },
            {
                "name": "plus-circle",
                "icon": "mdi-plus-circle"
            },
            {
                "name": "plus-circle-multiple",
                "icon": "mdi-plus-circle-multiple"
            },
            {
                "name": "plus-circle-multiple-outline",
                "icon": "mdi-plus-circle-multiple-outline"
            },
            {
                "name": "plus-circle-outline",
                "icon": "mdi-plus-circle-outline"
            },
            {
                "name": "plus-lock",
                "icon": "mdi-plus-lock"
            },
            {
                "name": "plus-lock-open",
                "icon": "mdi-plus-lock-open"
            },
            {
                "name": "plus-minus",
                "icon": "mdi-plus-minus"
            },
            {
                "name": "plus-minus-box",
                "icon": "mdi-plus-minus-box"
            },
            {
                "name": "plus-minus-variant",
                "icon": "mdi-plus-minus-variant"
            },
            {
                "name": "plus-network",
                "icon": "mdi-plus-network"
            },
            {
                "name": "plus-network-outline",
                "icon": "mdi-plus-network-outline"
            },
            {
                "name": "plus-outline",
                "icon": "mdi-plus-outline"
            },
            {
                "name": "plus-thick",
                "icon": "mdi-plus-thick"
            },
            {
                "name": "podcast",
                "icon": "mdi-podcast"
            },
            {
                "name": "podium",
                "icon": "mdi-podium"
            },
            {
                "name": "podium-bronze",
                "icon": "mdi-podium-bronze"
            },
            {
                "name": "podium-gold",
                "icon": "mdi-podium-gold"
            },
            {
                "name": "podium-silver",
                "icon": "mdi-podium-silver"
            },
            {
                "name": "point-of-sale",
                "icon": "mdi-point-of-sale"
            },
            {
                "name": "pokeball",
                "icon": "mdi-pokeball"
            },
            {
                "name": "pokemon-go",
                "icon": "mdi-pokemon-go"
            },
            {
                "name": "poker-chip",
                "icon": "mdi-poker-chip"
            },
            {
                "name": "polaroid",
                "icon": "mdi-polaroid"
            },
            {
                "name": "police-badge",
                "icon": "mdi-police-badge"
            },
            {
                "name": "police-badge-outline",
                "icon": "mdi-police-badge-outline"
            },
            {
                "name": "police-station",
                "icon": "mdi-police-station"
            },
            {
                "name": "poll",
                "icon": "mdi-poll"
            },
            {
                "name": "polo",
                "icon": "mdi-polo"
            },
            {
                "name": "polymer",
                "icon": "mdi-polymer"
            },
            {
                "name": "pool",
                "icon": "mdi-pool"
            },
            {
                "name": "pool-thermometer",
                "icon": "mdi-pool-thermometer"
            },
            {
                "name": "popcorn",
                "icon": "mdi-popcorn"
            },
            {
                "name": "post",
                "icon": "mdi-post"
            },
            {
                "name": "post-lamp",
                "icon": "mdi-post-lamp"
            },
            {
                "name": "post-outline",
                "icon": "mdi-post-outline"
            },
            {
                "name": "postage-stamp",
                "icon": "mdi-postage-stamp"
            },
            {
                "name": "pot",
                "icon": "mdi-pot"
            },
            {
                "name": "pot-mix",
                "icon": "mdi-pot-mix"
            },
            {
                "name": "pot-mix-outline",
                "icon": "mdi-pot-mix-outline"
            },
            {
                "name": "pot-outline",
                "icon": "mdi-pot-outline"
            },
            {
                "name": "pot-steam",
                "icon": "mdi-pot-steam"
            },
            {
                "name": "pot-steam-outline",
                "icon": "mdi-pot-steam-outline"
            },
            {
                "name": "pound",
                "icon": "mdi-pound"
            },
            {
                "name": "pound-box",
                "icon": "mdi-pound-box"
            },
            {
                "name": "pound-box-outline",
                "icon": "mdi-pound-box-outline"
            },
            {
                "name": "power",
                "icon": "mdi-power"
            },
            {
                "name": "power-cycle",
                "icon": "mdi-power-cycle"
            },
            {
                "name": "power-off",
                "icon": "mdi-power-off"
            },
            {
                "name": "power-on",
                "icon": "mdi-power-on"
            },
            {
                "name": "power-plug",
                "icon": "mdi-power-plug"
            },
            {
                "name": "power-plug-off",
                "icon": "mdi-power-plug-off"
            },
            {
                "name": "power-plug-off-outline",
                "icon": "mdi-power-plug-off-outline"
            },
            {
                "name": "power-plug-outline",
                "icon": "mdi-power-plug-outline"
            },
            {
                "name": "power-settings",
                "icon": "mdi-power-settings"
            },
            {
                "name": "power-sleep",
                "icon": "mdi-power-sleep"
            },
            {
                "name": "power-socket",
                "icon": "mdi-power-socket"
            },
            {
                "name": "power-socket-au",
                "icon": "mdi-power-socket-au"
            },
            {
                "name": "power-socket-ch",
                "icon": "mdi-power-socket-ch"
            },
            {
                "name": "power-socket-de",
                "icon": "mdi-power-socket-de"
            },
            {
                "name": "power-socket-eu",
                "icon": "mdi-power-socket-eu"
            },
            {
                "name": "power-socket-fr",
                "icon": "mdi-power-socket-fr"
            },
            {
                "name": "power-socket-it",
                "icon": "mdi-power-socket-it"
            },
            {
                "name": "power-socket-jp",
                "icon": "mdi-power-socket-jp"
            },
            {
                "name": "power-socket-uk",
                "icon": "mdi-power-socket-uk"
            },
            {
                "name": "power-socket-us",
                "icon": "mdi-power-socket-us"
            },
            {
                "name": "power-standby",
                "icon": "mdi-power-standby"
            },
            {
                "name": "powershell",
                "icon": "mdi-powershell"
            },
            {
                "name": "prescription",
                "icon": "mdi-prescription"
            },
            {
                "name": "presentation",
                "icon": "mdi-presentation"
            },
            {
                "name": "presentation-play",
                "icon": "mdi-presentation-play"
            },
            {
                "name": "pretzel",
                "icon": "mdi-pretzel"
            },
            {
                "name": "printer",
                "icon": "mdi-printer"
            },
            {
                "name": "printer-3d",
                "icon": "mdi-printer-3d"
            },
            {
                "name": "printer-3d-nozzle",
                "icon": "mdi-printer-3d-nozzle"
            },
            {
                "name": "printer-3d-nozzle-alert",
                "icon": "mdi-printer-3d-nozzle-alert"
            },
            {
                "name": "printer-3d-nozzle-alert-outline",
                "icon": "mdi-printer-3d-nozzle-alert-outline"
            },
            {
                "name": "printer-3d-nozzle-heat",
                "icon": "mdi-printer-3d-nozzle-heat"
            },
            {
                "name": "printer-3d-nozzle-heat-outline",
                "icon": "mdi-printer-3d-nozzle-heat-outline"
            },
            {
                "name": "printer-3d-nozzle-off",
                "icon": "mdi-printer-3d-nozzle-off"
            },
            {
                "name": "printer-3d-nozzle-off-outline",
                "icon": "mdi-printer-3d-nozzle-off-outline"
            },
            {
                "name": "printer-3d-nozzle-outline",
                "icon": "mdi-printer-3d-nozzle-outline"
            },
            {
                "name": "printer-3d-off",
                "icon": "mdi-printer-3d-off"
            },
            {
                "name": "printer-alert",
                "icon": "mdi-printer-alert"
            },
            {
                "name": "printer-check",
                "icon": "mdi-printer-check"
            },
            {
                "name": "printer-eye",
                "icon": "mdi-printer-eye"
            },
            {
                "name": "printer-off",
                "icon": "mdi-printer-off"
            },
            {
                "name": "printer-off-outline",
                "icon": "mdi-printer-off-outline"
            },
            {
                "name": "printer-outline",
                "icon": "mdi-printer-outline"
            },
            {
                "name": "printer-pos",
                "icon": "mdi-printer-pos"
            },
            {
                "name": "printer-pos-alert",
                "icon": "mdi-printer-pos-alert"
            },
            {
                "name": "printer-pos-alert-outline",
                "icon": "mdi-printer-pos-alert-outline"
            },
            {
                "name": "printer-pos-cancel",
                "icon": "mdi-printer-pos-cancel"
            },
            {
                "name": "printer-pos-cancel-outline",
                "icon": "mdi-printer-pos-cancel-outline"
            },
            {
                "name": "printer-pos-check",
                "icon": "mdi-printer-pos-check"
            },
            {
                "name": "printer-pos-check-outline",
                "icon": "mdi-printer-pos-check-outline"
            },
            {
                "name": "printer-pos-cog",
                "icon": "mdi-printer-pos-cog"
            },
            {
                "name": "printer-pos-cog-outline",
                "icon": "mdi-printer-pos-cog-outline"
            },
            {
                "name": "printer-pos-edit",
                "icon": "mdi-printer-pos-edit"
            },
            {
                "name": "printer-pos-edit-outline",
                "icon": "mdi-printer-pos-edit-outline"
            },
            {
                "name": "printer-pos-minus",
                "icon": "mdi-printer-pos-minus"
            },
            {
                "name": "printer-pos-minus-outline",
                "icon": "mdi-printer-pos-minus-outline"
            },
            {
                "name": "printer-pos-network",
                "icon": "mdi-printer-pos-network"
            },
            {
                "name": "printer-pos-network-outline",
                "icon": "mdi-printer-pos-network-outline"
            },
            {
                "name": "printer-pos-off",
                "icon": "mdi-printer-pos-off"
            },
            {
                "name": "printer-pos-off-outline",
                "icon": "mdi-printer-pos-off-outline"
            },
            {
                "name": "printer-pos-outline",
                "icon": "mdi-printer-pos-outline"
            },
            {
                "name": "printer-pos-pause",
                "icon": "mdi-printer-pos-pause"
            },
            {
                "name": "printer-pos-pause-outline",
                "icon": "mdi-printer-pos-pause-outline"
            },
            {
                "name": "printer-pos-play",
                "icon": "mdi-printer-pos-play"
            },
            {
                "name": "printer-pos-play-outline",
                "icon": "mdi-printer-pos-play-outline"
            },
            {
                "name": "printer-pos-plus",
                "icon": "mdi-printer-pos-plus"
            },
            {
                "name": "printer-pos-plus-outline",
                "icon": "mdi-printer-pos-plus-outline"
            },
            {
                "name": "printer-pos-refresh",
                "icon": "mdi-printer-pos-refresh"
            },
            {
                "name": "printer-pos-refresh-outline",
                "icon": "mdi-printer-pos-refresh-outline"
            },
            {
                "name": "printer-pos-remove",
                "icon": "mdi-printer-pos-remove"
            },
            {
                "name": "printer-pos-remove-outline",
                "icon": "mdi-printer-pos-remove-outline"
            },
            {
                "name": "printer-pos-star",
                "icon": "mdi-printer-pos-star"
            },
            {
                "name": "printer-pos-star-outline",
                "icon": "mdi-printer-pos-star-outline"
            },
            {
                "name": "printer-pos-stop",
                "icon": "mdi-printer-pos-stop"
            },
            {
                "name": "printer-pos-stop-outline",
                "icon": "mdi-printer-pos-stop-outline"
            },
            {
                "name": "printer-pos-sync",
                "icon": "mdi-printer-pos-sync"
            },
            {
                "name": "printer-pos-sync-outline",
                "icon": "mdi-printer-pos-sync-outline"
            },
            {
                "name": "printer-pos-wrench",
                "icon": "mdi-printer-pos-wrench"
            },
            {
                "name": "printer-pos-wrench-outline",
                "icon": "mdi-printer-pos-wrench-outline"
            },
            {
                "name": "printer-search",
                "icon": "mdi-printer-search"
            },
            {
                "name": "printer-settings",
                "icon": "mdi-printer-settings"
            },
            {
                "name": "printer-wireless",
                "icon": "mdi-printer-wireless"
            },
            {
                "name": "priority-high",
                "icon": "mdi-priority-high"
            },
            {
                "name": "priority-low",
                "icon": "mdi-priority-low"
            },
            {
                "name": "professional-hexagon",
                "icon": "mdi-professional-hexagon"
            },
            {
                "name": "progress-alert",
                "icon": "mdi-progress-alert"
            },
            {
                "name": "progress-check",
                "icon": "mdi-progress-check"
            },
            {
                "name": "progress-clock",
                "icon": "mdi-progress-clock"
            },
            {
                "name": "progress-close",
                "icon": "mdi-progress-close"
            },
            {
                "name": "progress-download",
                "icon": "mdi-progress-download"
            },
            {
                "name": "progress-helper",
                "icon": "mdi-progress-helper"
            },
            {
                "name": "progress-pencil",
                "icon": "mdi-progress-pencil"
            },
            {
                "name": "progress-question",
                "icon": "mdi-progress-question"
            },
            {
                "name": "progress-star",
                "icon": "mdi-progress-star"
            },
            {
                "name": "progress-upload",
                "icon": "mdi-progress-upload"
            },
            {
                "name": "progress-wrench",
                "icon": "mdi-progress-wrench"
            },
            {
                "name": "projector",
                "icon": "mdi-projector"
            },
            {
                "name": "projector-off",
                "icon": "mdi-projector-off"
            },
            {
                "name": "projector-screen",
                "icon": "mdi-projector-screen"
            },
            {
                "name": "projector-screen-off",
                "icon": "mdi-projector-screen-off"
            },
            {
                "name": "projector-screen-off-outline",
                "icon": "mdi-projector-screen-off-outline"
            },
            {
                "name": "projector-screen-outline",
                "icon": "mdi-projector-screen-outline"
            },
            {
                "name": "projector-screen-variant",
                "icon": "mdi-projector-screen-variant"
            },
            {
                "name": "projector-screen-variant-off",
                "icon": "mdi-projector-screen-variant-off"
            },
            {
                "name": "projector-screen-variant-off-outline",
                "icon": "mdi-projector-screen-variant-off-outline"
            },
            {
                "name": "projector-screen-variant-outline",
                "icon": "mdi-projector-screen-variant-outline"
            },
            {
                "name": "propane-tank",
                "icon": "mdi-propane-tank"
            },
            {
                "name": "propane-tank-outline",
                "icon": "mdi-propane-tank-outline"
            },
            {
                "name": "protocol",
                "icon": "mdi-protocol"
            },
            {
                "name": "publish",
                "icon": "mdi-publish"
            },
            {
                "name": "publish-off",
                "icon": "mdi-publish-off"
            },
            {
                "name": "pulse",
                "icon": "mdi-pulse"
            },
            {
                "name": "pump",
                "icon": "mdi-pump"
            },
            {
                "name": "pump-off",
                "icon": "mdi-pump-off"
            },
            {
                "name": "pumpkin",
                "icon": "mdi-pumpkin"
            },
            {
                "name": "purse",
                "icon": "mdi-purse"
            },
            {
                "name": "purse-outline",
                "icon": "mdi-purse-outline"
            },
            {
                "name": "puzzle",
                "icon": "mdi-puzzle"
            },
            {
                "name": "puzzle-check",
                "icon": "mdi-puzzle-check"
            },
            {
                "name": "puzzle-check-outline",
                "icon": "mdi-puzzle-check-outline"
            },
            {
                "name": "puzzle-edit",
                "icon": "mdi-puzzle-edit"
            },
            {
                "name": "puzzle-edit-outline",
                "icon": "mdi-puzzle-edit-outline"
            },
            {
                "name": "puzzle-heart",
                "icon": "mdi-puzzle-heart"
            },
            {
                "name": "puzzle-heart-outline",
                "icon": "mdi-puzzle-heart-outline"
            },
            {
                "name": "puzzle-minus",
                "icon": "mdi-puzzle-minus"
            },
            {
                "name": "puzzle-minus-outline",
                "icon": "mdi-puzzle-minus-outline"
            },
            {
                "name": "puzzle-outline",
                "icon": "mdi-puzzle-outline"
            },
            {
                "name": "puzzle-plus",
                "icon": "mdi-puzzle-plus"
            },
            {
                "name": "puzzle-plus-outline",
                "icon": "mdi-puzzle-plus-outline"
            },
            {
                "name": "puzzle-remove",
                "icon": "mdi-puzzle-remove"
            },
            {
                "name": "puzzle-remove-outline",
                "icon": "mdi-puzzle-remove-outline"
            },
            {
                "name": "puzzle-star",
                "icon": "mdi-puzzle-star"
            },
            {
                "name": "puzzle-star-outline",
                "icon": "mdi-puzzle-star-outline"
            },
            {
                "name": "pyramid",
                "icon": "mdi-pyramid"
            },
            {
                "name": "pyramid-off",
                "icon": "mdi-pyramid-off"
            },
            {
                "name": "qi",
                "icon": "mdi-qi"
            },
            {
                "name": "qqchat",
                "icon": "mdi-qqchat"
            },
            {
                "name": "qrcode",
                "icon": "mdi-qrcode"
            },
            {
                "name": "qrcode-edit",
                "icon": "mdi-qrcode-edit"
            },
            {
                "name": "qrcode-minus",
                "icon": "mdi-qrcode-minus"
            },
            {
                "name": "qrcode-plus",
                "icon": "mdi-qrcode-plus"
            },
            {
                "name": "qrcode-remove",
                "icon": "mdi-qrcode-remove"
            },
            {
                "name": "qrcode-scan",
                "icon": "mdi-qrcode-scan"
            },
            {
                "name": "quadcopter",
                "icon": "mdi-quadcopter"
            },
            {
                "name": "quality-high",
                "icon": "mdi-quality-high"
            },
            {
                "name": "quality-low",
                "icon": "mdi-quality-low"
            },
            {
                "name": "quality-medium",
                "icon": "mdi-quality-medium"
            },
            {
                "name": "quora",
                "icon": "mdi-quora"
            },
            {
                "name": "rabbit",
                "icon": "mdi-rabbit"
            },
            {
                "name": "rabbit-variant",
                "icon": "mdi-rabbit-variant"
            },
            {
                "name": "rabbit-variant-outline",
                "icon": "mdi-rabbit-variant-outline"
            },
            {
                "name": "racing-helmet",
                "icon": "mdi-racing-helmet"
            },
            {
                "name": "racquetball",
                "icon": "mdi-racquetball"
            },
            {
                "name": "radar",
                "icon": "mdi-radar"
            },
            {
                "name": "radiator",
                "icon": "mdi-radiator"
            },
            {
                "name": "radiator-disabled",
                "icon": "mdi-radiator-disabled"
            },
            {
                "name": "radiator-off",
                "icon": "mdi-radiator-off"
            },
            {
                "name": "radio",
                "icon": "mdi-radio"
            },
            {
                "name": "radio-am",
                "icon": "mdi-radio-am"
            },
            {
                "name": "radio-fm",
                "icon": "mdi-radio-fm"
            },
            {
                "name": "radio-handheld",
                "icon": "mdi-radio-handheld"
            },
            {
                "name": "radio-off",
                "icon": "mdi-radio-off"
            },
            {
                "name": "radio-tower",
                "icon": "mdi-radio-tower"
            },
            {
                "name": "radioactive",
                "icon": "mdi-radioactive"
            },
            {
                "name": "radioactive-circle",
                "icon": "mdi-radioactive-circle"
            },
            {
                "name": "radioactive-circle-outline",
                "icon": "mdi-radioactive-circle-outline"
            },
            {
                "name": "radioactive-off",
                "icon": "mdi-radioactive-off"
            },
            {
                "name": "radiobox-blank",
                "icon": "mdi-radiobox-blank"
            },
            {
                "name": "radiobox-marked",
                "icon": "mdi-radiobox-marked"
            },
            {
                "name": "radiology-box",
                "icon": "mdi-radiology-box"
            },
            {
                "name": "radiology-box-outline",
                "icon": "mdi-radiology-box-outline"
            },
            {
                "name": "radius",
                "icon": "mdi-radius"
            },
            {
                "name": "radius-outline",
                "icon": "mdi-radius-outline"
            },
            {
                "name": "railroad-light",
                "icon": "mdi-railroad-light"
            },
            {
                "name": "rake",
                "icon": "mdi-rake"
            },
            {
                "name": "raspberry-pi",
                "icon": "mdi-raspberry-pi"
            },
            {
                "name": "raw",
                "icon": "mdi-raw"
            },
            {
                "name": "raw-off",
                "icon": "mdi-raw-off"
            },
            {
                "name": "ray-end",
                "icon": "mdi-ray-end"
            },
            {
                "name": "ray-end-arrow",
                "icon": "mdi-ray-end-arrow"
            },
            {
                "name": "ray-start",
                "icon": "mdi-ray-start"
            },
            {
                "name": "ray-start-arrow",
                "icon": "mdi-ray-start-arrow"
            },
            {
                "name": "ray-start-end",
                "icon": "mdi-ray-start-end"
            },
            {
                "name": "ray-start-vertex-end",
                "icon": "mdi-ray-start-vertex-end"
            },
            {
                "name": "ray-vertex",
                "icon": "mdi-ray-vertex"
            },
            {
                "name": "razor-double-edge",
                "icon": "mdi-razor-double-edge"
            },
            {
                "name": "razor-single-edge",
                "icon": "mdi-razor-single-edge"
            },
            {
                "name": "react",
                "icon": "mdi-react"
            },
            {
                "name": "read",
                "icon": "mdi-read"
            },
            {
                "name": "receipt",
                "icon": "mdi-receipt"
            },
            {
                "name": "receipt-outline",
                "icon": "mdi-receipt-outline"
            },
            {
                "name": "receipt-text",
                "icon": "mdi-receipt-text"
            },
            {
                "name": "receipt-text-check",
                "icon": "mdi-receipt-text-check"
            },
            {
                "name": "receipt-text-check-outline",
                "icon": "mdi-receipt-text-check-outline"
            },
            {
                "name": "receipt-text-minus",
                "icon": "mdi-receipt-text-minus"
            },
            {
                "name": "receipt-text-minus-outline",
                "icon": "mdi-receipt-text-minus-outline"
            },
            {
                "name": "receipt-text-outline",
                "icon": "mdi-receipt-text-outline"
            },
            {
                "name": "receipt-text-plus",
                "icon": "mdi-receipt-text-plus"
            },
            {
                "name": "receipt-text-plus-outline",
                "icon": "mdi-receipt-text-plus-outline"
            },
            {
                "name": "receipt-text-remove",
                "icon": "mdi-receipt-text-remove"
            },
            {
                "name": "receipt-text-remove-outline",
                "icon": "mdi-receipt-text-remove-outline"
            },
            {
                "name": "record",
                "icon": "mdi-record"
            },
            {
                "name": "record-circle",
                "icon": "mdi-record-circle"
            },
            {
                "name": "record-circle-outline",
                "icon": "mdi-record-circle-outline"
            },
            {
                "name": "record-player",
                "icon": "mdi-record-player"
            },
            {
                "name": "record-rec",
                "icon": "mdi-record-rec"
            },
            {
                "name": "rectangle",
                "icon": "mdi-rectangle"
            },
            {
                "name": "rectangle-outline",
                "icon": "mdi-rectangle-outline"
            },
            {
                "name": "recycle",
                "icon": "mdi-recycle"
            },
            {
                "name": "recycle-variant",
                "icon": "mdi-recycle-variant"
            },
            {
                "name": "reddit",
                "icon": "mdi-reddit"
            },
            {
                "name": "redhat",
                "icon": "mdi-redhat"
            },
            {
                "name": "redo",
                "icon": "mdi-redo"
            },
            {
                "name": "redo-variant",
                "icon": "mdi-redo-variant"
            },
            {
                "name": "reflect-horizontal",
                "icon": "mdi-reflect-horizontal"
            },
            {
                "name": "reflect-vertical",
                "icon": "mdi-reflect-vertical"
            },
            {
                "name": "refresh",
                "icon": "mdi-refresh"
            },
            {
                "name": "refresh-auto",
                "icon": "mdi-refresh-auto"
            },
            {
                "name": "refresh-circle",
                "icon": "mdi-refresh-circle"
            },
            {
                "name": "regex",
                "icon": "mdi-regex"
            },
            {
                "name": "registered-trademark",
                "icon": "mdi-registered-trademark"
            },
            {
                "name": "reiterate",
                "icon": "mdi-reiterate"
            },
            {
                "name": "relation-many-to-many",
                "icon": "mdi-relation-many-to-many"
            },
            {
                "name": "relation-many-to-one",
                "icon": "mdi-relation-many-to-one"
            },
            {
                "name": "relation-many-to-one-or-many",
                "icon": "mdi-relation-many-to-one-or-many"
            },
            {
                "name": "relation-many-to-only-one",
                "icon": "mdi-relation-many-to-only-one"
            },
            {
                "name": "relation-many-to-zero-or-many",
                "icon": "mdi-relation-many-to-zero-or-many"
            },
            {
                "name": "relation-many-to-zero-or-one",
                "icon": "mdi-relation-many-to-zero-or-one"
            },
            {
                "name": "relation-one-or-many-to-many",
                "icon": "mdi-relation-one-or-many-to-many"
            },
            {
                "name": "relation-one-or-many-to-one",
                "icon": "mdi-relation-one-or-many-to-one"
            },
            {
                "name": "relation-one-or-many-to-one-or-many",
                "icon": "mdi-relation-one-or-many-to-one-or-many"
            },
            {
                "name": "relation-one-or-many-to-only-one",
                "icon": "mdi-relation-one-or-many-to-only-one"
            },
            {
                "name": "relation-one-or-many-to-zero-or-many",
                "icon": "mdi-relation-one-or-many-to-zero-or-many"
            },
            {
                "name": "relation-one-or-many-to-zero-or-one",
                "icon": "mdi-relation-one-or-many-to-zero-or-one"
            },
            {
                "name": "relation-one-to-many",
                "icon": "mdi-relation-one-to-many"
            },
            {
                "name": "relation-one-to-one",
                "icon": "mdi-relation-one-to-one"
            },
            {
                "name": "relation-one-to-one-or-many",
                "icon": "mdi-relation-one-to-one-or-many"
            },
            {
                "name": "relation-one-to-only-one",
                "icon": "mdi-relation-one-to-only-one"
            },
            {
                "name": "relation-one-to-zero-or-many",
                "icon": "mdi-relation-one-to-zero-or-many"
            },
            {
                "name": "relation-one-to-zero-or-one",
                "icon": "mdi-relation-one-to-zero-or-one"
            },
            {
                "name": "relation-only-one-to-many",
                "icon": "mdi-relation-only-one-to-many"
            },
            {
                "name": "relation-only-one-to-one",
                "icon": "mdi-relation-only-one-to-one"
            },
            {
                "name": "relation-only-one-to-one-or-many",
                "icon": "mdi-relation-only-one-to-one-or-many"
            },
            {
                "name": "relation-only-one-to-only-one",
                "icon": "mdi-relation-only-one-to-only-one"
            },
            {
                "name": "relation-only-one-to-zero-or-many",
                "icon": "mdi-relation-only-one-to-zero-or-many"
            },
            {
                "name": "relation-only-one-to-zero-or-one",
                "icon": "mdi-relation-only-one-to-zero-or-one"
            },
            {
                "name": "relation-zero-or-many-to-many",
                "icon": "mdi-relation-zero-or-many-to-many"
            },
            {
                "name": "relation-zero-or-many-to-one",
                "icon": "mdi-relation-zero-or-many-to-one"
            },
            {
                "name": "relation-zero-or-many-to-one-or-many",
                "icon": "mdi-relation-zero-or-many-to-one-or-many"
            },
            {
                "name": "relation-zero-or-many-to-only-one",
                "icon": "mdi-relation-zero-or-many-to-only-one"
            },
            {
                "name": "relation-zero-or-many-to-zero-or-many",
                "icon": "mdi-relation-zero-or-many-to-zero-or-many"
            },
            {
                "name": "relation-zero-or-many-to-zero-or-one",
                "icon": "mdi-relation-zero-or-many-to-zero-or-one"
            },
            {
                "name": "relation-zero-or-one-to-many",
                "icon": "mdi-relation-zero-or-one-to-many"
            },
            {
                "name": "relation-zero-or-one-to-one",
                "icon": "mdi-relation-zero-or-one-to-one"
            },
            {
                "name": "relation-zero-or-one-to-one-or-many",
                "icon": "mdi-relation-zero-or-one-to-one-or-many"
            },
            {
                "name": "relation-zero-or-one-to-only-one",
                "icon": "mdi-relation-zero-or-one-to-only-one"
            },
            {
                "name": "relation-zero-or-one-to-zero-or-many",
                "icon": "mdi-relation-zero-or-one-to-zero-or-many"
            },
            {
                "name": "relation-zero-or-one-to-zero-or-one",
                "icon": "mdi-relation-zero-or-one-to-zero-or-one"
            },
            {
                "name": "relative-scale",
                "icon": "mdi-relative-scale"
            },
            {
                "name": "reload",
                "icon": "mdi-reload"
            },
            {
                "name": "reload-alert",
                "icon": "mdi-reload-alert"
            },
            {
                "name": "reminder",
                "icon": "mdi-reminder"
            },
            {
                "name": "remote",
                "icon": "mdi-remote"
            },
            {
                "name": "remote-desktop",
                "icon": "mdi-remote-desktop"
            },
            {
                "name": "remote-off",
                "icon": "mdi-remote-off"
            },
            {
                "name": "remote-tv",
                "icon": "mdi-remote-tv"
            },
            {
                "name": "remote-tv-off",
                "icon": "mdi-remote-tv-off"
            },
            {
                "name": "rename-box",
                "icon": "mdi-rename-box"
            },
            {
                "name": "reorder-horizontal",
                "icon": "mdi-reorder-horizontal"
            },
            {
                "name": "reorder-vertical",
                "icon": "mdi-reorder-vertical"
            },
            {
                "name": "repeat",
                "icon": "mdi-repeat"
            },
            {
                "name": "repeat-off",
                "icon": "mdi-repeat-off"
            },
            {
                "name": "repeat-once",
                "icon": "mdi-repeat-once"
            },
            {
                "name": "repeat-variant",
                "icon": "mdi-repeat-variant"
            },
            {
                "name": "replay",
                "icon": "mdi-replay"
            },
            {
                "name": "reply",
                "icon": "mdi-reply"
            },
            {
                "name": "reply-all",
                "icon": "mdi-reply-all"
            },
            {
                "name": "reply-all-outline",
                "icon": "mdi-reply-all-outline"
            },
            {
                "name": "reply-circle",
                "icon": "mdi-reply-circle"
            },
            {
                "name": "reply-outline",
                "icon": "mdi-reply-outline"
            },
            {
                "name": "reproduction",
                "icon": "mdi-reproduction"
            },
            {
                "name": "resistor",
                "icon": "mdi-resistor"
            },
            {
                "name": "resistor-nodes",
                "icon": "mdi-resistor-nodes"
            },
            {
                "name": "resize",
                "icon": "mdi-resize"
            },
            {
                "name": "resize-bottom-right",
                "icon": "mdi-resize-bottom-right"
            },
            {
                "name": "responsive",
                "icon": "mdi-responsive"
            },
            {
                "name": "restart",
                "icon": "mdi-restart"
            },
            {
                "name": "restart-alert",
                "icon": "mdi-restart-alert"
            },
            {
                "name": "restart-off",
                "icon": "mdi-restart-off"
            },
            {
                "name": "restore",
                "icon": "mdi-restore"
            },
            {
                "name": "restore-alert",
                "icon": "mdi-restore-alert"
            },
            {
                "name": "rewind",
                "icon": "mdi-rewind"
            },
            {
                "name": "rewind-10",
                "icon": "mdi-rewind-10"
            },
            {
                "name": "rewind-15",
                "icon": "mdi-rewind-15"
            },
            {
                "name": "rewind-30",
                "icon": "mdi-rewind-30"
            },
            {
                "name": "rewind-45",
                "icon": "mdi-rewind-45"
            },
            {
                "name": "rewind-5",
                "icon": "mdi-rewind-5"
            },
            {
                "name": "rewind-60",
                "icon": "mdi-rewind-60"
            },
            {
                "name": "rewind-outline",
                "icon": "mdi-rewind-outline"
            },
            {
                "name": "rhombus",
                "icon": "mdi-rhombus"
            },
            {
                "name": "rhombus-medium",
                "icon": "mdi-rhombus-medium"
            },
            {
                "name": "rhombus-medium-outline",
                "icon": "mdi-rhombus-medium-outline"
            },
            {
                "name": "rhombus-outline",
                "icon": "mdi-rhombus-outline"
            },
            {
                "name": "rhombus-split",
                "icon": "mdi-rhombus-split"
            },
            {
                "name": "rhombus-split-outline",
                "icon": "mdi-rhombus-split-outline"
            },
            {
                "name": "ribbon",
                "icon": "mdi-ribbon"
            },
            {
                "name": "rice",
                "icon": "mdi-rice"
            },
            {
                "name": "rickshaw",
                "icon": "mdi-rickshaw"
            },
            {
                "name": "rickshaw-electric",
                "icon": "mdi-rickshaw-electric"
            },
            {
                "name": "ring",
                "icon": "mdi-ring"
            },
            {
                "name": "rivet",
                "icon": "mdi-rivet"
            },
            {
                "name": "road",
                "icon": "mdi-road"
            },
            {
                "name": "road-variant",
                "icon": "mdi-road-variant"
            },
            {
                "name": "robber",
                "icon": "mdi-robber"
            },
            {
                "name": "robot",
                "icon": "mdi-robot"
            },
            {
                "name": "robot-angry",
                "icon": "mdi-robot-angry"
            },
            {
                "name": "robot-angry-outline",
                "icon": "mdi-robot-angry-outline"
            },
            {
                "name": "robot-confused",
                "icon": "mdi-robot-confused"
            },
            {
                "name": "robot-confused-outline",
                "icon": "mdi-robot-confused-outline"
            },
            {
                "name": "robot-dead",
                "icon": "mdi-robot-dead"
            },
            {
                "name": "robot-dead-outline",
                "icon": "mdi-robot-dead-outline"
            },
            {
                "name": "robot-excited",
                "icon": "mdi-robot-excited"
            },
            {
                "name": "robot-excited-outline",
                "icon": "mdi-robot-excited-outline"
            },
            {
                "name": "robot-happy",
                "icon": "mdi-robot-happy"
            },
            {
                "name": "robot-happy-outline",
                "icon": "mdi-robot-happy-outline"
            },
            {
                "name": "robot-industrial",
                "icon": "mdi-robot-industrial"
            },
            {
                "name": "robot-industrial-outline",
                "icon": "mdi-robot-industrial-outline"
            },
            {
                "name": "robot-love",
                "icon": "mdi-robot-love"
            },
            {
                "name": "robot-love-outline",
                "icon": "mdi-robot-love-outline"
            },
            {
                "name": "robot-mower",
                "icon": "mdi-robot-mower"
            },
            {
                "name": "robot-mower-outline",
                "icon": "mdi-robot-mower-outline"
            },
            {
                "name": "robot-off",
                "icon": "mdi-robot-off"
            },
            {
                "name": "robot-off-outline",
                "icon": "mdi-robot-off-outline"
            },
            {
                "name": "robot-outline",
                "icon": "mdi-robot-outline"
            },
            {
                "name": "robot-vacuum",
                "icon": "mdi-robot-vacuum"
            },
            {
                "name": "robot-vacuum-alert",
                "icon": "mdi-robot-vacuum-alert"
            },
            {
                "name": "robot-vacuum-variant",
                "icon": "mdi-robot-vacuum-variant"
            },
            {
                "name": "robot-vacuum-variant-alert",
                "icon": "mdi-robot-vacuum-variant-alert"
            },
            {
                "name": "rocket",
                "icon": "mdi-rocket"
            },
            {
                "name": "rocket-launch",
                "icon": "mdi-rocket-launch"
            },
            {
                "name": "rocket-launch-outline",
                "icon": "mdi-rocket-launch-outline"
            },
            {
                "name": "rocket-outline",
                "icon": "mdi-rocket-outline"
            },
            {
                "name": "rodent",
                "icon": "mdi-rodent"
            },
            {
                "name": "roller-shade",
                "icon": "mdi-roller-shade"
            },
            {
                "name": "roller-shade-closed",
                "icon": "mdi-roller-shade-closed"
            },
            {
                "name": "roller-skate",
                "icon": "mdi-roller-skate"
            },
            {
                "name": "roller-skate-off",
                "icon": "mdi-roller-skate-off"
            },
            {
                "name": "rollerblade",
                "icon": "mdi-rollerblade"
            },
            {
                "name": "rollerblade-off",
                "icon": "mdi-rollerblade-off"
            },
            {
                "name": "rollupjs",
                "icon": "mdi-rollupjs"
            },
            {
                "name": "rolodex",
                "icon": "mdi-rolodex"
            },
            {
                "name": "rolodex-outline",
                "icon": "mdi-rolodex-outline"
            },
            {
                "name": "roman-numeral-1",
                "icon": "mdi-roman-numeral-1"
            },
            {
                "name": "roman-numeral-10",
                "icon": "mdi-roman-numeral-10"
            },
            {
                "name": "roman-numeral-2",
                "icon": "mdi-roman-numeral-2"
            },
            {
                "name": "roman-numeral-3",
                "icon": "mdi-roman-numeral-3"
            },
            {
                "name": "roman-numeral-4",
                "icon": "mdi-roman-numeral-4"
            },
            {
                "name": "roman-numeral-5",
                "icon": "mdi-roman-numeral-5"
            },
            {
                "name": "roman-numeral-6",
                "icon": "mdi-roman-numeral-6"
            },
            {
                "name": "roman-numeral-7",
                "icon": "mdi-roman-numeral-7"
            },
            {
                "name": "roman-numeral-8",
                "icon": "mdi-roman-numeral-8"
            },
            {
                "name": "roman-numeral-9",
                "icon": "mdi-roman-numeral-9"
            },
            {
                "name": "room-service",
                "icon": "mdi-room-service"
            },
            {
                "name": "room-service-outline",
                "icon": "mdi-room-service-outline"
            },
            {
                "name": "rotate-360",
                "icon": "mdi-rotate-360"
            },
            {
                "name": "rotate-3d",
                "icon": "mdi-rotate-3d"
            },
            {
                "name": "rotate-3d-variant",
                "icon": "mdi-rotate-3d-variant"
            },
            {
                "name": "rotate-left",
                "icon": "mdi-rotate-left"
            },
            {
                "name": "rotate-left-variant",
                "icon": "mdi-rotate-left-variant"
            },
            {
                "name": "rotate-orbit",
                "icon": "mdi-rotate-orbit"
            },
            {
                "name": "rotate-right",
                "icon": "mdi-rotate-right"
            },
            {
                "name": "rotate-right-variant",
                "icon": "mdi-rotate-right-variant"
            },
            {
                "name": "rounded-corner",
                "icon": "mdi-rounded-corner"
            },
            {
                "name": "router",
                "icon": "mdi-router"
            },
            {
                "name": "router-network",
                "icon": "mdi-router-network"
            },
            {
                "name": "router-wireless",
                "icon": "mdi-router-wireless"
            },
            {
                "name": "router-wireless-off",
                "icon": "mdi-router-wireless-off"
            },
            {
                "name": "router-wireless-settings",
                "icon": "mdi-router-wireless-settings"
            },
            {
                "name": "routes",
                "icon": "mdi-routes"
            },
            {
                "name": "routes-clock",
                "icon": "mdi-routes-clock"
            },
            {
                "name": "rowing",
                "icon": "mdi-rowing"
            },
            {
                "name": "rss",
                "icon": "mdi-rss"
            },
            {
                "name": "rss-box",
                "icon": "mdi-rss-box"
            },
            {
                "name": "rss-off",
                "icon": "mdi-rss-off"
            },
            {
                "name": "rug",
                "icon": "mdi-rug"
            },
            {
                "name": "rugby",
                "icon": "mdi-rugby"
            },
            {
                "name": "ruler",
                "icon": "mdi-ruler"
            },
            {
                "name": "ruler-square",
                "icon": "mdi-ruler-square"
            },
            {
                "name": "ruler-square-compass",
                "icon": "mdi-ruler-square-compass"
            },
            {
                "name": "run",
                "icon": "mdi-run"
            },
            {
                "name": "run-fast",
                "icon": "mdi-run-fast"
            },
            {
                "name": "rv-truck",
                "icon": "mdi-rv-truck"
            },
            {
                "name": "sack",
                "icon": "mdi-sack"
            },
            {
                "name": "sack-percent",
                "icon": "mdi-sack-percent"
            },
            {
                "name": "safe",
                "icon": "mdi-safe"
            },
            {
                "name": "safe-square",
                "icon": "mdi-safe-square"
            },
            {
                "name": "safe-square-outline",
                "icon": "mdi-safe-square-outline"
            },
            {
                "name": "safety-goggles",
                "icon": "mdi-safety-goggles"
            },
            {
                "name": "sail-boat",
                "icon": "mdi-sail-boat"
            },
            {
                "name": "sail-boat-sink",
                "icon": "mdi-sail-boat-sink"
            },
            {
                "name": "sale",
                "icon": "mdi-sale"
            },
            {
                "name": "sale-outline",
                "icon": "mdi-sale-outline"
            },
            {
                "name": "salesforce",
                "icon": "mdi-salesforce"
            },
            {
                "name": "sass",
                "icon": "mdi-sass"
            },
            {
                "name": "satellite",
                "icon": "mdi-satellite"
            },
            {
                "name": "satellite-uplink",
                "icon": "mdi-satellite-uplink"
            },
            {
                "name": "satellite-variant",
                "icon": "mdi-satellite-variant"
            },
            {
                "name": "sausage",
                "icon": "mdi-sausage"
            },
            {
                "name": "sausage-off",
                "icon": "mdi-sausage-off"
            },
            {
                "name": "saw-blade",
                "icon": "mdi-saw-blade"
            },
            {
                "name": "sawtooth-wave",
                "icon": "mdi-sawtooth-wave"
            },
            {
                "name": "saxophone",
                "icon": "mdi-saxophone"
            },
            {
                "name": "scale",
                "icon": "mdi-scale"
            },
            {
                "name": "scale-balance",
                "icon": "mdi-scale-balance"
            },
            {
                "name": "scale-bathroom",
                "icon": "mdi-scale-bathroom"
            },
            {
                "name": "scale-off",
                "icon": "mdi-scale-off"
            },
            {
                "name": "scale-unbalanced",
                "icon": "mdi-scale-unbalanced"
            },
            {
                "name": "scan-helper",
                "icon": "mdi-scan-helper"
            },
            {
                "name": "scanner",
                "icon": "mdi-scanner"
            },
            {
                "name": "scanner-off",
                "icon": "mdi-scanner-off"
            },
            {
                "name": "scatter-plot",
                "icon": "mdi-scatter-plot"
            },
            {
                "name": "scatter-plot-outline",
                "icon": "mdi-scatter-plot-outline"
            },
            {
                "name": "scent",
                "icon": "mdi-scent"
            },
            {
                "name": "scent-off",
                "icon": "mdi-scent-off"
            },
            {
                "name": "school",
                "icon": "mdi-school"
            },
            {
                "name": "school-outline",
                "icon": "mdi-school-outline"
            },
            {
                "name": "scissors-cutting",
                "icon": "mdi-scissors-cutting"
            },
            {
                "name": "scooter",
                "icon": "mdi-scooter"
            },
            {
                "name": "scooter-electric",
                "icon": "mdi-scooter-electric"
            },
            {
                "name": "scoreboard",
                "icon": "mdi-scoreboard"
            },
            {
                "name": "scoreboard-outline",
                "icon": "mdi-scoreboard-outline"
            },
            {
                "name": "screen-rotation",
                "icon": "mdi-screen-rotation"
            },
            {
                "name": "screen-rotation-lock",
                "icon": "mdi-screen-rotation-lock"
            },
            {
                "name": "screw-flat-top",
                "icon": "mdi-screw-flat-top"
            },
            {
                "name": "screw-lag",
                "icon": "mdi-screw-lag"
            },
            {
                "name": "screw-machine-flat-top",
                "icon": "mdi-screw-machine-flat-top"
            },
            {
                "name": "screw-machine-round-top",
                "icon": "mdi-screw-machine-round-top"
            },
            {
                "name": "screw-round-top",
                "icon": "mdi-screw-round-top"
            },
            {
                "name": "screwdriver",
                "icon": "mdi-screwdriver"
            },
            {
                "name": "script",
                "icon": "mdi-script"
            },
            {
                "name": "script-outline",
                "icon": "mdi-script-outline"
            },
            {
                "name": "script-text",
                "icon": "mdi-script-text"
            },
            {
                "name": "script-text-key",
                "icon": "mdi-script-text-key"
            },
            {
                "name": "script-text-key-outline",
                "icon": "mdi-script-text-key-outline"
            },
            {
                "name": "script-text-outline",
                "icon": "mdi-script-text-outline"
            },
            {
                "name": "script-text-play",
                "icon": "mdi-script-text-play"
            },
            {
                "name": "script-text-play-outline",
                "icon": "mdi-script-text-play-outline"
            },
            {
                "name": "sd",
                "icon": "mdi-sd"
            },
            {
                "name": "seal",
                "icon": "mdi-seal"
            },
            {
                "name": "seal-variant",
                "icon": "mdi-seal-variant"
            },
            {
                "name": "search-web",
                "icon": "mdi-search-web"
            },
            {
                "name": "seat",
                "icon": "mdi-seat"
            },
            {
                "name": "seat-flat",
                "icon": "mdi-seat-flat"
            },
            {
                "name": "seat-flat-angled",
                "icon": "mdi-seat-flat-angled"
            },
            {
                "name": "seat-individual-suite",
                "icon": "mdi-seat-individual-suite"
            },
            {
                "name": "seat-legroom-extra",
                "icon": "mdi-seat-legroom-extra"
            },
            {
                "name": "seat-legroom-normal",
                "icon": "mdi-seat-legroom-normal"
            },
            {
                "name": "seat-legroom-reduced",
                "icon": "mdi-seat-legroom-reduced"
            },
            {
                "name": "seat-outline",
                "icon": "mdi-seat-outline"
            },
            {
                "name": "seat-passenger",
                "icon": "mdi-seat-passenger"
            },
            {
                "name": "seat-recline-extra",
                "icon": "mdi-seat-recline-extra"
            },
            {
                "name": "seat-recline-normal",
                "icon": "mdi-seat-recline-normal"
            },
            {
                "name": "seatbelt",
                "icon": "mdi-seatbelt"
            },
            {
                "name": "security",
                "icon": "mdi-security"
            },
            {
                "name": "security-network",
                "icon": "mdi-security-network"
            },
            {
                "name": "seed",
                "icon": "mdi-seed"
            },
            {
                "name": "seed-off",
                "icon": "mdi-seed-off"
            },
            {
                "name": "seed-off-outline",
                "icon": "mdi-seed-off-outline"
            },
            {
                "name": "seed-outline",
                "icon": "mdi-seed-outline"
            },
            {
                "name": "seed-plus",
                "icon": "mdi-seed-plus"
            },
            {
                "name": "seed-plus-outline",
                "icon": "mdi-seed-plus-outline"
            },
            {
                "name": "seesaw",
                "icon": "mdi-seesaw"
            },
            {
                "name": "segment",
                "icon": "mdi-segment"
            },
            {
                "name": "select",
                "icon": "mdi-select"
            },
            {
                "name": "select-all",
                "icon": "mdi-select-all"
            },
            {
                "name": "select-arrow-down",
                "icon": "mdi-select-arrow-down"
            },
            {
                "name": "select-arrow-up",
                "icon": "mdi-select-arrow-up"
            },
            {
                "name": "select-color",
                "icon": "mdi-select-color"
            },
            {
                "name": "select-compare",
                "icon": "mdi-select-compare"
            },
            {
                "name": "select-drag",
                "icon": "mdi-select-drag"
            },
            {
                "name": "select-group",
                "icon": "mdi-select-group"
            },
            {
                "name": "select-inverse",
                "icon": "mdi-select-inverse"
            },
            {
                "name": "select-marker",
                "icon": "mdi-select-marker"
            },
            {
                "name": "select-multiple",
                "icon": "mdi-select-multiple"
            },
            {
                "name": "select-multiple-marker",
                "icon": "mdi-select-multiple-marker"
            },
            {
                "name": "select-off",
                "icon": "mdi-select-off"
            },
            {
                "name": "select-place",
                "icon": "mdi-select-place"
            },
            {
                "name": "select-remove",
                "icon": "mdi-select-remove"
            },
            {
                "name": "select-search",
                "icon": "mdi-select-search"
            },
            {
                "name": "selection",
                "icon": "mdi-selection"
            },
            {
                "name": "selection-drag",
                "icon": "mdi-selection-drag"
            },
            {
                "name": "selection-ellipse",
                "icon": "mdi-selection-ellipse"
            },
            {
                "name": "selection-ellipse-arrow-inside",
                "icon": "mdi-selection-ellipse-arrow-inside"
            },
            {
                "name": "selection-ellipse-remove",
                "icon": "mdi-selection-ellipse-remove"
            },
            {
                "name": "selection-marker",
                "icon": "mdi-selection-marker"
            },
            {
                "name": "selection-multiple",
                "icon": "mdi-selection-multiple"
            },
            {
                "name": "selection-multiple-marker",
                "icon": "mdi-selection-multiple-marker"
            },
            {
                "name": "selection-off",
                "icon": "mdi-selection-off"
            },
            {
                "name": "selection-remove",
                "icon": "mdi-selection-remove"
            },
            {
                "name": "selection-search",
                "icon": "mdi-selection-search"
            },
            {
                "name": "semantic-web",
                "icon": "mdi-semantic-web"
            },
            {
                "name": "send",
                "icon": "mdi-send"
            },
            {
                "name": "send-check",
                "icon": "mdi-send-check"
            },
            {
                "name": "send-check-outline",
                "icon": "mdi-send-check-outline"
            },
            {
                "name": "send-circle",
                "icon": "mdi-send-circle"
            },
            {
                "name": "send-circle-outline",
                "icon": "mdi-send-circle-outline"
            },
            {
                "name": "send-clock",
                "icon": "mdi-send-clock"
            },
            {
                "name": "send-clock-outline",
                "icon": "mdi-send-clock-outline"
            },
            {
                "name": "send-lock",
                "icon": "mdi-send-lock"
            },
            {
                "name": "send-lock-outline",
                "icon": "mdi-send-lock-outline"
            },
            {
                "name": "send-outline",
                "icon": "mdi-send-outline"
            },
            {
                "name": "serial-port",
                "icon": "mdi-serial-port"
            },
            {
                "name": "server",
                "icon": "mdi-server"
            },
            {
                "name": "server-minus",
                "icon": "mdi-server-minus"
            },
            {
                "name": "server-network",
                "icon": "mdi-server-network"
            },
            {
                "name": "server-network-off",
                "icon": "mdi-server-network-off"
            },
            {
                "name": "server-off",
                "icon": "mdi-server-off"
            },
            {
                "name": "server-plus",
                "icon": "mdi-server-plus"
            },
            {
                "name": "server-remove",
                "icon": "mdi-server-remove"
            },
            {
                "name": "server-security",
                "icon": "mdi-server-security"
            },
            {
                "name": "set-all",
                "icon": "mdi-set-all"
            },
            {
                "name": "set-center",
                "icon": "mdi-set-center"
            },
            {
                "name": "set-center-right",
                "icon": "mdi-set-center-right"
            },
            {
                "name": "set-left",
                "icon": "mdi-set-left"
            },
            {
                "name": "set-left-center",
                "icon": "mdi-set-left-center"
            },
            {
                "name": "set-left-right",
                "icon": "mdi-set-left-right"
            },
            {
                "name": "set-merge",
                "icon": "mdi-set-merge"
            },
            {
                "name": "set-none",
                "icon": "mdi-set-none"
            },
            {
                "name": "set-right",
                "icon": "mdi-set-right"
            },
            {
                "name": "set-split",
                "icon": "mdi-set-split"
            },
            {
                "name": "set-square",
                "icon": "mdi-set-square"
            },
            {
                "name": "set-top-box",
                "icon": "mdi-set-top-box"
            },
            {
                "name": "settings-helper",
                "icon": "mdi-settings-helper"
            },
            {
                "name": "shaker",
                "icon": "mdi-shaker"
            },
            {
                "name": "shaker-outline",
                "icon": "mdi-shaker-outline"
            },
            {
                "name": "shape",
                "icon": "mdi-shape"
            },
            {
                "name": "shape-circle-plus",
                "icon": "mdi-shape-circle-plus"
            },
            {
                "name": "shape-outline",
                "icon": "mdi-shape-outline"
            },
            {
                "name": "shape-oval-plus",
                "icon": "mdi-shape-oval-plus"
            },
            {
                "name": "shape-plus",
                "icon": "mdi-shape-plus"
            },
            {
                "name": "shape-polygon-plus",
                "icon": "mdi-shape-polygon-plus"
            },
            {
                "name": "shape-rectangle-plus",
                "icon": "mdi-shape-rectangle-plus"
            },
            {
                "name": "shape-square-plus",
                "icon": "mdi-shape-square-plus"
            },
            {
                "name": "shape-square-rounded-plus",
                "icon": "mdi-shape-square-rounded-plus"
            },
            {
                "name": "share",
                "icon": "mdi-share"
            },
            {
                "name": "share-all",
                "icon": "mdi-share-all"
            },
            {
                "name": "share-all-outline",
                "icon": "mdi-share-all-outline"
            },
            {
                "name": "share-circle",
                "icon": "mdi-share-circle"
            },
            {
                "name": "share-off",
                "icon": "mdi-share-off"
            },
            {
                "name": "share-off-outline",
                "icon": "mdi-share-off-outline"
            },
            {
                "name": "share-outline",
                "icon": "mdi-share-outline"
            },
            {
                "name": "share-variant",
                "icon": "mdi-share-variant"
            },
            {
                "name": "share-variant-outline",
                "icon": "mdi-share-variant-outline"
            },
            {
                "name": "shark",
                "icon": "mdi-shark"
            },
            {
                "name": "shark-fin",
                "icon": "mdi-shark-fin"
            },
            {
                "name": "shark-fin-outline",
                "icon": "mdi-shark-fin-outline"
            },
            {
                "name": "shark-off",
                "icon": "mdi-shark-off"
            },
            {
                "name": "sheep",
                "icon": "mdi-sheep"
            },
            {
                "name": "shield",
                "icon": "mdi-shield"
            },
            {
                "name": "shield-account",
                "icon": "mdi-shield-account"
            },
            {
                "name": "shield-account-outline",
                "icon": "mdi-shield-account-outline"
            },
            {
                "name": "shield-account-variant",
                "icon": "mdi-shield-account-variant"
            },
            {
                "name": "shield-account-variant-outline",
                "icon": "mdi-shield-account-variant-outline"
            },
            {
                "name": "shield-airplane",
                "icon": "mdi-shield-airplane"
            },
            {
                "name": "shield-airplane-outline",
                "icon": "mdi-shield-airplane-outline"
            },
            {
                "name": "shield-alert",
                "icon": "mdi-shield-alert"
            },
            {
                "name": "shield-alert-outline",
                "icon": "mdi-shield-alert-outline"
            },
            {
                "name": "shield-bug",
                "icon": "mdi-shield-bug"
            },
            {
                "name": "shield-bug-outline",
                "icon": "mdi-shield-bug-outline"
            },
            {
                "name": "shield-car",
                "icon": "mdi-shield-car"
            },
            {
                "name": "shield-check",
                "icon": "mdi-shield-check"
            },
            {
                "name": "shield-check-outline",
                "icon": "mdi-shield-check-outline"
            },
            {
                "name": "shield-cross",
                "icon": "mdi-shield-cross"
            },
            {
                "name": "shield-cross-outline",
                "icon": "mdi-shield-cross-outline"
            },
            {
                "name": "shield-crown",
                "icon": "mdi-shield-crown"
            },
            {
                "name": "shield-crown-outline",
                "icon": "mdi-shield-crown-outline"
            },
            {
                "name": "shield-edit",
                "icon": "mdi-shield-edit"
            },
            {
                "name": "shield-edit-outline",
                "icon": "mdi-shield-edit-outline"
            },
            {
                "name": "shield-half",
                "icon": "mdi-shield-half"
            },
            {
                "name": "shield-half-full",
                "icon": "mdi-shield-half-full"
            },
            {
                "name": "shield-home",
                "icon": "mdi-shield-home"
            },
            {
                "name": "shield-home-outline",
                "icon": "mdi-shield-home-outline"
            },
            {
                "name": "shield-key",
                "icon": "mdi-shield-key"
            },
            {
                "name": "shield-key-outline",
                "icon": "mdi-shield-key-outline"
            },
            {
                "name": "shield-link-variant",
                "icon": "mdi-shield-link-variant"
            },
            {
                "name": "shield-link-variant-outline",
                "icon": "mdi-shield-link-variant-outline"
            },
            {
                "name": "shield-lock",
                "icon": "mdi-shield-lock"
            },
            {
                "name": "shield-lock-open",
                "icon": "mdi-shield-lock-open"
            },
            {
                "name": "shield-lock-open-outline",
                "icon": "mdi-shield-lock-open-outline"
            },
            {
                "name": "shield-lock-outline",
                "icon": "mdi-shield-lock-outline"
            },
            {
                "name": "shield-moon",
                "icon": "mdi-shield-moon"
            },
            {
                "name": "shield-moon-outline",
                "icon": "mdi-shield-moon-outline"
            },
            {
                "name": "shield-off",
                "icon": "mdi-shield-off"
            },
            {
                "name": "shield-off-outline",
                "icon": "mdi-shield-off-outline"
            },
            {
                "name": "shield-outline",
                "icon": "mdi-shield-outline"
            },
            {
                "name": "shield-plus",
                "icon": "mdi-shield-plus"
            },
            {
                "name": "shield-plus-outline",
                "icon": "mdi-shield-plus-outline"
            },
            {
                "name": "shield-refresh",
                "icon": "mdi-shield-refresh"
            },
            {
                "name": "shield-refresh-outline",
                "icon": "mdi-shield-refresh-outline"
            },
            {
                "name": "shield-remove",
                "icon": "mdi-shield-remove"
            },
            {
                "name": "shield-remove-outline",
                "icon": "mdi-shield-remove-outline"
            },
            {
                "name": "shield-search",
                "icon": "mdi-shield-search"
            },
            {
                "name": "shield-star",
                "icon": "mdi-shield-star"
            },
            {
                "name": "shield-star-outline",
                "icon": "mdi-shield-star-outline"
            },
            {
                "name": "shield-sun",
                "icon": "mdi-shield-sun"
            },
            {
                "name": "shield-sun-outline",
                "icon": "mdi-shield-sun-outline"
            },
            {
                "name": "shield-sword",
                "icon": "mdi-shield-sword"
            },
            {
                "name": "shield-sword-outline",
                "icon": "mdi-shield-sword-outline"
            },
            {
                "name": "shield-sync",
                "icon": "mdi-shield-sync"
            },
            {
                "name": "shield-sync-outline",
                "icon": "mdi-shield-sync-outline"
            },
            {
                "name": "shimmer",
                "icon": "mdi-shimmer"
            },
            {
                "name": "ship-wheel",
                "icon": "mdi-ship-wheel"
            },
            {
                "name": "shipping-pallet",
                "icon": "mdi-shipping-pallet"
            },
            {
                "name": "shoe-ballet",
                "icon": "mdi-shoe-ballet"
            },
            {
                "name": "shoe-cleat",
                "icon": "mdi-shoe-cleat"
            },
            {
                "name": "shoe-formal",
                "icon": "mdi-shoe-formal"
            },
            {
                "name": "shoe-heel",
                "icon": "mdi-shoe-heel"
            },
            {
                "name": "shoe-print",
                "icon": "mdi-shoe-print"
            },
            {
                "name": "shoe-sneaker",
                "icon": "mdi-shoe-sneaker"
            },
            {
                "name": "shopping",
                "icon": "mdi-shopping"
            },
            {
                "name": "shopping-music",
                "icon": "mdi-shopping-music"
            },
            {
                "name": "shopping-outline",
                "icon": "mdi-shopping-outline"
            },
            {
                "name": "shopping-search",
                "icon": "mdi-shopping-search"
            },
            {
                "name": "shopping-search-outline",
                "icon": "mdi-shopping-search-outline"
            },
            {
                "name": "shore",
                "icon": "mdi-shore"
            },
            {
                "name": "shovel",
                "icon": "mdi-shovel"
            },
            {
                "name": "shovel-off",
                "icon": "mdi-shovel-off"
            },
            {
                "name": "shower",
                "icon": "mdi-shower"
            },
            {
                "name": "shower-head",
                "icon": "mdi-shower-head"
            },
            {
                "name": "shredder",
                "icon": "mdi-shredder"
            },
            {
                "name": "shuffle",
                "icon": "mdi-shuffle"
            },
            {
                "name": "shuffle-disabled",
                "icon": "mdi-shuffle-disabled"
            },
            {
                "name": "shuffle-variant",
                "icon": "mdi-shuffle-variant"
            },
            {
                "name": "shuriken",
                "icon": "mdi-shuriken"
            },
            {
                "name": "sickle",
                "icon": "mdi-sickle"
            },
            {
                "name": "sigma",
                "icon": "mdi-sigma"
            },
            {
                "name": "sigma-lower",
                "icon": "mdi-sigma-lower"
            },
            {
                "name": "sign-caution",
                "icon": "mdi-sign-caution"
            },
            {
                "name": "sign-direction",
                "icon": "mdi-sign-direction"
            },
            {
                "name": "sign-direction-minus",
                "icon": "mdi-sign-direction-minus"
            },
            {
                "name": "sign-direction-plus",
                "icon": "mdi-sign-direction-plus"
            },
            {
                "name": "sign-direction-remove",
                "icon": "mdi-sign-direction-remove"
            },
            {
                "name": "sign-language",
                "icon": "mdi-sign-language"
            },
            {
                "name": "sign-language-outline",
                "icon": "mdi-sign-language-outline"
            },
            {
                "name": "sign-pole",
                "icon": "mdi-sign-pole"
            },
            {
                "name": "sign-real-estate",
                "icon": "mdi-sign-real-estate"
            },
            {
                "name": "sign-text",
                "icon": "mdi-sign-text"
            },
            {
                "name": "sign-yield",
                "icon": "mdi-sign-yield"
            },
            {
                "name": "signal",
                "icon": "mdi-signal"
            },
            {
                "name": "signal-2g",
                "icon": "mdi-signal-2g"
            },
            {
                "name": "signal-3g",
                "icon": "mdi-signal-3g"
            },
            {
                "name": "signal-4g",
                "icon": "mdi-signal-4g"
            },
            {
                "name": "signal-5g",
                "icon": "mdi-signal-5g"
            },
            {
                "name": "signal-cellular-1",
                "icon": "mdi-signal-cellular-1"
            },
            {
                "name": "signal-cellular-2",
                "icon": "mdi-signal-cellular-2"
            },
            {
                "name": "signal-cellular-3",
                "icon": "mdi-signal-cellular-3"
            },
            {
                "name": "signal-cellular-outline",
                "icon": "mdi-signal-cellular-outline"
            },
            {
                "name": "signal-distance-variant",
                "icon": "mdi-signal-distance-variant"
            },
            {
                "name": "signal-hspa",
                "icon": "mdi-signal-hspa"
            },
            {
                "name": "signal-hspa-plus",
                "icon": "mdi-signal-hspa-plus"
            },
            {
                "name": "signal-off",
                "icon": "mdi-signal-off"
            },
            {
                "name": "signal-variant",
                "icon": "mdi-signal-variant"
            },
            {
                "name": "signature",
                "icon": "mdi-signature"
            },
            {
                "name": "signature-freehand",
                "icon": "mdi-signature-freehand"
            },
            {
                "name": "signature-image",
                "icon": "mdi-signature-image"
            },
            {
                "name": "signature-text",
                "icon": "mdi-signature-text"
            },
            {
                "name": "silo",
                "icon": "mdi-silo"
            },
            {
                "name": "silo-outline",
                "icon": "mdi-silo-outline"
            },
            {
                "name": "silverware",
                "icon": "mdi-silverware"
            },
            {
                "name": "silverware-clean",
                "icon": "mdi-silverware-clean"
            },
            {
                "name": "silverware-fork",
                "icon": "mdi-silverware-fork"
            },
            {
                "name": "silverware-fork-knife",
                "icon": "mdi-silverware-fork-knife"
            },
            {
                "name": "silverware-spoon",
                "icon": "mdi-silverware-spoon"
            },
            {
                "name": "silverware-variant",
                "icon": "mdi-silverware-variant"
            },
            {
                "name": "sim",
                "icon": "mdi-sim"
            },
            {
                "name": "sim-alert",
                "icon": "mdi-sim-alert"
            },
            {
                "name": "sim-alert-outline",
                "icon": "mdi-sim-alert-outline"
            },
            {
                "name": "sim-off",
                "icon": "mdi-sim-off"
            },
            {
                "name": "sim-off-outline",
                "icon": "mdi-sim-off-outline"
            },
            {
                "name": "sim-outline",
                "icon": "mdi-sim-outline"
            },
            {
                "name": "simple-icons",
                "icon": "mdi-simple-icons"
            },
            {
                "name": "sina-weibo",
                "icon": "mdi-sina-weibo"
            },
            {
                "name": "sine-wave",
                "icon": "mdi-sine-wave"
            },
            {
                "name": "sitemap",
                "icon": "mdi-sitemap"
            },
            {
                "name": "sitemap-outline",
                "icon": "mdi-sitemap-outline"
            },
            {
                "name": "size-l",
                "icon": "mdi-size-l"
            },
            {
                "name": "size-m",
                "icon": "mdi-size-m"
            },
            {
                "name": "size-s",
                "icon": "mdi-size-s"
            },
            {
                "name": "size-xl",
                "icon": "mdi-size-xl"
            },
            {
                "name": "size-xs",
                "icon": "mdi-size-xs"
            },
            {
                "name": "size-xxl",
                "icon": "mdi-size-xxl"
            },
            {
                "name": "size-xxs",
                "icon": "mdi-size-xxs"
            },
            {
                "name": "size-xxxl",
                "icon": "mdi-size-xxxl"
            },
            {
                "name": "skate",
                "icon": "mdi-skate"
            },
            {
                "name": "skate-off",
                "icon": "mdi-skate-off"
            },
            {
                "name": "skateboard",
                "icon": "mdi-skateboard"
            },
            {
                "name": "skateboarding",
                "icon": "mdi-skateboarding"
            },
            {
                "name": "skew-less",
                "icon": "mdi-skew-less"
            },
            {
                "name": "skew-more",
                "icon": "mdi-skew-more"
            },
            {
                "name": "ski",
                "icon": "mdi-ski"
            },
            {
                "name": "ski-cross-country",
                "icon": "mdi-ski-cross-country"
            },
            {
                "name": "ski-water",
                "icon": "mdi-ski-water"
            },
            {
                "name": "skip-backward",
                "icon": "mdi-skip-backward"
            },
            {
                "name": "skip-backward-outline",
                "icon": "mdi-skip-backward-outline"
            },
            {
                "name": "skip-forward",
                "icon": "mdi-skip-forward"
            },
            {
                "name": "skip-forward-outline",
                "icon": "mdi-skip-forward-outline"
            },
            {
                "name": "skip-next",
                "icon": "mdi-skip-next"
            },
            {
                "name": "skip-next-circle",
                "icon": "mdi-skip-next-circle"
            },
            {
                "name": "skip-next-circle-outline",
                "icon": "mdi-skip-next-circle-outline"
            },
            {
                "name": "skip-next-outline",
                "icon": "mdi-skip-next-outline"
            },
            {
                "name": "skip-previous",
                "icon": "mdi-skip-previous"
            },
            {
                "name": "skip-previous-circle",
                "icon": "mdi-skip-previous-circle"
            },
            {
                "name": "skip-previous-circle-outline",
                "icon": "mdi-skip-previous-circle-outline"
            },
            {
                "name": "skip-previous-outline",
                "icon": "mdi-skip-previous-outline"
            },
            {
                "name": "skull",
                "icon": "mdi-skull"
            },
            {
                "name": "skull-crossbones",
                "icon": "mdi-skull-crossbones"
            },
            {
                "name": "skull-crossbones-outline",
                "icon": "mdi-skull-crossbones-outline"
            },
            {
                "name": "skull-outline",
                "icon": "mdi-skull-outline"
            },
            {
                "name": "skull-scan",
                "icon": "mdi-skull-scan"
            },
            {
                "name": "skull-scan-outline",
                "icon": "mdi-skull-scan-outline"
            },
            {
                "name": "skype",
                "icon": "mdi-skype"
            },
            {
                "name": "skype-business",
                "icon": "mdi-skype-business"
            },
            {
                "name": "slack",
                "icon": "mdi-slack"
            },
            {
                "name": "slash-forward",
                "icon": "mdi-slash-forward"
            },
            {
                "name": "slash-forward-box",
                "icon": "mdi-slash-forward-box"
            },
            {
                "name": "sledding",
                "icon": "mdi-sledding"
            },
            {
                "name": "sleep",
                "icon": "mdi-sleep"
            },
            {
                "name": "sleep-off",
                "icon": "mdi-sleep-off"
            },
            {
                "name": "slide",
                "icon": "mdi-slide"
            },
            {
                "name": "slope-downhill",
                "icon": "mdi-slope-downhill"
            },
            {
                "name": "slope-uphill",
                "icon": "mdi-slope-uphill"
            },
            {
                "name": "slot-machine",
                "icon": "mdi-slot-machine"
            },
            {
                "name": "slot-machine-outline",
                "icon": "mdi-slot-machine-outline"
            },
            {
                "name": "smart-card",
                "icon": "mdi-smart-card"
            },
            {
                "name": "smart-card-off",
                "icon": "mdi-smart-card-off"
            },
            {
                "name": "smart-card-off-outline",
                "icon": "mdi-smart-card-off-outline"
            },
            {
                "name": "smart-card-outline",
                "icon": "mdi-smart-card-outline"
            },
            {
                "name": "smart-card-reader",
                "icon": "mdi-smart-card-reader"
            },
            {
                "name": "smart-card-reader-outline",
                "icon": "mdi-smart-card-reader-outline"
            },
            {
                "name": "smog",
                "icon": "mdi-smog"
            },
            {
                "name": "smoke",
                "icon": "mdi-smoke"
            },
            {
                "name": "smoke-detector",
                "icon": "mdi-smoke-detector"
            },
            {
                "name": "smoke-detector-alert",
                "icon": "mdi-smoke-detector-alert"
            },
            {
                "name": "smoke-detector-alert-outline",
                "icon": "mdi-smoke-detector-alert-outline"
            },
            {
                "name": "smoke-detector-off",
                "icon": "mdi-smoke-detector-off"
            },
            {
                "name": "smoke-detector-off-outline",
                "icon": "mdi-smoke-detector-off-outline"
            },
            {
                "name": "smoke-detector-outline",
                "icon": "mdi-smoke-detector-outline"
            },
            {
                "name": "smoke-detector-variant",
                "icon": "mdi-smoke-detector-variant"
            },
            {
                "name": "smoke-detector-variant-alert",
                "icon": "mdi-smoke-detector-variant-alert"
            },
            {
                "name": "smoke-detector-variant-off",
                "icon": "mdi-smoke-detector-variant-off"
            },
            {
                "name": "smoking",
                "icon": "mdi-smoking"
            },
            {
                "name": "smoking-off",
                "icon": "mdi-smoking-off"
            },
            {
                "name": "smoking-pipe",
                "icon": "mdi-smoking-pipe"
            },
            {
                "name": "smoking-pipe-off",
                "icon": "mdi-smoking-pipe-off"
            },
            {
                "name": "snail",
                "icon": "mdi-snail"
            },
            {
                "name": "snake",
                "icon": "mdi-snake"
            },
            {
                "name": "snapchat",
                "icon": "mdi-snapchat"
            },
            {
                "name": "snowboard",
                "icon": "mdi-snowboard"
            },
            {
                "name": "snowflake",
                "icon": "mdi-snowflake"
            },
            {
                "name": "snowflake-alert",
                "icon": "mdi-snowflake-alert"
            },
            {
                "name": "snowflake-check",
                "icon": "mdi-snowflake-check"
            },
            {
                "name": "snowflake-melt",
                "icon": "mdi-snowflake-melt"
            },
            {
                "name": "snowflake-off",
                "icon": "mdi-snowflake-off"
            },
            {
                "name": "snowflake-thermometer",
                "icon": "mdi-snowflake-thermometer"
            },
            {
                "name": "snowflake-variant",
                "icon": "mdi-snowflake-variant"
            },
            {
                "name": "snowman",
                "icon": "mdi-snowman"
            },
            {
                "name": "snowmobile",
                "icon": "mdi-snowmobile"
            },
            {
                "name": "snowshoeing",
                "icon": "mdi-snowshoeing"
            },
            {
                "name": "soccer",
                "icon": "mdi-soccer"
            },
            {
                "name": "soccer-field",
                "icon": "mdi-soccer-field"
            },
            {
                "name": "social-distance-2-meters",
                "icon": "mdi-social-distance-2-meters"
            },
            {
                "name": "social-distance-6-feet",
                "icon": "mdi-social-distance-6-feet"
            },
            {
                "name": "sofa",
                "icon": "mdi-sofa"
            },
            {
                "name": "sofa-outline",
                "icon": "mdi-sofa-outline"
            },
            {
                "name": "sofa-single",
                "icon": "mdi-sofa-single"
            },
            {
                "name": "sofa-single-outline",
                "icon": "mdi-sofa-single-outline"
            },
            {
                "name": "solar-panel",
                "icon": "mdi-solar-panel"
            },
            {
                "name": "solar-panel-large",
                "icon": "mdi-solar-panel-large"
            },
            {
                "name": "solar-power",
                "icon": "mdi-solar-power"
            },
            {
                "name": "solar-power-variant",
                "icon": "mdi-solar-power-variant"
            },
            {
                "name": "solar-power-variant-outline",
                "icon": "mdi-solar-power-variant-outline"
            },
            {
                "name": "soldering-iron",
                "icon": "mdi-soldering-iron"
            },
            {
                "name": "solid",
                "icon": "mdi-solid"
            },
            {
                "name": "sony-playstation",
                "icon": "mdi-sony-playstation"
            },
            {
                "name": "sort",
                "icon": "mdi-sort"
            },
            {
                "name": "sort-alphabetical-ascending",
                "icon": "mdi-sort-alphabetical-ascending"
            },
            {
                "name": "sort-alphabetical-ascending-variant",
                "icon": "mdi-sort-alphabetical-ascending-variant"
            },
            {
                "name": "sort-alphabetical-descending",
                "icon": "mdi-sort-alphabetical-descending"
            },
            {
                "name": "sort-alphabetical-descending-variant",
                "icon": "mdi-sort-alphabetical-descending-variant"
            },
            {
                "name": "sort-alphabetical-variant",
                "icon": "mdi-sort-alphabetical-variant"
            },
            {
                "name": "sort-ascending",
                "icon": "mdi-sort-ascending"
            },
            {
                "name": "sort-bool-ascending",
                "icon": "mdi-sort-bool-ascending"
            },
            {
                "name": "sort-bool-ascending-variant",
                "icon": "mdi-sort-bool-ascending-variant"
            },
            {
                "name": "sort-bool-descending",
                "icon": "mdi-sort-bool-descending"
            },
            {
                "name": "sort-bool-descending-variant",
                "icon": "mdi-sort-bool-descending-variant"
            },
            {
                "name": "sort-calendar-ascending",
                "icon": "mdi-sort-calendar-ascending"
            },
            {
                "name": "sort-calendar-descending",
                "icon": "mdi-sort-calendar-descending"
            },
            {
                "name": "sort-clock-ascending",
                "icon": "mdi-sort-clock-ascending"
            },
            {
                "name": "sort-clock-ascending-outline",
                "icon": "mdi-sort-clock-ascending-outline"
            },
            {
                "name": "sort-clock-descending",
                "icon": "mdi-sort-clock-descending"
            },
            {
                "name": "sort-clock-descending-outline",
                "icon": "mdi-sort-clock-descending-outline"
            },
            {
                "name": "sort-descending",
                "icon": "mdi-sort-descending"
            },
            {
                "name": "sort-numeric-ascending",
                "icon": "mdi-sort-numeric-ascending"
            },
            {
                "name": "sort-numeric-ascending-variant",
                "icon": "mdi-sort-numeric-ascending-variant"
            },
            {
                "name": "sort-numeric-descending",
                "icon": "mdi-sort-numeric-descending"
            },
            {
                "name": "sort-numeric-descending-variant",
                "icon": "mdi-sort-numeric-descending-variant"
            },
            {
                "name": "sort-numeric-variant",
                "icon": "mdi-sort-numeric-variant"
            },
            {
                "name": "sort-reverse-variant",
                "icon": "mdi-sort-reverse-variant"
            },
            {
                "name": "sort-variant",
                "icon": "mdi-sort-variant"
            },
            {
                "name": "sort-variant-lock",
                "icon": "mdi-sort-variant-lock"
            },
            {
                "name": "sort-variant-lock-open",
                "icon": "mdi-sort-variant-lock-open"
            },
            {
                "name": "sort-variant-off",
                "icon": "mdi-sort-variant-off"
            },
            {
                "name": "sort-variant-remove",
                "icon": "mdi-sort-variant-remove"
            },
            {
                "name": "soundbar",
                "icon": "mdi-soundbar"
            },
            {
                "name": "soundcloud",
                "icon": "mdi-soundcloud"
            },
            {
                "name": "source-branch",
                "icon": "mdi-source-branch"
            },
            {
                "name": "source-branch-check",
                "icon": "mdi-source-branch-check"
            },
            {
                "name": "source-branch-minus",
                "icon": "mdi-source-branch-minus"
            },
            {
                "name": "source-branch-plus",
                "icon": "mdi-source-branch-plus"
            },
            {
                "name": "source-branch-refresh",
                "icon": "mdi-source-branch-refresh"
            },
            {
                "name": "source-branch-remove",
                "icon": "mdi-source-branch-remove"
            },
            {
                "name": "source-branch-sync",
                "icon": "mdi-source-branch-sync"
            },
            {
                "name": "source-commit",
                "icon": "mdi-source-commit"
            },
            {
                "name": "source-commit-end",
                "icon": "mdi-source-commit-end"
            },
            {
                "name": "source-commit-end-local",
                "icon": "mdi-source-commit-end-local"
            },
            {
                "name": "source-commit-local",
                "icon": "mdi-source-commit-local"
            },
            {
                "name": "source-commit-next-local",
                "icon": "mdi-source-commit-next-local"
            },
            {
                "name": "source-commit-start",
                "icon": "mdi-source-commit-start"
            },
            {
                "name": "source-commit-start-next-local",
                "icon": "mdi-source-commit-start-next-local"
            },
            {
                "name": "source-fork",
                "icon": "mdi-source-fork"
            },
            {
                "name": "source-merge",
                "icon": "mdi-source-merge"
            },
            {
                "name": "source-pull",
                "icon": "mdi-source-pull"
            },
            {
                "name": "source-repository",
                "icon": "mdi-source-repository"
            },
            {
                "name": "source-repository-multiple",
                "icon": "mdi-source-repository-multiple"
            },
            {
                "name": "soy-sauce",
                "icon": "mdi-soy-sauce"
            },
            {
                "name": "soy-sauce-off",
                "icon": "mdi-soy-sauce-off"
            },
            {
                "name": "spa",
                "icon": "mdi-spa"
            },
            {
                "name": "spa-outline",
                "icon": "mdi-spa-outline"
            },
            {
                "name": "space-invaders",
                "icon": "mdi-space-invaders"
            },
            {
                "name": "space-station",
                "icon": "mdi-space-station"
            },
            {
                "name": "spade",
                "icon": "mdi-spade"
            },
            {
                "name": "speaker",
                "icon": "mdi-speaker"
            },
            {
                "name": "speaker-bluetooth",
                "icon": "mdi-speaker-bluetooth"
            },
            {
                "name": "speaker-message",
                "icon": "mdi-speaker-message"
            },
            {
                "name": "speaker-multiple",
                "icon": "mdi-speaker-multiple"
            },
            {
                "name": "speaker-off",
                "icon": "mdi-speaker-off"
            },
            {
                "name": "speaker-pause",
                "icon": "mdi-speaker-pause"
            },
            {
                "name": "speaker-play",
                "icon": "mdi-speaker-play"
            },
            {
                "name": "speaker-stop",
                "icon": "mdi-speaker-stop"
            },
            {
                "name": "speaker-wireless",
                "icon": "mdi-speaker-wireless"
            },
            {
                "name": "spear",
                "icon": "mdi-spear"
            },
            {
                "name": "speedometer",
                "icon": "mdi-speedometer"
            },
            {
                "name": "speedometer-medium",
                "icon": "mdi-speedometer-medium"
            },
            {
                "name": "speedometer-slow",
                "icon": "mdi-speedometer-slow"
            },
            {
                "name": "spellcheck",
                "icon": "mdi-spellcheck"
            },
            {
                "name": "sphere",
                "icon": "mdi-sphere"
            },
            {
                "name": "sphere-off",
                "icon": "mdi-sphere-off"
            },
            {
                "name": "spider",
                "icon": "mdi-spider"
            },
            {
                "name": "spider-thread",
                "icon": "mdi-spider-thread"
            },
            {
                "name": "spider-web",
                "icon": "mdi-spider-web"
            },
            {
                "name": "spirit-level",
                "icon": "mdi-spirit-level"
            },
            {
                "name": "spoon-sugar",
                "icon": "mdi-spoon-sugar"
            },
            {
                "name": "spotify",
                "icon": "mdi-spotify"
            },
            {
                "name": "spotlight",
                "icon": "mdi-spotlight"
            },
            {
                "name": "spotlight-beam",
                "icon": "mdi-spotlight-beam"
            },
            {
                "name": "spray",
                "icon": "mdi-spray"
            },
            {
                "name": "spray-bottle",
                "icon": "mdi-spray-bottle"
            },
            {
                "name": "sprinkler",
                "icon": "mdi-sprinkler"
            },
            {
                "name": "sprinkler-fire",
                "icon": "mdi-sprinkler-fire"
            },
            {
                "name": "sprinkler-variant",
                "icon": "mdi-sprinkler-variant"
            },
            {
                "name": "sprout",
                "icon": "mdi-sprout"
            },
            {
                "name": "sprout-outline",
                "icon": "mdi-sprout-outline"
            },
            {
                "name": "square",
                "icon": "mdi-square"
            },
            {
                "name": "square-circle",
                "icon": "mdi-square-circle"
            },
            {
                "name": "square-edit-outline",
                "icon": "mdi-square-edit-outline"
            },
            {
                "name": "square-medium",
                "icon": "mdi-square-medium"
            },
            {
                "name": "square-medium-outline",
                "icon": "mdi-square-medium-outline"
            },
            {
                "name": "square-off",
                "icon": "mdi-square-off"
            },
            {
                "name": "square-off-outline",
                "icon": "mdi-square-off-outline"
            },
            {
                "name": "square-opacity",
                "icon": "mdi-square-opacity"
            },
            {
                "name": "square-outline",
                "icon": "mdi-square-outline"
            },
            {
                "name": "square-root",
                "icon": "mdi-square-root"
            },
            {
                "name": "square-root-box",
                "icon": "mdi-square-root-box"
            },
            {
                "name": "square-rounded",
                "icon": "mdi-square-rounded"
            },
            {
                "name": "square-rounded-badge",
                "icon": "mdi-square-rounded-badge"
            },
            {
                "name": "square-rounded-badge-outline",
                "icon": "mdi-square-rounded-badge-outline"
            },
            {
                "name": "square-rounded-outline",
                "icon": "mdi-square-rounded-outline"
            },
            {
                "name": "square-small",
                "icon": "mdi-square-small"
            },
            {
                "name": "square-wave",
                "icon": "mdi-square-wave"
            },
            {
                "name": "squeegee",
                "icon": "mdi-squeegee"
            },
            {
                "name": "ssh",
                "icon": "mdi-ssh"
            },
            {
                "name": "stack-exchange",
                "icon": "mdi-stack-exchange"
            },
            {
                "name": "stack-overflow",
                "icon": "mdi-stack-overflow"
            },
            {
                "name": "stackpath",
                "icon": "mdi-stackpath"
            },
            {
                "name": "stadium",
                "icon": "mdi-stadium"
            },
            {
                "name": "stadium-outline",
                "icon": "mdi-stadium-outline"
            },
            {
                "name": "stadium-variant",
                "icon": "mdi-stadium-variant"
            },
            {
                "name": "stairs",
                "icon": "mdi-stairs"
            },
            {
                "name": "stairs-box",
                "icon": "mdi-stairs-box"
            },
            {
                "name": "stairs-down",
                "icon": "mdi-stairs-down"
            },
            {
                "name": "stairs-up",
                "icon": "mdi-stairs-up"
            },
            {
                "name": "stamper",
                "icon": "mdi-stamper"
            },
            {
                "name": "standard-definition",
                "icon": "mdi-standard-definition"
            },
            {
                "name": "star",
                "icon": "mdi-star"
            },
            {
                "name": "star-box",
                "icon": "mdi-star-box"
            },
            {
                "name": "star-box-multiple",
                "icon": "mdi-star-box-multiple"
            },
            {
                "name": "star-box-multiple-outline",
                "icon": "mdi-star-box-multiple-outline"
            },
            {
                "name": "star-box-outline",
                "icon": "mdi-star-box-outline"
            },
            {
                "name": "star-check",
                "icon": "mdi-star-check"
            },
            {
                "name": "star-check-outline",
                "icon": "mdi-star-check-outline"
            },
            {
                "name": "star-circle",
                "icon": "mdi-star-circle"
            },
            {
                "name": "star-circle-outline",
                "icon": "mdi-star-circle-outline"
            },
            {
                "name": "star-cog",
                "icon": "mdi-star-cog"
            },
            {
                "name": "star-cog-outline",
                "icon": "mdi-star-cog-outline"
            },
            {
                "name": "star-crescent",
                "icon": "mdi-star-crescent"
            },
            {
                "name": "star-david",
                "icon": "mdi-star-david"
            },
            {
                "name": "star-face",
                "icon": "mdi-star-face"
            },
            {
                "name": "star-four-points",
                "icon": "mdi-star-four-points"
            },
            {
                "name": "star-four-points-outline",
                "icon": "mdi-star-four-points-outline"
            },
            {
                "name": "star-half",
                "icon": "mdi-star-half"
            },
            {
                "name": "star-half-full",
                "icon": "mdi-star-half-full"
            },
            {
                "name": "star-minus",
                "icon": "mdi-star-minus"
            },
            {
                "name": "star-minus-outline",
                "icon": "mdi-star-minus-outline"
            },
            {
                "name": "star-off",
                "icon": "mdi-star-off"
            },
            {
                "name": "star-off-outline",
                "icon": "mdi-star-off-outline"
            },
            {
                "name": "star-outline",
                "icon": "mdi-star-outline"
            },
            {
                "name": "star-plus",
                "icon": "mdi-star-plus"
            },
            {
                "name": "star-plus-outline",
                "icon": "mdi-star-plus-outline"
            },
            {
                "name": "star-remove",
                "icon": "mdi-star-remove"
            },
            {
                "name": "star-remove-outline",
                "icon": "mdi-star-remove-outline"
            },
            {
                "name": "star-settings",
                "icon": "mdi-star-settings"
            },
            {
                "name": "star-settings-outline",
                "icon": "mdi-star-settings-outline"
            },
            {
                "name": "star-shooting",
                "icon": "mdi-star-shooting"
            },
            {
                "name": "star-shooting-outline",
                "icon": "mdi-star-shooting-outline"
            },
            {
                "name": "star-three-points",
                "icon": "mdi-star-three-points"
            },
            {
                "name": "star-three-points-outline",
                "icon": "mdi-star-three-points-outline"
            },
            {
                "name": "state-machine",
                "icon": "mdi-state-machine"
            },
            {
                "name": "steam",
                "icon": "mdi-steam"
            },
            {
                "name": "steering",
                "icon": "mdi-steering"
            },
            {
                "name": "steering-off",
                "icon": "mdi-steering-off"
            },
            {
                "name": "step-backward",
                "icon": "mdi-step-backward"
            },
            {
                "name": "step-backward-2",
                "icon": "mdi-step-backward-2"
            },
            {
                "name": "step-forward",
                "icon": "mdi-step-forward"
            },
            {
                "name": "step-forward-2",
                "icon": "mdi-step-forward-2"
            },
            {
                "name": "stethoscope",
                "icon": "mdi-stethoscope"
            },
            {
                "name": "sticker",
                "icon": "mdi-sticker"
            },
            {
                "name": "sticker-alert",
                "icon": "mdi-sticker-alert"
            },
            {
                "name": "sticker-alert-outline",
                "icon": "mdi-sticker-alert-outline"
            },
            {
                "name": "sticker-check",
                "icon": "mdi-sticker-check"
            },
            {
                "name": "sticker-check-outline",
                "icon": "mdi-sticker-check-outline"
            },
            {
                "name": "sticker-circle-outline",
                "icon": "mdi-sticker-circle-outline"
            },
            {
                "name": "sticker-emoji",
                "icon": "mdi-sticker-emoji"
            },
            {
                "name": "sticker-minus",
                "icon": "mdi-sticker-minus"
            },
            {
                "name": "sticker-minus-outline",
                "icon": "mdi-sticker-minus-outline"
            },
            {
                "name": "sticker-outline",
                "icon": "mdi-sticker-outline"
            },
            {
                "name": "sticker-plus",
                "icon": "mdi-sticker-plus"
            },
            {
                "name": "sticker-plus-outline",
                "icon": "mdi-sticker-plus-outline"
            },
            {
                "name": "sticker-remove",
                "icon": "mdi-sticker-remove"
            },
            {
                "name": "sticker-remove-outline",
                "icon": "mdi-sticker-remove-outline"
            },
            {
                "name": "sticker-text",
                "icon": "mdi-sticker-text"
            },
            {
                "name": "sticker-text-outline",
                "icon": "mdi-sticker-text-outline"
            },
            {
                "name": "stocking",
                "icon": "mdi-stocking"
            },
            {
                "name": "stomach",
                "icon": "mdi-stomach"
            },
            {
                "name": "stool",
                "icon": "mdi-stool"
            },
            {
                "name": "stool-outline",
                "icon": "mdi-stool-outline"
            },
            {
                "name": "stop",
                "icon": "mdi-stop"
            },
            {
                "name": "stop-circle",
                "icon": "mdi-stop-circle"
            },
            {
                "name": "stop-circle-outline",
                "icon": "mdi-stop-circle-outline"
            },
            {
                "name": "storage-tank",
                "icon": "mdi-storage-tank"
            },
            {
                "name": "storage-tank-outline",
                "icon": "mdi-storage-tank-outline"
            },
            {
                "name": "store",
                "icon": "mdi-store"
            },
            {
                "name": "store-24-hour",
                "icon": "mdi-store-24-hour"
            },
            {
                "name": "store-alert",
                "icon": "mdi-store-alert"
            },
            {
                "name": "store-alert-outline",
                "icon": "mdi-store-alert-outline"
            },
            {
                "name": "store-check",
                "icon": "mdi-store-check"
            },
            {
                "name": "store-check-outline",
                "icon": "mdi-store-check-outline"
            },
            {
                "name": "store-clock",
                "icon": "mdi-store-clock"
            },
            {
                "name": "store-clock-outline",
                "icon": "mdi-store-clock-outline"
            },
            {
                "name": "store-cog",
                "icon": "mdi-store-cog"
            },
            {
                "name": "store-cog-outline",
                "icon": "mdi-store-cog-outline"
            },
            {
                "name": "store-edit",
                "icon": "mdi-store-edit"
            },
            {
                "name": "store-edit-outline",
                "icon": "mdi-store-edit-outline"
            },
            {
                "name": "store-marker",
                "icon": "mdi-store-marker"
            },
            {
                "name": "store-marker-outline",
                "icon": "mdi-store-marker-outline"
            },
            {
                "name": "store-minus",
                "icon": "mdi-store-minus"
            },
            {
                "name": "store-minus-outline",
                "icon": "mdi-store-minus-outline"
            },
            {
                "name": "store-off",
                "icon": "mdi-store-off"
            },
            {
                "name": "store-off-outline",
                "icon": "mdi-store-off-outline"
            },
            {
                "name": "store-outline",
                "icon": "mdi-store-outline"
            },
            {
                "name": "store-plus",
                "icon": "mdi-store-plus"
            },
            {
                "name": "store-plus-outline",
                "icon": "mdi-store-plus-outline"
            },
            {
                "name": "store-remove",
                "icon": "mdi-store-remove"
            },
            {
                "name": "store-remove-outline",
                "icon": "mdi-store-remove-outline"
            },
            {
                "name": "store-search",
                "icon": "mdi-store-search"
            },
            {
                "name": "store-search-outline",
                "icon": "mdi-store-search-outline"
            },
            {
                "name": "store-settings",
                "icon": "mdi-store-settings"
            },
            {
                "name": "store-settings-outline",
                "icon": "mdi-store-settings-outline"
            },
            {
                "name": "storefront",
                "icon": "mdi-storefront"
            },
            {
                "name": "storefront-check",
                "icon": "mdi-storefront-check"
            },
            {
                "name": "storefront-check-outline",
                "icon": "mdi-storefront-check-outline"
            },
            {
                "name": "storefront-edit",
                "icon": "mdi-storefront-edit"
            },
            {
                "name": "storefront-edit-outline",
                "icon": "mdi-storefront-edit-outline"
            },
            {
                "name": "storefront-minus",
                "icon": "mdi-storefront-minus"
            },
            {
                "name": "storefront-minus-outline",
                "icon": "mdi-storefront-minus-outline"
            },
            {
                "name": "storefront-outline",
                "icon": "mdi-storefront-outline"
            },
            {
                "name": "storefront-plus",
                "icon": "mdi-storefront-plus"
            },
            {
                "name": "storefront-plus-outline",
                "icon": "mdi-storefront-plus-outline"
            },
            {
                "name": "storefront-remove",
                "icon": "mdi-storefront-remove"
            },
            {
                "name": "storefront-remove-outline",
                "icon": "mdi-storefront-remove-outline"
            },
            {
                "name": "stove",
                "icon": "mdi-stove"
            },
            {
                "name": "strategy",
                "icon": "mdi-strategy"
            },
            {
                "name": "stretch-to-page",
                "icon": "mdi-stretch-to-page"
            },
            {
                "name": "stretch-to-page-outline",
                "icon": "mdi-stretch-to-page-outline"
            },
            {
                "name": "string-lights",
                "icon": "mdi-string-lights"
            },
            {
                "name": "string-lights-off",
                "icon": "mdi-string-lights-off"
            },
            {
                "name": "subdirectory-arrow-left",
                "icon": "mdi-subdirectory-arrow-left"
            },
            {
                "name": "subdirectory-arrow-right",
                "icon": "mdi-subdirectory-arrow-right"
            },
            {
                "name": "submarine",
                "icon": "mdi-submarine"
            },
            {
                "name": "subtitles",
                "icon": "mdi-subtitles"
            },
            {
                "name": "subtitles-outline",
                "icon": "mdi-subtitles-outline"
            },
            {
                "name": "subway",
                "icon": "mdi-subway"
            },
            {
                "name": "subway-alert-variant",
                "icon": "mdi-subway-alert-variant"
            },
            {
                "name": "subway-variant",
                "icon": "mdi-subway-variant"
            },
            {
                "name": "summit",
                "icon": "mdi-summit"
            },
            {
                "name": "sun-angle",
                "icon": "mdi-sun-angle"
            },
            {
                "name": "sun-angle-outline",
                "icon": "mdi-sun-angle-outline"
            },
            {
                "name": "sun-clock",
                "icon": "mdi-sun-clock"
            },
            {
                "name": "sun-clock-outline",
                "icon": "mdi-sun-clock-outline"
            },
            {
                "name": "sun-compass",
                "icon": "mdi-sun-compass"
            },
            {
                "name": "sun-snowflake",
                "icon": "mdi-sun-snowflake"
            },
            {
                "name": "sun-snowflake-variant",
                "icon": "mdi-sun-snowflake-variant"
            },
            {
                "name": "sun-thermometer",
                "icon": "mdi-sun-thermometer"
            },
            {
                "name": "sun-thermometer-outline",
                "icon": "mdi-sun-thermometer-outline"
            },
            {
                "name": "sun-wireless",
                "icon": "mdi-sun-wireless"
            },
            {
                "name": "sun-wireless-outline",
                "icon": "mdi-sun-wireless-outline"
            },
            {
                "name": "sunglasses",
                "icon": "mdi-sunglasses"
            },
            {
                "name": "surfing",
                "icon": "mdi-surfing"
            },
            {
                "name": "surround-sound",
                "icon": "mdi-surround-sound"
            },
            {
                "name": "surround-sound-2-0",
                "icon": "mdi-surround-sound-2-0"
            },
            {
                "name": "surround-sound-2-1",
                "icon": "mdi-surround-sound-2-1"
            },
            {
                "name": "surround-sound-3-1",
                "icon": "mdi-surround-sound-3-1"
            },
            {
                "name": "surround-sound-5-1",
                "icon": "mdi-surround-sound-5-1"
            },
            {
                "name": "surround-sound-5-1-2",
                "icon": "mdi-surround-sound-5-1-2"
            },
            {
                "name": "surround-sound-7-1",
                "icon": "mdi-surround-sound-7-1"
            },
            {
                "name": "svg",
                "icon": "mdi-svg"
            },
            {
                "name": "swap-horizontal",
                "icon": "mdi-swap-horizontal"
            },
            {
                "name": "swap-horizontal-bold",
                "icon": "mdi-swap-horizontal-bold"
            },
            {
                "name": "swap-horizontal-circle",
                "icon": "mdi-swap-horizontal-circle"
            },
            {
                "name": "swap-horizontal-circle-outline",
                "icon": "mdi-swap-horizontal-circle-outline"
            },
            {
                "name": "swap-horizontal-variant",
                "icon": "mdi-swap-horizontal-variant"
            },
            {
                "name": "swap-vertical",
                "icon": "mdi-swap-vertical"
            },
            {
                "name": "swap-vertical-bold",
                "icon": "mdi-swap-vertical-bold"
            },
            {
                "name": "swap-vertical-circle",
                "icon": "mdi-swap-vertical-circle"
            },
            {
                "name": "swap-vertical-circle-outline",
                "icon": "mdi-swap-vertical-circle-outline"
            },
            {
                "name": "swap-vertical-variant",
                "icon": "mdi-swap-vertical-variant"
            },
            {
                "name": "swim",
                "icon": "mdi-swim"
            },
            {
                "name": "switch",
                "icon": "mdi-switch"
            },
            {
                "name": "sword",
                "icon": "mdi-sword"
            },
            {
                "name": "sword-cross",
                "icon": "mdi-sword-cross"
            },
            {
                "name": "syllabary-hangul",
                "icon": "mdi-syllabary-hangul"
            },
            {
                "name": "syllabary-hiragana",
                "icon": "mdi-syllabary-hiragana"
            },
            {
                "name": "syllabary-katakana",
                "icon": "mdi-syllabary-katakana"
            },
            {
                "name": "syllabary-katakana-halfwidth",
                "icon": "mdi-syllabary-katakana-halfwidth"
            },
            {
                "name": "symbol",
                "icon": "mdi-symbol"
            },
            {
                "name": "symfony",
                "icon": "mdi-symfony"
            },
            {
                "name": "synagogue",
                "icon": "mdi-synagogue"
            },
            {
                "name": "synagogue-outline",
                "icon": "mdi-synagogue-outline"
            },
            {
                "name": "sync",
                "icon": "mdi-sync"
            },
            {
                "name": "sync-alert",
                "icon": "mdi-sync-alert"
            },
            {
                "name": "sync-circle",
                "icon": "mdi-sync-circle"
            },
            {
                "name": "sync-off",
                "icon": "mdi-sync-off"
            },
            {
                "name": "tab",
                "icon": "mdi-tab"
            },
            {
                "name": "tab-minus",
                "icon": "mdi-tab-minus"
            },
            {
                "name": "tab-plus",
                "icon": "mdi-tab-plus"
            },
            {
                "name": "tab-remove",
                "icon": "mdi-tab-remove"
            },
            {
                "name": "tab-search",
                "icon": "mdi-tab-search"
            },
            {
                "name": "tab-unselected",
                "icon": "mdi-tab-unselected"
            },
            {
                "name": "table",
                "icon": "mdi-table"
            },
            {
                "name": "table-account",
                "icon": "mdi-table-account"
            },
            {
                "name": "table-alert",
                "icon": "mdi-table-alert"
            },
            {
                "name": "table-arrow-down",
                "icon": "mdi-table-arrow-down"
            },
            {
                "name": "table-arrow-left",
                "icon": "mdi-table-arrow-left"
            },
            {
                "name": "table-arrow-right",
                "icon": "mdi-table-arrow-right"
            },
            {
                "name": "table-arrow-up",
                "icon": "mdi-table-arrow-up"
            },
            {
                "name": "table-border",
                "icon": "mdi-table-border"
            },
            {
                "name": "table-cancel",
                "icon": "mdi-table-cancel"
            },
            {
                "name": "table-chair",
                "icon": "mdi-table-chair"
            },
            {
                "name": "table-check",
                "icon": "mdi-table-check"
            },
            {
                "name": "table-clock",
                "icon": "mdi-table-clock"
            },
            {
                "name": "table-cog",
                "icon": "mdi-table-cog"
            },
            {
                "name": "table-column",
                "icon": "mdi-table-column"
            },
            {
                "name": "table-column-plus-after",
                "icon": "mdi-table-column-plus-after"
            },
            {
                "name": "table-column-plus-before",
                "icon": "mdi-table-column-plus-before"
            },
            {
                "name": "table-column-remove",
                "icon": "mdi-table-column-remove"
            },
            {
                "name": "table-column-width",
                "icon": "mdi-table-column-width"
            },
            {
                "name": "table-edit",
                "icon": "mdi-table-edit"
            },
            {
                "name": "table-eye",
                "icon": "mdi-table-eye"
            },
            {
                "name": "table-eye-off",
                "icon": "mdi-table-eye-off"
            },
            {
                "name": "table-filter",
                "icon": "mdi-table-filter"
            },
            {
                "name": "table-furniture",
                "icon": "mdi-table-furniture"
            },
            {
                "name": "table-headers-eye",
                "icon": "mdi-table-headers-eye"
            },
            {
                "name": "table-headers-eye-off",
                "icon": "mdi-table-headers-eye-off"
            },
            {
                "name": "table-heart",
                "icon": "mdi-table-heart"
            },
            {
                "name": "table-key",
                "icon": "mdi-table-key"
            },
            {
                "name": "table-large",
                "icon": "mdi-table-large"
            },
            {
                "name": "table-large-plus",
                "icon": "mdi-table-large-plus"
            },
            {
                "name": "table-large-remove",
                "icon": "mdi-table-large-remove"
            },
            {
                "name": "table-lock",
                "icon": "mdi-table-lock"
            },
            {
                "name": "table-merge-cells",
                "icon": "mdi-table-merge-cells"
            },
            {
                "name": "table-minus",
                "icon": "mdi-table-minus"
            },
            {
                "name": "table-multiple",
                "icon": "mdi-table-multiple"
            },
            {
                "name": "table-network",
                "icon": "mdi-table-network"
            },
            {
                "name": "table-of-contents",
                "icon": "mdi-table-of-contents"
            },
            {
                "name": "table-off",
                "icon": "mdi-table-off"
            },
            {
                "name": "table-picnic",
                "icon": "mdi-table-picnic"
            },
            {
                "name": "table-pivot",
                "icon": "mdi-table-pivot"
            },
            {
                "name": "table-plus",
                "icon": "mdi-table-plus"
            },
            {
                "name": "table-question",
                "icon": "mdi-table-question"
            },
            {
                "name": "table-refresh",
                "icon": "mdi-table-refresh"
            },
            {
                "name": "table-remove",
                "icon": "mdi-table-remove"
            },
            {
                "name": "table-row",
                "icon": "mdi-table-row"
            },
            {
                "name": "table-row-height",
                "icon": "mdi-table-row-height"
            },
            {
                "name": "table-row-plus-after",
                "icon": "mdi-table-row-plus-after"
            },
            {
                "name": "table-row-plus-before",
                "icon": "mdi-table-row-plus-before"
            },
            {
                "name": "table-row-remove",
                "icon": "mdi-table-row-remove"
            },
            {
                "name": "table-search",
                "icon": "mdi-table-search"
            },
            {
                "name": "table-settings",
                "icon": "mdi-table-settings"
            },
            {
                "name": "table-split-cell",
                "icon": "mdi-table-split-cell"
            },
            {
                "name": "table-star",
                "icon": "mdi-table-star"
            },
            {
                "name": "table-sync",
                "icon": "mdi-table-sync"
            },
            {
                "name": "table-tennis",
                "icon": "mdi-table-tennis"
            },
            {
                "name": "tablet",
                "icon": "mdi-tablet"
            },
            {
                "name": "tablet-cellphone",
                "icon": "mdi-tablet-cellphone"
            },
            {
                "name": "tablet-dashboard",
                "icon": "mdi-tablet-dashboard"
            },
            {
                "name": "taco",
                "icon": "mdi-taco"
            },
            {
                "name": "tag",
                "icon": "mdi-tag"
            },
            {
                "name": "tag-arrow-down",
                "icon": "mdi-tag-arrow-down"
            },
            {
                "name": "tag-arrow-down-outline",
                "icon": "mdi-tag-arrow-down-outline"
            },
            {
                "name": "tag-arrow-left",
                "icon": "mdi-tag-arrow-left"
            },
            {
                "name": "tag-arrow-left-outline",
                "icon": "mdi-tag-arrow-left-outline"
            },
            {
                "name": "tag-arrow-right",
                "icon": "mdi-tag-arrow-right"
            },
            {
                "name": "tag-arrow-right-outline",
                "icon": "mdi-tag-arrow-right-outline"
            },
            {
                "name": "tag-arrow-up",
                "icon": "mdi-tag-arrow-up"
            },
            {
                "name": "tag-arrow-up-outline",
                "icon": "mdi-tag-arrow-up-outline"
            },
            {
                "name": "tag-check",
                "icon": "mdi-tag-check"
            },
            {
                "name": "tag-check-outline",
                "icon": "mdi-tag-check-outline"
            },
            {
                "name": "tag-faces",
                "icon": "mdi-tag-faces"
            },
            {
                "name": "tag-heart",
                "icon": "mdi-tag-heart"
            },
            {
                "name": "tag-heart-outline",
                "icon": "mdi-tag-heart-outline"
            },
            {
                "name": "tag-minus",
                "icon": "mdi-tag-minus"
            },
            {
                "name": "tag-minus-outline",
                "icon": "mdi-tag-minus-outline"
            },
            {
                "name": "tag-multiple",
                "icon": "mdi-tag-multiple"
            },
            {
                "name": "tag-multiple-outline",
                "icon": "mdi-tag-multiple-outline"
            },
            {
                "name": "tag-off",
                "icon": "mdi-tag-off"
            },
            {
                "name": "tag-off-outline",
                "icon": "mdi-tag-off-outline"
            },
            {
                "name": "tag-outline",
                "icon": "mdi-tag-outline"
            },
            {
                "name": "tag-plus",
                "icon": "mdi-tag-plus"
            },
            {
                "name": "tag-plus-outline",
                "icon": "mdi-tag-plus-outline"
            },
            {
                "name": "tag-remove",
                "icon": "mdi-tag-remove"
            },
            {
                "name": "tag-remove-outline",
                "icon": "mdi-tag-remove-outline"
            },
            {
                "name": "tag-search",
                "icon": "mdi-tag-search"
            },
            {
                "name": "tag-search-outline",
                "icon": "mdi-tag-search-outline"
            },
            {
                "name": "tag-text",
                "icon": "mdi-tag-text"
            },
            {
                "name": "tag-text-outline",
                "icon": "mdi-tag-text-outline"
            },
            {
                "name": "tailwind",
                "icon": "mdi-tailwind"
            },
            {
                "name": "tally-mark-1",
                "icon": "mdi-tally-mark-1"
            },
            {
                "name": "tally-mark-2",
                "icon": "mdi-tally-mark-2"
            },
            {
                "name": "tally-mark-3",
                "icon": "mdi-tally-mark-3"
            },
            {
                "name": "tally-mark-4",
                "icon": "mdi-tally-mark-4"
            },
            {
                "name": "tally-mark-5",
                "icon": "mdi-tally-mark-5"
            },
            {
                "name": "tangram",
                "icon": "mdi-tangram"
            },
            {
                "name": "tank",
                "icon": "mdi-tank"
            },
            {
                "name": "tanker-truck",
                "icon": "mdi-tanker-truck"
            },
            {
                "name": "tape-drive",
                "icon": "mdi-tape-drive"
            },
            {
                "name": "tape-measure",
                "icon": "mdi-tape-measure"
            },
            {
                "name": "target",
                "icon": "mdi-target"
            },
            {
                "name": "target-account",
                "icon": "mdi-target-account"
            },
            {
                "name": "target-variant",
                "icon": "mdi-target-variant"
            },
            {
                "name": "taxi",
                "icon": "mdi-taxi"
            },
            {
                "name": "tea",
                "icon": "mdi-tea"
            },
            {
                "name": "tea-outline",
                "icon": "mdi-tea-outline"
            },
            {
                "name": "teamviewer",
                "icon": "mdi-teamviewer"
            },
            {
                "name": "teddy-bear",
                "icon": "mdi-teddy-bear"
            },
            {
                "name": "telescope",
                "icon": "mdi-telescope"
            },
            {
                "name": "television",
                "icon": "mdi-television"
            },
            {
                "name": "television-ambient-light",
                "icon": "mdi-television-ambient-light"
            },
            {
                "name": "television-box",
                "icon": "mdi-television-box"
            },
            {
                "name": "television-classic",
                "icon": "mdi-television-classic"
            },
            {
                "name": "television-classic-off",
                "icon": "mdi-television-classic-off"
            },
            {
                "name": "television-guide",
                "icon": "mdi-television-guide"
            },
            {
                "name": "television-off",
                "icon": "mdi-television-off"
            },
            {
                "name": "television-pause",
                "icon": "mdi-television-pause"
            },
            {
                "name": "television-play",
                "icon": "mdi-television-play"
            },
            {
                "name": "television-shimmer",
                "icon": "mdi-television-shimmer"
            },
            {
                "name": "television-speaker",
                "icon": "mdi-television-speaker"
            },
            {
                "name": "television-speaker-off",
                "icon": "mdi-television-speaker-off"
            },
            {
                "name": "television-stop",
                "icon": "mdi-television-stop"
            },
            {
                "name": "temperature-celsius",
                "icon": "mdi-temperature-celsius"
            },
            {
                "name": "temperature-fahrenheit",
                "icon": "mdi-temperature-fahrenheit"
            },
            {
                "name": "temperature-kelvin",
                "icon": "mdi-temperature-kelvin"
            },
            {
                "name": "temple-buddhist",
                "icon": "mdi-temple-buddhist"
            },
            {
                "name": "temple-buddhist-outline",
                "icon": "mdi-temple-buddhist-outline"
            },
            {
                "name": "temple-hindu",
                "icon": "mdi-temple-hindu"
            },
            {
                "name": "temple-hindu-outline",
                "icon": "mdi-temple-hindu-outline"
            },
            {
                "name": "tennis",
                "icon": "mdi-tennis"
            },
            {
                "name": "tennis-ball",
                "icon": "mdi-tennis-ball"
            },
            {
                "name": "tent",
                "icon": "mdi-tent"
            },
            {
                "name": "terraform",
                "icon": "mdi-terraform"
            },
            {
                "name": "terrain",
                "icon": "mdi-terrain"
            },
            {
                "name": "test-tube",
                "icon": "mdi-test-tube"
            },
            {
                "name": "test-tube-empty",
                "icon": "mdi-test-tube-empty"
            },
            {
                "name": "test-tube-off",
                "icon": "mdi-test-tube-off"
            },
            {
                "name": "text",
                "icon": "mdi-text"
            },
            {
                "name": "text-account",
                "icon": "mdi-text-account"
            },
            {
                "name": "text-box",
                "icon": "mdi-text-box"
            },
            {
                "name": "text-box-check",
                "icon": "mdi-text-box-check"
            },
            {
                "name": "text-box-check-outline",
                "icon": "mdi-text-box-check-outline"
            },
            {
                "name": "text-box-edit",
                "icon": "mdi-text-box-edit"
            },
            {
                "name": "text-box-edit-outline",
                "icon": "mdi-text-box-edit-outline"
            },
            {
                "name": "text-box-minus",
                "icon": "mdi-text-box-minus"
            },
            {
                "name": "text-box-minus-outline",
                "icon": "mdi-text-box-minus-outline"
            },
            {
                "name": "text-box-multiple",
                "icon": "mdi-text-box-multiple"
            },
            {
                "name": "text-box-multiple-outline",
                "icon": "mdi-text-box-multiple-outline"
            },
            {
                "name": "text-box-outline",
                "icon": "mdi-text-box-outline"
            },
            {
                "name": "text-box-plus",
                "icon": "mdi-text-box-plus"
            },
            {
                "name": "text-box-plus-outline",
                "icon": "mdi-text-box-plus-outline"
            },
            {
                "name": "text-box-remove",
                "icon": "mdi-text-box-remove"
            },
            {
                "name": "text-box-remove-outline",
                "icon": "mdi-text-box-remove-outline"
            },
            {
                "name": "text-box-search",
                "icon": "mdi-text-box-search"
            },
            {
                "name": "text-box-search-outline",
                "icon": "mdi-text-box-search-outline"
            },
            {
                "name": "text-long",
                "icon": "mdi-text-long"
            },
            {
                "name": "text-recognition",
                "icon": "mdi-text-recognition"
            },
            {
                "name": "text-search",
                "icon": "mdi-text-search"
            },
            {
                "name": "text-search-variant",
                "icon": "mdi-text-search-variant"
            },
            {
                "name": "text-shadow",
                "icon": "mdi-text-shadow"
            },
            {
                "name": "text-short",
                "icon": "mdi-text-short"
            },
            {
                "name": "texture",
                "icon": "mdi-texture"
            },
            {
                "name": "texture-box",
                "icon": "mdi-texture-box"
            },
            {
                "name": "theater",
                "icon": "mdi-theater"
            },
            {
                "name": "theme-light-dark",
                "icon": "mdi-theme-light-dark"
            },
            {
                "name": "thermometer",
                "icon": "mdi-thermometer"
            },
            {
                "name": "thermometer-alert",
                "icon": "mdi-thermometer-alert"
            },
            {
                "name": "thermometer-auto",
                "icon": "mdi-thermometer-auto"
            },
            {
                "name": "thermometer-bluetooth",
                "icon": "mdi-thermometer-bluetooth"
            },
            {
                "name": "thermometer-check",
                "icon": "mdi-thermometer-check"
            },
            {
                "name": "thermometer-chevron-down",
                "icon": "mdi-thermometer-chevron-down"
            },
            {
                "name": "thermometer-chevron-up",
                "icon": "mdi-thermometer-chevron-up"
            },
            {
                "name": "thermometer-high",
                "icon": "mdi-thermometer-high"
            },
            {
                "name": "thermometer-lines",
                "icon": "mdi-thermometer-lines"
            },
            {
                "name": "thermometer-low",
                "icon": "mdi-thermometer-low"
            },
            {
                "name": "thermometer-minus",
                "icon": "mdi-thermometer-minus"
            },
            {
                "name": "thermometer-off",
                "icon": "mdi-thermometer-off"
            },
            {
                "name": "thermometer-plus",
                "icon": "mdi-thermometer-plus"
            },
            {
                "name": "thermometer-probe",
                "icon": "mdi-thermometer-probe"
            },
            {
                "name": "thermometer-probe-off",
                "icon": "mdi-thermometer-probe-off"
            },
            {
                "name": "thermometer-water",
                "icon": "mdi-thermometer-water"
            },
            {
                "name": "thermostat",
                "icon": "mdi-thermostat"
            },
            {
                "name": "thermostat-auto",
                "icon": "mdi-thermostat-auto"
            },
            {
                "name": "thermostat-box",
                "icon": "mdi-thermostat-box"
            },
            {
                "name": "thermostat-box-auto",
                "icon": "mdi-thermostat-box-auto"
            },
            {
                "name": "thought-bubble",
                "icon": "mdi-thought-bubble"
            },
            {
                "name": "thought-bubble-outline",
                "icon": "mdi-thought-bubble-outline"
            },
            {
                "name": "thumb-down",
                "icon": "mdi-thumb-down"
            },
            {
                "name": "thumb-down-outline",
                "icon": "mdi-thumb-down-outline"
            },
            {
                "name": "thumb-up",
                "icon": "mdi-thumb-up"
            },
            {
                "name": "thumb-up-outline",
                "icon": "mdi-thumb-up-outline"
            },
            {
                "name": "thumbs-up-down",
                "icon": "mdi-thumbs-up-down"
            },
            {
                "name": "thumbs-up-down-outline",
                "icon": "mdi-thumbs-up-down-outline"
            },
            {
                "name": "ticket",
                "icon": "mdi-ticket"
            },
            {
                "name": "ticket-account",
                "icon": "mdi-ticket-account"
            },
            {
                "name": "ticket-confirmation",
                "icon": "mdi-ticket-confirmation"
            },
            {
                "name": "ticket-confirmation-outline",
                "icon": "mdi-ticket-confirmation-outline"
            },
            {
                "name": "ticket-outline",
                "icon": "mdi-ticket-outline"
            },
            {
                "name": "ticket-percent",
                "icon": "mdi-ticket-percent"
            },
            {
                "name": "ticket-percent-outline",
                "icon": "mdi-ticket-percent-outline"
            },
            {
                "name": "tie",
                "icon": "mdi-tie"
            },
            {
                "name": "tilde",
                "icon": "mdi-tilde"
            },
            {
                "name": "tilde-off",
                "icon": "mdi-tilde-off"
            },
            {
                "name": "timelapse",
                "icon": "mdi-timelapse"
            },
            {
                "name": "timeline",
                "icon": "mdi-timeline"
            },
            {
                "name": "timeline-alert",
                "icon": "mdi-timeline-alert"
            },
            {
                "name": "timeline-alert-outline",
                "icon": "mdi-timeline-alert-outline"
            },
            {
                "name": "timeline-check",
                "icon": "mdi-timeline-check"
            },
            {
                "name": "timeline-check-outline",
                "icon": "mdi-timeline-check-outline"
            },
            {
                "name": "timeline-clock",
                "icon": "mdi-timeline-clock"
            },
            {
                "name": "timeline-clock-outline",
                "icon": "mdi-timeline-clock-outline"
            },
            {
                "name": "timeline-minus",
                "icon": "mdi-timeline-minus"
            },
            {
                "name": "timeline-minus-outline",
                "icon": "mdi-timeline-minus-outline"
            },
            {
                "name": "timeline-outline",
                "icon": "mdi-timeline-outline"
            },
            {
                "name": "timeline-plus",
                "icon": "mdi-timeline-plus"
            },
            {
                "name": "timeline-plus-outline",
                "icon": "mdi-timeline-plus-outline"
            },
            {
                "name": "timeline-question",
                "icon": "mdi-timeline-question"
            },
            {
                "name": "timeline-question-outline",
                "icon": "mdi-timeline-question-outline"
            },
            {
                "name": "timeline-remove",
                "icon": "mdi-timeline-remove"
            },
            {
                "name": "timeline-remove-outline",
                "icon": "mdi-timeline-remove-outline"
            },
            {
                "name": "timeline-text",
                "icon": "mdi-timeline-text"
            },
            {
                "name": "timeline-text-outline",
                "icon": "mdi-timeline-text-outline"
            },
            {
                "name": "timer",
                "icon": "mdi-timer"
            },
            {
                "name": "timer-10",
                "icon": "mdi-timer-10"
            },
            {
                "name": "timer-3",
                "icon": "mdi-timer-3"
            },
            {
                "name": "timer-alert",
                "icon": "mdi-timer-alert"
            },
            {
                "name": "timer-alert-outline",
                "icon": "mdi-timer-alert-outline"
            },
            {
                "name": "timer-cancel",
                "icon": "mdi-timer-cancel"
            },
            {
                "name": "timer-cancel-outline",
                "icon": "mdi-timer-cancel-outline"
            },
            {
                "name": "timer-check",
                "icon": "mdi-timer-check"
            },
            {
                "name": "timer-check-outline",
                "icon": "mdi-timer-check-outline"
            },
            {
                "name": "timer-cog",
                "icon": "mdi-timer-cog"
            },
            {
                "name": "timer-cog-outline",
                "icon": "mdi-timer-cog-outline"
            },
            {
                "name": "timer-edit",
                "icon": "mdi-timer-edit"
            },
            {
                "name": "timer-edit-outline",
                "icon": "mdi-timer-edit-outline"
            },
            {
                "name": "timer-lock",
                "icon": "mdi-timer-lock"
            },
            {
                "name": "timer-lock-open",
                "icon": "mdi-timer-lock-open"
            },
            {
                "name": "timer-lock-open-outline",
                "icon": "mdi-timer-lock-open-outline"
            },
            {
                "name": "timer-lock-outline",
                "icon": "mdi-timer-lock-outline"
            },
            {
                "name": "timer-marker",
                "icon": "mdi-timer-marker"
            },
            {
                "name": "timer-marker-outline",
                "icon": "mdi-timer-marker-outline"
            },
            {
                "name": "timer-minus",
                "icon": "mdi-timer-minus"
            },
            {
                "name": "timer-minus-outline",
                "icon": "mdi-timer-minus-outline"
            },
            {
                "name": "timer-music",
                "icon": "mdi-timer-music"
            },
            {
                "name": "timer-music-outline",
                "icon": "mdi-timer-music-outline"
            },
            {
                "name": "timer-off",
                "icon": "mdi-timer-off"
            },
            {
                "name": "timer-off-outline",
                "icon": "mdi-timer-off-outline"
            },
            {
                "name": "timer-outline",
                "icon": "mdi-timer-outline"
            },
            {
                "name": "timer-pause",
                "icon": "mdi-timer-pause"
            },
            {
                "name": "timer-pause-outline",
                "icon": "mdi-timer-pause-outline"
            },
            {
                "name": "timer-play",
                "icon": "mdi-timer-play"
            },
            {
                "name": "timer-play-outline",
                "icon": "mdi-timer-play-outline"
            },
            {
                "name": "timer-plus",
                "icon": "mdi-timer-plus"
            },
            {
                "name": "timer-plus-outline",
                "icon": "mdi-timer-plus-outline"
            },
            {
                "name": "timer-refresh",
                "icon": "mdi-timer-refresh"
            },
            {
                "name": "timer-refresh-outline",
                "icon": "mdi-timer-refresh-outline"
            },
            {
                "name": "timer-remove",
                "icon": "mdi-timer-remove"
            },
            {
                "name": "timer-remove-outline",
                "icon": "mdi-timer-remove-outline"
            },
            {
                "name": "timer-sand",
                "icon": "mdi-timer-sand"
            },
            {
                "name": "timer-sand-complete",
                "icon": "mdi-timer-sand-complete"
            },
            {
                "name": "timer-sand-empty",
                "icon": "mdi-timer-sand-empty"
            },
            {
                "name": "timer-sand-full",
                "icon": "mdi-timer-sand-full"
            },
            {
                "name": "timer-sand-paused",
                "icon": "mdi-timer-sand-paused"
            },
            {
                "name": "timer-settings",
                "icon": "mdi-timer-settings"
            },
            {
                "name": "timer-settings-outline",
                "icon": "mdi-timer-settings-outline"
            },
            {
                "name": "timer-star",
                "icon": "mdi-timer-star"
            },
            {
                "name": "timer-star-outline",
                "icon": "mdi-timer-star-outline"
            },
            {
                "name": "timer-stop",
                "icon": "mdi-timer-stop"
            },
            {
                "name": "timer-stop-outline",
                "icon": "mdi-timer-stop-outline"
            },
            {
                "name": "timer-sync",
                "icon": "mdi-timer-sync"
            },
            {
                "name": "timer-sync-outline",
                "icon": "mdi-timer-sync-outline"
            },
            {
                "name": "timetable",
                "icon": "mdi-timetable"
            },
            {
                "name": "tire",
                "icon": "mdi-tire"
            },
            {
                "name": "toaster",
                "icon": "mdi-toaster"
            },
            {
                "name": "toaster-off",
                "icon": "mdi-toaster-off"
            },
            {
                "name": "toaster-oven",
                "icon": "mdi-toaster-oven"
            },
            {
                "name": "toggle-switch",
                "icon": "mdi-toggle-switch"
            },
            {
                "name": "toggle-switch-off",
                "icon": "mdi-toggle-switch-off"
            },
            {
                "name": "toggle-switch-off-outline",
                "icon": "mdi-toggle-switch-off-outline"
            },
            {
                "name": "toggle-switch-outline",
                "icon": "mdi-toggle-switch-outline"
            },
            {
                "name": "toggle-switch-variant",
                "icon": "mdi-toggle-switch-variant"
            },
            {
                "name": "toggle-switch-variant-off",
                "icon": "mdi-toggle-switch-variant-off"
            },
            {
                "name": "toilet",
                "icon": "mdi-toilet"
            },
            {
                "name": "toolbox",
                "icon": "mdi-toolbox"
            },
            {
                "name": "toolbox-outline",
                "icon": "mdi-toolbox-outline"
            },
            {
                "name": "tools",
                "icon": "mdi-tools"
            },
            {
                "name": "tooltip",
                "icon": "mdi-tooltip"
            },
            {
                "name": "tooltip-account",
                "icon": "mdi-tooltip-account"
            },
            {
                "name": "tooltip-cellphone",
                "icon": "mdi-tooltip-cellphone"
            },
            {
                "name": "tooltip-check",
                "icon": "mdi-tooltip-check"
            },
            {
                "name": "tooltip-check-outline",
                "icon": "mdi-tooltip-check-outline"
            },
            {
                "name": "tooltip-edit",
                "icon": "mdi-tooltip-edit"
            },
            {
                "name": "tooltip-edit-outline",
                "icon": "mdi-tooltip-edit-outline"
            },
            {
                "name": "tooltip-image",
                "icon": "mdi-tooltip-image"
            },
            {
                "name": "tooltip-image-outline",
                "icon": "mdi-tooltip-image-outline"
            },
            {
                "name": "tooltip-minus",
                "icon": "mdi-tooltip-minus"
            },
            {
                "name": "tooltip-minus-outline",
                "icon": "mdi-tooltip-minus-outline"
            },
            {
                "name": "tooltip-outline",
                "icon": "mdi-tooltip-outline"
            },
            {
                "name": "tooltip-plus",
                "icon": "mdi-tooltip-plus"
            },
            {
                "name": "tooltip-plus-outline",
                "icon": "mdi-tooltip-plus-outline"
            },
            {
                "name": "tooltip-question",
                "icon": "mdi-tooltip-question"
            },
            {
                "name": "tooltip-question-outline",
                "icon": "mdi-tooltip-question-outline"
            },
            {
                "name": "tooltip-remove",
                "icon": "mdi-tooltip-remove"
            },
            {
                "name": "tooltip-remove-outline",
                "icon": "mdi-tooltip-remove-outline"
            },
            {
                "name": "tooltip-text",
                "icon": "mdi-tooltip-text"
            },
            {
                "name": "tooltip-text-outline",
                "icon": "mdi-tooltip-text-outline"
            },
            {
                "name": "tooth",
                "icon": "mdi-tooth"
            },
            {
                "name": "tooth-outline",
                "icon": "mdi-tooth-outline"
            },
            {
                "name": "toothbrush",
                "icon": "mdi-toothbrush"
            },
            {
                "name": "toothbrush-electric",
                "icon": "mdi-toothbrush-electric"
            },
            {
                "name": "toothbrush-paste",
                "icon": "mdi-toothbrush-paste"
            },
            {
                "name": "torch",
                "icon": "mdi-torch"
            },
            {
                "name": "tortoise",
                "icon": "mdi-tortoise"
            },
            {
                "name": "toslink",
                "icon": "mdi-toslink"
            },
            {
                "name": "tournament",
                "icon": "mdi-tournament"
            },
            {
                "name": "tow-truck",
                "icon": "mdi-tow-truck"
            },
            {
                "name": "tower-beach",
                "icon": "mdi-tower-beach"
            },
            {
                "name": "tower-fire",
                "icon": "mdi-tower-fire"
            },
            {
                "name": "town-hall",
                "icon": "mdi-town-hall"
            },
            {
                "name": "toy-brick",
                "icon": "mdi-toy-brick"
            },
            {
                "name": "toy-brick-marker",
                "icon": "mdi-toy-brick-marker"
            },
            {
                "name": "toy-brick-marker-outline",
                "icon": "mdi-toy-brick-marker-outline"
            },
            {
                "name": "toy-brick-minus",
                "icon": "mdi-toy-brick-minus"
            },
            {
                "name": "toy-brick-minus-outline",
                "icon": "mdi-toy-brick-minus-outline"
            },
            {
                "name": "toy-brick-outline",
                "icon": "mdi-toy-brick-outline"
            },
            {
                "name": "toy-brick-plus",
                "icon": "mdi-toy-brick-plus"
            },
            {
                "name": "toy-brick-plus-outline",
                "icon": "mdi-toy-brick-plus-outline"
            },
            {
                "name": "toy-brick-remove",
                "icon": "mdi-toy-brick-remove"
            },
            {
                "name": "toy-brick-remove-outline",
                "icon": "mdi-toy-brick-remove-outline"
            },
            {
                "name": "toy-brick-search",
                "icon": "mdi-toy-brick-search"
            },
            {
                "name": "toy-brick-search-outline",
                "icon": "mdi-toy-brick-search-outline"
            },
            {
                "name": "track-light",
                "icon": "mdi-track-light"
            },
            {
                "name": "track-light-off",
                "icon": "mdi-track-light-off"
            },
            {
                "name": "trackpad",
                "icon": "mdi-trackpad"
            },
            {
                "name": "trackpad-lock",
                "icon": "mdi-trackpad-lock"
            },
            {
                "name": "tractor",
                "icon": "mdi-tractor"
            },
            {
                "name": "tractor-variant",
                "icon": "mdi-tractor-variant"
            },
            {
                "name": "trademark",
                "icon": "mdi-trademark"
            },
            {
                "name": "traffic-cone",
                "icon": "mdi-traffic-cone"
            },
            {
                "name": "traffic-light",
                "icon": "mdi-traffic-light"
            },
            {
                "name": "traffic-light-outline",
                "icon": "mdi-traffic-light-outline"
            },
            {
                "name": "train",
                "icon": "mdi-train"
            },
            {
                "name": "train-car",
                "icon": "mdi-train-car"
            },
            {
                "name": "train-car-autorack",
                "icon": "mdi-train-car-autorack"
            },
            {
                "name": "train-car-box",
                "icon": "mdi-train-car-box"
            },
            {
                "name": "train-car-box-full",
                "icon": "mdi-train-car-box-full"
            },
            {
                "name": "train-car-box-open",
                "icon": "mdi-train-car-box-open"
            },
            {
                "name": "train-car-caboose",
                "icon": "mdi-train-car-caboose"
            },
            {
                "name": "train-car-centerbeam",
                "icon": "mdi-train-car-centerbeam"
            },
            {
                "name": "train-car-centerbeam-full",
                "icon": "mdi-train-car-centerbeam-full"
            },
            {
                "name": "train-car-container",
                "icon": "mdi-train-car-container"
            },
            {
                "name": "train-car-flatbed",
                "icon": "mdi-train-car-flatbed"
            },
            {
                "name": "train-car-flatbed-car",
                "icon": "mdi-train-car-flatbed-car"
            },
            {
                "name": "train-car-flatbed-tank",
                "icon": "mdi-train-car-flatbed-tank"
            },
            {
                "name": "train-car-gondola",
                "icon": "mdi-train-car-gondola"
            },
            {
                "name": "train-car-gondola-full",
                "icon": "mdi-train-car-gondola-full"
            },
            {
                "name": "train-car-hopper",
                "icon": "mdi-train-car-hopper"
            },
            {
                "name": "train-car-hopper-covered",
                "icon": "mdi-train-car-hopper-covered"
            },
            {
                "name": "train-car-hopper-full",
                "icon": "mdi-train-car-hopper-full"
            },
            {
                "name": "train-car-intermodal",
                "icon": "mdi-train-car-intermodal"
            },
            {
                "name": "train-car-passenger",
                "icon": "mdi-train-car-passenger"
            },
            {
                "name": "train-car-passenger-door",
                "icon": "mdi-train-car-passenger-door"
            },
            {
                "name": "train-car-passenger-door-open",
                "icon": "mdi-train-car-passenger-door-open"
            },
            {
                "name": "train-car-passenger-variant",
                "icon": "mdi-train-car-passenger-variant"
            },
            {
                "name": "train-car-tank",
                "icon": "mdi-train-car-tank"
            },
            {
                "name": "train-variant",
                "icon": "mdi-train-variant"
            },
            {
                "name": "tram",
                "icon": "mdi-tram"
            },
            {
                "name": "tram-side",
                "icon": "mdi-tram-side"
            },
            {
                "name": "transcribe",
                "icon": "mdi-transcribe"
            },
            {
                "name": "transcribe-close",
                "icon": "mdi-transcribe-close"
            },
            {
                "name": "transfer",
                "icon": "mdi-transfer"
            },
            {
                "name": "transfer-down",
                "icon": "mdi-transfer-down"
            },
            {
                "name": "transfer-left",
                "icon": "mdi-transfer-left"
            },
            {
                "name": "transfer-right",
                "icon": "mdi-transfer-right"
            },
            {
                "name": "transfer-up",
                "icon": "mdi-transfer-up"
            },
            {
                "name": "transit-connection",
                "icon": "mdi-transit-connection"
            },
            {
                "name": "transit-connection-horizontal",
                "icon": "mdi-transit-connection-horizontal"
            },
            {
                "name": "transit-connection-variant",
                "icon": "mdi-transit-connection-variant"
            },
            {
                "name": "transit-detour",
                "icon": "mdi-transit-detour"
            },
            {
                "name": "transit-skip",
                "icon": "mdi-transit-skip"
            },
            {
                "name": "transit-transfer",
                "icon": "mdi-transit-transfer"
            },
            {
                "name": "transition",
                "icon": "mdi-transition"
            },
            {
                "name": "transition-masked",
                "icon": "mdi-transition-masked"
            },
            {
                "name": "translate",
                "icon": "mdi-translate"
            },
            {
                "name": "translate-off",
                "icon": "mdi-translate-off"
            },
            {
                "name": "translate-variant",
                "icon": "mdi-translate-variant"
            },
            {
                "name": "transmission-tower",
                "icon": "mdi-transmission-tower"
            },
            {
                "name": "transmission-tower-export",
                "icon": "mdi-transmission-tower-export"
            },
            {
                "name": "transmission-tower-import",
                "icon": "mdi-transmission-tower-import"
            },
            {
                "name": "transmission-tower-off",
                "icon": "mdi-transmission-tower-off"
            },
            {
                "name": "trash-can",
                "icon": "mdi-trash-can"
            },
            {
                "name": "trash-can-outline",
                "icon": "mdi-trash-can-outline"
            },
            {
                "name": "tray",
                "icon": "mdi-tray"
            },
            {
                "name": "tray-alert",
                "icon": "mdi-tray-alert"
            },
            {
                "name": "tray-arrow-down",
                "icon": "mdi-tray-arrow-down"
            },
            {
                "name": "tray-arrow-up",
                "icon": "mdi-tray-arrow-up"
            },
            {
                "name": "tray-full",
                "icon": "mdi-tray-full"
            },
            {
                "name": "tray-minus",
                "icon": "mdi-tray-minus"
            },
            {
                "name": "tray-plus",
                "icon": "mdi-tray-plus"
            },
            {
                "name": "tray-remove",
                "icon": "mdi-tray-remove"
            },
            {
                "name": "treasure-chest",
                "icon": "mdi-treasure-chest"
            },
            {
                "name": "tree",
                "icon": "mdi-tree"
            },
            {
                "name": "tree-outline",
                "icon": "mdi-tree-outline"
            },
            {
                "name": "trello",
                "icon": "mdi-trello"
            },
            {
                "name": "trending-down",
                "icon": "mdi-trending-down"
            },
            {
                "name": "trending-neutral",
                "icon": "mdi-trending-neutral"
            },
            {
                "name": "trending-up",
                "icon": "mdi-trending-up"
            },
            {
                "name": "triangle",
                "icon": "mdi-triangle"
            },
            {
                "name": "triangle-outline",
                "icon": "mdi-triangle-outline"
            },
            {
                "name": "triangle-small-down",
                "icon": "mdi-triangle-small-down"
            },
            {
                "name": "triangle-small-up",
                "icon": "mdi-triangle-small-up"
            },
            {
                "name": "triangle-wave",
                "icon": "mdi-triangle-wave"
            },
            {
                "name": "triforce",
                "icon": "mdi-triforce"
            },
            {
                "name": "trophy",
                "icon": "mdi-trophy"
            },
            {
                "name": "trophy-award",
                "icon": "mdi-trophy-award"
            },
            {
                "name": "trophy-broken",
                "icon": "mdi-trophy-broken"
            },
            {
                "name": "trophy-outline",
                "icon": "mdi-trophy-outline"
            },
            {
                "name": "trophy-variant",
                "icon": "mdi-trophy-variant"
            },
            {
                "name": "trophy-variant-outline",
                "icon": "mdi-trophy-variant-outline"
            },
            {
                "name": "truck",
                "icon": "mdi-truck"
            },
            {
                "name": "truck-alert",
                "icon": "mdi-truck-alert"
            },
            {
                "name": "truck-alert-outline",
                "icon": "mdi-truck-alert-outline"
            },
            {
                "name": "truck-cargo-container",
                "icon": "mdi-truck-cargo-container"
            },
            {
                "name": "truck-check",
                "icon": "mdi-truck-check"
            },
            {
                "name": "truck-check-outline",
                "icon": "mdi-truck-check-outline"
            },
            {
                "name": "truck-delivery",
                "icon": "mdi-truck-delivery"
            },
            {
                "name": "truck-delivery-outline",
                "icon": "mdi-truck-delivery-outline"
            },
            {
                "name": "truck-fast",
                "icon": "mdi-truck-fast"
            },
            {
                "name": "truck-fast-outline",
                "icon": "mdi-truck-fast-outline"
            },
            {
                "name": "truck-flatbed",
                "icon": "mdi-truck-flatbed"
            },
            {
                "name": "truck-minus",
                "icon": "mdi-truck-minus"
            },
            {
                "name": "truck-minus-outline",
                "icon": "mdi-truck-minus-outline"
            },
            {
                "name": "truck-outline",
                "icon": "mdi-truck-outline"
            },
            {
                "name": "truck-plus",
                "icon": "mdi-truck-plus"
            },
            {
                "name": "truck-plus-outline",
                "icon": "mdi-truck-plus-outline"
            },
            {
                "name": "truck-remove",
                "icon": "mdi-truck-remove"
            },
            {
                "name": "truck-remove-outline",
                "icon": "mdi-truck-remove-outline"
            },
            {
                "name": "truck-snowflake",
                "icon": "mdi-truck-snowflake"
            },
            {
                "name": "truck-trailer",
                "icon": "mdi-truck-trailer"
            },
            {
                "name": "trumpet",
                "icon": "mdi-trumpet"
            },
            {
                "name": "tshirt-crew",
                "icon": "mdi-tshirt-crew"
            },
            {
                "name": "tshirt-crew-outline",
                "icon": "mdi-tshirt-crew-outline"
            },
            {
                "name": "tshirt-v",
                "icon": "mdi-tshirt-v"
            },
            {
                "name": "tshirt-v-outline",
                "icon": "mdi-tshirt-v-outline"
            },
            {
                "name": "tsunami",
                "icon": "mdi-tsunami"
            },
            {
                "name": "tumble-dryer",
                "icon": "mdi-tumble-dryer"
            },
            {
                "name": "tumble-dryer-alert",
                "icon": "mdi-tumble-dryer-alert"
            },
            {
                "name": "tumble-dryer-off",
                "icon": "mdi-tumble-dryer-off"
            },
            {
                "name": "tune",
                "icon": "mdi-tune"
            },
            {
                "name": "tune-variant",
                "icon": "mdi-tune-variant"
            },
            {
                "name": "tune-vertical",
                "icon": "mdi-tune-vertical"
            },
            {
                "name": "tune-vertical-variant",
                "icon": "mdi-tune-vertical-variant"
            },
            {
                "name": "tunnel",
                "icon": "mdi-tunnel"
            },
            {
                "name": "tunnel-outline",
                "icon": "mdi-tunnel-outline"
            },
            {
                "name": "turbine",
                "icon": "mdi-turbine"
            },
            {
                "name": "turkey",
                "icon": "mdi-turkey"
            },
            {
                "name": "turnstile",
                "icon": "mdi-turnstile"
            },
            {
                "name": "turnstile-outline",
                "icon": "mdi-turnstile-outline"
            },
            {
                "name": "turtle",
                "icon": "mdi-turtle"
            },
            {
                "name": "twitch",
                "icon": "mdi-twitch"
            },
            {
                "name": "twitter",
                "icon": "mdi-twitter"
            },
            {
                "name": "two-factor-authentication",
                "icon": "mdi-two-factor-authentication"
            },
            {
                "name": "typewriter",
                "icon": "mdi-typewriter"
            },
            {
                "name": "ubisoft",
                "icon": "mdi-ubisoft"
            },
            {
                "name": "ubuntu",
                "icon": "mdi-ubuntu"
            },
            {
                "name": "ufo",
                "icon": "mdi-ufo"
            },
            {
                "name": "ufo-outline",
                "icon": "mdi-ufo-outline"
            },
            {
                "name": "ultra-high-definition",
                "icon": "mdi-ultra-high-definition"
            },
            {
                "name": "umbraco",
                "icon": "mdi-umbraco"
            },
            {
                "name": "umbrella",
                "icon": "mdi-umbrella"
            },
            {
                "name": "umbrella-beach",
                "icon": "mdi-umbrella-beach"
            },
            {
                "name": "umbrella-beach-outline",
                "icon": "mdi-umbrella-beach-outline"
            },
            {
                "name": "umbrella-closed",
                "icon": "mdi-umbrella-closed"
            },
            {
                "name": "umbrella-closed-outline",
                "icon": "mdi-umbrella-closed-outline"
            },
            {
                "name": "umbrella-closed-variant",
                "icon": "mdi-umbrella-closed-variant"
            },
            {
                "name": "umbrella-outline",
                "icon": "mdi-umbrella-outline"
            },
            {
                "name": "undo",
                "icon": "mdi-undo"
            },
            {
                "name": "undo-variant",
                "icon": "mdi-undo-variant"
            },
            {
                "name": "unfold-less-horizontal",
                "icon": "mdi-unfold-less-horizontal"
            },
            {
                "name": "unfold-less-vertical",
                "icon": "mdi-unfold-less-vertical"
            },
            {
                "name": "unfold-more-horizontal",
                "icon": "mdi-unfold-more-horizontal"
            },
            {
                "name": "unfold-more-vertical",
                "icon": "mdi-unfold-more-vertical"
            },
            {
                "name": "ungroup",
                "icon": "mdi-ungroup"
            },
            {
                "name": "unicode",
                "icon": "mdi-unicode"
            },
            {
                "name": "unicorn",
                "icon": "mdi-unicorn"
            },
            {
                "name": "unicorn-variant",
                "icon": "mdi-unicorn-variant"
            },
            {
                "name": "unicycle",
                "icon": "mdi-unicycle"
            },
            {
                "name": "unity",
                "icon": "mdi-unity"
            },
            {
                "name": "unreal",
                "icon": "mdi-unreal"
            },
            {
                "name": "update",
                "icon": "mdi-update"
            },
            {
                "name": "upload",
                "icon": "mdi-upload"
            },
            {
                "name": "upload-lock",
                "icon": "mdi-upload-lock"
            },
            {
                "name": "upload-lock-outline",
                "icon": "mdi-upload-lock-outline"
            },
            {
                "name": "upload-multiple",
                "icon": "mdi-upload-multiple"
            },
            {
                "name": "upload-network",
                "icon": "mdi-upload-network"
            },
            {
                "name": "upload-network-outline",
                "icon": "mdi-upload-network-outline"
            },
            {
                "name": "upload-off",
                "icon": "mdi-upload-off"
            },
            {
                "name": "upload-off-outline",
                "icon": "mdi-upload-off-outline"
            },
            {
                "name": "upload-outline",
                "icon": "mdi-upload-outline"
            },
            {
                "name": "usb",
                "icon": "mdi-usb"
            },
            {
                "name": "usb-flash-drive",
                "icon": "mdi-usb-flash-drive"
            },
            {
                "name": "usb-flash-drive-outline",
                "icon": "mdi-usb-flash-drive-outline"
            },
            {
                "name": "usb-port",
                "icon": "mdi-usb-port"
            },
            {
                "name": "vacuum",
                "icon": "mdi-vacuum"
            },
            {
                "name": "vacuum-outline",
                "icon": "mdi-vacuum-outline"
            },
            {
                "name": "valve",
                "icon": "mdi-valve"
            },
            {
                "name": "valve-closed",
                "icon": "mdi-valve-closed"
            },
            {
                "name": "valve-open",
                "icon": "mdi-valve-open"
            },
            {
                "name": "van-passenger",
                "icon": "mdi-van-passenger"
            },
            {
                "name": "van-utility",
                "icon": "mdi-van-utility"
            },
            {
                "name": "vanish",
                "icon": "mdi-vanish"
            },
            {
                "name": "vanish-quarter",
                "icon": "mdi-vanish-quarter"
            },
            {
                "name": "vanity-light",
                "icon": "mdi-vanity-light"
            },
            {
                "name": "variable",
                "icon": "mdi-variable"
            },
            {
                "name": "variable-box",
                "icon": "mdi-variable-box"
            },
            {
                "name": "vector-arrange-above",
                "icon": "mdi-vector-arrange-above"
            },
            {
                "name": "vector-arrange-below",
                "icon": "mdi-vector-arrange-below"
            },
            {
                "name": "vector-bezier",
                "icon": "mdi-vector-bezier"
            },
            {
                "name": "vector-circle",
                "icon": "mdi-vector-circle"
            },
            {
                "name": "vector-circle-variant",
                "icon": "mdi-vector-circle-variant"
            },
            {
                "name": "vector-combine",
                "icon": "mdi-vector-combine"
            },
            {
                "name": "vector-curve",
                "icon": "mdi-vector-curve"
            },
            {
                "name": "vector-difference",
                "icon": "mdi-vector-difference"
            },
            {
                "name": "vector-difference-ab",
                "icon": "mdi-vector-difference-ab"
            },
            {
                "name": "vector-difference-ba",
                "icon": "mdi-vector-difference-ba"
            },
            {
                "name": "vector-ellipse",
                "icon": "mdi-vector-ellipse"
            },
            {
                "name": "vector-intersection",
                "icon": "mdi-vector-intersection"
            },
            {
                "name": "vector-line",
                "icon": "mdi-vector-line"
            },
            {
                "name": "vector-link",
                "icon": "mdi-vector-link"
            },
            {
                "name": "vector-point",
                "icon": "mdi-vector-point"
            },
            {
                "name": "vector-point-edit",
                "icon": "mdi-vector-point-edit"
            },
            {
                "name": "vector-point-minus",
                "icon": "mdi-vector-point-minus"
            },
            {
                "name": "vector-point-plus",
                "icon": "mdi-vector-point-plus"
            },
            {
                "name": "vector-point-select",
                "icon": "mdi-vector-point-select"
            },
            {
                "name": "vector-polygon",
                "icon": "mdi-vector-polygon"
            },
            {
                "name": "vector-polygon-variant",
                "icon": "mdi-vector-polygon-variant"
            },
            {
                "name": "vector-polyline",
                "icon": "mdi-vector-polyline"
            },
            {
                "name": "vector-polyline-edit",
                "icon": "mdi-vector-polyline-edit"
            },
            {
                "name": "vector-polyline-minus",
                "icon": "mdi-vector-polyline-minus"
            },
            {
                "name": "vector-polyline-plus",
                "icon": "mdi-vector-polyline-plus"
            },
            {
                "name": "vector-polyline-remove",
                "icon": "mdi-vector-polyline-remove"
            },
            {
                "name": "vector-radius",
                "icon": "mdi-vector-radius"
            },
            {
                "name": "vector-rectangle",
                "icon": "mdi-vector-rectangle"
            },
            {
                "name": "vector-selection",
                "icon": "mdi-vector-selection"
            },
            {
                "name": "vector-square",
                "icon": "mdi-vector-square"
            },
            {
                "name": "vector-square-close",
                "icon": "mdi-vector-square-close"
            },
            {
                "name": "vector-square-edit",
                "icon": "mdi-vector-square-edit"
            },
            {
                "name": "vector-square-minus",
                "icon": "mdi-vector-square-minus"
            },
            {
                "name": "vector-square-open",
                "icon": "mdi-vector-square-open"
            },
            {
                "name": "vector-square-plus",
                "icon": "mdi-vector-square-plus"
            },
            {
                "name": "vector-square-remove",
                "icon": "mdi-vector-square-remove"
            },
            {
                "name": "vector-triangle",
                "icon": "mdi-vector-triangle"
            },
            {
                "name": "vector-union",
                "icon": "mdi-vector-union"
            },
            {
                "name": "vhs",
                "icon": "mdi-vhs"
            },
            {
                "name": "vibrate",
                "icon": "mdi-vibrate"
            },
            {
                "name": "vibrate-off",
                "icon": "mdi-vibrate-off"
            },
            {
                "name": "video",
                "icon": "mdi-video"
            },
            {
                "name": "video-2d",
                "icon": "mdi-video-2d"
            },
            {
                "name": "video-3d",
                "icon": "mdi-video-3d"
            },
            {
                "name": "video-3d-off",
                "icon": "mdi-video-3d-off"
            },
            {
                "name": "video-3d-variant",
                "icon": "mdi-video-3d-variant"
            },
            {
                "name": "video-4k-box",
                "icon": "mdi-video-4k-box"
            },
            {
                "name": "video-account",
                "icon": "mdi-video-account"
            },
            {
                "name": "video-box",
                "icon": "mdi-video-box"
            },
            {
                "name": "video-box-off",
                "icon": "mdi-video-box-off"
            },
            {
                "name": "video-check",
                "icon": "mdi-video-check"
            },
            {
                "name": "video-check-outline",
                "icon": "mdi-video-check-outline"
            },
            {
                "name": "video-high-definition",
                "icon": "mdi-video-high-definition"
            },
            {
                "name": "video-image",
                "icon": "mdi-video-image"
            },
            {
                "name": "video-input-antenna",
                "icon": "mdi-video-input-antenna"
            },
            {
                "name": "video-input-component",
                "icon": "mdi-video-input-component"
            },
            {
                "name": "video-input-hdmi",
                "icon": "mdi-video-input-hdmi"
            },
            {
                "name": "video-input-scart",
                "icon": "mdi-video-input-scart"
            },
            {
                "name": "video-input-svideo",
                "icon": "mdi-video-input-svideo"
            },
            {
                "name": "video-marker",
                "icon": "mdi-video-marker"
            },
            {
                "name": "video-marker-outline",
                "icon": "mdi-video-marker-outline"
            },
            {
                "name": "video-minus",
                "icon": "mdi-video-minus"
            },
            {
                "name": "video-minus-outline",
                "icon": "mdi-video-minus-outline"
            },
            {
                "name": "video-off",
                "icon": "mdi-video-off"
            },
            {
                "name": "video-off-outline",
                "icon": "mdi-video-off-outline"
            },
            {
                "name": "video-outline",
                "icon": "mdi-video-outline"
            },
            {
                "name": "video-plus",
                "icon": "mdi-video-plus"
            },
            {
                "name": "video-plus-outline",
                "icon": "mdi-video-plus-outline"
            },
            {
                "name": "video-stabilization",
                "icon": "mdi-video-stabilization"
            },
            {
                "name": "video-switch",
                "icon": "mdi-video-switch"
            },
            {
                "name": "video-switch-outline",
                "icon": "mdi-video-switch-outline"
            },
            {
                "name": "video-vintage",
                "icon": "mdi-video-vintage"
            },
            {
                "name": "video-wireless",
                "icon": "mdi-video-wireless"
            },
            {
                "name": "video-wireless-outline",
                "icon": "mdi-video-wireless-outline"
            },
            {
                "name": "view-agenda",
                "icon": "mdi-view-agenda"
            },
            {
                "name": "view-agenda-outline",
                "icon": "mdi-view-agenda-outline"
            },
            {
                "name": "view-array",
                "icon": "mdi-view-array"
            },
            {
                "name": "view-array-outline",
                "icon": "mdi-view-array-outline"
            },
            {
                "name": "view-carousel",
                "icon": "mdi-view-carousel"
            },
            {
                "name": "view-carousel-outline",
                "icon": "mdi-view-carousel-outline"
            },
            {
                "name": "view-column",
                "icon": "mdi-view-column"
            },
            {
                "name": "view-column-outline",
                "icon": "mdi-view-column-outline"
            },
            {
                "name": "view-comfy",
                "icon": "mdi-view-comfy"
            },
            {
                "name": "view-comfy-outline",
                "icon": "mdi-view-comfy-outline"
            },
            {
                "name": "view-compact",
                "icon": "mdi-view-compact"
            },
            {
                "name": "view-compact-outline",
                "icon": "mdi-view-compact-outline"
            },
            {
                "name": "view-dashboard",
                "icon": "mdi-view-dashboard"
            },
            {
                "name": "view-dashboard-edit",
                "icon": "mdi-view-dashboard-edit"
            },
            {
                "name": "view-dashboard-edit-outline",
                "icon": "mdi-view-dashboard-edit-outline"
            },
            {
                "name": "view-dashboard-outline",
                "icon": "mdi-view-dashboard-outline"
            },
            {
                "name": "view-dashboard-variant",
                "icon": "mdi-view-dashboard-variant"
            },
            {
                "name": "view-dashboard-variant-outline",
                "icon": "mdi-view-dashboard-variant-outline"
            },
            {
                "name": "view-day",
                "icon": "mdi-view-day"
            },
            {
                "name": "view-day-outline",
                "icon": "mdi-view-day-outline"
            },
            {
                "name": "view-gallery",
                "icon": "mdi-view-gallery"
            },
            {
                "name": "view-gallery-outline",
                "icon": "mdi-view-gallery-outline"
            },
            {
                "name": "view-grid",
                "icon": "mdi-view-grid"
            },
            {
                "name": "view-grid-outline",
                "icon": "mdi-view-grid-outline"
            },
            {
                "name": "view-grid-plus",
                "icon": "mdi-view-grid-plus"
            },
            {
                "name": "view-grid-plus-outline",
                "icon": "mdi-view-grid-plus-outline"
            },
            {
                "name": "view-headline",
                "icon": "mdi-view-headline"
            },
            {
                "name": "view-list",
                "icon": "mdi-view-list"
            },
            {
                "name": "view-list-outline",
                "icon": "mdi-view-list-outline"
            },
            {
                "name": "view-module",
                "icon": "mdi-view-module"
            },
            {
                "name": "view-module-outline",
                "icon": "mdi-view-module-outline"
            },
            {
                "name": "view-parallel",
                "icon": "mdi-view-parallel"
            },
            {
                "name": "view-parallel-outline",
                "icon": "mdi-view-parallel-outline"
            },
            {
                "name": "view-quilt",
                "icon": "mdi-view-quilt"
            },
            {
                "name": "view-quilt-outline",
                "icon": "mdi-view-quilt-outline"
            },
            {
                "name": "view-sequential",
                "icon": "mdi-view-sequential"
            },
            {
                "name": "view-sequential-outline",
                "icon": "mdi-view-sequential-outline"
            },
            {
                "name": "view-split-horizontal",
                "icon": "mdi-view-split-horizontal"
            },
            {
                "name": "view-split-vertical",
                "icon": "mdi-view-split-vertical"
            },
            {
                "name": "view-stream",
                "icon": "mdi-view-stream"
            },
            {
                "name": "view-stream-outline",
                "icon": "mdi-view-stream-outline"
            },
            {
                "name": "view-week",
                "icon": "mdi-view-week"
            },
            {
                "name": "view-week-outline",
                "icon": "mdi-view-week-outline"
            },
            {
                "name": "vimeo",
                "icon": "mdi-vimeo"
            },
            {
                "name": "violin",
                "icon": "mdi-violin"
            },
            {
                "name": "virtual-reality",
                "icon": "mdi-virtual-reality"
            },
            {
                "name": "virus",
                "icon": "mdi-virus"
            },
            {
                "name": "virus-off",
                "icon": "mdi-virus-off"
            },
            {
                "name": "virus-off-outline",
                "icon": "mdi-virus-off-outline"
            },
            {
                "name": "virus-outline",
                "icon": "mdi-virus-outline"
            },
            {
                "name": "vlc",
                "icon": "mdi-vlc"
            },
            {
                "name": "voicemail",
                "icon": "mdi-voicemail"
            },
            {
                "name": "volcano",
                "icon": "mdi-volcano"
            },
            {
                "name": "volcano-outline",
                "icon": "mdi-volcano-outline"
            },
            {
                "name": "volleyball",
                "icon": "mdi-volleyball"
            },
            {
                "name": "volume-equal",
                "icon": "mdi-volume-equal"
            },
            {
                "name": "volume-high",
                "icon": "mdi-volume-high"
            },
            {
                "name": "volume-low",
                "icon": "mdi-volume-low"
            },
            {
                "name": "volume-medium",
                "icon": "mdi-volume-medium"
            },
            {
                "name": "volume-minus",
                "icon": "mdi-volume-minus"
            },
            {
                "name": "volume-mute",
                "icon": "mdi-volume-mute"
            },
            {
                "name": "volume-off",
                "icon": "mdi-volume-off"
            },
            {
                "name": "volume-plus",
                "icon": "mdi-volume-plus"
            },
            {
                "name": "volume-source",
                "icon": "mdi-volume-source"
            },
            {
                "name": "volume-variant-off",
                "icon": "mdi-volume-variant-off"
            },
            {
                "name": "volume-vibrate",
                "icon": "mdi-volume-vibrate"
            },
            {
                "name": "vote",
                "icon": "mdi-vote"
            },
            {
                "name": "vote-outline",
                "icon": "mdi-vote-outline"
            },
            {
                "name": "vpn",
                "icon": "mdi-vpn"
            },
            {
                "name": "vuejs",
                "icon": "mdi-vuejs"
            },
            {
                "name": "vuetify",
                "icon": "mdi-vuetify"
            },
            {
                "name": "walk",
                "icon": "mdi-walk"
            },
            {
                "name": "wall",
                "icon": "mdi-wall"
            },
            {
                "name": "wall-fire",
                "icon": "mdi-wall-fire"
            },
            {
                "name": "wall-sconce",
                "icon": "mdi-wall-sconce"
            },
            {
                "name": "wall-sconce-flat",
                "icon": "mdi-wall-sconce-flat"
            },
            {
                "name": "wall-sconce-flat-outline",
                "icon": "mdi-wall-sconce-flat-outline"
            },
            {
                "name": "wall-sconce-flat-variant",
                "icon": "mdi-wall-sconce-flat-variant"
            },
            {
                "name": "wall-sconce-flat-variant-outline",
                "icon": "mdi-wall-sconce-flat-variant-outline"
            },
            {
                "name": "wall-sconce-outline",
                "icon": "mdi-wall-sconce-outline"
            },
            {
                "name": "wall-sconce-round",
                "icon": "mdi-wall-sconce-round"
            },
            {
                "name": "wall-sconce-round-outline",
                "icon": "mdi-wall-sconce-round-outline"
            },
            {
                "name": "wall-sconce-round-variant",
                "icon": "mdi-wall-sconce-round-variant"
            },
            {
                "name": "wall-sconce-round-variant-outline",
                "icon": "mdi-wall-sconce-round-variant-outline"
            },
            {
                "name": "wallet",
                "icon": "mdi-wallet"
            },
            {
                "name": "wallet-giftcard",
                "icon": "mdi-wallet-giftcard"
            },
            {
                "name": "wallet-membership",
                "icon": "mdi-wallet-membership"
            },
            {
                "name": "wallet-outline",
                "icon": "mdi-wallet-outline"
            },
            {
                "name": "wallet-plus",
                "icon": "mdi-wallet-plus"
            },
            {
                "name": "wallet-plus-outline",
                "icon": "mdi-wallet-plus-outline"
            },
            {
                "name": "wallet-travel",
                "icon": "mdi-wallet-travel"
            },
            {
                "name": "wallpaper",
                "icon": "mdi-wallpaper"
            },
            {
                "name": "wan",
                "icon": "mdi-wan"
            },
            {
                "name": "wardrobe",
                "icon": "mdi-wardrobe"
            },
            {
                "name": "wardrobe-outline",
                "icon": "mdi-wardrobe-outline"
            },
            {
                "name": "warehouse",
                "icon": "mdi-warehouse"
            },
            {
                "name": "washing-machine",
                "icon": "mdi-washing-machine"
            },
            {
                "name": "washing-machine-alert",
                "icon": "mdi-washing-machine-alert"
            },
            {
                "name": "washing-machine-off",
                "icon": "mdi-washing-machine-off"
            },
            {
                "name": "watch",
                "icon": "mdi-watch"
            },
            {
                "name": "watch-export",
                "icon": "mdi-watch-export"
            },
            {
                "name": "watch-export-variant",
                "icon": "mdi-watch-export-variant"
            },
            {
                "name": "watch-import",
                "icon": "mdi-watch-import"
            },
            {
                "name": "watch-import-variant",
                "icon": "mdi-watch-import-variant"
            },
            {
                "name": "watch-variant",
                "icon": "mdi-watch-variant"
            },
            {
                "name": "watch-vibrate",
                "icon": "mdi-watch-vibrate"
            },
            {
                "name": "watch-vibrate-off",
                "icon": "mdi-watch-vibrate-off"
            },
            {
                "name": "water",
                "icon": "mdi-water"
            },
            {
                "name": "water-alert",
                "icon": "mdi-water-alert"
            },
            {
                "name": "water-alert-outline",
                "icon": "mdi-water-alert-outline"
            },
            {
                "name": "water-boiler",
                "icon": "mdi-water-boiler"
            },
            {
                "name": "water-boiler-alert",
                "icon": "mdi-water-boiler-alert"
            },
            {
                "name": "water-boiler-auto",
                "icon": "mdi-water-boiler-auto"
            },
            {
                "name": "water-boiler-off",
                "icon": "mdi-water-boiler-off"
            },
            {
                "name": "water-check",
                "icon": "mdi-water-check"
            },
            {
                "name": "water-check-outline",
                "icon": "mdi-water-check-outline"
            },
            {
                "name": "water-circle",
                "icon": "mdi-water-circle"
            },
            {
                "name": "water-minus",
                "icon": "mdi-water-minus"
            },
            {
                "name": "water-minus-outline",
                "icon": "mdi-water-minus-outline"
            },
            {
                "name": "water-off",
                "icon": "mdi-water-off"
            },
            {
                "name": "water-off-outline",
                "icon": "mdi-water-off-outline"
            },
            {
                "name": "water-opacity",
                "icon": "mdi-water-opacity"
            },
            {
                "name": "water-outline",
                "icon": "mdi-water-outline"
            },
            {
                "name": "water-percent",
                "icon": "mdi-water-percent"
            },
            {
                "name": "water-percent-alert",
                "icon": "mdi-water-percent-alert"
            },
            {
                "name": "water-plus",
                "icon": "mdi-water-plus"
            },
            {
                "name": "water-plus-outline",
                "icon": "mdi-water-plus-outline"
            },
            {
                "name": "water-polo",
                "icon": "mdi-water-polo"
            },
            {
                "name": "water-pump",
                "icon": "mdi-water-pump"
            },
            {
                "name": "water-pump-off",
                "icon": "mdi-water-pump-off"
            },
            {
                "name": "water-remove",
                "icon": "mdi-water-remove"
            },
            {
                "name": "water-remove-outline",
                "icon": "mdi-water-remove-outline"
            },
            {
                "name": "water-sync",
                "icon": "mdi-water-sync"
            },
            {
                "name": "water-thermometer",
                "icon": "mdi-water-thermometer"
            },
            {
                "name": "water-thermometer-outline",
                "icon": "mdi-water-thermometer-outline"
            },
            {
                "name": "water-well",
                "icon": "mdi-water-well"
            },
            {
                "name": "water-well-outline",
                "icon": "mdi-water-well-outline"
            },
            {
                "name": "waterfall",
                "icon": "mdi-waterfall"
            },
            {
                "name": "watering-can",
                "icon": "mdi-watering-can"
            },
            {
                "name": "watering-can-outline",
                "icon": "mdi-watering-can-outline"
            },
            {
                "name": "watermark",
                "icon": "mdi-watermark"
            },
            {
                "name": "wave",
                "icon": "mdi-wave"
            },
            {
                "name": "waveform",
                "icon": "mdi-waveform"
            },
            {
                "name": "waves",
                "icon": "mdi-waves"
            },
            {
                "name": "waves-arrow-left",
                "icon": "mdi-waves-arrow-left"
            },
            {
                "name": "waves-arrow-right",
                "icon": "mdi-waves-arrow-right"
            },
            {
                "name": "waves-arrow-up",
                "icon": "mdi-waves-arrow-up"
            },
            {
                "name": "waze",
                "icon": "mdi-waze"
            },
            {
                "name": "weather-cloudy",
                "icon": "mdi-weather-cloudy"
            },
            {
                "name": "weather-cloudy-alert",
                "icon": "mdi-weather-cloudy-alert"
            },
            {
                "name": "weather-cloudy-arrow-right",
                "icon": "mdi-weather-cloudy-arrow-right"
            },
            {
                "name": "weather-cloudy-clock",
                "icon": "mdi-weather-cloudy-clock"
            },
            {
                "name": "weather-dust",
                "icon": "mdi-weather-dust"
            },
            {
                "name": "weather-fog",
                "icon": "mdi-weather-fog"
            },
            {
                "name": "weather-hail",
                "icon": "mdi-weather-hail"
            },
            {
                "name": "weather-hazy",
                "icon": "mdi-weather-hazy"
            },
            {
                "name": "weather-hurricane",
                "icon": "mdi-weather-hurricane"
            },
            {
                "name": "weather-lightning",
                "icon": "mdi-weather-lightning"
            },
            {
                "name": "weather-lightning-rainy",
                "icon": "mdi-weather-lightning-rainy"
            },
            {
                "name": "weather-night",
                "icon": "mdi-weather-night"
            },
            {
                "name": "weather-night-partly-cloudy",
                "icon": "mdi-weather-night-partly-cloudy"
            },
            {
                "name": "weather-partly-cloudy",
                "icon": "mdi-weather-partly-cloudy"
            },
            {
                "name": "weather-partly-lightning",
                "icon": "mdi-weather-partly-lightning"
            },
            {
                "name": "weather-partly-rainy",
                "icon": "mdi-weather-partly-rainy"
            },
            {
                "name": "weather-partly-snowy",
                "icon": "mdi-weather-partly-snowy"
            },
            {
                "name": "weather-partly-snowy-rainy",
                "icon": "mdi-weather-partly-snowy-rainy"
            },
            {
                "name": "weather-pouring",
                "icon": "mdi-weather-pouring"
            },
            {
                "name": "weather-rainy",
                "icon": "mdi-weather-rainy"
            },
            {
                "name": "weather-snowy",
                "icon": "mdi-weather-snowy"
            },
            {
                "name": "weather-snowy-heavy",
                "icon": "mdi-weather-snowy-heavy"
            },
            {
                "name": "weather-snowy-rainy",
                "icon": "mdi-weather-snowy-rainy"
            },
            {
                "name": "weather-sunny",
                "icon": "mdi-weather-sunny"
            },
            {
                "name": "weather-sunny-alert",
                "icon": "mdi-weather-sunny-alert"
            },
            {
                "name": "weather-sunny-off",
                "icon": "mdi-weather-sunny-off"
            },
            {
                "name": "weather-sunset",
                "icon": "mdi-weather-sunset"
            },
            {
                "name": "weather-sunset-down",
                "icon": "mdi-weather-sunset-down"
            },
            {
                "name": "weather-sunset-up",
                "icon": "mdi-weather-sunset-up"
            },
            {
                "name": "weather-tornado",
                "icon": "mdi-weather-tornado"
            },
            {
                "name": "weather-windy",
                "icon": "mdi-weather-windy"
            },
            {
                "name": "weather-windy-variant",
                "icon": "mdi-weather-windy-variant"
            },
            {
                "name": "web",
                "icon": "mdi-web"
            },
            {
                "name": "web-box",
                "icon": "mdi-web-box"
            },
            {
                "name": "web-cancel",
                "icon": "mdi-web-cancel"
            },
            {
                "name": "web-check",
                "icon": "mdi-web-check"
            },
            {
                "name": "web-clock",
                "icon": "mdi-web-clock"
            },
            {
                "name": "web-minus",
                "icon": "mdi-web-minus"
            },
            {
                "name": "web-off",
                "icon": "mdi-web-off"
            },
            {
                "name": "web-plus",
                "icon": "mdi-web-plus"
            },
            {
                "name": "web-refresh",
                "icon": "mdi-web-refresh"
            },
            {
                "name": "web-remove",
                "icon": "mdi-web-remove"
            },
            {
                "name": "web-sync",
                "icon": "mdi-web-sync"
            },
            {
                "name": "webcam",
                "icon": "mdi-webcam"
            },
            {
                "name": "webcam-off",
                "icon": "mdi-webcam-off"
            },
            {
                "name": "webhook",
                "icon": "mdi-webhook"
            },
            {
                "name": "webpack",
                "icon": "mdi-webpack"
            },
            {
                "name": "webrtc",
                "icon": "mdi-webrtc"
            },
            {
                "name": "wechat",
                "icon": "mdi-wechat"
            },
            {
                "name": "weight",
                "icon": "mdi-weight"
            },
            {
                "name": "weight-gram",
                "icon": "mdi-weight-gram"
            },
            {
                "name": "weight-kilogram",
                "icon": "mdi-weight-kilogram"
            },
            {
                "name": "weight-lifter",
                "icon": "mdi-weight-lifter"
            },
            {
                "name": "weight-pound",
                "icon": "mdi-weight-pound"
            },
            {
                "name": "whatsapp",
                "icon": "mdi-whatsapp"
            },
            {
                "name": "wheel-barrow",
                "icon": "mdi-wheel-barrow"
            },
            {
                "name": "wheelchair",
                "icon": "mdi-wheelchair"
            },
            {
                "name": "wheelchair-accessibility",
                "icon": "mdi-wheelchair-accessibility"
            },
            {
                "name": "whistle",
                "icon": "mdi-whistle"
            },
            {
                "name": "whistle-outline",
                "icon": "mdi-whistle-outline"
            },
            {
                "name": "white-balance-auto",
                "icon": "mdi-white-balance-auto"
            },
            {
                "name": "white-balance-incandescent",
                "icon": "mdi-white-balance-incandescent"
            },
            {
                "name": "white-balance-iridescent",
                "icon": "mdi-white-balance-iridescent"
            },
            {
                "name": "white-balance-sunny",
                "icon": "mdi-white-balance-sunny"
            },
            {
                "name": "widgets",
                "icon": "mdi-widgets"
            },
            {
                "name": "widgets-outline",
                "icon": "mdi-widgets-outline"
            },
            {
                "name": "wifi",
                "icon": "mdi-wifi"
            },
            {
                "name": "wifi-alert",
                "icon": "mdi-wifi-alert"
            },
            {
                "name": "wifi-arrow-down",
                "icon": "mdi-wifi-arrow-down"
            },
            {
                "name": "wifi-arrow-left",
                "icon": "mdi-wifi-arrow-left"
            },
            {
                "name": "wifi-arrow-left-right",
                "icon": "mdi-wifi-arrow-left-right"
            },
            {
                "name": "wifi-arrow-right",
                "icon": "mdi-wifi-arrow-right"
            },
            {
                "name": "wifi-arrow-up",
                "icon": "mdi-wifi-arrow-up"
            },
            {
                "name": "wifi-arrow-up-down",
                "icon": "mdi-wifi-arrow-up-down"
            },
            {
                "name": "wifi-cancel",
                "icon": "mdi-wifi-cancel"
            },
            {
                "name": "wifi-check",
                "icon": "mdi-wifi-check"
            },
            {
                "name": "wifi-cog",
                "icon": "mdi-wifi-cog"
            },
            {
                "name": "wifi-lock",
                "icon": "mdi-wifi-lock"
            },
            {
                "name": "wifi-lock-open",
                "icon": "mdi-wifi-lock-open"
            },
            {
                "name": "wifi-marker",
                "icon": "mdi-wifi-marker"
            },
            {
                "name": "wifi-minus",
                "icon": "mdi-wifi-minus"
            },
            {
                "name": "wifi-off",
                "icon": "mdi-wifi-off"
            },
            {
                "name": "wifi-plus",
                "icon": "mdi-wifi-plus"
            },
            {
                "name": "wifi-refresh",
                "icon": "mdi-wifi-refresh"
            },
            {
                "name": "wifi-remove",
                "icon": "mdi-wifi-remove"
            },
            {
                "name": "wifi-settings",
                "icon": "mdi-wifi-settings"
            },
            {
                "name": "wifi-star",
                "icon": "mdi-wifi-star"
            },
            {
                "name": "wifi-strength-1",
                "icon": "mdi-wifi-strength-1"
            },
            {
                "name": "wifi-strength-1-alert",
                "icon": "mdi-wifi-strength-1-alert"
            },
            {
                "name": "wifi-strength-1-lock",
                "icon": "mdi-wifi-strength-1-lock"
            },
            {
                "name": "wifi-strength-1-lock-open",
                "icon": "mdi-wifi-strength-1-lock-open"
            },
            {
                "name": "wifi-strength-2",
                "icon": "mdi-wifi-strength-2"
            },
            {
                "name": "wifi-strength-2-alert",
                "icon": "mdi-wifi-strength-2-alert"
            },
            {
                "name": "wifi-strength-2-lock",
                "icon": "mdi-wifi-strength-2-lock"
            },
            {
                "name": "wifi-strength-2-lock-open",
                "icon": "mdi-wifi-strength-2-lock-open"
            },
            {
                "name": "wifi-strength-3",
                "icon": "mdi-wifi-strength-3"
            },
            {
                "name": "wifi-strength-3-alert",
                "icon": "mdi-wifi-strength-3-alert"
            },
            {
                "name": "wifi-strength-3-lock",
                "icon": "mdi-wifi-strength-3-lock"
            },
            {
                "name": "wifi-strength-3-lock-open",
                "icon": "mdi-wifi-strength-3-lock-open"
            },
            {
                "name": "wifi-strength-4",
                "icon": "mdi-wifi-strength-4"
            },
            {
                "name": "wifi-strength-4-alert",
                "icon": "mdi-wifi-strength-4-alert"
            },
            {
                "name": "wifi-strength-4-lock",
                "icon": "mdi-wifi-strength-4-lock"
            },
            {
                "name": "wifi-strength-4-lock-open",
                "icon": "mdi-wifi-strength-4-lock-open"
            },
            {
                "name": "wifi-strength-alert-outline",
                "icon": "mdi-wifi-strength-alert-outline"
            },
            {
                "name": "wifi-strength-lock-open-outline",
                "icon": "mdi-wifi-strength-lock-open-outline"
            },
            {
                "name": "wifi-strength-lock-outline",
                "icon": "mdi-wifi-strength-lock-outline"
            },
            {
                "name": "wifi-strength-off",
                "icon": "mdi-wifi-strength-off"
            },
            {
                "name": "wifi-strength-off-outline",
                "icon": "mdi-wifi-strength-off-outline"
            },
            {
                "name": "wifi-strength-outline",
                "icon": "mdi-wifi-strength-outline"
            },
            {
                "name": "wifi-sync",
                "icon": "mdi-wifi-sync"
            },
            {
                "name": "wikipedia",
                "icon": "mdi-wikipedia"
            },
            {
                "name": "wind-power",
                "icon": "mdi-wind-power"
            },
            {
                "name": "wind-power-outline",
                "icon": "mdi-wind-power-outline"
            },
            {
                "name": "wind-turbine",
                "icon": "mdi-wind-turbine"
            },
            {
                "name": "wind-turbine-alert",
                "icon": "mdi-wind-turbine-alert"
            },
            {
                "name": "wind-turbine-check",
                "icon": "mdi-wind-turbine-check"
            },
            {
                "name": "window-close",
                "icon": "mdi-window-close"
            },
            {
                "name": "window-closed",
                "icon": "mdi-window-closed"
            },
            {
                "name": "window-closed-variant",
                "icon": "mdi-window-closed-variant"
            },
            {
                "name": "window-maximize",
                "icon": "mdi-window-maximize"
            },
            {
                "name": "window-minimize",
                "icon": "mdi-window-minimize"
            },
            {
                "name": "window-open",
                "icon": "mdi-window-open"
            },
            {
                "name": "window-open-variant",
                "icon": "mdi-window-open-variant"
            },
            {
                "name": "window-restore",
                "icon": "mdi-window-restore"
            },
            {
                "name": "window-shutter",
                "icon": "mdi-window-shutter"
            },
            {
                "name": "window-shutter-alert",
                "icon": "mdi-window-shutter-alert"
            },
            {
                "name": "window-shutter-auto",
                "icon": "mdi-window-shutter-auto"
            },
            {
                "name": "window-shutter-cog",
                "icon": "mdi-window-shutter-cog"
            },
            {
                "name": "window-shutter-open",
                "icon": "mdi-window-shutter-open"
            },
            {
                "name": "window-shutter-settings",
                "icon": "mdi-window-shutter-settings"
            },
            {
                "name": "windsock",
                "icon": "mdi-windsock"
            },
            {
                "name": "wiper",
                "icon": "mdi-wiper"
            },
            {
                "name": "wiper-wash",
                "icon": "mdi-wiper-wash"
            },
            {
                "name": "wiper-wash-alert",
                "icon": "mdi-wiper-wash-alert"
            },
            {
                "name": "wizard-hat",
                "icon": "mdi-wizard-hat"
            },
            {
                "name": "wordpress",
                "icon": "mdi-wordpress"
            },
            {
                "name": "wrap",
                "icon": "mdi-wrap"
            },
            {
                "name": "wrap-disabled",
                "icon": "mdi-wrap-disabled"
            },
            {
                "name": "wrench",
                "icon": "mdi-wrench"
            },
            {
                "name": "wrench-check",
                "icon": "mdi-wrench-check"
            },
            {
                "name": "wrench-check-outline",
                "icon": "mdi-wrench-check-outline"
            },
            {
                "name": "wrench-clock",
                "icon": "mdi-wrench-clock"
            },
            {
                "name": "wrench-clock-outline",
                "icon": "mdi-wrench-clock-outline"
            },
            {
                "name": "wrench-cog",
                "icon": "mdi-wrench-cog"
            },
            {
                "name": "wrench-cog-outline",
                "icon": "mdi-wrench-cog-outline"
            },
            {
                "name": "wrench-outline",
                "icon": "mdi-wrench-outline"
            },
            {
                "name": "xamarin",
                "icon": "mdi-xamarin"
            },
            {
                "name": "xml",
                "icon": "mdi-xml"
            },
            {
                "name": "xmpp",
                "icon": "mdi-xmpp"
            },
            {
                "name": "yahoo",
                "icon": "mdi-yahoo"
            },
            {
                "name": "yeast",
                "icon": "mdi-yeast"
            },
            {
                "name": "yin-yang",
                "icon": "mdi-yin-yang"
            },
            {
                "name": "yoga",
                "icon": "mdi-yoga"
            },
            {
                "name": "youtube",
                "icon": "mdi-youtube"
            },
            {
                "name": "youtube-gaming",
                "icon": "mdi-youtube-gaming"
            },
            {
                "name": "youtube-studio",
                "icon": "mdi-youtube-studio"
            },
            {
                "name": "youtube-subscription",
                "icon": "mdi-youtube-subscription"
            },
            {
                "name": "youtube-tv",
                "icon": "mdi-youtube-tv"
            },
            {
                "name": "yurt",
                "icon": "mdi-yurt"
            },
            {
                "name": "z-wave",
                "icon": "mdi-z-wave"
            },
            {
                "name": "zend",
                "icon": "mdi-zend"
            },
            {
                "name": "zigbee",
                "icon": "mdi-zigbee"
            },
            {
                "name": "zip-box",
                "icon": "mdi-zip-box"
            },
            {
                "name": "zip-box-outline",
                "icon": "mdi-zip-box-outline"
            },
            {
                "name": "zip-disk",
                "icon": "mdi-zip-disk"
            },
            {
                "name": "zodiac-aquarius",
                "icon": "mdi-zodiac-aquarius"
            },
            {
                "name": "zodiac-aries",
                "icon": "mdi-zodiac-aries"
            },
            {
                "name": "zodiac-cancer",
                "icon": "mdi-zodiac-cancer"
            },
            {
                "name": "zodiac-capricorn",
                "icon": "mdi-zodiac-capricorn"
            },
            {
                "name": "zodiac-gemini",
                "icon": "mdi-zodiac-gemini"
            },
            {
                "name": "zodiac-leo",
                "icon": "mdi-zodiac-leo"
            },
            {
                "name": "zodiac-libra",
                "icon": "mdi-zodiac-libra"
            },
            {
                "name": "zodiac-pisces",
                "icon": "mdi-zodiac-pisces"
            },
            {
                "name": "zodiac-sagittarius",
                "icon": "mdi-zodiac-sagittarius"
            },
            {
                "name": "zodiac-scorpio",
                "icon": "mdi-zodiac-scorpio"
            },
            {
                "name": "zodiac-taurus",
                "icon": "mdi-zodiac-taurus"
            },
            {
                "name": "zodiac-virgo",
                "icon": "mdi-zodiac-virgo"
            }
        ]
    }
}